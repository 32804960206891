import "./index.scss";

import React from "react";

export class InputTextarea extends React.Component {
   render() {
      return <textarea
                  name={this.props.name}
                  id={this.props.id}
                  className="mnh--teatarea"
                  value={this.props.value}
                  onChange={this.props.onChange}
            />;
   }
}

export default InputTextarea;
