import { FC, PropsWithChildren } from "react";
import "./tag.css";

interface Props {
  closable: boolean;
  onClose?: () => void;
}

export const Tag: FC<PropsWithChildren<Props>> = ({
  closable,
  children,
  onClose,
}) => {
  return (
    <div className="mn-tag" onClick={onClose}>
      <span>{children}</span>
      {closable && (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0834 3.73917L10.2609 2.91667L7.00008 6.17751L3.73925 2.91667L2.91675 3.73917L6.17758 7.00001L2.91675 10.2608L3.73925 11.0833L7.00008 7.82251L10.2609 11.0833L11.0834 10.2608L7.82258 7.00001L11.0834 3.73917Z"
            fill="#0064C3"
          />
        </svg>
      )}
    </div>
  );
};
