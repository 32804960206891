import "./index.scss";

import React from "react";

import Checkbox from "../../inputs/checkbox";
import InputText from "../../inputs/text";
import Select from "../../inputs/select";

const numberValidation = (str) => {
   const string = [...str];
   let result = "";
   string.forEach(char=>{
      if ("0123456789".indexOf(char) > -1) {
         result += char;
      }
   });
   return result
}

const types = {text: "Текст", number: "Число"};

export class PatientCodeResearches extends React.Component {

   constructor (props) {
      super(props);

      this.state = {
         patientCode: true,
         placeholder: "",
         type: "text",
         isLimit: false,
         limitFrom: "0",
         limitTo: "999",
         note: ""      
      };

      if (props.data) {
         this.state = {
            patientCode: !!props.data.patientCode,
            placeholder: props.data.placeholder ? props.data.placeholder : "",
            type: props.data.type ? props.data.type : "text",
            isLimit: !!props.data.isLimit,
            limitFrom: props.data.limitFrom ? props.data.limitFrom : "0",
            limitTo: props.data.limitTo ? props.data.limitTo : "999",
            note: props.data.note ? props.data.note : ""
         }
      }
   }

   onChange = () => {
      this.props.onChange(this.state);
   }

   onPatientCodeChange = (e) => {
      const stateChange = {patientCode: e.target.checked};
      if (stateChange.patientCode === false) {
         stateChange.placeholder = "";
         stateChange.type = "text";
         stateChange.isLimit = false;
         stateChange.limitFrom = "0";
         stateChange.limitTo = "999";
      }
      this.setState(stateChange, this.onChange);
   }

   onPlaceholderTextChange = (e) => {
      this.setState({placeholder: e.target.value}, this.onChange);
   }

   onNoteChange = (e) => {
      this.setState({note: e.target.value}, this.onChange);
   }

   onTypeChange = (type) => {
      const stateChange = {type};
      if (type === "text") {
         stateChange.isLimit = false;
         stateChange.limitFrom = "0";
         stateChange.limitTo = "999";
      }
      this.setState(stateChange, this.onChange);
   }

   onIsLimitChange = (e) => {
      const stateChange = {isLimit: e.target.checked};
      if (!e.target.checked) {
         stateChange.limitFrom = "0";
         stateChange.limitTo = "999";
      }
      this.setState(stateChange, this.onChange);
   }

   onLimitFromChange = (e) => {
      if (Number(e.target.value) > Number(this.state.limitTo)) {
         return
      }
      this.setState({limitFrom: numberValidation(e.target.value)}, this.onChange);
   }

   onLimitToChange = (e) => {
      this.setState({limitTo: numberValidation(e.target.value)}, this.onChange);
   }

   render() {
      return (
         <div className="patientcode--researches">
            <div className="patientcode--researches--card">
               <ul className="patientcode--researches--form">
                  <li className="patientcode--researches--form--item">
                     <div className="item--text semi__bold">
                        <Checkbox value={this.state.patientCode} onChange={this.onPatientCodeChange}>Код пациента</Checkbox>
                     </div>
                  </li>
                  {
                     this.state.patientCode && (
                        <>
                           <li className="new--researches--form--item">
                              <div className="item--text semi__bold">
                                 <span>Заполнитель</span>
                              </div>
                              <InputText value={this.state.placeholder} onChange={this.onPlaceholderTextChange} name="placeholder" placeholder="Заполнитель" style={{width: "25%"}} />
                           </li>
                           <li className="new--researches--form--item">
                              <div className="item--text semi__bold">
                                 <span>Примечание</span>
                              </div>
                              <InputText value={this.state.note} onChange={this.onNoteChange} placeholder="Примечание" style={{width: "25%"}} />
                           </li>
                           <li className="new--researches--form--item">
                              <span className="item--text semi__bold">Тип</span>
                              <Select
                                 items={types}
                                 onChange={this.onTypeChange}
                                 style={{width: "50%"}}
                                 value={this.state.type}
                                 type="outline"
                              />
                           </li>
                        </>
                     )
                  }
                  {
                     this.state.type === "number" && (
                        <li className="patientcode--researches--form--item">
                           <div className="item--text semi__bold">
                              <Checkbox value={this.state.isLimit} onChange={this.onIsLimitChange}>Ограничение диапазона</Checkbox>
                           </div>
                        </li>
                     )
                  }
                  {
                     this.state.isLimit && (
                        <>
                           <li className="patientcode--researches--form--item">
                              <div className="item--text semi__bold">
                                 <span>От</span>
                              </div>
                              <InputText
                                 type="number"
                                 value={this.state.limitFrom}
                                 onChange={this.onLimitFromChange}
                                 style={{width: "80px"}}
                              />
                           </li>
                           <li className="patientcode--researches--form--item">
                              <div className="item--text semi__bold">
                                 <span>До</span>
                              </div>
                              <InputText
                                 type="number"
                                 value={this.state.limitTo}
                                 onChange={this.onLimitToChange}
                                 style={{width: "80px"}}
                              />
                           </li>
                        </>
                     )
                  }
               </ul>
            </div>
         </div>
      );
   }
}

export default PatientCodeResearches;
