import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Alert } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Navigate } from "react-router";
import icons from "../../images/iconSet";
import { customFetch } from "../../utils/http";

const generatePassword = (passwordLength = 8) => {
  const lowerCase = "abcdefghjkmnopqrstuvwxyz";
  const upperCase = lowerCase.toUpperCase();
  const numberChars = "0123456789";

  let generatedPassword = "";
  let restPassword = "";

  const restLength = passwordLength % 4;
  const usableLength = passwordLength - restLength;
  const generateLength = usableLength / 4;

  const randomString = (char) => {
    return char[Math.floor(Math.random() * char.length)];
  };
  for (let i = 0; i <= generateLength - 1; i++) {
    const passwordPart = [
      randomString(lowerCase),
      randomString(lowerCase),
      randomString(upperCase),
      randomString(numberChars),
    ];

    let shuffled = passwordPart
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    generatedPassword += shuffled.join("");
  }

  for (let i = 0; i <= restLength - 1; i++) {
    restPassword += randomString([...lowerCase, ...upperCase, ...numberChars]);
  }

  return generatedPassword + restPassword;
};

class BasicExample extends React.Component {
  constructor({ props, data }) {
    super(props);
    this.state = {
      data: {},
      cities: [],
      selectedCity: [],
      redirect: false,
      msg: "",
      showPassword: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        ...this.state.data,
        city: this.state.selectedCity[0]?.id,
      }),
      redirect: "follow",
    };

    customFetch(global.apiUrl + "/user/add", requestOptions)
      .then((response) => {
        if (response.ok) {
          this.setState({ msg: "" });
          return response.text();
        }
        this.setState({ msg: "Error" });
        throw new Error("User exists");
      })
      .then(() => {
        this.setState({ redirect: true });
      })
      .catch((error) => {
        console.log("error handler was called");
        console.log(error);
      });
  }

  getCities = (query) => {
    customFetch(global.apiUrl + "/cities/search", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        limit: 10,
        offset: 0,
        query: query,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ cities: data });
      });
  };

  componentDidMount() {
    this.getCities("");
  }

  onCityChange = (selectedCity) => {
    this.setState({ selectedCity });
  };

  onRoleChange = (e) => {
    const stateChange = { role: 0 };
    if (e.target.innerText == "Пользователь") {
      stateChange.role = 0;
    }
    if (e.target.innerText == "Врач") {
      stateChange.role = 1;
    }
    if (e.target.innerText == "Модератор") {
      stateChange.role = 2;
    }
    if (e.target.innerText == "Монитор") {
      stateChange.role = 3;
    }
    if (e.target.innerText == "Администратор") {
      stateChange.role = 4;
    }
    if (e.target.innerText == "Суперадминистратор") {
      stateChange.role = 5;
    }
    this.setState((prev) => ({ data: { ...prev.data, ...stateChange } }));
  };

  onChangeInputValue = (e) => {
    this.setState((prev) => ({
      data: {
        ...prev.data,
        [e.target.getAttribute("dataname")]: e.target.value,
      },
    }));
  };

  generateUserPassword = () => {
    const password = generatePassword(8);
    this.setState((prev) => ({
      data: { ...prev.data, password },
    }));
  };

  render() {
    if (this.state.redirect) {
      return <Navigate replace to="/users" />;
    }
    return (
      <div id="page-content">
        <Form
          onSubmit={this.handleSubmit.bind(this)}
          className="advanced_fields row col-md-6"
          autoComplete="false"
        >
          <Form.Group className="row mb-3" controlId="formBasicPassword">
            <Form.Label className="col-sm-3 col-form-label">
              Фамилия <span className="require">*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                autoComplete="off"
                onChange={this.onChangeInputValue}
                dataname="surname"
                type="text"
                required
              />
            </Col>
          </Form.Group>

          <Form.Group className="row mb-3" controlId="formBasicPassword">
            <Form.Label className="col-sm-3 col-form-label">
              Имя <span className="require">*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                autoComplete="off"
                onChange={this.onChangeInputValue}
                dataname="name"
                type="text"
                required
              />
            </Col>
          </Form.Group>

          <Form.Group className="row mb-3" controlId="formBasicPassword">
            <Form.Label className="col-sm-3 col-form-label">
              Отчество
            </Form.Label>
            <Col sm="9">
              <Form.Control
                autoComplete="off"
                onChange={this.onChangeInputValue}
                dataname="patronymic"
                className="col-sm-9"
                type="text"
              />
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3" controlId="formBasicPassword">
            <Form.Label className="col-sm-3 col-form-label">
              Email <span className="require">*</span>
            </Form.Label>
            <Col sm="9">
              <InputGroup>
                <InputGroup.Text id="btnGroupAddon2">✉</InputGroup.Text>
                <Form.Control
                  autoComplete="off"
                  readonly
                  onfocus="this.removeAttribute('readonly')"
                  onChange={this.onChangeInputValue}
                  dataname="email"
                  type="email"
                  aria-label="Email"
                  aria-describedby="btnGroupAddon2"
                />
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3" controlId="formBasicPassword">
            <Form.Label className="col-sm-3 col-form-label">Город</Form.Label>
            <Col sm="9">
              <AsyncTypeahead
                id="city"
                labelKey={(option) => `${option.name}`}
                onChange={this.onCityChange}
                onSearch={(q) => this.getCities(q)}
                options={this.state.cities}
                placeholder="Выбрать город"
                selected={this.state.selectedCity}
                paginationText="Показать ещё..."
                emptyLabel="Совпадений не найдено"
                promptText="Введите для поиска..."
              />
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3" controlId="formBasicPassword">
            <Form.Label className="col-sm-3 col-form-label">
              Пароль пользователя <span className="require">*</span>
            </Form.Label>
            <Col sm="6">
              <InputGroup>
                <Form.Control
                  autoComplete="off"
                  value={this.state.data.password ?? ""}
                  onChange={this.onChangeInputValue}
                  dataname="password"
                  type={this.state.showPassword ? "text" : "password"}
                  readonly
                  require
                  onfocus="this.removeAttribute('readonly')"
                />
                <InputGroup.Text
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState((prev) => ({
                      ...prev,
                      showPassword: !prev.showPassword,
                    }))
                  }
                >
                  <img
                    src={
                      this.state.showPassword
                        ? icons.icons.eyeSlash
                        : icons.icons.eye
                    }
                    alt=""
                  />
                </InputGroup.Text>
              </InputGroup>
              <Form.Text className="text-muted">
                Email является логином для авторизации в системе
              </Form.Text>
            </Col>
            <Col sm="1">
              <Button
                onClick={this.generateUserPassword}
                style={{ backgroundColor: "#2196f3" }}
              >
                Случайный пароль
              </Button>
            </Col>
          </Form.Group>
          <Form.Group className="row mb-3" controlId="formBasicPassword">
            <Form.Label className="col-form-label">
              Роль пользователя <span className="require">*</span>
            </Form.Label>
            <ButtonToolbar
              aria-label="Toolbar with button groups"
              className={"btncontrol"}
            >
              <ButtonGroup
                onClick={this.onRoleChange}
                className="me-2"
                aria-label="First group"
              >
                <Button
                  variant={this.state.data.role == 0 ? "success" : "light"}
                >
                  Пользователь
                </Button>
                <Button
                  variant={this.state.data.role == 1 ? "success" : "light"}
                >
                  Врач
                </Button>
                <Button
                  variant={this.state.data.role == 2 ? "success" : "light"}
                >
                  Модератор
                </Button>
                <Button
                  variant={this.state.data.role == 3 ? "success" : "light"}
                >
                  Монитор
                </Button>
                <Button
                  variant={this.state.data.role == 4 ? "success" : "light"}
                >
                  Администратор
                </Button>
                <Button
                  variant={this.state.data.role == 5 ? "success" : "light"}
                >
                  Суперадминистратор
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Form.Group>
          {this.state.data.role == 1 && (
            <div className="advanced_fields">
              <Form.Group className="row mb-3" controlId="formBasicPassword">
                <Form.Label className="col-sm-3 col-form-label">
                  Табельный номер
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    autoComplete="off"
                    onChange={this.onChangeInputValue}
                    dataname="sn"
                    type="number"
                  />
                </Col>
              </Form.Group>
              <Form.Group className="row mb-3" controlId="formBasicPassword">
                <Form.Label className="col-sm-3 col-form-label">
                  Специализация врача
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    autoComplete="off"
                    onChange={this.onChangeInputValue}
                    dataname="spec"
                    type="text"
                  />
                </Col>
              </Form.Group>

              <Form.Group className="row mb-3" controlId="formBasicPassword">
                <Form.Label className="col-sm-3 col-form-label">
                  Организация
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    autoComplete="off"
                    onChange={this.onChangeInputValue}
                    dataname="organization"
                    type="text"
                  />
                </Col>
              </Form.Group>

              <Form.Group className="row mb-3" controlId="formBasicPassword">
                <Form.Label className="col-sm-3 col-form-label">
                  Адрес организации
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    autoComplete="off"
                    onChange={this.onChangeInputValue}
                    dataname="addres"
                    type="text"
                  />
                </Col>
              </Form.Group>
            </div>
          )}
          <Form.Group className="row mb-3" controlId="formBasicPassword">
            <Form.Label className="col-sm-3 col-form-label">
              Номер телефона
            </Form.Label>
            <Col sm="9">
              <InputGroup className="col-sm-9">
                <InputGroup.Text id="btnGroupAddon2">+</InputGroup.Text>
                <Form.Control
                  autoComplete="off"
                  onChange={this.onChangeInputValue}
                  dataname="phone"
                  type="number"
                  placeholder="Номер телефона"
                />
              </InputGroup>
            </Col>
          </Form.Group>

          <Button variant="primary" type="submit">
            Добавить
          </Button>
          {this.state.msg && (
            <Alert variant="danger" style={{ marginTop: "20px" }}>
              <Alert.Heading>Ошибка!</Alert.Heading>
              <p>Email должен быть уникальным!</p>
            </Alert>
          )}
        </Form>
      </div>
    );
  }
}

export default BasicExample;
