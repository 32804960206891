import React  from 'react';
import {UseFormReturn} from 'react-hook-form';
import {SearchIcon} from '../assets/icons';

interface IInputProps {
    registerName: string;
    register: UseFormReturn['register'];
    placeholder: string;
}

export const InputField = (
    {
        registerName,
        register,
        placeholder,
    }: IInputProps) => {

    return (
        <div className={'input-field-search'}>
            <SearchIcon width={12} height={12}/>
            <input
                {...register(registerName)}
                type="text"
                placeholder={placeholder}
                className={'input-field-search__input'}
            />
        </div>
    );
};
