import _ from "lodash";
import { useAppSelector, useSettings } from "../../hooks";
import { RowMenu } from "./RowMenu";

interface IRowProps {
  data: any;
  url: string;
  onDelete: (id: string, url: string) => void;
}

export const TableRow = ({ data, url, onDelete }: IRowProps) => {
  const { checkCasesRender } = useSettings();
  const { initData } = useAppSelector((state) => state.table);
  const { fields } = initData;
  const { actionButtons } = initData;
  const renderData = Object.entries(data)
    .map((entry) => ({
      [entry[0]]: entry[1],
    }))
    .filter((data) => !data["originalId"]);

  return (
    <tr className={"index" ? "table-row table-row--bg" : "table-row"}>
      {renderData.map((i: any, index) => (
        <td key={Object.keys(i).toString()} className={"table-row__item"}>
          <div className={"table-row__wrap-text"}>
            <span className="d-lg-none table-row__mobile-column-title">
              {Object.values(fields)[index]["DisplayName"]}
            </span>
            <span
              className={"table-row__text"}
              dangerouslySetInnerHTML={{
                __html: checkCasesRender(i),
              }}
            />
          </div>
        </td>
      ))}
      {/* <button onClick={() => setDashboard(true)}>Отчет</button> */}
      {actionButtons.length > 0 && (
        <td className={"table-row__item"}>
          <RowMenu data={actionButtons} id={data.id} onDelete={onDelete} />
        </td>
      )}
    </tr>
  );
};
