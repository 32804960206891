import React from 'react';
import IMAGES from '../../../images/iconSet';
import Checkbox from '../checkbox';

import './style.scss';

class MultiSelectList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shown: false,
            showTimer: null,
            search: "",
            items: []
        }

        this.list = React.createRef();
    }

    componentDidMount = () => {
        let items = [];

        if (this.props.data) {

            const { selected } = this.props;
            let selectedTmp = [];

            if (Array.isArray(selected)) {
                if (selected.length > 0) {
                    selectedTmp = selected;
                }
            }

            this.props.data.forEach(item => {
                items.push({
                    ...item,
                    selected: selectedTmp.indexOf(item.id) > -1
                });
            });
        }

        this.state.items = items;
        this.setState(this.state);
    }

    onSearchChange = (e) => {
        this.state.search = e.target.value;
        this.setState(this.state);
    }

    clearSerach = () => {
        this.state.search = "";
        this.setState(this.state);
    }

    onChange = () => {
        let data = [];
        this.state.items.forEach(item => {
            if (item.selected) data.push(item.id);
        })
        this.props.onChange(data);
    }

    onValueChange = (e, id) => {
        this.state.items.forEach((item, i) => {
            if (item.id === id) {
                this.state.items[i].selected = e.target.checked;
                this.setState(this.state);
            }
        });
    }

    removeItem = (id) => {
        this.state.items.forEach((item, i) => {
            if (item.id === id) {
                this.state.items[i].selected = false;
                this.setState(this.state, this.onChange);
            }
        });
    }

    show = (e) => {
        this.state.shown = true;
        this.setState(this.state, () => {
            const html = document.querySelector("html");

            let elem = e.target;

            const findInput = (node) => {
                if ([...node.classList].indexOf("mh-multiSelect_input") > -1) {
                    elem = node;
                }
                else {
                    findInput(node.parentNode);
                }
            }

            findInput(elem);

            const rect = elem.getBoundingClientRect();

            if (rect.y + rect.height + this.list.current.offsetHeight > window.innerHeight) {
                const newPos = html.scrollTop + (rect.y + rect.height + this.list.current.offsetHeight - window.innerHeight) + 20;
                html.scrollTo(0, newPos);
            }
        });
    }

    preventHiding = () => {
        this.state.showTimer = null;
        this.setState(this.state);
    }
    hide = () => {
        this.state.showTimer = setTimeout(() => {
            if (this.state.showTimer) {
                this.state.shown = false;
                this.setState(this.state);
            }
        }, 300);
        this.setState(this.state);
    }

    render() {

        const { search, items } = this.state;
        const { count } = this.props;

        const { listArrow } = IMAGES.constructor;

        return (
            <div className='mh-multiSelect'>
                <div className='mh-multiSelect_input' onClick={this.show} onMouseLeave={this.hide}>
                    <div className='mh-multiSelect_inputItems'>
                        {
                            items.map(item => (
                                this.props.selected ? (
                                    this.props.selected.indexOf(item.id) > -1 ? (
                                        <div className='mh-multiSelect_inputItem' key={item.id}>
                                            <span className='mh-multiSelect_inputItemText' dangerouslySetInnerHTML={{ __html: item.value }}></span>
                                            <button type='button' className='mh-multiSelect_inputItemBtn' onClick={() => this.removeItem(item.id)}>
                                                <img src={IMAGES.constructor.removeSelectedItem} alt='' />
                                            </button>
                                        </div>
                                    ) : ""
                                ) : ""))
                        }
                    </div>
                    <img src={listArrow} alt='' />
                </div>
                <div
                    className={'mh-multiSelect_listBlock' + (this.state.shown ? " mh-multiSelect_listBlock__shown" : "")}
                    onMouseEnter={this.preventHiding}
                    onMouseLeave={this.hide}
                    ref={this.list}
                >
                    <div className='mh-multiSelect_searchBlock'>
                        <div className='mh-multiSelect_searchField'>
                            <img src={IMAGES.constructor.search} alt='' width="16" height="16" />
                            <input
                                type="text"
                                className='mh-multiSelect_searchInput'
                                value={search}
                                onChange={this.onSearchChange}
                                placeholder="Поиск"
                            />
                        </div>
                        {
                            search ? (
                                <button type='button' className='mh-multiSelect_cancelSearch' onClick={this.clearSerach}>
                                    <img src={IMAGES.constructor.closeSearch} alt='' />
                                </button>
                            ) : ""
                        }
                    </div>
                    <ul className='mh-multiSelect_items'>
                        {
                            items.map(item => (
                                search ? (
                                    item.value.toLowerCase().indexOf(search.toLowerCase()) > -1 ? (
                                        <li className='mh-multiSelect_item' key={item.id}>
                                            <Checkbox value={item.selected}
                                                onChange={(e) => this.onValueChange(e, item.id)}>
                                                <p dangerouslySetInnerHTML={{ __html: item.value }} ></p>
                                            </Checkbox>
                                        </li>
                                    ) : ""
                                ) : (
                                    <li className='mh-multiSelect_item' key={item.id}>
                                        <Checkbox
                                            value={item.selected}
                                            onChange={(e) => this.onValueChange(e, item.id)}
                                        >
                                            <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
                                        </Checkbox>
                                    </li>
                                )
                            ))
                        }
                    </ul>
                    <div className='mh-multiSelect_footer'>
                        <button type='button' className='mh-multiSelect_button' onClick={this.onChange} >Применить</button>
                        <span className='mh-multiSelect_count'>Выбрано: {count ? count : 0}</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default MultiSelectList;