import type { FC } from "react";

interface IQuestionDocumentsProps {
  files: {
    target: string;
    source: string;
  }[];
}

export const QuestionDocuments: FC<IQuestionDocumentsProps> = ({ files }) => {
  return (
    <div className="d-flex flex-column mb-2" style={{ fontSize: "13px" }}>
      Документы для скачивания
      {files.map((f) => (
        <a
          key={f.source}
          target="_blank"
          download
          href={`${global.apiUrl}/download?source=${f.source}&target=${f.target}`}
          rel="noreferrer"
          style={{ color: "#019467" }}
        >
          {f.target}
        </a>
      ))}
    </div>
  );
};
