import React from 'react';
import {IIcons} from './types';

export const UnChecked = ({width = 15, height = 10, color = '#C2C2C2'}: IIcons) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke={color}/>
        </svg>
    );
};
