import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Alert,
  Button,
  Col,
  Stack,
  Container,
} from "react-bootstrap";
import "./component.scss";
import ellipse from "./Ellipse.png";
import { changeUserInfo, getUserInfoById } from "./services/userInfo";
import VerticallyModal from "./VerticallyModal";
import Toast from "./Toast";
import UserAvatar from "../avatar";
import { useNavigate } from "react-router";
import md5 from "md5";

const UserComponent = ({ id = 1 }) => {
  const [userInfo, setUserInfo] = useState({});
  const [error, setError] = useState(false);
  const [modalShow, setModalShow] = useState({
    show: false,
    type: "",
  });
  const navigate = useNavigate();

  const role = JSON.parse(sessionStorage.getItem("token"))?.role;

  useEffect(() => {
    getUserInfoById(id, setUserInfo);
  }, [id]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  const setEmail = (email) => {
    setUserInfo({
      ...userInfo,
      email,
    });
  };

  return (
    <Container id="page-content">
      <Row style={{ height: "100%" }}>
        <Col md="auto">
          <Card
            className="border-0 p-1 align-items-center"
            style={{ width: "100px" }}
          >
            <UserAvatar
              img={userInfo.picture}
              name={userInfo.name}
              surname={userInfo.surname}
              id={userInfo.id}
            />

            <Alert className="border-0 online-button p-0 d-flex justify-content-around align-items-center">
              <Card.Img
                style={{ width: "unset" }}
                src={ellipse}
                alt="user-image"
              />{" "}
              Онлайн
            </Alert>
          </Card>
        </Col>
        {userInfo && (
          <Col>
            <Stack
              style={{ gap: "10px" }}
              id="user-page-info"
              className="user-page-info"
            >
              <div className="user-page-info-title">
                <div className="user-page-info-title-name">
                  {userInfo.name + " " + userInfo.surname}
                </div>
                <div className="user-page-info-title-id">{userInfo.id}</div>
              </div>
              <div className="user-page-info-item">
                <div className="item-group">
                  Email: <span>{userInfo.email}</span>
                </div>
              </div>
              {!!userInfo.city && userInfo.city !== "null" && (
                <div className="user-page-info-item">
                  {`Город: ${userInfo.city}`}
                </div>
              )}
              {!!userInfo.spec && userInfo.spec !== "null" && (
                <div className="user-page-info-item">
                  {`Специализация: ${userInfo.spec}`}
                </div>
              )}
              {!!userInfo.organization && userInfo.organization !== "null" && (
                <div className="user-page-info-item">
                  {`ЛПУ: ${userInfo.organization}`}
                </div>
              )}
              {!!userInfo.addres && userInfo.addres !== "null" && (
                <div className="user-page-info-item">
                  {`Адрес ЛПУ: ${userInfo.addres}`}
                </div>
              )}
            </Stack>
          </Col>
        )}
        <Col md="auto">
          <Stack
            gap={3}
            id="user-page-action-btn-group"
            className="profile-button-group"
          >
            {md5(1) !== role && md5(0) !== role && (
              <Button onClick={() => navigate(`/users/edit/?id=${id}`)}>
                Редактировать
              </Button>
            )}
            {/* <span
              className="change-password"
              onClick={() => setModalShow({ show: true, type: "password" })}
            >
              |** <span className="change-password-link">Сменить пароль</span>
            </span> */}
          </Stack>
        </Col>
      </Row>
      {error && <Toast />}
      <VerticallyModal
        show={modalShow.show}
        onHide={() => setModalShow({ show: false, type: "" })}
        type={modalShow.type}
        changeUserInfo={changeUserInfo}
        id={id}
        setError={setError}
        setEmail={setEmail}
      />
    </Container>
  );
};

export default UserComponent;
