import React from "react";

import style from './style.module.scss';

import IMAGES from "../../images/iconSet";

class ToastComponent extends React.Component {
    timer;

    componentDidUpdate(prevProps) {

        if (this.props.notifications !== prevProps.notifications) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() =>
                this.props.notifications.map(({ id }) => {
                    return this.props.onClose(id)
                })
                , 3000);
        }
    }

    render = () => {
        const { notifications } = this.props;
        return (
            <div className={style["mh-toast"]}>
                {
                    notifications ? (

                        notifications.map(({ id, type, text, ttl }) => (
                            <div className={style["mh-toast_item"] + " " + style["mh-toast_item__" + type]} key={id}>

                                <div className={style["mh-toast_contentWrapper"]}>

                                    <div className={style["mh-toast_content"]}>
                                        <div className={style["mh-toast_imgWrapper"]}>
                                            <img alt="" src={IMAGES.toast[type]} className={style["mh-toast_img"]} />
                                        </div>
                                        <div className={style["mh-toast_msg"]}>
                                            {text}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))

                    ) : ""
                }
            </div>
        );
    }
}

export default ToastComponent;