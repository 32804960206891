import { useLocation, useParams, useNavigate } from "react-router-dom";

export const withRouter = (Component) => {
   const Wrapper = (props) => {
      const location = useLocation();
      const params = useParams();
      const navigate = useNavigate();
      
      let {search} = location;
      const searchParams = {};

      search = search.substring(1);
      search = search.split("&");
      search.forEach((el, i)=>{
         el = el.split("=");
         searchParams[el[0]] = el[1];
      });

      return <Component
                  location={location}
                  params={params}
                  navigate={navigate}
                  search={searchParams}
                  {...props}
             />;
   };

   return Wrapper;
};
