import "./index.scss";
import React from "react";
import { Link } from "react-router-dom";

class HeaderMenuButton extends React.Component {
   render() {
      return (
         <div className={this.props.notification ? "header--button header--button--new" : "header--button"}>
            <Link to={this.props.link} className="button">
               <img src={this.props.pathIcon} alt="" className="button--icon" />
            </Link>
         </div>
      );
   }
}

export default HeaderMenuButton;
