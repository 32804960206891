import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { SearchIcon } from "../../../../assets/icons";
import { CheckBox, Button } from "../../../../fields";
import { Item } from "../SelectorSearch";

interface ISearchProps {
  options: Item[];
  selected: Item[];
  value: string;
  handler: (items: Item[]) => void;
  handleClose: () => void;
  handleChangeSelected: (items: Item[]) => void;
  handleChangeValue: (value: string) => void;
}

export const Search = ({
  options,
  handler,
  handleClose,
  selected,
  value,
  handleChangeSelected,
  handleChangeValue,
}: ISearchProps) => {
  const handleSelect = useCallback(
    (item: Item) => {
      if (
        !_.includes(
          Object.values(selected).map((i) => i.id),
          item.id
        )
      ) {
        handleChangeSelected([...selected, item]);
      } else {
        const update = selected.filter((i) => i.id !== item.id);
        handleChangeSelected(update);
      }
    },
    [selected]
  );

  const renderOptions = useMemo(() => {
    return options
      .filter((i) => _.includes(i.name.toLowerCase(), value.toLowerCase()))
      .map((i) => (
        <div
          key={i.id}
          className={"selector-search__item"}
          onClick={() => handleSelect(i)}
        >
          <CheckBox
            isChecked={_.includes(
              Object.values(selected).map((i) => i.id),
              i.id
            )}
          />
          <div className={"selector-search__text"}>
            <span className="selector-search__text--title">{i.name}</span>
            {i.addittional && (
              <span className="selector-search__text--subtitle">
                {i.addittional}
              </span>
            )}
          </div>
        </div>
      ));
  }, [value, selected, handleSelect, options]);

  return (
    <div className={"selector-search"}>
      <div className={"selector-search__header"}>
        <div className={"selector-search__wrapper"}>
          <SearchIcon width={12} height={12} />
          <input
            className={"selector-search__input"}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeValue(e.target.value)
            }
          />
        </div>
      </div>

      <div className={"selector-search__list"}>{renderOptions}</div>
      <div className={"selector-search__footer"}>
        <Button
          text={"Применить"}
          onClick={() => {
            handler(selected);
            handleClose();
          }}
        />
        <span className={"selector-search__counter"}>
          Выбрано: {selected.length}
        </span>
      </div>
    </div>
  );
};
