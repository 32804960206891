import React from "react";
import IMAGES from "../../images/iconSet";

import "./style.scss";

class Modals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modals: props.modals,
    };
  }

  componentDidMount() {
    this.setScroll();
  }

  componentDidUpdate() {
    this.setScroll();
  }

  setScroll = () => {
    if (this.state.modals.length > 0) {
      document.querySelector("body").style = "overflow: hidden";
    } else {
      document.querySelector("body").style = "";
    }
  };

  closeModal(i) {
    const { state } = this;

    state.modals.splice(i, 1);

    this.setState(state, () => {
      setTimeout(() => {
        this.props.onCloseModal(i);
      }, 500);
    });
  }

  render() {
    return this.state.modals.length > 0 ? (
      <>
        <div className="mh-modalBackground" />
        <div className="mh-modalWrapper">
          {this.state.modals.map((modal, i) => (
            <div className="mh-modal" key={i}>
              <div className="mh-modal_header">
                <span className="mh-modal_headerText">{modal.header}</span>
                <button
                  className="mh-modal_close"
                  type="button"
                  onClick={() => this.closeModal(i)}
                >
                  <img src={IMAGES.icons.modalClose} />
                </button>
              </div>
              <div className="mh-modal_content">{modal.content}</div>
            </div>
          ))}
        </div>
      </>
    ) : (
      ""
    );
  }
}

export default Modals;
