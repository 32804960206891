function IsReadedIcon() {
   return (
      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M5.45849 9.67532L3.24591 7.46274C3.13589 7.35648 2.98854 7.29768 2.83559 7.29901C2.68264 7.30034 2.53633 7.36169 2.42818 7.46985C2.32002 7.578 2.25868 7.72431 2.25735 7.87726C2.25602 8.0302 2.31481 8.17755 2.42107 8.28757L5.04607 10.9126C5.15546 11.0219 5.30381 11.0834 5.45849 11.0834C5.61317 11.0834 5.76151 11.0219 5.87091 10.9126L12.2876 4.49591C12.3938 4.38589 12.4526 4.23854 12.4513 4.08559C12.45 3.93264 12.3886 3.78633 12.2805 3.67818C12.1723 3.57002 12.026 3.50868 11.8731 3.50735C11.7201 3.50602 11.5728 3.56481 11.4627 3.67107L5.45849 9.67532Z"
            fill="#68A25E"
         />
      </svg>
   );
}

export default IsReadedIcon;
