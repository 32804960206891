import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./styles.scss";
import { SelectPageCount } from "./components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setLimit, setOffset } from "../../store/table.slice";

export const Pagination = ({
  usePaginator,
  onPageChange,
  onLimitChange,
}: {
  usePaginator: boolean;
  onPageChange: (offset: number) => void;
  onLimitChange: (limit: number) => void;
}) => {
  const { count } = useAppSelector((state) => state.formsTable.initData);
  const { limit, offset } = useAppSelector((state) => state.formsTable);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(count / limit));
  }, [count, limit]);

  const handlePageClick = (event: any) => {
    onPageChange(event.selected * limit);
  };

  return (
    <>
      <div className={"pagination-wrapper"}>
        {usePaginator && (
          <>
            <SelectPageCount
              handleSelect={onLimitChange}
              placeholder={String(limit)}
            />
            <ReactPaginate
              breakLabel="..."
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              forcePage={Math.round(offset / limit)}
              previousLabel=""
              className={"pagination"}
              previousLinkClassName={
                "pagination__button pagination__button--prev"
              }
              nextLinkClassName={"pagination__button pagination__button--next"}
              pageClassName={"pagination__page"}
              breakClassName={"pagination__break"}
              activeClassName={"pagination__page--active"}
            />
          </>
        )}
      </div>
    </>
  );
};
