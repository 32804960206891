import "./index.scss";
import * as React from "react";

import { Navbar } from "react-bootstrap";
import Popover from "../../components/popover";
import md5 from "md5";
import Offcanvas from "react-bootstrap/Offcanvas";
import icons from "../../images/iconSet";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    const { unreadCount } = props;

    var scope = JSON.parse(sessionStorage.getItem("token")).role;
    /* console.log("╰(*°▽°*)╯"); */
    if (md5(0) == scope) {
      var MenuList = {
        menuList: [
          {
            title: "Главная",
            icon: icons.menu.home,
            url: "/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Исследования",
            icon: icons.menu.researhes,
            url: "/research/",
            subelemets: [
              {
                title: "Все исследования",
                url: "/research/",
              },
            ],
            notification: "0",
          },
          {
            title: "Анкеты",
            icon: icons.menu.forms,
            url: "/forms/",
            subelemets: [],
            notification: "",
          },
        ],
        activeMenuIndex: -1,
      };
    }
    if (md5(1) == scope) {
      var MenuList = {
        menuList: [
          {
            title: "Главная",
            icon: icons.menu.home,
            url: "/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Анкеты",
            icon: icons.menu.forms,
            url: "/forms/",
            subelemets: [
              {
                title: "Все анкеты",
                url: "/forms/",
              },
            ],
            notification: "0",
          },
          {
            title: "Исследования",
            icon: icons.menu.researhes,
            url: "/research/",
            subelemets: [
              {
                title: "Все исследования",
                url: "/research/",
              },
            ],
            notification: "0",
          },
          {
            title: "Сообщения",
            icon: icons.menu.messages,
            url: "/messages/",
            subelemets: [],
            notification: unreadCount.toString(),
          },
        ],
        activeMenuIndex: -1,
      };
    }
    if (md5(2) == scope) {
      var MenuList = {
        menuList: [
          {
            title: "Главная",
            icon: icons.menu.home,
            url: "/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Анкеты",
            icon: icons.menu.forms,
            url: "/forms/",
            subelemets: [
              {
                title: "Все анкеты",
                url: "/forms/",
              },
            ],
            notification: "0",
          },

          {
            title: "Исследования",
            icon: icons.menu.researhes,
            url: "/research/",
            subelemets: [
              {
                title: "Все исследования",
                url: "/research/",
              },
            ],
            notification: "0",
          },
          {
            title: "Сообщения",
            icon: icons.menu.messages,
            url: "/messages/",
            subelemets: [],
            notification: "",
          },
        ],
        activeMenuIndex: -1,
      };
    }
    if (md5(3) == scope) {
      var MenuList = {
        menuList: [
          {
            title: "Главная",
            icon: icons.menu.home,
            url: "/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Анкеты",
            icon: icons.menu.forms,
            url: "/forms/",
            subelemets: [
              {
                title: "Все анкеты",
                url: "/forms/",
              },
            ],
            notification: "0",
          },
          {
            title: "Исследования",
            icon: icons.menu.researhes,
            url: "/research/",
            subelemets: [
              {
                title: "Все исследования",
                url: "/research/",
              },
            ],
            notification: "0",
          },
          {
            title: "Сообщения",
            icon: icons.menu.messages,
            url: "/messages/",
            subelemets: [],
            notification: unreadCount.toString(),
          },
          {
            title: "Справочник",
            icon: icons.menu.manuals,
            url: "",
            subelemets: [
              {
                title: "Города и регионы",
                url: "/manual/regions/",
              },
              {
                title: "Специализации",
                url: "/manual/specializations/",
              },
            ],
            notification: "0",
          },
        ],
        activeMenuIndex: -1,
      };
    }
    if (md5(4) == scope) {
      var MenuList = {
        menuList: [
          {
            title: "Главная",
            icon: icons.menu.home,
            url: "/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Анкеты",
            icon: icons.menu.forms,
            url: "/forms/",
            subelemets: [
              {
                title: "Все анкеты",
                url: "/forms/",
              },
              {
                title: "Отчеты",
                url: "/forms/reports/",
              },
            ],
            notification: "0",
          },
          {
            title: "Города",
            icon: icons.menu.customers,
            url: "/cities/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Пользователи",
            icon: icons.menu.users,
            url: "/users/",
            subelemets: [
              {
                title: "Все пользователи",
                url: "/users/",
              },
            ],
            notification: "0",
          },
          {
            title: "Исследования",
            icon: icons.menu.researhes,
            url: "/research/",
            subelemets: [
              {
                title: "Все исследования",
                url: "/research/",
              },
            ],
            notification: "0",
          },
          {
            title: "Сообщения",
            icon: icons.menu.messages,
            url: "/messages/",
            subelemets: [],
            notification: unreadCount.toString(),
          },
          {
            title: "Справочник",
            icon: icons.menu.manuals,
            url: "",
            subelemets: [
              {
                title: "Города и регионы",
                url: "/manual/regions/",
              },
              {
                title: "Специализации",
                url: "/manual/specializations/",
              },
            ],
            notification: "0",
          },
        ],
        activeMenuIndex: -1,
      };
    }
    if (md5(5) == scope) {
      var MenuList = {
        menuList: [
          {
            title: "Главная",
            icon: icons.menu.home,
            url: "/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Анкеты",
            icon: icons.menu.forms,
            url: "/forms/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Города",
            icon: icons.menu.customers,
            url: "/cities/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Пользователи",
            icon: icons.menu.users,
            url: "/users/",
            subelemets: [
              {
                title: "Все пользователи",
                url: "/users/",
              },
              {
                title: "Добавить пользователя",
                url: "/users/add/",
              },
              {
                title: "Заказчики",
                url: "/customers/",
              },
            ],
            notification: "0",
          },

          // {
          //   title: "Заказчики",
          //   icon: icons.menu.customers,
          //   url: "/customers/",
          //   subelemets: [
          //     {
          //       title: "Все заказчики",
          //       url: "/customers/",
          //     },
          //     {
          //       title: "Добавить заказчика",
          //       url: "/customers/add/",
          //     },
          //   ],
          //   notification: "0",
          // },
          {
            title: "Исследования",
            icon: icons.menu.researhes,
            url: "/research/",
            subelemets: [
              {
                title: "Все исследования",
                url: "/research/",
              },
              {
                title: "Добавить исследование",
                url: "/research/designer/",
              },
            ],
            notification: "0",
          },
          {
            title: "Сообщения",
            icon: icons.menu.messages,
            url: "/messages/",
            subelemets: [],
            notification: unreadCount.toString(),
          },
          /* {
            title: "Справочник",
            icon: icons.menu.manuals,
            url: "",
            subelemets: [
              {
                title: "Города и регионы",
                url: "/manual/regions/",
              },
              {
                title: "Специализации",
                url: "/manual/specializations/",
              },
            ],
            notification: "0",
          }, */
          {
            title: "Отчеты",
            icon: icons.menu.reports,
            url: "reports",
            subelemets: [],
            notification: "0",
          },
        ],
        activeMenuIndex: -1,
      };
    }
    if (md5(6) == scope) {
      var MenuList = {
        menuList: [
          {
            title: "Главная",
            icon: icons.menu.home,
            url: "/",
            subelemets: [],
            notification: "0",
          },
          {
            title: "Анкеты",
            icon: icons.menu.forms,
            url: "/forms/",
            subelemets: [
              {
                title: "Все анкеты",
                url: "/forms/",
              },
            ],
            notification: "0",
          },
          {
            title: "Пользователи",
            icon: icons.menu.users,
            url: "/users/",
            subelemets: [
              {
                title: "Все пользователи",
                url: "/users/",
              },
              {
                title: "Добавить пользователя",
                url: "/users/add/",
              },
              {
                title: "Заказчики",
                url: "/customers/",
              },
            ],
            notification: "0",
          },
          // {
          //   title: "Заказчики",
          //   icon: icons.menu.customers,
          //   url: "/customers/",
          //   subelemets: [
          //     {
          //       title: "Все заказчики",
          //       url: "/customers/",
          //     },
          //     {
          //       title: "Добавить заказчика",
          //       url: "/customers/add/",
          //     },
          //   ],
          //   notification: "0",
          // },
          {
            title: "Исследования",
            icon: icons.menu.researhes,
            url: "/research/",
            subelemets: [
              {
                title: "Все исследования",
                url: "/research/",
              },
              {
                title: "Добавить исследование",
                url: "/research/designer/",
              },
            ],
            notification: "0",
          },
          {
            title: "Сообщения",
            icon: icons.menu.messages,
            url: "/messages/",
            subelemets: [],
            notification: unreadCount.toString(),
          },
          {
            title: "Справочник",
            icon: icons.menu.manuals,
            url: "",
            subelemets: [
              {
                title: "Города и регионы",
                url: "/manual/regions/",
              },
              {
                title: "Специализации",
                url: "/manual/specializations/",
              },
            ],
            notification: "0",
          },
          {
            title: "Аналитика",
            icon: icons.menu.reports,
            url: "/reports/",
            subelemets: [
              {
                title: "Все отчёты",
                url: "/reports/",
              },
              {
                title: "Отчёт по исслдованиям",
                url: "/reports/research/",
              },
              {
                title: "Отчёт по врачам",
                url: "/reports/doctors/",
              },
              {
                title: "Добавить отчёт",
                url: "/reports/add/",
              },
            ],
            notification: "0",
          },
        ],
        activeMenuIndex: -1,
      };
    }
    if (md5(7) == scope) {
      var MenuList = {
        menuList: [
          {
            title: "Главная",
            icon: icons.menu.home,
            url: "/",
            subelemets: [],
            notification: "0",
          },
        ],
      };
    }
    this.state = {
      MenuList,
      show: false,
      setShow: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.state.show && this.handleClose();
    }
  }

  changeActiveMenu = (i) => {
    this.setState((state) => {
      /* state.activeMenuIndex = state.activeMenuIndex !== i ? i : -1; */
      state.activeMenuIndex = i;
      return { ...state };
    });
  };

  handleShow = () => {
    this.setState((state) => {
      state.show = true;
      return { ...state };
    });
  };

  handleClose = () => {
    this.setState((state) => {
      state.show = false;
      return { ...state };
    });
  };
  handleExit() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  }
  render() {
    const { MenuList, activeMenuIndex, show, setShow } = this.state;
    const { unreadCount } = this.props;
    // const setShowState = show => this.setState({ show });

    return (
      <aside className="aside">
        <Navbar collapseOnSelect expand="md">
          <div className="aside--title">
            <Navbar.Brand href="/" className="logo">
              <img src={icons.logo} alt="" className="logo--icon" />
              <div className="logo--text bold">РООИ</div>
            </Navbar.Brand>
            <Navbar.Offcanvas
              id="responsive-navbar-nav"
              placement="end"
              show={this.state.show}
              onHide={this.handleClose}
              className="offcanvas-navbar"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <Navbar.Brand href="/" className="logo">
                    <img src={icons.logo} alt="" className="logo--icon" />
                    <div className="logo--text bold">РООИ</div>
                  </Navbar.Brand>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <nav className="navigation">
                  <ul className="navigation--list">
                    {MenuList.menuList.map((item, i) => (
                      <Popover
                        header={item.title}
                        count={MenuList.menuList.length}
                        icon={item.icon}
                        link={item.url}
                        notification={
                          item.title === "Сообщения" ? unreadCount.toString() : item.notification
                        }
                        subelemnts={item.subelemets}
                        key={i}
                        isActive={activeMenuIndex === i}
                        onClick={() => this.changeActiveMenu(i)}
                      />
                    ))}
                  </ul>
                </nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <Navbar.Toggle
              className="menu-toggle"
              onClick={this.handleShow}
              children={
                <span className="menu-toggle-btn">
                  Меню<span class="navbar-toggler-icon"></span>
                </span>
              }
            />
          </div>
        </Navbar>
        <div className="aside--submenu">
          {/* <div className="actions">
                  <span className="actions--title regular">Быстрый доступ</span>
                  <div className="actions--row">
                     <QuickActionButton link="/users/" text="Врачи" />
                     <QuickActionButton link="/notes/drafts/" text="Черновики" />
                  </div>
               </div> */}
          <button className="actions--exit" type="button">
            <img className="icon--exit" src={icons.icons.out} alt="" />
            <a className="text--exit" onClick={() => this.handleExit()}>
              Выход
            </a>
          </button>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    unreadCount: state.unreadCount,
  };
};

export default connect(mapStateToProps)(withRouter(Menu));
