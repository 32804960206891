import "./index.scss";

import React from "react";

export class InputText extends React.Component {
  render() {
    return (
      <input
        required={this.props.required ?? false}
        type={this.props.type ? this.props.type : "text"}
        onChange={this.props.onChange}
        name={this.props.name}
        id={this.props.id}
        value={this.props.value}
        className="mnh--text regular"
        disabled={this.props.disabled}
        style={this.props.style}
        min={this.props.min}
        max={this.props.max}
      />
    );
  }
}

export default InputText;
