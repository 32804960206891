import React from "react";
import "./style.scss";
import { Placeholder } from "react-bootstrap";

class ContactListPlaceholder extends React.Component {
  render() {

    return (
      <div
        style={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        {
          new Array(8).fill(0)
            .map((_, i) =>
                <div
                  className="chat-contact"
                  key={i}
                >
                  <Placeholder animation="glow">
                    <Placeholder className="chat-contact_avatar" style={{width: "40px", height: "40px", borderRadius: "50%"}} />
                  </Placeholder>

                  <div className="chat-contact_info chat-contactInfo">
                    <Placeholder animation="glow">
                      <Placeholder className="chat-contactInfo_name" xs={5} />
                    </Placeholder>
                    <Placeholder animation="glow">
                      <Placeholder className="chat-contactInfo_lastMessage" style={{color: "inherit"}} xs={9} />
                    </Placeholder>
                  </div>
                </div>
              )
        }
      </div>
    );
  }
}

export default ContactListPlaceholder;
