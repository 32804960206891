function AttachmentIcon() {
   return (
      <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M14.2501 6.87501L7.09153 14.1539C6.85454 14.4155 6.72721 14.7582 6.73589 15.111C6.74458 15.4639 6.88863 15.7999 7.1382 16.0494C7.38778 16.299 7.72378 16.4431 8.07663 16.4517C8.42948 16.4604 8.77216 16.3331 9.03372 16.0961L17.5673 7.4422C18.0413 6.91908 18.296 6.23372 18.2786 5.52802C18.2612 4.82231 17.9731 4.15032 17.474 3.65116C16.9748 3.152 16.3028 2.86391 15.5971 2.84654C14.8914 2.82916 14.2061 3.08383 13.6829 3.55782L5.14935 12.2117C4.3767 12.9844 3.94263 14.0323 3.94263 15.125C3.94263 16.2177 4.3767 17.2656 5.14935 18.0383C5.922 18.8109 6.96994 19.245 8.06263 19.245C9.15532 19.245 10.2033 18.8109 10.9759 18.0383L18.0314 11"
            stroke="#8BABD8"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   );
}

export default AttachmentIcon;
