import { useState, useEffect } from "react";

function getStorageValue<ValueType>(
  key: string,
  defaultValue: ValueType
): ValueType {
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export function useLocalStorage<ValueType>(
  key: string,
  defaultValue: ValueType
): [ValueType, React.Dispatch<React.SetStateAction<ValueType>>] {
  const [value, setValue] = useState(() => {
    return getStorageValue<ValueType>(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
