import Tass from '../../tables';
import { useCallback, useState } from "react";




const Researches = () => {
   const [count, setCount] = useState(0);

   const handleChangeCount = useCallback((value) => {
      setCount(value);
   }, [])



   /*   useEffect(() => {
       getSupersetToken();
    }, []);
 
 
    const [supersetToken, setSupersetToken] = useState();
 
    function getSupersetToken() {
       // Simple POST request with a JSON body using fetch
       const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
       };
       fetch(global.apiUrl + "/getAccessSuperset", requestOptions)
          .then((response) => response.json())
          .then((data) => {
             setSupersetToken(data.token);
          });
    }  */



   return (
      <div className="container-fluid" id="main-page-block">
         <div id="page-content">
            <h2>
               Исследования <span id="sup">{count ? count : ""}</span>
            </h2>
            <Tass url={global.apiUrl + "/research/list/"} //сменить эндпойнт на global.apiUrl
               token={sessionStorage.getItem('token')}
               updateCount={handleChangeCount}
            /*   supersetToken={supersetToken} */
            />
            <div id="dashboard--container11"></div>

         </div>
      </div>
   );
}

export default Researches;
