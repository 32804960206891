import "./index.scss";
import React from "react";

class Accordion extends React.Component {
   render() {
      return (
         <div className="accordion" style={this.props.style}>
            <input type="checkbox" id={this.props.id} className="accordion--check" defaultChecked={this.props.defaultOpen} />
            <h3 className="accordion--title">
               <label className="accordion--title--text" htmlFor={this.props.id}>
                  {this.props.title}
               </label>
            </h3>
            <div className="accordion--content">
               <div className="content--container">{this.props.content}</div>
            </div>
         </div>
      );
   }
}

export default Accordion;
