import parse from "html-react-parser";
import { useEffect, useState, type FC } from "react";
import { Alert, Button, Form, Table } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import IMAGES from "../../images/iconSet";
import classes from "./AboutForm.module.scss";

type Cell = {
  checked: boolean;
  id: string;
  parentId: string;
  type: string;
  value: string;
};

type Row = {
  id: string;
  cells: Cell[];
};

interface IParsedTableProps {
  id: string;
  counterTab: number;
  sub: number;
  title: string;
  files?: { source: string; target: string }[];
  required: boolean;
  note?: string;
  dynamic: boolean;
  headers: {
    id: string;
    text: string;
    type: string;
  }[];
  rows: Row[];
  disabled?: boolean;
  dv: Object;
  tableId: string;
  error?: string;
  isEmpty: boolean;
}

const getCells = (row: Row, tableId: string, dv: Object, disabled: boolean) => {
  return row.cells.map((cell, index) => {
    if (cell.type == "title") {
      if (index === 0) {
        return (
          <td key={cell.id}>
            <div className="text-in-table">{cell.value}</div>
          </td>
        );
      } else {
        return (
          <td key={cell.id}>
            <Form.Group
              controlId={cell.id + "||" + tableId}
              className="text-in-table"
            >
              <Form.Control
                type="text"
                name={"Title" + cell.id}
                value={cell.value}
                readOnly
                disabled
                style={{ backgroundColor: "transparent", border: "none" }}
              />
            </Form.Group>
          </td>
        );
      }
    }
    if (cell.type == "radio") {
      // if (dv[row.id]) isEmpty = false;
      return (
        <td key={cell.id}>
          <Form.Check
            className="radio-in-table"
            type="radio"
            id={cell.id + "||" + tableId}
            label={cell.value}
            name={row.id}
            // pid={tableId}
            defaultChecked={dv[row.id] == cell.id}
            disabled={disabled}
          />
        </td>
      );
    }
    if (cell.type == "checkbox") {
      // if (dv[cell.id] !== undefined && dv[cell.id] !== "") isEmpty = false;
      return (
        <td key={cell.id}>
          <Form.Check
            className="checkbox-in-table"
            type="checkbox"
            id={cell.id + "||" + tableId}
            label={cell.value}
            // pid={tableId}
            name={cell.id}
            defaultChecked={dv[cell.id] == "true"}
            disabled={disabled}
          />
        </td>
      );
    }
    if (cell.type == "text") {
      // if (dv[cell.id] !== undefined && dv[cell.id] !== "") isEmpty = false;
      return (
        <td key={cell.id}>
          <Form.Group
            controlId={cell.id + "||" + tableId}
            // pid={tableId}
            className="text-in-table"
          >
            <Form.Control
              type="text"
              name={"Text" + cell.id}
              defaultValue={dv[cell.id]}
              disabled={disabled}
            />
          </Form.Group>
        </td>
      );
    }
    if (cell.type == "number") {
      // if (dv[cell.id] !== undefined && dv[cell.id] !== "") isEmpty = false;
      return (
        <td key={cell.id}>
          <Form.Group
            controlId={cell.id + "||" + tableId}
            // pid={tableId}
            className="number-in-table"
            // value={"1"}
          >
            <Form.Control
              type="number"
              name={"Number" + cell.id}
              defaultValue={dv[cell.id]}
              disabled={disabled}
            />
          </Form.Group>
        </td>
      );
    }
    if (cell.type == "time") {
      return (
        <td key={cell.id}>
          <Form.Group
            controlId={cell.id + "||" + tableId}
            className="time-in-table"
          >
            <Form.Control
              type="time"
              name={"Time" + cell.id}
              defaultValue={dv[cell.id]}
              disabled={disabled}
            />
          </Form.Group>
        </td>
      );
    }
    if (cell.type == "date") {
      return (
        <td key={cell.id}>
          <Form.Group
            controlId={cell.id + "||" + tableId}
            className="date-in-table"
          >
            <Form.Control
              type="text"
              as={MaskedInput}
              mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
              name={"Date" + cell.id}
              defaultValue={dv[cell.id]}
              disabled={disabled}
              placeholderChar={"-"}
              placeholder="--.--.----"
            />
          </Form.Group>
        </td>
      );
    }
  });
};

const ParsedTable: FC<IParsedTableProps> = ({
  id,
  counterTab,
  sub,
  title,
  files,
  required,
  note,
  headers,
  rows,
  dynamic,
  tableId,
  dv,
  disabled,
  error,
  isEmpty,
}) => {
  const [tableRows, setTableRows] = useState<(Row & { showed: boolean })[]>(
    rows.map((row) => ({ ...row, showed: true }))
  );
  const [isMaxRows, setIsMaxRows] = useState(false);

  // скрывает пустые строки, если таблица динамическая
  useEffect(() => {
    if (dynamic) {
      setTableRows((prev) =>
        prev.map((row, index) => {
          let isRowEmpty = true;
          row.cells.forEach((cell) => {
            switch (cell.type) {
              case "title":
                break;
              case "radio":
                if (dv[row.id]) isRowEmpty = false;
                break;
              default:
                if (dv[cell.id] !== undefined && dv[cell.id] !== "") {
                  isRowEmpty = false;
                }
            }
          });
          return { ...row, showed: !(isRowEmpty && index !== 0) };
        })
      );
    } else {
      setTableRows(rows.map((row) => ({ ...row, showed: true })));
    }
  }, []);

  // показ незаполненных строк, если за ними уже есть заполненные
  useEffect(() => {
    if (!dynamic) return;
    const tableRowsIsShowed = [];

    tableRows.forEach((r, i) => {
      if (r.showed) tableRowsIsShowed.push(i);
    });

    let isOrdered = true;

    tableRowsIsShowed.forEach((showedIndex, index) => {
      if (showedIndex !== index) {
        isOrdered = false;
      }
    });

    if (!isOrdered) {
      setTableRows((prev) =>
        prev.map((row, index, arr) => {
          const arrSlice = arr.slice(index + 1);
          if (!row.showed && !!arrSlice.find((r) => r.showed)) {
            return { ...row, showed: true };
          }
          return row;
        })
      );
    }
  }, [dynamic, tableRows]);

  const showedRows = tableRows.filter((row) => row.showed);

  const handleAddRow = () => {
    const showedLength = showedRows.length;
    if (showedLength < tableRows.length) {
      const lastShowedIndex = showedLength - 1;
      setTableRows((prev) =>
        prev.map((row, index) => {
          if (lastShowedIndex + 1 !== index) return row;
          return { ...row, showed: true };
        })
      );
    } else {
      setIsMaxRows(true);
    }
  };

  const handleRemoveRow = () => {
    const lastShowedIndex = showedRows.length - 1;
    const lastShowedRow = showedRows[lastShowedIndex];
    const row = document.getElementById(
      `${lastShowedRow.id}-${lastShowedIndex}`
    );
    const inputElements = row.getElementsByTagName("input");
    for (var i = 0, max = inputElements.length; i < max; i++) {
      inputElements[i].value = "";
      inputElements[i].checked = false;
    }
    setTableRows((prev) =>
      prev.map((row) => {
        if (row.id !== lastShowedRow.id) return row;
        return { ...row, showed: false };
      })
    );
  };

  return (
    <div className="answer-wrapper">
      <div className="counterTab">
        <span className="form-counter">
          {counterTab}
          {sub ? "." + sub : ""}
        </span>{" "}
        {title}
        {required ? <span style={{ color: "red" }}>*</span> : ""}
      </div>
      {files && files.length > 0 && (
        <div className="d-flex flex-column mb-2" style={{ fontSize: "13px" }}>
          Файлы
          {files.map((f) => (
            <a
              key={f.source}
              target="_blank"
              download
              href={`${global.apiUrl}/download?source=${f.source}&target=${f.target}`}
              rel="noreferrer"
              style={{ color: "#019467" }}
            >
              {f.target}
            </a>
          ))}
        </div>
      )}
      {note && <Alert variant="danger">{parse(note)}</Alert>}
      <div
        style={{
          overflowX: "auto",
        }}
      >
        <Table
          striped
          bordered
          hover
          className={"custom-table-component"}
          id={id}
        >
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header.id}>{header.text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {showedRows.map((row, index) => (
              <tr id={`${row.id}-${index}`} key={row.id}>
                {getCells(row, tableId, dv, disabled)}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {dynamic && (
        <div className="d-flex">
          <Button onClick={handleAddRow}>
            <img
              src={IMAGES.constructor.addBlock}
              width={12}
              height={12}
              alt=""
            />{" "}
            Добавить строку
          </Button>
          {showedRows.length > 1 && tableRows.length > 1 && dynamic && (
            <Button
              style={{ borderRadius: 20, fontSize: 14.4 }}
              variant="danger"
              onClick={handleRemoveRow}
            >
              Удалить
            </Button>
          )}
        </div>
      )}
      {isMaxRows && (
        <div
          className={classes["mh-formValidationMessage"]}
          style={{ marginTop: "10px" }}
        >
          Максимальное количество строк {tableRows.length}
        </div>
      )}
      {error ||
        (isEmpty && (
          <div
            className={classes["mh-formValidationMessage"]}
            style={{ marginTop: "10px" }}
          >
            {error ? error : isEmpty ? "Вопрос обязателен к заполнению." : ""}
          </div>
        ))}
    </div>
  );
};

export default ParsedTable;
