import { anyArr, IOption, strArr } from "../../types";

export interface IQuestionnairesState {
  loader: boolean;
  selectAll: boolean;
  token: string;
  selectedQuestionnaire: any[];
  initData: IInit;
  content: any[];
  selectors: IOption[];
  filters: IQuestionnairesFilters[];
  currentUrl: string;
}

export enum FilterTypes {
  cities = "cities",
  researches = "researches",
  searchValue = "searchValue",
}

export type IFilterType = keyof typeof FilterTypes;

export type IQuestionnairesFilters = {
  type: IFilterType;
  id?: string | number;
  value: string;
};

export interface IInit {
  UrlToFilter: string;
  fields: any;
  dropdownfilter: strArr;
  useSearch: boolean;
  usePaginator: boolean;
  actionButtons: IActonButtons[];
  useTabs: boolean;
  tabs: any;
  availableSorts: any;
  globalActionTable: any;
  fastFilters: IFastFilters[] | [];
  content: any[];
  allowFilterByDate: boolean;
}

export interface IResponseInit {
  UrlToFilter: string;
  fields: anyArr;
  content: any;
  dropdownfilter: strArr;
  useSearch: boolean;
  usePaginator: boolean;
  actionButtons: IActonButtons[];
  useTabs: boolean;
  tabs: any;
  availableSorts: any;
  globalActionTable: any;
  fastFilters: any;
}

export interface IParam {
  data: string;
  icon: string;
  title: string;
  type: string;
  url: string;
}

export interface IActonButtons {
  type: string;
  params: IParam[];
}

export interface IFastFilters {
  DisplayName: string;
  filter_param: string;
  value: string;
}
