import {  ISelector, strArr} from '../types';

export const createSelectorOptions = (fields:any, dropdownfilter:strArr) => {
    const dropArray = Object.values(dropdownfilter).map(i => Object.keys(i).pop());
    let filters:any[] = [];
    for (const key of dropArray) {
        filters = [...filters, {item: fields[`${key}`], key}];
    }

    let selectors: ISelector[] = [];
    for (let i = 0; i < filters.length; i++) {

        selectors = [...selectors, {
            name: filters[i].item.DisplayName,
            value: filters[i].key,
            options: filters[i].item.cases
        }];
    }

    return {selectors};
};
