import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "../../fields";
import { SelectorSearch } from "../../components/selectorSearch";
import { ICasesSelector } from "../../types";
import { DatePicker } from "../../components/datePicker";
import { useAppDispatch, useAppSelector, useSettings } from "../../hooks";
import { FilterTypes, IFastFilters } from "../store/types";
import { StaticButtons } from "./StaticButtons";
import { sendFilterData } from "../store/table.slice";

interface ISelectedItems {
  type: string;
  options: ICasesSelector[];
}

const schema = yup.object({
  search: yup.string().required(),
});

export const Filters = ({
  useSearch,
  allowFilterByDate,
}: {
  useSearch: boolean;
  allowFilterByDate: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { selectors } = useSettings();
  const { fastFilters } = useAppSelector((state) => state.formsTable.initData);
  const filters = useAppSelector((state) => state.formsTable.filters);

  const { register, resetField } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSearch = useCallback(
    (value: string) => {
      const str = value.toLowerCase();
      const otherFilters = filters.filter(
        (el) => el.type !== FilterTypes.searchValue
      );
      if (otherFilters.length > 0) {
        const otherFiltersObject = {};
        otherFilters.forEach((el) => {
          otherFiltersObject[el.type] = otherFiltersObject[el.type]
            ? [...otherFiltersObject[el.type], el.id]
            : [el.id];
        });
        dispatch(
          sendFilterData({ data: { search: str, ...otherFiltersObject } })
        );
      } else {
        dispatch(sendFilterData({ data: { search: str } }));
      }

      resetField("search");
    },
    [filters]
  );

  const handleSelect = ({ type, options }: ISelectedItems) => {
    const data = {
      [type]: options.map((i) => i.case),
    };
    dispatch(sendFilterData({ data }));
  };

  const handleFastFilters = (item: IFastFilters) => {
    const data: any = { [`${item.filter_param}`]: item.value };
  };

  return (
    <div className={"filters"}>
      {useSearch ? (
        <div className={"filters__item"}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch(e.target[0].value);
            }}
          >
            <InputField
              registerName={"search"}
              register={register}
              placeholder={"Поиск по id"}
            />
          </form>
        </div>
      ) : null}
      {selectors.map((i) => (
        <div key={i.value} className={"filters__item"}>
          <SelectorSearch
            title={i.name}
            options={i.options}
            getSelected={(options) =>
              handleSelect({
                type: i.value === "city" ? FilterTypes.cities : i.value,
                options,
              })
            }
          />
        </div>
      ))}
      {allowFilterByDate && (
        <div className={"filters__item"}>
          <DatePicker title={"Даты"} />
        </div>
      )}
      <div className={"filters__item"}>
        {fastFilters.length ? (
          <StaticButtons options={fastFilters} onClick={handleFastFilters} />
        ) : null}
      </div>
    </div>
  );
};
