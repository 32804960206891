import "../number/index.scss";
import React from "react";

class TimeNote extends React.Component {
  render() {
    return (
      <div className="mh-constructor_number">
        <div className="mh-constructor_numberArea">
          <input
            type="time"
            className="mh-constructor_numberInput"
            disabled
          />
        </div>
      </div>
    );
  }
}

export default TimeNote;
