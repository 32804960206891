import React from 'react';

import './style.scss';

class Field extends React.Component {
    render () {
        return (
            <div
                className="mh-constructorField"
                onDragOver={(e) => this.props.onDragOver(e)}
                onDrop={(e) => this.props.onDrop(e)}
            >
                Перенесите вопрос в это поле
            </div>
        );
    }
}

export default Field;