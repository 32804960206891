import "../../table/styles.scss";
import "./styles.scss";
import Placeholder from 'react-bootstrap/Placeholder';
import { FC } from "react";

export const TablePlaceholder: FC = () => {
    return (
        <div className="wrapper-table" id="main-page-block">
            <div className="filter">
                <Placeholder as="div" animation="glow">
                    <Placeholder className="table-placeholder_filters" xs={3} size="lg" />
                </Placeholder>            
            </div>
            <Placeholder as="div" animation="glow">
                <Placeholder className="table-placeholder_tabs" xs={4} size="lg" />
            </Placeholder>   

            <div className="table table-placeholder">
                {new Array(5).fill(0).map((_, i) => {
                    return (
                        <Placeholder as="div" animation="glow" className="table-placeholder_row" key={i}>
                            <Placeholder style={{ width: '40px' }} size="lg" />{' '}
                            <Placeholder xs={2} size="lg" />{' '}
                            <Placeholder xs={2} size="lg" />{' '}
                            <Placeholder xs={3} size="lg" />{' '}
                            <Placeholder xs={2} size="lg" />{' '}
                            <Placeholder xs={1} size="lg" />
                        </Placeholder>
                    )
                })}
            </div>
        </div>
    );
}