import React, { useCallback, useEffect, useState } from "react";
//import * as ReactDOM from 'react-dom';
import "./styles.scss";
import { Pagination } from "../components/pagination";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IAvailabel, ITableProps } from "../types";
import { AllTypesButtons, Filters, TableHeader, TableRow } from "./components";
import {
  clearFilters,
  deleteItem,
  getInitTable,
  lsTableFiltersKey,
  removeContent,
  setFilterTabs,
  setToken,
  setUrl,
} from "./store/table.slice";
import { ActiveFilters } from "./components/ActiveFilters";
import { Button, Dropdown } from "react-bootstrap";

import plusIconUrl from "../../images/icons/plus.svg";
import { NavLink } from "react-router-dom";
import { TablePlaceholder } from "../components/tablePlaceholder";
import DeleteItemModal from "../../components/deleteItemModal/DeleteItemModal";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const CustomToggle = React.forwardRef(
  ({ children, onClick }: any, ref: any) => (
    <Button
      className={"table-head__add"}
      variant={"primary"}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  )
);

export const Table = ({
  url,
  token,
  updateCount,
  hideFilters,
  hideTabs,
}: ITableProps) => {
  const dispatch = useAppDispatch();
  const { useTabs, useSearch, usePaginator, tabs, content, allowFilterByDate } =
    useAppSelector((state) => state.table.initData);

  const filters = useAppSelector((state) => state.table.filters);
  const currentSavedUrl = useAppSelector((state) => state.table.currentUrl);
  const loader = useAppSelector((state) => state.table.loader);
  const [urlChecked, setUrlChecked] = useState(false);

  const [rerenderTable, setRerenderTable] = useState<boolean>(false);

  const countRows = useAppSelector(
    (state) => state.table.initData.content?.length
  );
  //Pagination
  const { content: currentContent } = useAppSelector((state) => state.table);

  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    id: string;
    url: string;
  } | null>(null);
  const [pagination, setPagination] = useLocalStorage(`${url}pagination`, {
    active: 0,
    itemsPerPage: 10,
  });

  const handlePageClick = (event: any) => {
    setPagination((prev) => ({ ...prev, active: event.selected }));
  };

  const handleItemsPerPageChange = (perPage: number) => {
    setPagination({ active: 0, itemsPerPage: perPage });
  };

  useEffect(() => {
    const { active, itemsPerPage } = pagination;
    const newOffset = (active * itemsPerPage) % currentContent.length;
    setItemOffset(newOffset);
  }, [currentContent?.length, pagination.active, pagination.itemsPerPage]);

  useEffect(() => {
    const { itemsPerPage } = pagination;
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(currentContent.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(currentContent.length / itemsPerPage));
  }, [itemOffset, pagination.itemsPerPage, currentContent]);

  useEffect(() => {
    if (currentSavedUrl !== url) {
      dispatch(clearFilters());
    }
    setUrlChecked(true);

    return () => {
      dispatch(removeContent());
    };
  }, []);

  useEffect(() => {
    if (urlChecked) {
      dispatch(getInitTable({ url, token }));
      dispatch(setUrl(url));
      dispatch(setToken(token));
    }
  }, [urlChecked]);

  useEffect(() => {
    setRerenderTable((prev) => !prev);
    if (countRows) {
      updateCount(countRows);
    }
  }, [content]);

  useEffect(() => {
    localStorage.setItem(
      lsTableFiltersKey,
      JSON.stringify({
        filters: filters,
        url: url,
      })
    );
  }, [filters, url]);

  const handleGetInitTable = useCallback(() => {
    dispatch(getInitTable({ url, token }));
  }, [url, token]);

  const handleTypesButton = (item: IAvailabel, tabsBy: string) => {
    const value = item.value;
    const upDate =
      value === "*"
        ? content
        : content.filter((i) => i[tabsBy]?.toString() === value);

    dispatch(setFilterTabs(upDate));
    setRerenderTable(!rerenderTable);
  };

  const handleOpenDeleteModal = (id: string, url: string) => {
    setOpenDeleteModal(true);
    setItemToDelete({ id, url });
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setItemToDelete(null);
  };

  if (loader) {
    return <TablePlaceholder />;
  }
  console.log("url", url);


  return (
    <div className={"wrapper-table"}>
      <DeleteItemModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        title="Удалить элемент"
        body="Вы действительно хотите удалить выбранный элемент?"
        onDelete={() =>
          dispatch(deleteItem({ id: itemToDelete.id, url: itemToDelete.url }))
        }
      />
      {!hideFilters && (
        <Filters
          allowFilterByDate={allowFilterByDate}
          useSearch={useSearch}
          resetCurrentPage={() =>
            setPagination((prev) => ({ ...prev, active: 0 }))
          }
        />
      )}
      {filters.length > 0 && !hideFilters && (
        <ActiveFilters
          handleGetInitTable={handleGetInitTable}
          filters={filters}
        />
      )}
      {useTabs && !hideTabs ? (
        <div className={"table-head__wrapper"}>
          <AllTypesButtons tabs={tabs} onClick={handleTypesButton} />
          {url.indexOf("/users/") > -1 && (
            <Dropdown placement={"left-end"}>
              <Dropdown.Toggle
                as={CustomToggle}
                variant="success"
                id="dropdown-basic"
              >
                <img src={plusIconUrl} alt="" />
                Добавить
              </Dropdown.Toggle>

              <Dropdown.Menu className={"table-head__menu"}>
                <Dropdown.Item
                  as={React.forwardRef(
                    ({ children, onClick }: any, ref: any) => (
                      <NavLink ref={ref} to={"/users/add"}>
                        {children}
                      </NavLink>
                    )
                  )}
                >
                  Добавить вручную
                </Dropdown.Item>
                <Dropdown.Item
                  as={React.forwardRef(
                    ({ children, onClick }: any, ref: any) => (
                      <NavLink ref={ref} to={"/users/import"}>
                        {children}
                      </NavLink>
                    )
                  )}
                >
                  Загрузить из Excel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      ) : null}

      <div className={"table"}>
        <table style={{ width: "100%" }}>
          <TableHeader />
          {currentItems && (
            <tbody>
              {currentItems.map((item: any, index) => (
                <TableRow
                  key={index}
                  data={item}
                  url={url}
                  onDelete={handleOpenDeleteModal}
                />
              ))}
            </tbody>
          )}
        </table>
        <Pagination
          usePaginator={usePaginator}
          pageCount={pageCount}
          active={pagination.active}
          handlePageClick={handlePageClick}
          setItemsPerPage={handleItemsPerPageChange}
          itemsPerPage={pagination.itemsPerPage}
        />
      </div>
    </div>
  );
};
