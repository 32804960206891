export const colors = {
    AccentColor: '#019467',
    NeutralBlue: '#97CCFF',
    AttentionYellow: '#FFE5A2',
    BlackToBlue: '#0C1E5B',
    WarningREd: '#FF837B',
    SuccessGreen: '#9BDB5A',
    LightBlue: '#EDF4FB',
    LightGrey: '#FCFCFC',
    NotActiveGrey: '#888888',
    Text: '#333333'
};
