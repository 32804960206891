import {
  ICondition,
  IFormDataItems,
} from "../researches/builderResearches/modalQuestionTerms";

interface IVisit {
  items: IFormDataItems[];
}

type IFormId = number | string;

export type IConditionMap = Record<
  IFormId,
  {
    conditions: ICondition[];
    isShow: boolean;
  }
>;

export const getConditionsMap: (visits: IVisit[]) => IConditionMap = (
  visits
) => {
  let result: IConditionMap = {};

  visits.forEach((visit) => {
 
    visit.items.forEach((visitItem) => {
      result = {
        ...result,
        [visitItem.id]: {
          conditions: visitItem.options.conditions ?? [],
          isShow: true,
        },
      };
      
      if (visitItem.type === "chapter") {
        visitItem.options.data.items.forEach((chapterItem) => {
          /* console.log(chapterItem); */
         
          result = {
            ...result,
            [chapterItem.id]: {
              conditions: chapterItem.options.conditions ?? [],
              isShow: true,
            },
          };
        
        });
      }
    });
  });

  return result;
};
