import React from "react";
import "./index.scss";

import axios from "axios";
import { format } from "date-fns";
import IMAGES from "../../../images/iconSet";
import Checkbox from "../../inputs/checkbox";
import Dates from "../../inputs/dates";
import InputFile from "../../inputs/file";
import Select from "../../inputs/select";
import InputText from "../../inputs/text";
import NotificationModal from "../../notificationModal/NotificationModal";
import ListEditor from "./listEditor";

const blockTypes = {
  chapter: "Раздел",
  textarea: "Текст",
  date: "Дата",
  table: "Таблица",
  number: "Число",
  checkbox: "Чекбокс",
  radio: "Радио",
  select: "Список",
  scale: "Шкала",
};

const types = { radio: "Радио", checkbox: "Чекбокс" };

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date.valueOf());
};

class OptionMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...props.block, isModalOpened: false };
  }

  changeRequired = (e) => {
    this.state.options.required = e.target.checked;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeTitle = (e) => {
    let value = e.target.value;

    const matchMultiSpaces = value.match(/\s{2,}/);

    if (matchMultiSpaces?.[0]) {
      value = value.replace(matchMultiSpaces[0], " ");
    }

    this.state.options.title = value;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeNote = (e) => {
    let value = e.target.value;

    const matchMultiSpaces = value.match(/\s{2,}/);

    if (matchMultiSpaces?.[0]) {
      value = value.replace(matchMultiSpaces[0], " ");
    }

    this.state.options.note = value;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeUnit = (e) => {
    let value = e.target.value;

    const matchMultiSpaces = value.match(/\s{2,}/);

    if (matchMultiSpaces?.[0]) {
      value = value.replace(matchMultiSpaces[0], " ");
    }

    this.state.options.data.unit = value;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeNumberLimit = (e) => {
    this.state.options.data.isLimit = e.target.checked;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
    console.log("limit: " + e.target.checked);
  };

  changeShowCalendar = (e) => {
    this.state.options.data.showCalendar = e.target.checked;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeLimitFrom = (e) => {
    this.state.options.data.limitFrom = e.target.value ? e.target.value : 0;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeLimitTo = (e) => {
    this.state.options.data.limitTo = e.target.value;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeCommasLimit = (e) => {
    let value = Number(e.target.value);
    if (value < 0 || value > 3) value = this.state.options.data.commasLimit;
    this.state.options.data.commasLimit = value;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeDateLimitFrom = (time) => {
    let limitFrom = null;
    if (time) {
      const limitTo = this.state.options.data.limitTo;

      const isValidLimitTo = limitTo && isValidDate(new Date(limitTo));

      limitFrom =
        !isValidLimitTo || time.getTime() <= new Date(limitTo).getTime()
          ? time.toISOString()
          : limitTo;
    }

    this.setState(
      (prev) => ({
        ...prev,
        options: { ...prev.options, data: { ...prev.options.data, limitFrom } },
      }),
      () => {
        const data = { ...this.state };
        delete data.isModalOpened;
        this.props.onChange(data);
      }
    );
  };

  changeDateLimitTo = (time) => {
    let limitTo = null;
    if (time) {
      const limitFrom = this.state.options.data.limitFrom;

      const isValidLimitFrom = limitFrom && isValidDate(new Date(limitFrom));

      limitTo =
        !isValidLimitFrom ||
        new Date(time).getTime() >= new Date(limitFrom).getTime()
          ? time.toISOString()
          : limitFrom;
    }
    this.setState(
      (prev) => ({
        ...prev,
        options: { ...prev.options, data: { ...prev.options.data, limitTo } },
      }),
      () => {
        const data = { ...this.state };
        delete data.isModalOpened;
        this.props.onChange(data);
      }
    );
  };

  changeLayout = (value) => {
    this.state.options.data.layout = value;
    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  changeList = (list) => {
    if (this.state.options.data.items) {
      this.state.options.data.items = list;
    } else {
      this.state.options.data = list;
    }

    this.setState(this.state, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  onTypeChange = (type) => {
    const stateChange = { type };
    this.setState(stateChange, () => {
      const data = { ...this.state };
      delete data.isModalOpened;
      this.props.onChange(data);
    });
  };

  onNotificationChange = (items) => {
    this.setState(
      (state) => ({
        ...state,
        options: {
          ...state.options,
          data: {
            ...state.options.data,
            items,
          },
        },
      }),
      () => {
        const data = { ...this.state };
        delete data.isModalOpened;
        this.props.onChange(data);
      }
    );
  };

  uploadFile = (file, onUploadProgress) => {
    let formData = new FormData();

    formData.append("researchAttachment", file);
    formData.append("id", this.props.researchId);

    return axios
      .post(global.apiUrl + "/common/s3upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      })
      .then((res) => {
        this.setState(
          (prev) => ({
            ...prev,
            options: {
              ...prev.options,
              files: [res.data.response],
            },
          }),
          () => {
            const data = { ...this.state };
            delete data.isModalOpened;
            this.props.onChange(data);
          }
        );
        return res.data;
      });
  };

  removeFile = (source) => {
    this.setState(
      (prev) => ({
        ...prev,
        options: {
          ...prev.options,
          files: prev.options.files.filter((file) => file.source !== source),
        },
      }),
      () => {
        const data = { ...this.state };
        delete data.isModalOpened;
        this.props.onChange(data);
      }
    );
    return Promise.resolve({ message: "ok" });
  };

  render() {
    const { state } = this;
    const { options } = state;
    const isNotify = (this.state?.options?.data?.items ?? []).some(
      (el) => el?.notification?.isNotify
    );

    return this.state ? (
      <div className="option--menu">
        <NotificationModal
          open={this.state.isModalOpened}
          onClose={() => this.setState({ isModalOpened: false })}
          title={this.state.options?.title ?? ""}
          items={this.state?.options?.data?.items ?? []}
          onNotificationChange={this.onNotificationChange}
        />
        <div className="option--menu--header">
          <h4 className="option--menu--title">{blockTypes[state.type]}</h4>
          <div className="option--menu--conditional">
            {(this.state.type === "radio" ||
              this.state.type === "checkbox") && (
              <button
                type="button"
                className={`option--menu--conditional--button option--menu--conditional--button${
                  isNotify ? " notify-yellow" : ""
                }`}
                onClick={() => this.setState({ isModalOpened: true })}
              >
                <img
                  src={isNotify ? IMAGES.thin.bell : IMAGES.constructor.bell}
                  alt=""
                />
              </button>
            )}
            <button
              type="button"
              className={
                this.props.block.options.conditions
                  ? this.props.block.options.conditions.length > 0
                    ? "option--menu--conditional--button mn-green"
                    : "option--menu--conditional--button"
                  : "option--menu--conditional--button"
              }
              onClick={this.props.addQuestionTermsModal}
            >
              {this.props.block.options.conditions ? (
                this.props.block.options.conditions.length > 0 ? (
                  <img src={IMAGES.constructor.conditionWhite} alt="" />
                ) : (
                  <img src={IMAGES.constructor.condition} alt="" />
                )
              ) : (
                <img src={IMAGES.constructor.condition} alt="" />
              )}
            </button>
            {(this.state.type === "number" || this.state.type === "date") && (
              <button
                type="button"
                className={
                  this.props.block.options.numberCondition
                    ? "option--menu--conditional--button mn-blue"
                    : "option--menu--conditional--button"
                }
                onClick={() =>
                  this.props.addNumberQuestionTermsModal(this.state.type)
                }
              >
                <img src={IMAGES.constructor.calculator} alt="" />
              </button>
            )}
          </div>
        </div>

        {state.type !== "chapter" ? (
          <div className="option--menu--block">
            <Checkbox value={options.required} onChange={this.changeRequired}>
              Обязательный
            </Checkbox>
          </div>
        ) : (
          ""
        )}

        {(state.type === "radio" || state.type === "checkbox") && (
          <div className="option--menu--block">
            <label className="option--menu--label" htmlFor="option-title">
              Тип
            </label>
            <Select
              items={types}
              onChange={this.onTypeChange}
              style={{ width: "100%" }}
              value={this.state.type}
              type="outline"
            />
          </div>
        )}
        {state.options.title !== undefined ? (
          <div className="option--menu--block">
            <label className="option--menu--label" htmlFor="option-title">
              Заголовок
            </label>
            <textarea
              className="option--menu--input"
              value={state.options.title}
              onChange={this.changeTitle}
              id="option-title"
            />
          </div>
        ) : (
          ""
        )}
        {state.options.data && state.type !== "chapter" ? (
          <>
            {state.type === "number" ? (
              <>
                {state.options.data.unit !== undefined ? (
                  <div className="option--menu--block">
                    <label
                      className="option--menu--label"
                      htmlFor="option-unit"
                    >
                      Единицы измерения
                    </label>
                    <input
                      type="text"
                      className="option--menu--input"
                      value={state.options.data.unit}
                      onChange={this.changeUnit}
                      id="option-unit"
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="option--menu--block">
                  <label
                    className="option--menu--label"
                    htmlFor="option-commasLimit"
                  >
                    Знаков после запятой
                  </label>
                  <InputText
                    type="number"
                    value={state.options.data.commasLimit}
                    onChange={this.changeCommasLimit}
                    id="option-commasLimit"
                    min={0}
                    max={3}
                  />
                </div>
                {state.options.data.isLimit !== undefined ? (
                  <div className="option--menu--block">
                    <Checkbox
                      value={state.options.data.isLimit}
                      onChange={this.changeNumberLimit}
                    >
                      Ограничить диапазон
                    </Checkbox>
                  </div>
                ) : (
                  ""
                )}
                {state.options.data.isLimit &&
                state.options.data.limitFrom !== undefined &&
                state.options.data.limitTo !== undefined ? (
                  <div className="option--menu--block">
                    <label
                      className="option--menu--label"
                      htmlFor="option-limitFrom"
                    >
                      Лимит
                    </label>
                    <div className="option--menu--limit">
                      <p className="option--menu--limitText">от</p>
                      <InputText
                        type="number"
                        value={state.options.data.limitFrom}
                        onChange={this.changeLimitFrom}
                        id="option-limitFrom"
                      />
                      <p className="option--menu--limitText">до</p>
                      <InputText
                        type="number"
                        value={state.options.data.limitTo}
                        onChange={this.changeLimitTo}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {state.type === "date" ? (
              <>
                <div className="option--menu--block">
                  <Checkbox
                    value={state.options.data.showCalendar ?? true}
                    onChange={this.changeShowCalendar}
                  >
                    Показывать календарь
                  </Checkbox>
                </div>
                {state.options.data.isLimit !== undefined ? (
                  <div className="option--menu--block">
                    <Checkbox
                      value={state.options.data.isLimit}
                      onChange={this.changeNumberLimit}
                    >
                      Ограничить диапазон
                    </Checkbox>
                  </div>
                ) : (
                  ""
                )}
                {state.options.data.isLimit &&
                state.options.data.limitFrom !== undefined &&
                state.options.data.limitTo !== undefined ? (
                  <div className="option--menu--block">
                    <label
                      className="option--menu--label"
                      htmlFor="option-limitFrom"
                    >
                      Лимит
                    </label>
                    <div className="option--menu--limit">
                      <p className="option--menu--limitText">от</p>
                      <Dates
                        className="mh-dates_input__filed"
                        defaultDate={(() => {
                          if (!state.options.data.limitFrom) return "";
                          const date = new Date(state.options.data.limitFrom);
                          if (isValidDate(date)) {
                            return format(
                              new Date(state.options.data.limitFrom),
                              "dd.MM.yyyy"
                            );
                          }
                          return "";
                        })()}
                        onDateChange={this.changeDateLimitFrom}
                      />
                    </div>
                    <div className="option--menu--limit">
                      <p className="option--menu--limitText">до</p>
                      <Dates
                        className="mh-dates_input__filed"
                        defaultDate={(() => {
                          if (!state.options.data.limitTo) return "";
                          const date = new Date(state.options.data.limitTo);
                          if (isValidDate(date)) {
                            return format(
                              new Date(state.options.data.limitTo),
                              "dd.MM.yyyy"
                            );
                          }
                          return "";
                        })()}
                        onDateChange={this.changeDateLimitTo}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {state.options.data.layout !== undefined ? (
              <div className="option--menu--block">
                <label className="option--menu--label">Расположение</label>
                <div className="option--menu--layout">
                  <button
                    type="button"
                    className={
                      "option--menu--layoutButton" +
                      (state.options.data.layout === "column"
                        ? " option--menu--layoutButton-active"
                        : "")
                    }
                    onClick={() => this.changeLayout("column")}
                  >
                    <img src={IMAGES.constructor.column} alt="" />
                  </button>
                  <button
                    type="button"
                    className={
                      "option--menu--layoutButton" +
                      (state.options.data.layout === "row"
                        ? " option--menu--layoutButton-active"
                        : "")
                    }
                    onClick={() => this.changeLayout("row")}
                  >
                    <img src={IMAGES.constructor.row} alt="" />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {state.options.data.items !== undefined ||
            Array.isArray(state.options.data) ? (
              <ListEditor
                data={
                  state.options.data.items
                    ? state.options.data.items
                    : state.options.data
                }
                onChange={this.changeList}
                type={state.type}
              />
            ) : (
              ""
            )}
            {state.options.data.headers !== undefined &&
            state.options.data.rows !== undefined ? (
              <>
                <label className="option--menu--label">
                  Конструктор таблиц
                </label>
                <button
                  className="option--menu--tableButton"
                  type="button"
                  onClick={() => this.props.onTableEditor(state)}
                >
                  <img src={IMAGES.constructor.table} alt="" /> Перейти в
                  конструктор
                </button>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {state.options.note !== undefined ? (
          <div className="option--menu--block">
            <label className="option--menu--label" htmlFor="option-note">
              Примечание
            </label>
            <textarea
              className="option--menu--input"
              value={state.options.note}
              onChange={this.changeNote}
              id="option-note"
            />
          </div>
        ) : (
          ""
        )}
        <div>
          <span className="option--menu--label">Файлы для скачивания</span>
          <InputFile
            text="Выбрать файлы"
            multiple
            files={this.state.options.files ?? []}
            uploadFile={this.uploadFile}
            removeFile={this.removeFile}
          />
        </div>
        <div className="option--menu--bottom">
          <button
            className="option--menu--delete"
            type="button"
            onClick={this.props.onDelete}
          >
            <img src={IMAGES.constructor.trash} alt="" /> Удалить
          </button>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default OptionMenu;
