import React from "react";

import SearchInput from "../../components/messages/SearchInput";
import ContactList from "../../components/messages/ContactList";
import ChatView from "../../components/messages/ChatView";
import MessageSerch from "../../components/messages/MessageSearch";

import { connect } from "react-redux";
import store from "../../store/store";

import { withRouter } from "../../components/withRouter";

import { RedirectProvider } from "../../utils/RedirectService.tsx";
import ContactListPlaceholder from "../../components/messages/ContactList/ContactListPlaceholder";

class Chat extends React.Component {
  static contextType = RedirectProvider;
  constructor(props) {
    super(props);

    if (props.search["id"]) {
      store.dispatch({
        type: "SELECT_CHAT",
        payload: {
          id: parseInt(props.search["id"]),
          chat: this.getContactById(parseInt(props.search["id"])),
        },
      });
    }

    this.state = {
      isMessageSearchActive: false,
      token: props.token,
      searchContacts: "",
      findedContacts: props.contacts ? props.contacts : [],
    };
  }

  componentDidMount() {
    document.body.onkeyup = (e) => {
      if (e.keyCode === 27) {
        if (this.props.selectedChatId !== -1) {
          store.dispatch({
            type: "SELECT_CHAT",
            payload: {
              id: -1,
              chat: null,
            },
          });
          this.props.navigate("/messages");
        }
      }
    };
  }

  componentDidUpdate = () => {
    const { state } = this;
    let haveAnUpdates = false;

    if (this.props.search["id"]) {
      const id = parseInt(this.props.search["id"]);

      if (id !== this.props.selectedChatId) {
        store.dispatch({
          type: "SELECT_CHAT",
          payload: {
            id: parseInt(id),
            chat: this.getContactById(parseInt(id)),
            callBack: () => {
              this.props.socket.emit("chat.history", {
                room_id: this.getContactById(id).roomId,
              });
            },
          },
        });
      }
    } else if (this.props.selectedChatId > -1) {
      store.dispatch({
        type: "SELECT_CHAT",
        payload: {
          id: -1,
          chat: null,
        },
      });
    }

    if (
      state.searchContacts === "" &&
      state.findedContacts.length === 0 &&
      this.props.contacts.length > 0
    ) {
      state.findedContacts = this.props.contacts;
      haveAnUpdates = true;
    }

    if (haveAnUpdates) {
      this.setState(state);
    }
  };

  componentWillUnmount = () => {
    document.body.onkeyup = null;
  };

  selectChat(chatId) {
    this.props.navigate("/messages/?id=" + chatId);
  }

  attachments() {
    store.dispatch({
      type: "PUSH_NOTIFICATION",
      payload: {
        type: "info",
        title: "Прикрепление файлов",
        text: "Данная функция находится в разработке.",
      },
    });
  }

  sendMessage = (text) => {};

  setContactList(newContactList) {
    this.setState((state) => (state.contactList = newContactList));
  }

  changeMessageSerchState(searchState) {
    this.setState((state) => (state.isMessageSearchActive = searchState));
  }

  changeSearchContact = (value) => {
    let { state } = this;
    state.searchContacts = value;
    this.setState(state, () => {
      if (this.props.contacts) {
        let { state } = this;
        let findedContacts = [];

        if (value) {
          this.props.contacts.forEach((contact) => {
            if (contact) {
              let { name, patronymic, surname, spec } = contact;
              let searchString = "";

              searchString += name && name !== "null" ? name + " " : "";
              searchString +=
                patronymic && patronymic !== "null" ? patronymic + " " : "";
              searchString +=
                surname && surname !== "null" ? surname + " " : "";
              searchString += spec && spec !== "null" ? spec + " " : "";

              if (
                searchString
                  .toLowerCase()
                  .indexOf(state.searchContacts.toLowerCase()) > -1
              ) {
                findedContacts.push(contact);
              }
            }
          });
        } else {
          findedContacts = this.props.contacts;
        }

        state.findedContacts = findedContacts;
        this.setState(state);
      }
    });
  };

  getContactById = (id) => {
    return this.props.contacts.find((contact) => {
      if (contact.id === id) {
        return contact;
      }
      return null;
    });
  };

  render() {
    const { isMessageSearchActive, searchContacts, findedContacts } =
      this.state;

    const { selectedChatId, selectedChat, contacts, loading } = this.props;

    return (
      <div className="chat-Wrapper">
        <div className="chat-Contacts">
          <div className="chat-Contacts_search">
            <SearchInput
              value={searchContacts}
              onChange={(e) => {
                this.changeSearchContact(e.target.value);
              }}
            />
          </div>
          {
            loading 
              ? <ContactListPlaceholder /> 
              : <ContactList
                  contacts={searchContacts !== "" ? findedContacts : contacts}
                  onChatSelect={(id) => this.selectChat(id)}
                  isSearch={searchContacts !== ""}
                />
          }
        </div>
        {selectedChatId === -1 || !selectedChat ? (
          <div className="chat-Area"></div>
        ) : (
          <ChatView
            className="chat-Area"
            onMessageSearchClick={() => this.changeMessageSerchState(true)}
            onAttachment={() => this.attachments()}
            onSend={this.sendMessage}
            onReadCallback={() => {
              this.changeSearchContact(searchContacts);
            }}
          />
        )}
        <MessageSerch
          className={
            "chat-MessageSearch" +
            (isMessageSearchActive ? " chat-MessageSearch__active" : "")
          }
          onClose={() => this.changeMessageSerchState(false)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dialogs: state.dialogs,
    contacts: state.contacts,
    selectedChatId: state.selectedChatId,
    selectedChat: state.selectedChat,
    messages: state.messages,
    socket: state.io,
    usersOnline: state.usersOnline,
    loading: state.loading
  };
};

export default withRouter(connect(mapStateToProps)(Chat));
