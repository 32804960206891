import { FC, useCallback, useMemo, useState } from "react";

import { v4 } from "uuid";
import IMAGES from "../../../../images/iconSet";
import { ConditionItem } from "./ConditionItem";
import styles from "./ModalQuestionTerms.module.scss";

export interface ICondition {
  questionId?: string | number | undefined; // id Вопроса
  condition: "equal" | "more" | "less" | "less_or_equal" | "more_or_equal";
  id: string; // id Условия
}

export interface ILayoutItem {
  id: string;
  initialValue: boolean;
  text: string;
}

type IFormDataBaseOptions = {
  note: string;
  required: boolean;
  title: string;
  conditions: ICondition[];
  notification: {
    isNotify: boolean;
    notifyDate: any;
  };
};

type IFormDataCheckbox = {
  id: number;
  type: "checkbox";
  options: {
    data: {
      layout: string;
      items: ILayoutItem[];
    };
  } & IFormDataBaseOptions;
};

type IFormDataRadio = {
  id: number;
  type: "radio";
  options: {
    data: {
      layout: string;
      items: ILayoutItem[];
    };
  } & IFormDataBaseOptions;
};

type IFormDataList = {
  id: number;
  type: "list";
  options: {
    data: Array<{
      id: string;
      value: string;
    }>;
  } & IFormDataBaseOptions;
};

type IFormDataScale = {
  id: number;
  type: "scale";
  options: {
    data: Array<{
      id: string;
      value: string;
    }>;
  } & IFormDataBaseOptions;
};

type IFormDataNumber = {
  id: number;
  type: "number";
  options: {
    data: {
      isLimit: boolean;
      limitFrom: number;
      limitTo: number;
      unit: string;
    };
  } & IFormDataBaseOptions;
};

type IFormDataTextArea = {
  id: number;
  type: "textarea";
  options: {} & IFormDataBaseOptions;
};

type IFormDataTable = {
  id: number;
  type: "table";
  options: { data: any } & IFormDataBaseOptions;
};

export type IDefaultItems =
  | IFormDataNumber
  | IFormDataTextArea
  | IFormDataCheckbox
  | IFormDataRadio
  | IFormDataList
  | IFormDataScale
  | IFormDataTable;

export interface IFormDataNumberIndexed extends IFormDataNumber {
  index: string;
  visitIndex: number;
}
export interface IFormDataTextAreaIndexed extends IFormDataTextArea {
  index: string;
  visitIndex: number;
}
export interface IFormDataCheckboxIndexed extends IFormDataCheckbox {
  index: string;
  visitIndex: number;
}
export interface IFormDataRadioIndexed extends IFormDataRadio {
  index: string;
  visitIndex: number;
}
export interface IFormDataListIndexed extends IFormDataList {
  index: string;
  visitIndex: number;
}
export interface IFormDataScaleIndexed extends IFormDataScale {
  index: string;
  visitIndex: number;
}
export interface IFormDataTableIndexed extends IFormDataTable {
  index: string;
  visitIndex: number;
}

export type IDefaultItemsIndexed =
  | IFormDataNumberIndexed
  | IFormDataTextAreaIndexed
  | IFormDataCheckboxIndexed
  | IFormDataRadioIndexed
  | IFormDataListIndexed
  | IFormDataScaleIndexed
  | IFormDataTableIndexed;

type IFormDataChapter = {
  id: number;
  type: "chapter";
  options: {
    conditions: ICondition[];
    notification: {
      isNotify: boolean;
      notifyDate: any;
    };
    title: string;
    data: {
      items: Array<IDefaultItems>;
    };
  };
};

export type IFormDataItems = IDefaultItems | IFormDataChapter;

interface IFormData {
  items: IFormDataItems[];
}

interface Props {
  questionId: number;
  currentData: IFormData[];
  action: () => void;
  setConditionsToBlock: (conditions: ICondition) => void;
  initialConditions: ICondition;
  type?: "number" | "date";
}

export const ModalNumberDateQuestionTerms: FC<Props> = ({
  questionId,
  currentData,
  action,
  setConditionsToBlock,
  initialConditions,
  type = "number",
}) => {
  const availableVisits: number[] = useMemo(() => {
    return currentData.map((el, index) => index);
  }, [currentData]);

  const availableAnswers: IDefaultItemsIndexed[] = useMemo(() => {
    let result: IDefaultItemsIndexed[] = [];
    currentData.forEach((itemList, visitIndex) => {
      itemList.items.forEach((dataItem, index) => {
        if (!(dataItem.type === "chapter" && dataItem.id === questionId)) {
          if (dataItem.type === "chapter") {
            dataItem.options.data.items.forEach((chapterItem, chapterIndex) => {
              if (chapterItem.id !== questionId) {
                result.push({
                  ...chapterItem,
                  index: `${index + 1}.${chapterIndex + 1}`,
                  visitIndex: visitIndex + 1,
                });
              }
            });
          } else {
            if (dataItem.id !== questionId) {
              result.push({
                ...dataItem,
                index: `${index + 1}`,
                visitIndex: visitIndex + 1,
              });
            }
          }
        }
      });
    });
    return result.filter((q) => q.type === type);
  }, [currentData, questionId]);

  const currentQuestion = useMemo(() => {
    let question = null;

    currentData.forEach((itemList) => {
      itemList.items.forEach((item, index) => {
        if (item.id === questionId)
          question = { ...item, index: `${index + 1}` };
        if (item.type === "chapter") {
          item.options.data.items.forEach((chaptItem, childIndex) => {
            if (chaptItem.id === questionId)
              question = { ...item, index: `${index + 1}.${childIndex + 1}` };
          });
        }
      });
    });
    return question;
  }, [currentData, questionId]);

  const [condition, setConditions] = useState<ICondition | null>(
    initialConditions
      ? initialConditions
      : {
          condition: "equal",
          id: v4(),
        }
  );

  const handleEditCondition = useCallback((condition: ICondition) => {
    setConditions(condition);
  }, []);

  const handleSave = useCallback(
    (condition: ICondition) => {
      setConditionsToBlock(condition);
    },
    [setConditionsToBlock]
  );

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        Вопрос {currentQuestion.index} {currentQuestion.options.title}
      </span>
      <span className={styles.title}>Ответ зависит от</span>
      <div className={styles.conditionList}>
        <ConditionItem
          condition={condition}
          availableAnswers={availableAnswers}
          handleEditCondition={handleEditCondition}
          availableVisits={availableVisits}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <button
          className={styles.button}
          type={"button"}
          onClick={(e) => {
            e.preventDefault();
            handleSave(condition);
          }}
        >
          Сохранить
        </button>
        <button
          className={`${styles.button} ${styles.delete}`}
          type={"button"}
          onClick={(e) => {
            e.preventDefault();
            setConditionsToBlock(null);
            action();
          }}
        >
          <img src={IMAGES.constructor.trash} alt="" /> Удалить
        </button>
      </div>
    </div>
  );
};
