import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { RD, CiD, Doctors, ActionsTable, CountPicker, PaginationBasic } from "../../components/ddTest.js";
import Stack from 'react-bootstrap/Stack';
import icon from '../../images/iconSet';
import UserComponent from '../../components/userComponent';
import React, { Component } from "react";
export class Users extends React.Component {
  GetUserCount(){

  }
   render() {
      return (
         <div className="container-fluid" id="main-page-block">
          <UserComponent id={JSON.parse(sessionStorage.getItem('token')).insecureid}/>

         </div>
      )
   }
}

export default Users;
