import "./index.scss";

import _ from "lodash";
import React from "react";

import Accordion from "../../../components/accordion";
import BuilderResearches from "../../../components/researches/builderResearches";
import NewResearches from "../../../components/researches/newResearches";
import PatientCodeResearches from "../../../components/researches/patientCodeResearches";
import UserResearches from "../../../components/researches/userResearches";
import StatusBadge from "../../../components/statusBadge";

import { withRouter } from "../../../components/withRouter";
import store from "../../../store/store";
import { customFetch } from "../../../utils/http";

export class ResearchDesigner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {},
      form: { visits: [{ items: [] }] },
      files: [],
      id: 0,
      synchronizationAttempts: 3,
      isReady: false,
    };

    this.debouncedSendUpdate = _.debounce(this.sendUpdate, 10000);
  }

  componentDidMount = () => {
    if (this.props.mode === "create" || !this.props.mode) {
      const { state } = this;

      customFetch(global.apiUrl + "/research/save/", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            info: { id: 0 },
            form: this.state.form,
          },
        }),
      }).then((response) => {
        response
          .json()
          .then((json) => {
            const { accepted } = json;

            if (accepted !== 0) {
              if (accepted !== 1) {
                state.id = accepted;
                state.isReady = true;

                this.setState(this.state, () => {
                  this.props.navigate("/research/draft/" + accepted);
                });
              }
            } else {
              if (state.synchronizationAttempts > 0) {
                state.synchronizationAttempts--;
                this.setState(state, this.sendUpdate);
              } else {
                store.dispatch({
                  type: "PUSH_NOTIFICATION",
                  payload: {
                    type: "error",
                    title: "Ошибка",
                    text: "Не получается записать изменения. Попробуйте позже.",
                  },
                });
                state.synchronizationAttempts = 3;
                this.setState(this.state);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      const id = this.props.params.id;
      let url, data;

      if (this.props.mode === "draft") {
        url = global.apiUrl + "/research/draft/load/";
        data = JSON.stringify({
          data: {
            id: id,
          },
          // token: JSON.parse(sessionStorage.getItem("token")),
        });
      } else if (this.props.mode === "edit") {
        url = global.apiUrl + "/research/listtable/";
        data = JSON.stringify({
          id,
          // token: JSON.parse(sessionStorage.getItem("token")),
        });
      } else return;

      if (id) {
        customFetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: data,
        })
          .then((resp) => {
            resp
              .json()
              .then((json) => {
                if (!json.error) {
                  if (this.props.mode !== "edit") {
                    this.state.info = json.info;
                    this.state.form = json.form;
                    this.state.isReady = true;
                    this.state.id = id;
                    this.setState(this.state);
                  } else {
                    this.state.info = {
                      id: json.id,
                      name: json.name,
                      dateFrom:
                        json.date_start && json.date_start !== "undefined"
                          ? json.date_start
                          : null,
                      dateTo:
                        json.until_expired && json.until_expired !== "undefined"
                          ? json.until_expired
                          : null,
                      lastDeadline:
                        json.lastDeadline && json.lastDeadline !== "undefined"
                          ? json.lastDeadline
                          : null,
                      anketsLimit: json.anketsLimit,
                      description: json.announce,
                      foreignId: json.foreignId,
                      object: json.object,
                      requestor: json.requestor,
                      regions: json.regions
                        ? json.regions.split(",").map((i) => parseInt(i))
                        : [],
                      admins: json.admins
                        ? json.admins.split(",").map((i) => parseInt(i))
                        : [],
                      moderators:
                        json.moderators && json.moderators !== "undefined"
                          ? json.moderators.split(",").map((i) => parseInt(i))
                          : [],
                      monitors: json.monitor
                        ? json.monitor.split(",").map((i) => parseInt(i))
                        : [],
                      doctors: json.doctors
                        ? json.doctors.split(",").map((i) => parseInt(i))
                        : [],
                      requestorRepresentatives: json.requestorRepresentatives
                        ? json.requestorRepresentatives
                            .split(",")
                            .map((i) => parseInt(i))
                        : [],
                      doctorsLimit: json.limits,
                      patientCodeSettings:
                        json.patientCodeSettings &&
                        json.patientCodeSettings !== "undefined" &&
                        json.patientCodeSettings !== "null"
                          ? JSON.parse(json.patientCodeSettings)
                          : {
                              patientCode: true,
                              placeholder: "",
                              type: "text",
                              isLimit: false,
                              limitFrom: "0",
                              limitTo: "999",
                              note: "",
                            },
                      files:
                        json.files &&
                        json.files !== "undefined" &&
                        json.files !== "null"
                          ? JSON.parse(json.files)
                          : [],
                      moderate:
                        json.moderate !== "undefined"
                          ? !!JSON.parse(json?.moderate)
                          : false,
                      notifyMail:
                        json.notifyMail !== "undefined"
                          ? JSON.parse(json?.notifyMail)
                          : [],
                    };
                    this.state.isReady = true;
                    this.state.id = json.id;
                    this.state.form = JSON.parse(json.form);
                    this.setState(this.state);
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.state.isReady = true;
        this.setState(this.state);
      }
    }
  };

  sendUpdate = () => {
    if (this.state.isReady) {
      const info = _.cloneDeep(this.state.info);
      info.id = this.state.id;

      let url,
        data,
        callback = () => {};

      if (this.props.mode === "edit") {
        url = global.apiUrl + "/research/save/publish/";
        data = JSON.stringify({
          data: {
            info,
            form: this.state.form,
            edit: true,
          },
          // token: JSON.parse(sessionStorage.getItem("token")),
        });
      } else {
        url = global.apiUrl + "/research/save/";

        data = JSON.stringify({
          data: {
            info,
            form: this.state.form,
          },
          // token: JSON.parse(sessionStorage.getItem("token")),
        });
      }

      customFetch(url, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: data,
      }).then((response) => {
        response
          .json()
          .then((json) => {
            const { accepted, msg } = json;

            if (accepted !== 0 || msg === "1") {
              if (accepted !== 1 && !msg) {
                this.state.id = accepted;
                callback(this.state.id);
              }

              this.setState(this.state);
            } else {
              if (this.state.synchronizationAttempts > 0) {
                this.state.synchronizationAttempts--;
                this.setState(this.state, this.sendUpdate);
              } else {
                store.dispatch({
                  type: "PUSH_NOTIFICATION",
                  payload: {
                    type: "error",
                    title: "Ошибка",
                    text: "Не получается записать изменения. Попробуйте позже.",
                  },
                });
                this.state.synchronizationAttempts = 3;
                this.setState(this.state);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  onConstructorChange = (data) => {
    this.setState((state) => {
      state.form = data;
      return { ...state };
    }, this.debouncedSendUpdate);
  };

  onInfoChange = (data) => {
    console.log("onInfoChange");
    this.setState((state) => {
      console.log("data", data);
      return {
        info: {
          ...state.info,
          ...data,
        },
      };
    }, this.debouncedSendUpdate);
  };

  onPatientCodeSettingsChange = (data) => {
    this.onInfoChange({
      patientCodeSettings: {
        ...data,
        limitFrom: Number(data.limitFrom),
        limitTo: Number(data.limitTo),
      },
    });
  };

  onResearchSubmit = () => {
    if (global.confirm("Опубликовать?")) {
      const info = {
        ...this.state.info,
        id: this.state.id,
      };
      console.log("info", info);
      customFetch(global.apiUrl + "/research/save/", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            info,
            form: this.state.form,
          },
        }),
      }).then((response) => {
        customFetch(global.apiUrl + "/research/save/publish/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              info,
              form: this.state.form,
            },
          }),
        })
          .then((resp) => {
            resp
              .json()
              .then((json) => {
                if (json.msg >= 1) {
                  store.dispatch({
                    type: "PUSH_NOTIFICATION",
                    payload: {
                      type: "success",
                      title: "Опубликовано!",
                      text: "Исследование опубликовано.",
                    },
                  });
                  this.props.navigate("/research/edit/" + json.msg);
                } else {
                  store.dispatch({
                    type: "PUSH_NOTIFICATION",
                    payload: {
                      type: "error",
                      title: "Ошибка",
                      text: "Что-то пошло не так. Повторите попытку позже.",
                    },
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  onResearchDelete = () => {
    if (global.confirm("Удалить?")) {
      customFetch(global.apiUrl + "/research/delete/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            id: this.state.id,
          },
        }),
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.message === "ok") {
            store.dispatch({
              type: "PUSH_NOTIFICATION",
              payload: {
                type: "success",
                title: "",
                text: "Исследование удалено.",
              },
            });
          }
        })
        .catch((err) => {
          store.dispatch({
            type: "PUSH_NOTIFICATION",
            payload: {
              type: "error",
              title: "",
              text: "При удалении исследования произошла ошибка.",
            },
          });
          console.log(err);
        });
    }
  };

  render() {
    const { info, files, form, id } = this.state;
    const { mode } = this.props;
    const reserachHeader = (
      <div className="disigner_tabStatus">
        <span className="disigner_tabStatusText">Исследование</span>
        {mode ? (
          <StatusBadge
            state={
              mode === "create" ? "draft" : mode === "edit" ? "publish" : mode
            }
          >
            {mode === "edit"
              ? "Опубликовано"
              : mode === "draft" || mode === "create"
              ? "Черновик"
              : "В архиве"}
          </StatusBadge>
        ) : (
          ""
        )}
      </div>
    );

    const newResearchComponent = (
      <NewResearches
        onChange={this.onInfoChange}
        onUpdate={this.sendUpdate}
        onSubmit={this.onResearchSubmit}
        onDelete={this.onResearchDelete}
        status={mode}
        data={this.state.info}
        id={id}
        key={this.state.isReady}
      />
    );

    return (
      <form className="disigner">
        {!this.state.isReady && !this.state.id ? (
          <div
            style={{
              width: "100%",
              padding: "30% 0px",
              textAlign: "center",
              fontSize: "24px",
            }}
          >
            Загрузка...
          </div>
        ) : (
          <>
            <Accordion
              style={{ marginBottom: "20px" }}
              id="accordion-1"
              title={reserachHeader}
              content={newResearchComponent}
              data={info}
              files={files}
              defaultOpen={true}
            />
            {mode != "draft" && (
              <>
                <Accordion
                  style={{ marginBottom: "20px" }}
                  id="accordion-2"
                  title="Пользователи"
                  content={
                    <UserResearches
                      data={this.state.info}
                      onChange={this.onInfoChange}
                      researchIf={id}
                      key={this.state.isReady}
                    />
                  }
                  data={info}
                  defaultOpen={true}
                />
                <Accordion
                  id="accordion-3"
                  title="Конструктор анкет"
                  content={
                    <>
                      <PatientCodeResearches
                        onChange={this.onPatientCodeSettingsChange}
                        data={this.state.info.patientCodeSettings}
                      />
                      <BuilderResearches
                        form={this.state.form}
                        onChange={this.onConstructorChange}
                        key={this.state.isReady}
                        researchId={id}
                      />
                    </>
                  }
                  form={form}
                  defaultOpen={true}
                />
              </>
            )}
          </>
        )}
      </form>
    );
  }
}

export default withRouter(ResearchDesigner);
