import "./index.scss";
import React from "react";

import EmptyField from "../../builderResearches/emptyField";

class Chapter extends React.Component {
   render() {
      return ""
   }
}

export default Chapter;
