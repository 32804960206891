import {Navbar, Nav, NavItem, Button, Container, Row, Col, Breadcrumb, Table, Input, Dropdown, Pagination} from 'react-bootstrap';
import { Link } from "react-router-dom";

import React, {Component} from 'react';
export  function DD(){
  return (
    <>
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Врачи
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href=""><input type ='checkbox' />Просекин И Б <span>IDHOLDER</span></Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Просекин И Б <span>IDHOLDER</span></Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Просекин И Б <span>IDHOLDER</span></Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Просекин И Б <span>IDHOLDER</span></Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Просекин И Б <span>IDHOLDER</span></Dropdown.Item>
        <Button variant='primary'>Применить</Button>
      </Dropdown.Menu>
    </Dropdown>
  </>
)
}
export  function SaD(){
  return (
    <>
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Врачи
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href=""><input type ='checkbox' />Черновик </Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />На проверке </Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Доработать </Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Принято</Dropdown.Item>
        <Button variant='primary'>Применить</Button>
      </Dropdown.Menu>
    </Dropdown>
  </>
)
}
export  function RD(){
  return (
    <>
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Исследования
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href=""><input type ='checkbox' />Опрос врачей<span>IDHOLDER</span></Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Опрос врачей<span>IDHOLDER</span></Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Опрос врачей<span>IDHOLDER</span></Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Опрос врачей<span>IDHOLDER</span></Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />Опрос врачей<span>IDHOLDER</span></Dropdown.Item>
        <Button variant='primary'>Применить</Button>

      </Dropdown.Menu>
    </Dropdown>
  </>
)
}
export  function RaD(){
  return (
    <>
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Заказчик
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href=""><input type ='checkbox' />ООО МАНИПУЛА</Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />ООО КОГОРТА</Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />ООО ПЕРТУРАБО</Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />ООО МАНИПУЛА</Dropdown.Item>
        <Dropdown.Item href=""><input type ='checkbox' />ООО КОГОРТА</Dropdown.Item>
        <Button variant='primary'>Применить</Button>
      </Dropdown.Menu>
    </Dropdown>
  </>
)
}
export  function DaD(){
  return (
    <>
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Даты
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <button variant='primary'>Применить</button>
      </Dropdown.Menu>
    </Dropdown>
  </>
)
}
export class CiD extends React.Component {
  constructor({data}) {
    super(data);
    this.state = {
      hits: []
    };
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick(event) {
    console.log(event.target);

  }
  componentDidMount() {
    fetch("https://mnemohuman.insanedeveloper.ru/api/cities/list/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
    .then(response => response.json())
    .then(data => this.setState({ hits: data }));
  };
  render() {
    const { hits } = this.state;
    const DisplayData=hits.map(
        (info)=>{
            info = JSON.parse(info);
            return(
              <Dropdown.Item onClick={this.handleClick}>
              <input type ='checkbox' data-id={info.id}  />{info.value}
              </Dropdown.Item>
            )
        }
    )
    return (
    <>
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Город
      </Dropdown.Toggle>
    <Dropdown.Menu>
        {DisplayData}
    </Dropdown.Menu>
    </Dropdown>
    </>
    )
  }
}
export function ActionsTable(){
  return (
    <>
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Действия
      </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item href="">Удалить</Dropdown.Item>
      <Dropdown.Item href="">Отправить на проверку</Dropdown.Item>
      <Dropdown.Item href="">Открыть в редакторе</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </>
)
}


export class Doctors extends React.Component {
  constructor({data, filter, tts, offset, limit}) {
    super(data);
    this.state = {
      hits: [],
      filter: filter || '',
      tts: tts || "",
      offset: offset | "",
      limit: limit | ""
    };
  }
  componentDidMount() {
    const { filter } = this.state;
    const { offset } = this.state;
    const { tts } = this.state;

    const { limit } = this.state;
    fetch("https://mnemohuman.insanedeveloper.ru/api/user/list", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {role: filter,
           offset: offset,
           tts: tts,
           limit: limit
          })
      })
    .then(response => response.json())
    .then(data => this.setState({ hits: data }));
  };
  componentDidUpdate(prevProps, prevState) {
    console.log(prevProps);
    console.log(this.props);
    if(this.props.tts!=prevProps.tts){
      const { filter } = this.props;
      const { offset } = this.props;
      const tts  = this.props.tts;
      const { limit } = this.props;
      fetch("https://mnemohuman.insanedeveloper.ru/api/user/list", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {role: filter,
           offset: offset,
           tts: tts,
           limit: limit
          })
      })
    .then(response => response.json())
    .then(data => this.setState({ hits: data }));
  }
  };
  render() {
    const { hits } = this.state;
    const { tts } = this.props;
    const { filter } = this.state;
    const DisplayData=hits.map(
        (info)=>{
            info = JSON.parse(info);
            return(
              <tr>
                <td><input type = 'checkbox' data-id={info.id}/> </td>
                <td>{info.id}</td>
                <td>{info.sn}</td>
                <td>{info.name} {info.surname}</td>
                <td>STATUS_HOLDER</td>
                <td>{info.role}</td>
                <td>{info.email}</td>
                <td>{info.city}</td>
                <td>{info.researches}</td>
            </tr>
            )
        }
    )
    return (
      <>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th><input type = 'checkbox' /> </th>
          <th>№</th>
          <th>Таб №</th>
          <th>ФИО</th>
          <th>Статус</th>
          <th>Роль</th>
          <th>email</th>
          <th>Город</th>
          <th>Исследования</th>
        </tr>
      </thead>
      <tbody>
        {DisplayData}
      </tbody>
      </Table>
    </>
    )
  }
}

export function CustomersTable(){
  return (
    <>
    <Table striped bordered hover>
    <thead>
      <tr>
        <th><input type = 'checkbox' /> </th>
        <th>ID</th>
        <th>Название</th>
        <th>Представитель</th>
        <th>Исследования</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1</td>
        <td>Хеель</td>
        <td>Просекин И.Б.</td>
        <td><a href = ''>Лимфомиозот</a> <a href = ''>Лимфомиозот</a></td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1</td>
        <td>Хеель</td>
        <td>Просекин И.Б.</td>
        <td><a href = ''>Лимфомиозот</a> <a href = ''>Лимфомиозот</a></td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1</td>
        <td>Хеель</td>
        <td>Просекин И.Б.</td>
        <td><a href = ''>Лимфомиозот</a> <a href = ''>Лимфомиозот</a></td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1</td>
        <td>Хеель</td>
        <td>Просекин И.Б.</td>
        <td><a href = ''>Лимфомиозот</a> <a href = ''>Лимфомиозот</a></td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1</td>
        <td>Хеель</td>
        <td>Просекин И.Б.</td>
        <td><a href = ''>Лимфомиозот</a> <a href = ''>Лимфомиозот</a></td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1</td>
        <td>Хеель</td>
        <td>Просекин И.Б.</td>
        <td><a href = ''>Лимфомиозот</a> <a href = ''>Лимфомиозот</a></td>
        <td>AP</td>
      </tr>



    </tbody>
    </Table>
  </>
)
}
export  class ResTable extends React.Component{
  constructor({data, filter, tts, offset, limit}) {
    super(data);
    this.state = {
      hits: [],
      tts: tts || "",
    };
  }
  componentDidMount() {
    const { tts } = this.state;
    const {hits} = this.state;
    fetch("https://mnemohuman.insanedeveloper.ru/api/research/list", {
        method: "post",
        body: JSON.stringify(
          {tts: tts})
      })
    .then(response => response.json())
    .then(data => this.setState({ hits: data }));
  };
  componentDidUpdate(prevProps, prevState) {
    if(this.props.tts!=prevProps.tts){
      const { filter } = this.props;
      const { offset } = this.props;
      const tts  = this.props.tts;
      const { limit } = this.props;
      fetch("https://mnemohuman.insanedeveloper.ru/api/research/list", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {tts: tts})
      })
    .then(response => response.json())
    .then(data => this.setState({ hits: data }));
  }
  };
  render(){
    const { hits } = this.state;
    const { tts } = this.props;
    const { filter } = this.state;
    const DisplayData=hits.map(
        (info)=>{
            console.log(info);
            return(
              <tr>
                <td><input type = 'checkbox' /> </td>
                <td>{info.id}</td>
                <td><a href={"/researches/research?id="+info.id}>{info.name} <span>{info.object}</span></a></td>
                <td><a href = ''>Сплюшка Е.А.</a></td>
                <td>1</td>
                <td>23</td>
                <td>29.07.2022</td>
                <td>Оффлайн</td>
                <td><a href = {'/researches/editor?id='+info.id}>Редактировать</a></td>
              </tr>
            )
        }
    )
  return (
    <>
    <Table striped bordered hover>
    <thead>
      <tr>
        <th><input type = 'checkbox' /> </th>
        <th>ID</th>
        <th>Исследование</th>
        <th>Врач</th>
        <th>Визиты</th>
        <th>Код пацента</th>
        <th>Изменено</th>
        <th>Статус</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {DisplayData}
    </tbody>
    </Table>
  </>
)
}
}
export  function SResTable(){
  return (
    <>
    <Table striped bordered hover>
    <thead>
      <tr>
        <th><input type = 'checkbox' /> </th>
        <th>ID</th>
        <th>Наименование</th>
        <th>Заказчик</th>
        <th>Сроки</th>
        <th>Врачи</th>
        <th>Анкеты</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1502</td>
        <td><a href = '/researches/research'>MULTISPECT <span>Множественная миелонома</span></a></td>
        <td>Джонсон и не Джонсон</td>
        <td>4 мая <span>ALERT_PLACEHOLDER</span></td>
        <td>999 из 10000</td>
        <td>6868 из 9000</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1502</td>
        <td><a href = '/researches/research'>MULTISPECT <span>Множественная миелонома</span></a></td>
        <td>Джонсон и не Джонсон</td>
        <td>4 мая <span>ALERT_PLACEHOLDER</span></td>
        <td>999 из 10000</td>
        <td>6868 из 9000</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1502</td>
        <td><a href = '/researches/research'>MULTISPECT <span>Множественная миелонома</span></a></td>
        <td>Джонсон и не Джонсон</td>
        <td>4 мая <span>ALERT_PLACEHOLDER</span></td>
        <td>999 из 10000</td>
        <td>6868 из 9000</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1502</td>
        <td><a href = '/researches/research'>MULTISPECT <span>Множественная миелонома</span></a></td>
        <td>Джонсон и не Джонсон</td>
        <td>4 мая <span>ALERT_PLACEHOLDER</span></td>
        <td>999 из 10000</td>
        <td>6868 из 9000</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1502</td>
        <td><a href = '/researches/research'>MULTISPECT <span>Множественная миелонома</span></a></td>
        <td>Джонсон и не Джонсон</td>
        <td>4 мая <span>ALERT_PLACEHOLDER</span></td>
        <td>999 из 10000</td>
        <td>6868 из 9000</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1502</td>
        <td><a href = '/researches/research'>MULTISPECT <span>Множественная миелонома</span></a></td>
        <td>Джонсон и не Джонсон</td>
        <td>4 мая <span>ALERT_PLACEHOLDER</span></td>
        <td>999 из 10000</td>
        <td>6868 из 9000</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1502</td>
        <td><a href = '/researches/research'>MULTISPECT <span>Множественная миелонома</span></a></td>
        <td>Джонсон и не Джонсон</td>
        <td>4 мая <span>ALERT_PLACEHOLDER</span></td>
        <td>999 из 10000</td>
        <td>6868 из 9000</td>
        <td>AP</td>
      </tr>
      <tr>
        <td><input type = 'checkbox' /> </td>
        <td>1502</td>
        <td><a href = '/researches/research'>MULTISPECT <span>Множественная миелонома</span></a></td>
        <td>Джонсон и не Джонсон</td>
        <td>4 мая <span>ALERT_PLACEHOLDER</span></td>
        <td>999 из 10000</td>
        <td>6868 из 9000</td>
        <td>AP</td>
      </tr>
    </tbody>
    </Table>
  </>
)
}
export  function CountPicker(){
  return (
    <>
    <Dropdown className="d-inline">
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        10
      </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item href="">10</Dropdown.Item>
      <Dropdown.Item href="">20</Dropdown.Item>
      <Dropdown.Item href="">50</Dropdown.Item>
      <Dropdown.Item href="">100</Dropdown.Item>
      <Dropdown.Item href="">500</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </>
)
}

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}
export function PaginationBasic(){
  return (
    <div>
      <Pagination>{items}</Pagination>
      <br />
    </div>
  )
}
