import React from "react";

import style from './style.module.scss';

class UserAvatar extends React.Component {


    render = () => {

        const {img, name, surname, size, id, className} = this.props;
        
        return (
            <div className={style["mh-userAvatar"]+(className ? " "+className : "")} style={{
                minWidth: size ? size + "px" : "80px",
                minHeight: size ? size + "px" : "80px",
                maxWidth: size ? size + "px" : "80px",
                maxHeight: size ? size + "px" : "80px",
                fontSize: !img || img === "null" ? (size ? size : 48) / 2 + "px" : null,
                lineHeight: !img || img === "null" ? (size ? size : 48) / 2 + "px" : null,
                backgroundColor: "var(--allowed-color-" + (id ? id : 1) % 10 + ")"
            }}>
                {
                    img && img != "null" ? (
                        <img src={img} alt="" />
                    )
                    :
                    (
                        
                        (name ? name[0].toUpperCase() : '') + (surname ? surname[0].toUpperCase() : '')
                    )
                }
            </div>
        )
    }
}

export default UserAvatar;