import "./index.scss";
import React from "react";

class NumberNote extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className="mh-constructor_number">
        <div className="mh-constructor_numberArea">
          <input
            type="number"
            className="mh-constructor_numberInput"
            value={data.isLimit && data.limitFrom ? data.limitFrom : 0}
            onChange={() => { }}
          />
          <span className="mh-constructor_numberUnit">{data.unit}</span>
        </div>
        {data.isLimit ? (
          <p className="mh-constructor_numberLimit">
            Введите число в диапазоне от {data.limitFrom} до {data.limitTo}
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default NumberNote;
