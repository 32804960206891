import "./index.scss";
import React from "react";
import { Link } from "react-router-dom";

import icons from "../../images/iconSet";
import UserAvatar from '../avatar';
import PopupHeaderMenu from "../popupHeaderMenu/";

class User extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         showPopupHeaderMenu: false,
      };
   }

   changePopupState = () => {
      this.setState((state) => {
         state.showPopupHeaderMenu = !state.showPopupHeaderMenu;
         return { ...state };
      });
   };

   changePopupStateFalse = () => {
      this.setState((state) => {
         state.showPopupHeaderMenu = false;
         return { ...state };
      });
   };

   render() {

      return (
         <div
            className="user"
            onMouseLeave={this.state.showPopupHeaderMenu ? this.changePopupState : this.changePopupStateFalse}
         >
            <div className="user--avatar">
               <UserAvatar
                  img={this.props.userAvatar}
                  name={this.props.userName}
                  surname={this.props.userSurame}
                  id={this.props.userId}
                  size="33"
               />
            </div>



            <Link to="/profile/" className="user--name light">
               {this.props.userName} {this.props.userSurame}
           
            </Link>
            <div className="user--popup">
               <img src={icons.icons.chewron} alt="" className="user--icon--arrow" onClick={this.changePopupState} />
               <PopupHeaderMenu shown={this.state.showPopupHeaderMenu} userName={this.props.userName} userSurname={this.props.userSurame} />
            </div>
         </div>
      );
   }
}

export default User;
