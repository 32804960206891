import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

function AutohideExample() {
    const [show, setShow] = useState(true);



    return (
        <Row>
            <Col style={{ position: "absolute", bottom: "20px", left: "15px" }} xs={6}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body style={{ color: "red" }}>Cant post updates</Toast.Body>
                </Toast>
            </Col>
            {/* <Col xs={6}>
                <Button onClick={() => setShow(true)}>Show Toast</Button>
            </Col> */}
        </Row>
    );
}

export default AutohideExample;