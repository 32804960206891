import { FC, useCallback, useEffect, useMemo, useState } from "react";

import IMAGES from "../../../../images/iconSet";
import styles from "./ModalQuestionTerms.module.scss";
import {
  ICondition,
  IDefaultItems,
  IDefaultItemsIndexed,
  ILayoutItem,
} from "./index";

interface Props {
  condition: ICondition;
  availableAnswers: IDefaultItemsIndexed[];
  handleEditCondition: (condition: ICondition) => void;
  handleDeleteCondition: (id: string) => void;
  index: number;
  conditionsCount: number;
  haveRangeCondition: boolean;
  availableVisits: number[];
}

type IOptionTitle =
  | string
  | null
  | {
      from: number;
      to: number;
    }
  | {
      isText: true;
    };

const getOptionTitleByAnswer: (
  answer: IDefaultItems,
  id: string | number
) => IOptionTitle = (answer, id) => {
  switch (answer.type) {
    case "checkbox":
      return answer.options.data.items.find((x) => x.id === id)
        ? answer.options.data.items.find((x) => x.id === id).text
        : (null as string | null);
    case "list":
      return answer.options.data.find((x) => x.id === id)
        ? answer.options.data.find((x) => x.id === id).value
        : (null as string | null);
    case "number":
      return {
        from: answer.options.data.limitFrom,
        to: answer.options.data.limitTo,
      } as {
        from: number;
        to: number;
      };
    case "radio":
      return answer.options.data.items.find((x) => x.id === id)
        ? answer.options.data.items.find((x) => x.id === id).text
        : (null as string | null);
    case "scale":
      return answer.options.data.find((x) => x.id === id)
        ? answer.options.data.find((x) => x.id === id).value
        : (null as string | null);
    case "textarea":
      return {
        isText: true,
      } as {
        isText: true;
      };
    default:
      return null as null;
  }
};

export const ConditionItem: FC<Props> = ({
  condition,
  availableAnswers,
  handleEditCondition,
  handleDeleteCondition,
  index,
  conditionsCount,
  haveRangeCondition,
  availableVisits,
}) => {
  const [selectedVisit, setSelectedVisit] = useState<number | undefined>(
    availableVisits.length === 1 ? 0 : undefined
  );

  const defaultVisit: undefined | number = useMemo(() => {
    return condition.questionId !== undefined
      ? availableAnswers.find((x) => x.id === condition.questionId)
          ?.visitIndex - 1
      : availableVisits.length === 1
      ? 0
      : undefined;
  }, [availableAnswers, condition]);

  const handleChangeVisit = (visit: number) => {
    setSelectedVisit(visit);
    handleEditCondition({
      ...condition,
      equals: true,
      questionId: undefined,
      answer: undefined,
    });
  };

  useEffect(() => {
    setSelectedVisit(defaultVisit);
  }, [defaultVisit]);

  const haveAnswer = useMemo(
    () =>
      condition.questionId !== undefined
        ? !!availableAnswers.find((x) => x.id === condition.questionId)
        : false,
    [availableAnswers, condition]
  );
  const answerTitle = useMemo(
    () =>
      condition.questionId !== undefined
        ? availableAnswers.find((x) => x.id === condition.questionId)
          ? `${
              availableAnswers.find((x) => x.id === condition.questionId).index
            }
          ${
            availableAnswers.find((x) => x.id === condition.questionId).options
              .title
          }`
          : "Вопрос удален"
        : "Выберите из списка",
    [condition, availableAnswers]
  );
  const selectedAnswer: undefined | IDefaultItems = useMemo(
    () =>
      haveAnswer
        ? availableAnswers.find((x) => x.id === condition.questionId)
        : undefined,
    [haveAnswer, availableAnswers, condition]
  );
  const getOptionTitle = useCallback(
    (id: string | number | undefined) => {
      if (haveAnswer && selectedAnswer && id) {
        const result = getOptionTitleByAnswer(selectedAnswer, id);
        if (result !== null) {
          if (typeof result === "string" || typeof result === "number") {
            return result;
          } else if ("from" in result) {
            return "Значение из промежутка";
          } else if (result.isText) {
            return "Введите текст";
          }
        } else return "Ответы были изменены";
      } else return "Выберите из списка";
    },
    [condition, availableAnswers, haveAnswer, selectedAnswer]
  );
  const availableOptions:
    | ILayoutItem[]
    | Array<{
        id: string;
        value: string;
      }> = useMemo(() => {
    if (selectedAnswer) {
      if (
        selectedAnswer.type === "checkbox" ||
        selectedAnswer.type === "radio"
      ) {
        return selectedAnswer.options.data.items;
      } else if (selectedAnswer.type === "list") {
        return selectedAnswer.options.data;
      } else if (selectedAnswer.type === "scale") {
        return selectedAnswer.options.data;
      } else {
        return [];
      }
    } else return [];
  }, [selectedAnswer]);

  return (
    <div className={styles.condition}>
      <div
        className={`mh-selectWrapper__condition ${styles.select} ${styles.answer}`}
      >
        <div className={`mh-select ${styles.selectcondwrapper}`}>
          <div
            className={`mh-select_input mh-selectFix_input${
              selectedVisit === undefined ? ` ${styles.disabled}` : ""
            }`}
          >
            <span className={styles.selectText}>
              {selectedVisit !== undefined
                ? `Визит ${selectedVisit + 1}`
                : "Выберите визит"}
            </span>
            <img src={IMAGES.constructor.listArrow} alt="" />
          </div>
          <ul
            className={`mh-select_list mh-selectFix_list ${styles.selectcondwrapper__list}`}
            style={{
              minWidth: "100%",
            }}
          >
            {availableVisits.map((visit) => (
              <li
                className={
                  `mh-select_item ${styles.selectItem}` +
                  (selectedVisit === visit ? " mh-select_item__current" : "")
                }
                key={visit}
                onClick={() => {
                  handleChangeVisit(visit);
                }}
              >
                Визит {visit + 1}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={`mh-selectWrapper__condition ${styles.select} ${styles.answer}`}
      >
        <div className={`mh-select ${styles.selectcondwrapper}`}>
          <div
            className={`mh-select_input mh-selectFix_input${
              !haveAnswer ? ` ${styles.disabled}` : ""
            }`}
          >
            <span className={styles.selectText}>{answerTitle}</span>
            <img src={IMAGES.constructor.listArrow} alt="" />
          </div>
          <ul
            className={`mh-select_list mh-selectFix_list ${styles.selectcondwrapper__list}`}
          >
            {availableAnswers
              .filter((el) => {
                if (selectedVisit !== undefined) {
                  return el.visitIndex - 1 === selectedVisit;
                } else return true;
              })
              .map((question) => (
                <li
                  className={
                    `mh-select_item ${styles.selectItem}` +
                    (condition.questionId === question.id
                      ? " mh-select_item__current"
                      : "")
                  }
                  key={question.id}
                  onClick={() => {
                    if (question.type === "number") {
                      handleEditCondition({
                        ...condition,
                        questionId: question.id,
                        answer: {
                          type: "range",
                          from: question.options.data.limitFrom,
                          to: question.options.data.limitTo,
                        },
                      });
                    } else {
                      handleEditCondition({
                        ...condition,
                        questionId: question.id,
                        answer: undefined,
                      });
                    }
                  }}
                >
                  {question.index} {question.options.title}
                  <span className={`${styles.conditionvisitnumber}`}>
                    {question.visitIndex}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className={`mh-selectWrapper ${styles.selectcondwrapper}`}>
        <div className={`mh-select ${styles.selectcondwrapper}`}>
          <div className="mh-select_input mh-selectFix_input">
            {condition.equals === "range"
              ? "Между"
              : condition.equals
              ? "Равно"
              : "Не равно"}
            <img src={IMAGES.constructor.listArrow} alt="" />
          </div>
          <ul className="mh-select_list mh-selectFix_list">
            <li
              className={
                `mh-select_item ${styles.selectItem}` +
                (condition.equals !== "range" && condition.equals === true
                  ? " mh-select_item__current"
                  : "")
              }
              onClick={() => {
                if (selectedAnswer && selectedAnswer.type === "number") {
                  handleEditCondition({
                    ...condition,
                    equals: true,
                    answer: undefined,
                  });
                } else {
                  handleEditCondition({
                    ...condition,
                    equals: true,
                  });
                }
              }}
            >
              Равно
            </li>
            <li
              className={
                `mh-select_item ${styles.selectItem}` +
                (condition.equals === false ? " mh-select_item__current" : "")
              }
              onClick={() => {
                if (selectedAnswer && selectedAnswer.type === "number") {
                  handleEditCondition({
                    ...condition,
                    equals: false,
                    answer: undefined,
                  });
                } else {
                  handleEditCondition({
                    ...condition,
                    equals: false,
                  });
                }
              }}
            >
              Не равно
            </li>
            {selectedAnswer && selectedAnswer.type === "number" && (
              <li
                className={
                  `mh-select_item ${styles.selectItem}` +
                  (condition.equals === "range"
                    ? " mh-select_item__current"
                    : "")
                }
                onClick={() =>
                  handleEditCondition({
                    ...condition,
                    equals: "range",
                    answer: {
                      type: "range",
                      from: selectedAnswer.options.data.limitFrom,
                      to: selectedAnswer.options.data.limitTo,
                    },
                  })
                }
              >
                Между
              </li>
            )}
          </ul>
        </div>
      </div>
      {haveAnswer && selectedAnswer ? (
        selectedAnswer.type === "list" ||
        selectedAnswer.type === "checkbox" ||
        selectedAnswer.type === "scale" ||
        selectedAnswer.type === "radio" ? (
          <div
            className={`mh-selectWrapper ${styles.selectcondwrapper} ${
              haveRangeCondition ? styles.selectBig : styles.select
            }`}
          >
            <div className={`mh-select ${styles.selectcondwrapper}`}>
              <div
                className={`mh-select_input mh-selectFix_input${
                  !haveAnswer ? ` ${styles.disabled}` : ""
                }`}
              >
                <span className={styles.selectText}>
                  {getOptionTitle(
                    condition.answer && "value" in condition.answer
                      ? condition.answer?.value
                      : ""
                  )}
                </span>
                <img src={IMAGES.constructor.listArrow} alt="" />
              </div>
              <ul className="mh-select_list mh-selectFix_list">
                {availableOptions?.map((option) => (
                  <li
                    className={
                      `mh-select_item ${styles.selectItem}` +
                      (condition.answer &&
                      "value" in condition.answer &&
                      condition.answer?.value === option.id
                        ? " mh-select_item__current"
                        : "")
                    }
                    key={option.id}
                    onClick={() =>
                      handleEditCondition({
                        ...condition,
                        answer: {
                          type: "id",
                          value: option.id,
                        },
                      })
                    }
                  >
                    {"value" in option
                      ? option.value
                      : "text" in option
                      ? option.text
                      : ""}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : selectedAnswer.type === "textarea" ? (
          <div>
            <textarea
              className="itme--textarea"
              placeholder="Введите значение"
              style={{
                height: "32px",
                minHeight: "32px",
                /* width: haveRangeCondition ? 300 : 200, */
                borderRadius: 4,
              }}
              value={
                condition.answer && "value" in condition.answer
                  ? condition.answer.value
                  : ""
              }
              onChange={(e) => {
                handleEditCondition({
                  ...condition,
                  answer: {
                    type: "value",
                    value: e.target.value,
                  },
                });
              }}
            />
          </div>
        ) : (
          <div
            className="mh-constructor_number"
            /*  style={{
              width: haveRangeCondition ? 300 : 220,
            }} */
          >
            <div className="mh-constructor_numberArea">
              {condition.equals === "range" ? (
                <div className={styles.rangeInput}>
                  <input
                    type="number"
                    className="mh-constructor_numberInput"
                    min={selectedAnswer.options.data.limitFrom}
                    max={selectedAnswer.options.data.limitTo}
                    multiple
                    value={
                      condition.answer && condition.answer?.type === "range"
                        ? condition.answer.from
                        : 0
                    }
                    onChange={(e) => {
                      handleEditCondition({
                        ...condition,
                        answer: {
                          type: "range",
                          from: Number(e.target.value),
                          to:
                            "to" in condition.answer ? condition.answer.to : 0,
                        },
                      });
                    }}
                  />
                  <input
                    type="number"
                    className="mh-constructor_numberInput"
                    min={selectedAnswer.options.data.limitFrom}
                    max={selectedAnswer.options.data.limitTo}
                    multiple
                    value={
                      condition.answer && condition.answer?.type === "range"
                        ? condition.answer.to
                        : 0
                    }
                    onChange={(e) => {
                      handleEditCondition({
                        ...condition,
                        answer: {
                          type: "range",
                          from:
                            condition.answer?.type === "range"
                              ? condition.answer.from
                              : 0,
                          to: Number(e.target.value),
                        },
                      });
                    }}
                  />
                </div>
              ) : (
                <input
                  type="number"
                  className="mh-constructor_numberInput"
                  min={selectedAnswer.options.data.limitFrom}
                  max={selectedAnswer.options.data.limitTo}
                  multiple
                  value={
                    condition.answer && condition.answer?.type === "value"
                      ? condition.answer.value
                      : 0
                  }
                  onChange={(e) => {
                    handleEditCondition({
                      ...condition,
                      answer: {
                        type: "value",
                        value: Number(e.target.value),
                      },
                    });
                  }}
                />
              )}
              <span
                className="mh-constructor_numberUnit"
                style={{
                  maxWidth: 90,
                  display: "block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedAnswer.options.data.unit}
              </span>
            </div>
            {selectedAnswer.type === "number" &&
              (selectedAnswer.options.data.isLimit ? (
                <p
                  className="mh-constructor_numberLimit"
                  style={{
                    maxWidth: 200,
                  }}
                >
                  Введите число в диапазоне от{" "}
                  {selectedAnswer.options.data.limitFrom} до{" "}
                  {selectedAnswer.options.data.limitTo}
                </p>
              ) : (
                ""
              ))}
          </div>
        )
      ) : (
        <div
          className={`mh-selectWrapper ${styles.selectcondwrapper} ${
            haveRangeCondition ? styles.selectBig : styles.select
          }`}
        >
          <div className={`mh-select ${styles.selectcondwrapper}`}>
            <div
              className={`mh-select_input mh-selectFix_input ${styles.disabled}`}
            >
              Выберите из списка
              <img src={IMAGES.constructor.listArrow} alt="" />
            </div>
            <ul className="mh-select_list mh-selectFix_list" />
          </div>
        </div>
      )}
      {index === 0 && conditionsCount === 1 ? (
        <div className={styles.emptyDelete} />
      ) : (
        <div
          className={styles.deleteCondition}
          onClick={() => {
            handleDeleteCondition(condition.id);
          }}
        >
          <img src={IMAGES.constructor.trash} alt="" />
        </div>
      )}
    </div>
  );
};
