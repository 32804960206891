import { useState } from "react";
import { useAppSelector } from "./app.hooks";
import { ISortingInitProps } from "../types";

export const useSort = () => {
  const { fields } = useAppSelector((state) => state.formsTable.initData);

  const [isSort, setSort] = useState<any>({
    name: "",
    sort: false,
    trend: "asc",
  });

  const sorting = ({ DisplayName, index }: ISortingInitProps) => {
    let value: string = "asc";
    if (isSort.name !== DisplayName && !isSort.sort) {
      setSort({ name: DisplayName, sort: true, trend: "desc" });
      value = "desc";
    }
    if (isSort.name === DisplayName && isSort.sort) {
      setSort({ name: "", sort: false, trend: "asc" });
      value = "asc";
    }
    if (
      (isSort.name !== DisplayName && isSort.trend === "asc") ||
      (isSort.name !== DisplayName && isSort.sort)
    ) {
      setSort({ name: DisplayName, sort: true, trend: "desc" });
      value = "desc";
    }

    const key = Object.keys(fields)[index];

    return {
      sorting: { [key]: value },
    };
  };

  return { sorting, isSort };
};
