import React  from 'react';
import {IFastFilters} from '../store/types';


interface IStaticButtonsProps {
    onClick: (item:IFastFilters) => void;
    options:IFastFilters[]
}

export const StaticButtons = ({options, onClick}:IStaticButtonsProps) => {
    return (
        <div className={'static-buttons'}>
            {options.map(item => (
                <button
                    key={item.value}
                    className={'static-buttons__button'}
                    onClick={() => onClick(item)}>{item.DisplayName}</button>
            ))}
        </div>
    );
};
