import React from 'react';
import {Checked, UnChecked} from '../assets/icons';

interface ICheckboxProps {
    isChecked: boolean;
    handleCheckbox?: () => void;
}

export const CheckBox = ({isChecked, handleCheckbox}:ICheckboxProps) => {
    return (
        <div className={'checkbox'} onClick={handleCheckbox}>
            {isChecked ? <Checked width={15} height={10} /> : <UnChecked width={15} height={10} />}
        </div>
    );
};
