import React from "react";
import IMAGES from "../../../../../images/iconSet";

import "./style.scss";

class TypeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      types: {
        title: {
          title: "Надпись",
          img: IMAGES.icons.chapter,
        },
        text: {
          title: "Текст",
          img: IMAGES.icons.text,
        },
        radio: {
          title: "Радиокнопка",
          img: IMAGES.icons.radio,
        },
        checkbox: {
          title: "Переключатель",
          img: IMAGES.icons.checkbox,
        },
        number: {
          title: "Число",
          img: IMAGES.icons.number,
        },
        date: {
          title: "Дата",
          img: IMAGES.icons.date,
        },
        time: {
          title: "Время",
          img: IMAGES.icons.time,
        },
      },
      selectedIndex: props.type,
    };
  }
  render = () => {
    return (
      <div className="mnh-typeSelect">
        <button type="button" className="mnh-typeSelect_button">
          {this.state.types[this.state.selectedIndex] ? (
            <img
              src={this.state.types[this.state.selectedIndex].img}
              style={{ maxWidth: 21 }}
              alt=""
            />
          ) : (
            ""
          )}

          <img
            className="mnh-typeSelect_arrow"
            src={IMAGES.constructor.arrow}
            alt=""
          />
        </button>
        <div className="mnh-typeSelect_list">
          {Object.keys(this.state.types).map((type, i) => (
            <div
              className="mnh-typeSelect_listItem"
              key={i}
              onClick={() => this.props.onChange(type)}
            >
              <img
                src={this.state.types[type].img}
                style={{ maxWidth: 21 }}
                alt=""
              />
              {this.state.types[type].title}
            </div>
          ))}
        </div>
      </div>
    );
  };
}

export default TypeSelect;
