import React from "react";

import './style.scss';

class StatusBadge extends React.Component {
    render = () => {
        const {children, state} = this.props;
        return (
            <div className={"mh-statusBadge" + (state ? " mh-statusBadge__"+state : "")}>
                {children}
            </div>
        );
    }
}

export default StatusBadge;