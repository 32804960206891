import React, { useCallback, useMemo, useState } from "react";
import { ChevronDown } from "../assets/icons";
import { IOption } from "../types";

interface ISelectProps {
  handleSelect: (num: number) => void;
  options: IOption[];
  placeholder?: string;
}

export const Select = ({
  handleSelect,
  options,
  placeholder,
}: ISelectProps) => {
  const [page, setPage] = useState<string>(placeholder ?? options[0].name);
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleSelectItem = useCallback(
    (text: string) => {
      handleSelect(Number(text));
      setPage(text);
    },
    [handleSelect]
  );

  const renderOptions = useMemo(() => {
    return options.map((i) => (
      <li
        key={i.value}
        className={"select-count__item"}
        onClick={() => handleSelectItem(i.name)}
      >
        {i.name}
      </li>
    ));
  }, [handleSelectItem, options]);

  return (
    <div
      className={"select-count__output"}
      onClick={() => setVisible(!isVisible)}
    >
      <span className={"select-count__text"}>
        {page}
        <ChevronDown width={10} height={7} />
      </span>
      {isVisible ? (
        <ul className={"select-count__list"}>{renderOptions}</ul>
      ) : null}
      {isVisible ? (
        <div className={"selector-overlay"} onClick={() => setVisible(false)} />
      ) : null}
    </div>
  );
};
