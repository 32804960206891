import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

interface IEvent {
  type: "redirect" | "redirect-outside";
  to: string;
}

interface IContext {
  addEvent: (props: IEvent) => void;
}

export const RedirectProvider = createContext<IContext>({ addEvent: () => {} });

export const useRedirect = () => useContext<IContext>(RedirectProvider);

export const RedirectService: FC<PropsWithChildren> = ({ children }) => {
  const [events, setEvents] = useState<Array<IEvent>>([]);

  const handleAddEvent = useCallback((props: IEvent) => {
    setEvents((prev) => [...prev, props]);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (events.length > 0) {
      events.forEach((evt) => {
        if (evt.type === "redirect") {
          navigate(evt.to);
        } else if (evt.type === "redirect-outside") {
          window.location.href = evt.to;
        }

        setEvents([]);
      });
    }
  }, [events, navigate]);

  return (
    <RedirectProvider.Provider
      value={{
        addEvent: handleAddEvent,
      }}
    >
      <>{children}</>
    </RedirectProvider.Provider>
  );
};
