import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "./styles.scss";
import { ru } from "date-fns/locale";
import { CalendarIcon } from "../../assets/icons";
import { colors } from "../../styles/styles";

interface IDatePicker {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface IDatePickerProps {
  title: string;
}

export const DatePicker = ({ title }: IDatePickerProps) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [state, setState] = useState<IDatePicker[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleSelect = (data: IDatePicker[]) => {
    setState(data);
  };

  return (
    <div className={"date-picker"}>
      <div
        className={"date-picker__header"}
        style={{ zIndex: isVisible ? 10 : 0 }}
        onClick={() => setVisible(!isVisible)}
      >
        <span className={"date-picker__head"}>{title}</span>
        <span className={"date-picker__header--icon"}>
          <CalendarIcon width={16} height={16} />
        </span>
      </div>
      {isVisible ? (
        <DateRangePicker
          locale={ru}
          onChange={(item: any) => handleSelect([item.selection])}
          // @ts-ignore
          showSelectionPreview={true}
          rangeColors={[colors.AccentColor, colors.AccentColor]}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          staticRanges={[]}
          inputRanges={[]}
          direction="horizontal"
        />
      ) : null}
      {isVisible ? (
        <div
          className={"date-picker-overlay"}
          onClick={() => setVisible(false)}
        />
      ) : null}
    </div>
  );
};
