import React from "react";
import { IOption } from "../../../types";
import { Select } from "../../../fields";

interface ISelectPageCountProps {
  handleSelect: (num: number) => void;
  itemsPerPage: number;
}

const options: IOption[] = [
  { name: "10", value: "10" },
  { name: "20", value: "20" },
  { name: "30", value: "30" },
  { name: "40", value: "40" },
];

export const SelectPageCount = ({
  handleSelect,
  itemsPerPage,
}: ISelectPageCountProps) => {
  return (
    <div className={"select-page-count"}>
      <span className={"select-page-count__text"}>Показать</span>
      <Select
        handleSelect={handleSelect}
        placeholder={String(itemsPerPage)}
        options={options}
      />
      <span className={"select-page-count__text"}>строк</span>
    </div>
  );
};
