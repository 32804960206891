import React, { useState, useCallback } from "react";
import { Table } from "./Table";

const Cities = () => {
  const [count, setCount] = useState(0);

  const handleChangeCount = useCallback((value) => {
    setCount(value);
  }, []);

  return (
    <div className="container-fluid" id="main-page-block">
      <div id="page-content">
        <div>
          <h2>
            Города <span id="sup">{count ? count : ""}</span>
          </h2>
          <Table updateCount={handleChangeCount} />
        </div>
      </div>
    </div>
  );
};
export default Cities;
