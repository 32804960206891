import { useState } from "react";
import { DropUpFull } from "../../assets/icons";
import { useAppDispatch, useAppSelector, useSortFormsTable } from "../../hooks";
import { ISortingInitProps } from "../../types";

export const TableHeader = () => {
  const dispatch = useAppDispatch();
  const { initData, content, filters } = useAppSelector(
    (state) => state.formsTable
  );
  const { actionButtons } = initData;
  const { fields } = initData;
  const { isSort, sorting } = useSortFormsTable();

  const [isCheckedAll, setCheckedAll] = useState<boolean>(false);

  const handleSort = ({ DisplayName, index }: ISortingInitProps) => {
    const data = sorting({ DisplayName, index });

    // const otherFiltersObject = {};
    // filters.forEach((el) => {
    //   if (el.type === FilterTypes.searchValue) {
    //     otherFiltersObject["search"] = el.value;
    //   } else {
    //     otherFiltersObject[el.type] = otherFiltersObject[el.type]
    //       ? [...otherFiltersObject[el.type], el.id]
    //       : [el.id];
    //   }
    // });

    // dispatch(sendFilterData({ data: Object.assign(data, otherFiltersObject) }));
  };

  return (
    <thead className={"table-row table-row--header"}>
      <tr className={"table-row table-row--header"}>
        {Object.values(fields).map((i: any, index) => (
          <td
            key={`${i.DisplayName}-${index}`}
            // style={{ width: `${TABLE_WIDTH / Object.values(fields).length}px` }}
            className={"table-row__item"}
          >
            <span className={"table-head"}>
              {i.DisplayName}
              {i?.useSort ? (
                <span
                  className={"table-head--icon"}
                  style={{
                    transform:
                      isSort.name === i.DisplayName && isSort.sort
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  onClick={() => {
                    handleSort({
                      DisplayName: i.DisplayName,
                      index,
                    });
                  }}
                >
                  <DropUpFull width={10} height={8} />
                </span>
              ) : null}
            </span>
          </td>
        ))}
        {actionButtons.length > 0 && (
          <td style={{ width: "80px" }} className={"table-row__item"} />
        )}
      </tr>
    </thead>
  );
};
