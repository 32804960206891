import "./index.scss";

import addDays from "date-fns/addDays";
import ru from "date-fns/locale/ru";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";

registerLocale("ru", ru);
class Dates extends React.Component {
  constructor(props) {
    super(props);

    const years = [];

    for (let i = 1900 + new Date().getYear(); i >= 1990; i--) {
      years.push(i);
    }
    if (this.props.defaultDate) {
      var defaultDate = this.props.defaultDate.split(".");
    }

    this.state = {
      dateSelect: this.props.defaultDate
        ? new Date(defaultDate[2], defaultDate[1] - 1, defaultDate[0])
        : null,
      years,
      isOpen: false,
      months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
    };

    this.dateRef = React.createRef();
  }

  onChange = (data) => {
    const { state } = this;
    state.dateSelect = data;
    this.setState(state, () =>
      this.props.onDateChange
        ? this.props.onDateChange(state.dateSelect)
        : undefined
    );
  };

  render = () => {
    // const { years, months, isOpen } = this.state;
    const { limitFrom, limitTo } = this.props;

    // const renderCustomHeader = (data) => {
    //   const {
    //     monthDate,
    //     changeYear,
    //     changeMonth,
    //     decreaseMonth,
    //     increaseMonth,
    //   } = data;

    //   const date = new Date(data.date);
    //   const prevMonthButtonDisabled =
    //     1900 + monthDate.getYear() === 1990 && monthDate.getMonth() === 0;
    //   const nextMonthButtonDisabled =
    //     1900 + monthDate.getYear() === 1900 + new Date().getYear() &&
    //     monthDate.getMonth() === 11;

    //   return (
    //     <div className="mh-dates_monthControler">
    //       <button
    //         onClick={decreaseMonth}
    //         disabled={prevMonthButtonDisabled}
    //         className="mh-dates_monthButton"
    //         type="button"
    //       >
    //         <img
    //           src={IMAGES.constructor.arrow}
    //           style={{
    //             opacity: prevMonthButtonDisabled ? "0.4" : "1",
    //           }}
    //           alt=""
    //         />
    //       </button>

    //       <Select
    //         items={months}
    //         value={date.getMonth()}
    //         onChange={(value) => changeMonth(value)}
    //         type="outline"
    //         className="mh-dates_select"
    //         id={this.props.id}
    //       />

    //       <Select
    //         items={years}
    //         value={years.indexOf(1900 + date.getYear())}
    //         onChange={(value) => changeYear(years[value])}
    //         type="outline"
    //         className="mh-dates_select"
    //       />

    //       <button
    //         onClick={increaseMonth}
    //         disabled={nextMonthButtonDisabled}
    //         className="mh-dates_monthButton"
    //         type="button"
    //       >
    //         <img
    //           src={IMAGES.constructor.arrow}
    //           style={{
    //             transform: "rotate(180deg)",
    //             opacity: nextMonthButtonDisabled ? "0.2" : "1",
    //           }}
    //           alt=""
    //         />
    //       </button>
    //     </div>
    //   );
    // };

    const showCalendar =
      this.props.showCalendar !== undefined ? this.props.showCalendar : true;

    const exclude = [];
    const include = [];

    if (limitFrom && limitTo) {
      include.push({
        start: new Date(limitFrom),
        end: new Date(limitTo),
      });
    } else {
      if (limitTo) {
        exclude.push({
          start: addDays(new Date(limitTo), 1),
          end: new Date(1901 + new Date().getYear() + "-01-01"),
        });
      }
      if (limitFrom) {
        exclude.push({
          start: new Date("1990-01-01"),
          end: addDays(new Date(limitFrom), -1),
        });
      }
    }
    return (
      <DatePicker
        selected={
          this.props.value !== undefined
            ? this.props.value
            : this.state.dateSelect
        }
        onChange={this.onChange}
        className={`calendarInput ${
          !showCalendar ? "calendarInputWithoutOverlay" : ""
        }`.trim()}
        calendarClassName="calendarDatepicker"
        wrapperClassName={this.props.wrapperClassName}
        closeOnScroll={false}
        excludeDateIntervals={
          exclude.length && exclude.length > 0 ? exclude : null
        }
        includeDateIntervals={
          include.length && include.length > 0 ? include : null
        }
        dateFormat="dd.MM.yyyy"
        locale="ru"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        shouldCloseOnSelect={true}
        placeholderText={
          this.props.placeholder ? this.props.placeholder : "Выберите дату"
        }
        /*  renderCustomHeader={renderCustomHeader} */
        disabled={this.props.disabled}
        id={this.props.id}
        customInput={
          <MaskedInput
            type="text"
            mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
            guide={false}
          />
        }
        {...(!showCalendar && {
          open: false,
          onChangeRaw: (event) => {
            const value = event.target.value;
            if (value.match(/\d{2}\.\d{2}\.\d{4}/g)) {
              const tmp = value.split(".");
              const date = new Date(tmp[2] + "-" + tmp[1] + "-" + tmp[0]);
              this.onChange(date);
            }
          },
        })}
      />
    );
  };
}

export default Dates;
