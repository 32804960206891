import axios from "axios";
import { getTokenString } from "../../utils/getToken";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  function (config) {
    const token = getTokenString();
    config.headers = { ...config.headers, Authorization: token };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
