import Home from "./views/home";
import System from "./views/system";

import FormsNew from "./views/forms/index_new.jsx";

import FormsView from "./views/formsView";

import Users from "./views/users";

import Customers from "./views/customers";

import Researches from "./views/researches";

import ResearchDesigner from "./views/researches/designer";

import Messages from "./views/messages";

import Profile from "./views/profile";

import UserEdit from "./views/usersedit";

import Errors from "./views/errors/message";

import UserAdd from "./views/useradd/";
import UserEditNew from "./views/usereditnew/";

import Restore from "./views/login/restore.jsx";
import Analyst from "./views/analyst/index.js";

import { RequestorEdit } from "./views/requestor/requestoredit.jsx";
import { RequestorAdd } from "./views/requestor/requestoradd.jsx";
import { UserImport } from "./views/userimport";
import { Navigate } from "react-router-dom";
import md5 from "md5";
import EGFRCalculator from "./components/calculator/Calculator";
import { Reports } from "./views/reports/Reports";
import Cities from "./views/cities";

const ProtectedRoute = (props) => {
  const role = JSON.parse(sessionStorage.getItem("token"))?.role;

  if (md5(1) === role || md5(0) === role) {
    return <Navigate replace to="/" />;
  }

  return <>{props.children}</>;
};

const AdminOnlyRoute = (props) => {
  const role = JSON.parse(sessionStorage.getItem("token"))?.role;

  if (md5(4) !== role && md5(5) !== role) {
    return <Navigate replace to="/" />;
  }

  return <>{props.children}</>;
};

const AdminRoute = (props) => {
  const role = JSON.parse(sessionStorage.getItem("token"))?.role;

  if (md5(4) !== role && md5(5) !== role) {
    return <Navigate replace to="/" />;
  }

  return <>{props.children}</>;
};

const routes = [
  {
    title: "Калькулятор",
    url: "/calculator/",
    page: (
      <div style={{ width: 800 }}>
        <EGFRCalculator />
      </div>
    ),
  },
  {
    title: "Восстановление пароля",
    url: "/restore/",
    page: <Restore />,
  },
  {
    title: "Главная",
    url: "/",
    page: <Home />,
  },
  {
    title: "Все анкеты",
    url: "/forms/",
    page: <FormsNew />,
  },
  {
    title: "Просмотр анкеты",
    url: "form/",
    page: <FormsView />,
  },
  {
    title: "Просмотр анкеты",
    url: "forms_new/",
    page: <FormsNew />,
  },
  {
    title: "Черновики",
    url: "/forms/drafts/",
    page: <Errors />,
  },
  {
    title: "Отчеты",
    url: "/reports/",
    page: (
      <AdminRoute>
        <Reports />
      </AdminRoute>
    ),
  },
  {
    title: "Все пользователи",
    url: "/users/",
    page: (
      <ProtectedRoute>
        <Users />
      </ProtectedRoute>
    ),
  },
  {
    title: "Все города",
    url: "/cities/",
    page: (
      <AdminOnlyRoute>
        <Cities />
      </AdminOnlyRoute>
    ),
  },
  {
    title: "Добавить пользователя",
    url: "/users/add/",
    page: (
      <ProtectedRoute>
        <UserAdd />
      </ProtectedRoute>
    ),
  },
  {
    title: "Статус системы",
    url: "/system/",
    page: (
      <ProtectedRoute>
        <System />
      </ProtectedRoute>
    ),
  },
  {
    title: "Просмотр пользователя",
    url: "/users/view/",
    page: (
      <ProtectedRoute>
        <UserEdit />
      </ProtectedRoute>
    ),
  },
  {
    title: "Редактирование пользователя",
    url: "/users/edit/",
    page: (
      <ProtectedRoute>
        <UserEditNew />
      </ProtectedRoute>
    ),
  },
  {
    title: "Импорт пользователей",
    url: "/users/import/",
    page: (
      <ProtectedRoute>
        <UserImport />
      </ProtectedRoute>
    ),
  },
  {
    title: "Все заказчики",
    url: "/customers/",
    page: (
      <ProtectedRoute>
        <Customers />
      </ProtectedRoute>
    ),
  },
  {
    title: "Редактирование заказчика",
    url: "/customers/edit/",
    page: (
      <ProtectedRoute>
        <RequestorEdit />
      </ProtectedRoute>
    ),
  },
  {
    title: "Аналитика",
    url: "/analyst/",
    page: (
      <ProtectedRoute>
        <Analyst />
      </ProtectedRoute>
    ),
  },
  {
    title: "Добавить заказчика",
    url: "/customers/add/",
    page: (
      <ProtectedRoute>
        <RequestorAdd />
      </ProtectedRoute>
    ),
  },
  {
    title: "Все исследования",
    url: "/research/",
    page: <Researches />,
  },
  {
    title: "Добавить исследование",
    url: "/research/designer/",
    page: (
      <ProtectedRoute>
        <ResearchDesigner mode="create" key={1} />
      </ProtectedRoute>
    ),
  },
  {
    title: "Добавить исследование",
    url: "/research/draft/:id",
    page: (
      <ProtectedRoute>
        <ResearchDesigner mode="draft" key={2} />
      </ProtectedRoute>
    ),
  },
  {
    title: "Редактировать исследование",
    url: "/research/edit/:id",
    page: (
      <ProtectedRoute>
        <ResearchDesigner mode="edit" key={3} />
      </ProtectedRoute>
    ),
  },
  {
    title: "Сообщения",
    url: "/messages/",
    page: <Messages />,
  },
  {
    title: "Справочники",
    url: "/manual/",
    page: (
      <ProtectedRoute>
        <Errors />
      </ProtectedRoute>
    ),
  },
  {
    title: "Города и регионы",
    url: "/manual/regions/",
    page: (
      <ProtectedRoute>
        <Errors />
      </ProtectedRoute>
    ),
  },
  {
    title: "Специализации",
    url: "/manual/specializations/",
    page: (
      <ProtectedRoute>
        <Errors />
      </ProtectedRoute>
    ),
  },
  {
    title: "Уведомления",
    url: "/notifications/",
    page: (
      <ProtectedRoute>
        <Errors />
      </ProtectedRoute>
    ),
  },
  {
    title: "Пользователь",
    url: "/profile/",
    page: <Profile />,
  },
  {
    title: "Ошибка",
    url: "*",
    page: <Errors />,
  },
];

export default routes;
