import { Button, Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TestTable } from "../../components/testTable.js";
import React, { Component } from "react";

import LoadForm from "../../components/viewForm/index.jsx";
class FormView extends React.Component {
  render(){
   return (
      <div className="container-fluid" id="main-page-block">
        <LoadForm prop-id = "64"/>
      </div>

   );
}
}
export default FormView;
