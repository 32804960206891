import { useRef, useState, type FC } from "react";
import { Button } from "react-bootstrap";
import clearIcon from "../../images/icons/clear.svg";
import { colors } from "../../tables/styles/styles";

interface IInputsCleanerProps {
  children: React.ReactNode;
  isAdmin: number;
  clearState: (id: string) => void;
  clearValues: (element: Element) => void;
  clearScale: (val: string) => void;
}

const InputsCleaner: FC<IInputsCleanerProps> = ({
  children,
  isAdmin,
  clearState,
  clearValues,
  clearScale,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);

  const handleMouseEnter = () => {
    isAdmin && setShowButton(true);
  };

  const handleMouseLeave = () => {
    setShowButton(false);
  };

  const handleClean = () => {
    const inputElements = ref.current.getElementsByTagName("input");
    const textareaElements = ref.current.getElementsByTagName("textarea");
    const selectElements = ref.current.getElementsByTagName("select");
    const buttonsElements = ref.current.getElementsByClassName("btn-primary");
    const calendarInputElements =
      ref.current.getElementsByClassName("calendarInput");

    for (var i = 0, max = calendarInputElements.length; i < max; i++) {
      const id = calendarInputElements[i].id;
      clearState(id);
    }
    for (var i = 0, max = inputElements.length; i < max; i++) {
      inputElements[i].value = "";
      inputElements[i].checked = false;
      clearValues(inputElements[i]);
    }
    for (var i = 0, max = textareaElements.length; i < max; i++) {
      textareaElements[i].value = "";
      clearValues(textareaElements[i]);
    }
    for (var i = 0, max = selectElements.length; i < max; i++) {
      selectElements[i].selectedIndex = 0;
      clearValues(selectElements[i]);
    }
    for (var i = 0, max = buttonsElements.length; i < max; i++) {
      if (buttonsElements[i].id) {
        const value = buttonsElements[i].getAttribute("value");
        buttonsElements[i].className = "btn btn-secondary";
        clearScale(value);
      }
    }
  };

  return (
    <div
      className="position-relative"
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showButton && (
        <Button
          type="button"
          variant="secondary"
          onClick={handleClean}
          className="position-absolute d-flex align-items-center"
          style={{
            top: 20,
            right: 20,
            backgroundColor: colors.LightGrey,
            color: "black",
            borderRadius: "20px",
            fontSize: 14,
            border: "none",
            gap: 10,
          }}
        >
          <img width={12} src={clearIcon} alt="" />
          Очистить
        </Button>
      )}
    </div>
  );
};

export default InputsCleaner;
