import IMAGES from "../../../images/iconSet";

import Textarea from "../ques/textarea";
import DateNote from "../ques/date";
import Chapter from "../ques/chapter";
import ScaleNote from "../ques/scale";
import TableNote from "../ques/table";
import NumberNote from "../ques/number";
import CheckboxNote from "../ques/checkbox";
import RadioNote from "../ques/radio";
import ListNote from "../ques/list";
import TimeNote from "../ques/time";

const getComponentByType = (type, fieldType) => {
   if (type) {
      if (type === "chapter")
         return {
            component: Chapter,
            title: "Раздел",
            icon: IMAGES.icons.chapter,
         };
      if (type === "textarea" && fieldType === "time")
         return {
            component: TimeNote,
            title: "Время",
            icon: IMAGES.icons.time,
         };
      if (type === "textarea")
         return {
            component: Textarea,
            title: "Текст",
            icon: IMAGES.icons.text,
         };
      if (type === "date")
         return {
            component: DateNote,
            title: "Дата",
            icon: IMAGES.icons.date,
         };
      if (type === "file")
         return {
            component: Textarea,
            title: "Файл",
            icon: IMAGES.icons.file,
         };
      if (type === "table")
         return {
            component: TableNote,
            title: "Таблица",
            icon: IMAGES.icons.table,
         };
      if (type === "number")
         return {
            component: NumberNote,
            title: "Число",
            icon: IMAGES.icons.number,
         };
      if (type === "checkbox")
         return {
            component: CheckboxNote,
            title: "Чекбокс",
            icon: IMAGES.icons.checkbox,
         };
      if (type === "radio")
         return {
            component: RadioNote,
            title: "Радио",
            icon: IMAGES.icons.radio,
         };
      if (type === "list")
         return {
            component: ListNote,
            title: "Список",
            icon: IMAGES.icons.list,
         };
      if (type === "scale")
         return {
            component: ScaleNote,
            title: "Шкала",
            icon: IMAGES.icons.bar,
         };
      if (type === "date")
         return {
            component: Textarea,
            title: "Дата",
            icon: "",
         };
      return "";
   } else {
      return "";
   }
};

export default getComponentByType;
