import { FC, useEffect, useMemo, useState } from "react";

import IMAGES from "../../../../images/iconSet";
import styles from "./ModalQuestionTerms.module.scss";
import { ICondition, IDefaultItemsIndexed } from "./index";

interface Props {
  condition: ICondition;
  availableAnswers: IDefaultItemsIndexed[];
  handleEditCondition: (condition: ICondition) => void;
  availableVisits: number[];
}

export const ConditionItem: FC<Props> = ({
  condition,
  availableAnswers,
  handleEditCondition,
  availableVisits,
}) => {
  const [selectedVisit, setSelectedVisit] = useState<number | undefined>(
    availableVisits.length === 1 ? 0 : undefined
  );

  const defaultVisit: undefined | number = useMemo(() => {
    return condition.questionId !== undefined
      ? availableAnswers.find((x) => x.id === condition.questionId)
          ?.visitIndex - 1
      : availableVisits.length === 1
      ? 0
      : undefined;
  }, [availableAnswers, condition]);

  const handleChangeVisit = (visit: number) => {
    setSelectedVisit(visit);
    handleEditCondition({
      ...condition,
      condition: "equal",
      questionId: undefined,
    });
  };

  useEffect(() => {
    setSelectedVisit(defaultVisit);
  }, [defaultVisit]);

  const haveAnswer = useMemo(
    () =>
      condition.questionId !== undefined
        ? !!availableAnswers.find((x) => x.id === condition.questionId)
        : false,
    [availableAnswers, condition]
  );
  const answerTitle = useMemo(
    () =>
      condition.questionId !== undefined
        ? availableAnswers.find((x) => x.id === condition.questionId)
          ? `${
              availableAnswers.find((x) => x.id === condition.questionId).index
            }
          ${
            availableAnswers.find((x) => x.id === condition.questionId).options
              .title
          }`
          : "Вопрос удален"
        : "Выберите из списка",
    [condition, availableAnswers]
  );

  return (
    <div className={styles.condition}>
      <div
        className={`mh-selectWrapper__condition ${styles.select} ${styles.answer}`}
      >
        <div className={`mh-select ${styles.selectcondwrapper}`}>
          <div
            className={`mh-select_input mh-selectFix_input${
              selectedVisit === undefined ? ` ${styles.disabled}` : ""
            }`}
          >
            <span className={styles.selectText}>
              {selectedVisit !== undefined
                ? `Визит ${selectedVisit + 1}`
                : "Выберите визит"}
            </span>
            <img src={IMAGES.constructor.listArrow} alt="" />
          </div>
          <ul
            className={`mh-select_list mh-selectFix_list ${styles.selectcondwrapper__list}`}
            style={{
              minWidth: "100%",
            }}
          >
            {availableVisits.map((visit) => (
              <li
                className={
                  `mh-select_item ${styles.selectItem}` +
                  (selectedVisit === visit ? " mh-select_item__current" : "")
                }
                key={visit}
                onClick={() => {
                  handleChangeVisit(visit);
                }}
              >
                Визит {visit + 1}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={`mh-selectWrapper__condition ${styles.select} ${styles.answer}`}
      >
        <div className={`mh-select ${styles.selectcondwrapper}`}>
          <div
            className={`mh-select_input mh-selectFix_input${
              !haveAnswer ? ` ${styles.disabled}` : ""
            }`}
          >
            <span className={styles.selectText}>{answerTitle}</span>
            <img src={IMAGES.constructor.listArrow} alt="" />
          </div>
          <ul
            className={`mh-select_list mh-selectFix_list ${styles.selectcondwrapper__list}`}
          >
            {availableAnswers
              .filter((el) => {
                if (selectedVisit !== undefined) {
                  return el.visitIndex - 1 === selectedVisit;
                } else return true;
              })
              .map((question) => (
                <li
                  className={
                    `mh-select_item ${styles.selectItem}` +
                    (condition.questionId === question.id
                      ? " mh-select_item__current"
                      : "")
                  }
                  key={question.id}
                  onClick={() => {
                    if (question.type === "number") {
                      handleEditCondition({
                        ...condition,
                        questionId: question.id,
                      });
                    } else {
                      handleEditCondition({
                        ...condition,
                        questionId: question.id,
                      });
                    }
                  }}
                >
                  {question.index} {question.options.title}
                  <span className={`${styles.conditionvisitnumber}`}>
                    {question.visitIndex}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div
        className={`mh-selectWrapper ${styles.selectcondwrapper}`}
        style={{ minWidth: 182 }}
      >
        <div className={`mh-select ${styles.selectcondwrapper}`}>
          <div className="mh-select_input mh-selectFix_input">
            {condition.condition === "equal" && "Равно"}
            {condition.condition === "less" && "Меньше"}
            {condition.condition === "more" && "Больше"}
            {condition.condition === "less_or_equal" && "Меньше или равно"}
            {condition.condition === "more_or_equal" && "Больше или равно"}
            <img src={IMAGES.constructor.listArrow} alt="" />
          </div>
          <ul className="mh-select_list mh-selectFix_list">
            <li
              className={
                `mh-select_item ${styles.selectItem}` +
                (condition.condition === "more"
                  ? " mh-select_item__current"
                  : "")
              }
              onClick={() =>
                handleEditCondition({
                  ...condition,
                  condition: "more",
                })
              }
            >
              Больше
            </li>
            <li
              className={
                `mh-select_item ${styles.selectItem}` +
                (condition.condition === "more_or_equal"
                  ? " mh-select_item__current"
                  : "")
              }
              onClick={() =>
                handleEditCondition({
                  ...condition,
                  condition: "more_or_equal",
                })
              }
            >
              Больше или равно
            </li>
            <li
              className={
                `mh-select_item ${styles.selectItem}` +
                (condition.condition === "equal"
                  ? " mh-select_item__current"
                  : "")
              }
              onClick={() => {
                handleEditCondition({
                  ...condition,
                  condition: "equal",
                });
              }}
            >
              Равно
            </li>
            <li
              className={
                `mh-select_item ${styles.selectItem}` +
                (condition.condition === "less_or_equal"
                  ? " mh-select_item__current"
                  : "")
              }
              onClick={() =>
                handleEditCondition({
                  ...condition,
                  condition: "less_or_equal",
                })
              }
            >
              Меньше или равно
            </li>
            <li
              className={
                `mh-select_item ${styles.selectItem}` +
                (condition.condition === "less"
                  ? " mh-select_item__current"
                  : "")
              }
              onClick={() => {
                handleEditCondition({
                  ...condition,
                  condition: "less",
                });
              }}
            >
              Меньше
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
