import { NavLink } from "react-router-dom";
import { useAppSelector, useSettings } from "../../hooks";
import { RowMenu } from "./RowMenu";

interface IRowProps {
  data: any;
  index: number;
}

export const TableRow = ({ data, index }: IRowProps) => {
  const { checkCasesRender } = useSettings();
  const { initData } = useAppSelector((state) => state.formsTable);
  const { actionButtons } = initData;

  const renderData = Object.entries(data)
    .map((entry) => ({
      [entry[0]]: entry[1],
    }))
    .sort((a, b) => {
      if (a?.actionButton) {
        return 1;
      }
      if (b?.actionButton) {
        return -1;
      }
      return 0;
    });

  const id = data.id ?? index;

  return (
    <tr className={"index" ? "table-row table-row--bg" : "table-row"}>
      {renderData.map((i: any) => {
        return (
          <td
            key={Object.keys(i).toString()}
            // style={{ width: `${TABLE_WIDTH / renderData.length}px` }}
            className={"table-row__item"}
          >
            <div className={"table-row__wrap-text"}>
              {i.actionButton ? (
                <NavLink to={i.actionButton}>Открыть</NavLink>
              ) : i.visit_info ? (
                <div className="visit-info">
                  {i.visit_info
                    .map((visit) => {
                      console.log(visit);
                      return JSON.parse(visit);
                    })
                    .sort((a, b) => a.visit_id - b.visit_id)
                    .map((parsedVisit, index) => {
                      const status = parsedVisit.status;
                      const visitId = parsedVisit.visit_id;
                      return (
                        <span
                          key={index}
                          className={
                            status === 0
                              ? "not_filled"
                              : status === 1
                              ? "draft"
                              : status === 3
                              ? "moderate"
                              : status === 2
                              ? "success"
                              : "unknoun_status"
                          }
                        >
                          {visitId}
                        </span>
                      );
                    })}
                </div>
              ) : (
                <span
                  className={"table-row__text"}
                  dangerouslySetInnerHTML={{
                    __html: checkCasesRender(i),
                  }}
                />
              )}
              {/* <span
            className={"table-row__text"}
            dangerouslySetInnerHTML={{
              __html: checkCasesRender(i),
            }}
          /> */}
              {/*<span className={'table-row__subtext'}>Офтальмология</span>*/}
            </div>
          </td>
        );
      })}
      {actionButtons.length > 0 && (
        <div className={"table-row__item"}>
          <RowMenu data={actionButtons} id={id} />
        </div>
      )}
    </tr>
  );
};
