import { useEffect, useRef, type FC } from "react";

interface IConditionalQuestionWrapperProps {
  isShowed: boolean;
  children: React.ReactNode;
  clearState: (id: string) => void;
}

export const ConditionalQuestionWrapper: FC<
  IConditionalQuestionWrapperProps
> = ({ children, isShowed, clearState }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isShowed) {
      const inputElements = ref.current.getElementsByTagName("input");
      const textareaElements = ref.current.getElementsByTagName("textarea");
      const selectElements = ref.current.getElementsByTagName("select");
      const buttonsElements = ref.current.getElementsByClassName("btn-primary");
      const calendarInputElements =
        ref.current.getElementsByClassName("calendarInput");
      for (var i = 0, max = calendarInputElements.length; i < max; i++) {
        const id = calendarInputElements[i].id;
        clearState(id);
      }
      for (var i = 0, max = inputElements.length; i < max; i++) {
        inputElements[i].value = "";
        inputElements[i].checked = false;
      }
      for (var i = 0, max = textareaElements.length; i < max; i++) {
        textareaElements[i].value = "";
      }
      for (var i = 0, max = selectElements.length; i < max; i++) {
        selectElements[i].selectedIndex = 0;
      }
      for (var i = 0, max = buttonsElements.length; i < max; i++) {
        buttonsElements[i].className = "btn btn-secondary";
      }
    }
  }, [isShowed]);

  return (
    <div ref={ref} style={{ display: isShowed ? "block" : "none" }}>
      {children}
    </div>
  );
};
