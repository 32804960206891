import React from "react";
import { connect } from "react-redux";

import IsReadedIcon from "../icons/isReaded";

import "./style.scss";

class Message extends React.Component {
   render() {
      const data = this.props.data;
      const type = data.type;
      const userId = this.props.user.id;

      if (type) {
         if ( type === "i" ) {
            return <div className="chat-message chat-message__info">{data.text}</div>
         }

         return "";
      }
      else {
         const date = new Date(data.updated_at);
         const hours = date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
         const minutes = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
         return data.user_id !== userId ? (
            <div className="chat-message">
               <p className="chat-message_text" dangerouslySetInnerHTML={{ __html: data.text }} />
               <div className="chat-message_info">
                  <span className="chat-messageTime">{hours+":"+minutes}</span>
               </div>
            </div>
         ) : (
            <div className="chat-message chat-message__right">
                <p className="chat-message_text" dangerouslySetInnerHTML={{ __html: data.text }} />
              <div className="chat-message_info">
                  <span className="chat-messageTime">{hours+":"+minutes}</span>
                 {/* {data.isReaded ? <IsReadedIcon /> : ""} */}
               </div>
            </div>
         );
      }
   }
}

const mapStateToProps = (state) => {
   return {
      user: state.user
   }
}

export default connect(mapStateToProps)(Message);
