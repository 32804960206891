import React from "react";
import "./style.scss";
import Message from "../Message";
import IconSearch from "../icons/search";
import AttachmentsIcon from "../icons/attachment";
import SendIcon from "../icons/send";
import { connect } from "react-redux";
import UserAvatar from "../../avatar";
import { v4 } from "uuid";
import moment from "moment";
import store from "../../../store/store";

class ChatView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageText: "",
    };
    this.messageInput = React.createRef();
  }

  chatContainer = React.createRef();

  messageWrite = (e) => {
    this.setState((state) => (state.messageText = e.target.value));
  };

  onMessageSend = () => {
    const { messageText } = this.state;
    if (messageText.length > 0) {
      const { state } = this;
      state.messageText = "";
      this.setState(state, () => {
        if (this.props.selectedChat.roomId) {
          this.props.socket.emit("message.send", {
            room_id: this.props.selectedChat.roomId,
            text: messageText,
          });
        } else {
          this.props.socket.emit("chat.start", {
            user_id: this.props.selectedChat.id,
            text: messageText,
          });
        }
        store.dispatch({ type: "UPDATE_CONTACT_LIST" });
      });
    } else {
      this.messageInput.current.focus();
    }
  };

  readRoom() {
    if (this.props.selectedChat && this.props.selectedChat.roomId) {
      this.props.socket.emit("chat.read", {
        room_id: this.props.selectedChat.roomId,
      });
      this.props.readRoom(this.props.selectedChat.roomId);
      this.props.onReadCallback();
    }
  }

  enterToSend = (e) => {
    if (e.keyCode === 13) {
      this.onMessageSend();
    }
  };

  openMessageSearch = () => {
    this.props.onMessageSearchClick();
  };

  scrollToLastMessage = () => {
    const scroll =
      this.chatContainer.current.scrollHeight -
      this.chatContainer.current.clientHeight;
    console.log(scroll);
    this.chatContainer.current.scrollTo(0, scroll);
  };

  componentDidUpdate(prevProps) {
    if (this.props.messages !== prevProps.messages) {
      this.scrollToLastMessage();
    }
  }

  render() {
    const roles = [
      "Пользователь",
      "Врач",
      "Модератор",
      "Монитор",
      "Администратор",
      "Супер администратор",
      "Удалённый пользователь",
    ];

    const { selectedChat } = this.props;

    if (this.props.selectedChat?.unreadCount > 0) {
      this.readRoom();
    }

    return (
      <div className={this.props.className}>
        <header className="chat-viewHeader">
          <UserAvatar
            className="chat-viewHeader_avatar"
            img={selectedChat.avatar}
            name={selectedChat.name}
            surname={selectedChat.surname}
            id={selectedChat.id}
            size={40}
          />
          <div className="chat-viewHeader_info">
            <p className="chat-viewUser">
              <span className="chat-viewUser_name">
                {selectedChat.name} {selectedChat.surname}
              </span>
              <span className="chat-viewUser_role">
                {roles[selectedChat.role]}
              </span>
            </p>
            <p className="chat-viewLastTimeOnline">
              {this.props.usersOnline.indexOf(selectedChat.id) > -1
                ? "Онлайн"
                : "Не в сети"}
            </p>
          </div>
          <button
            className="chat-viewHeader_searchButton"
            title="Поиск по сообщениям"
            onClick={this.openMessageSearch}
            style={{ display: "none" }}
          >
            <IconSearch />
          </button>
        </header>

        <div className="chat-viewMassages" ref={this.chatContainer}>
          {this.props.messages.map((message, i) => {
            if (i > 0) {
              if (selectedChat.messages[i - 1]) {
                if (
                  new Date(
                    selectedChat.messages[i - 1].updated_at
                  ).getDate() !== new Date(message.updated_at).getDate()
                ) {
                  return (
                    <Message
                      data={{
                        type: "i",
                        text: new Date(
                          selectedChat.messages[i - 1].updated_at
                        ).toLocaleDateString(),
                      }}
                      key={v4()}
                    />
                  );
                }
              }
            }

            return <Message data={message} key={message.id} />;
          })}
        </div>

        <div className="chat-viewSendForm">
          <button
            className="chat-viewSendForm_attachmentsButton"
            title="Прикрепить"
            onClick={this.props.onAttachment}
          >
            <AttachmentsIcon />
          </button>

          <input
            className="chat-viewSendForm_input"
            type="text"
            placeholder="Сообщение"
            onChange={this.messageWrite}
            value={this.state.messageText}
            ref={this.messageInput}
            onKeyUp={this.enterToSend}
            style={{
              border: "none",
              borderRadius: "0px",
              padding: "0px",
            }}
          />

          <button
            className="chat-viewSendForm_sendButton"
            title="Отправить"
            onClick={this.onMessageSend}
          >
            <SendIcon />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedChatId: state.selectedChatId,
    selectedChat: state.selectedChat,
    messages: state.messages,
    usersOnline: state.usersOnline,
    socket: state.io,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readRoom: (roomId) =>
      dispatch({
        type: "READ_ROOM",
        payload: {
          roomId,
        },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatView);
