import "./index.scss";

import React from "react";
import { ProgressBar } from "react-bootstrap";
import IMAGES from "../../../images/iconSet";
import { formatBytes } from "../../../utils/helpers";

export class InputFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFiles: [],
      filesInfo: [],
      progressInfos: [],
      messages: [],
      error: "",
      files: props.files ?? [],
    };
  }

  onFileChange = (e) => {
    const files = [...e.target.files];
    const progressInfos = files.map((f) => ({
      percentage: 0,
      fileName: f.name,
    }));
    const addedFiles = this.state.filesInfo.map((f) => ({
      extension: f.extension,
      size: f.size,
      source: f.source,
      target: f.target,
    }));

    this.setState(
      (prev) => ({
        selectedFiles: files,
        progressInfos,
        messages: [],
        error: "",
        filesInfo: [],
        files: [...prev.files, ...addedFiles],
      }),
      () => this.uploadFiles()
    );
  };

  uploadFiles = () => {
    this.state.selectedFiles.forEach((f, i) => this.upload(i, f));
  };

  upload = (idx, file) => {
    let _progressInfos = [...this.state.progressInfos];

    const handleErrors = () => {
      _progressInfos[idx].percentage = 0;
      this.setState((prev) => {
        let nextMessage = [
          ...prev.messages,
          { message: "Ошибка загрузки файла: " + file.name, type: "error" },
        ];
        return {
          progressInfos: _progressInfos,
          messages: nextMessage,
        };
      });
    };

    this.props
      .uploadFile(file, (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        this.setState({
          progressInfos: _progressInfos,
        });
      })
      .then((response) => {
        if (response.message === "ok") {
          this.setState((prev) => {
            let nextMessage = [
              ...prev.messages,
              {
                message: "Файл успешно загружен: " + file.name,
                type: "success",
                selectedFileIndex: idx,
              },
            ];
            return {
              messages: nextMessage,
              filesInfo: [
                ...prev.filesInfo,
                { ...response.response, selectedFileIndex: idx },
              ],
            };
          });
        } else {
          handleErrors();
        }
      })
      .catch(() => {
        handleErrors();
      });
  };

  removeFile = (source, name) => {
    this.setState({ error: "" });
    this.props
      .removeFile(source)
      .then((response) => {
        if (response.message === "ok") {
          this.setState({
            files: this.state.files.filter((file) => file.source !== source),
          });
        } else {
          this.setState({ error: `Ошибка удаления файла: ${name}` });
        }
      })
      .catch(() => this.setState({ error: `Ошибка удаления файла: ${name}` }));
  };

  removeSelectedFile = (index, name) => {
    this.setState({ error: "" });
    const file = this.state.filesInfo.find(
      (file) => file.selectedFileIndex === index
    );
    this.props
      .removeFile(file?.source)
      .then((response) => {
        if (response.message === "ok") {
          this.setState({
            selectedFiles: this.state.selectedFiles.filter(
              (f, i) => i !== index
            ),
            filesInfo: this.state.filesInfo.filter(
              (f) => f.selectedFileIndex !== index
            ),
            messages: this.state.messages.filter(
              (f) => f.selectedFileIndex !== index
            ),
          });
        } else {
          this.setState({ error: `Ошибка удаления файла: ${name}` });
        }
      })
      .catch(() => this.setState({ error: `Ошибка удаления файла: ${name}` }));
  };

  render() {
    return (
      <div className="mh-file_wrapper">
        <label className="mh-file">
          {this.props.text}
          <input
            type="file"
            name={this.props.name}
            id={this.props.id}
            multiple={this.props.multiple}
            onChange={this.onFileChange}
          />
        </label>
        <table>
          <tbody className="mh-file_list">
            {this.state.files.map((file, i) => (
              <tr className="mh-file_item" key={i}>
                <td className="mh-file_name">{file.target}</td>
                <td>
                  <button
                    type="button"
                    className="mh-file_remove"
                    onClick={() => this.removeFile(file.source, file.target)}
                  >
                    <img src={IMAGES.constructor.removeSelectedItem} alt="" />
                  </button>
                </td>
              </tr>
            ))}
            {this.state.selectedFiles.map((file, i) => (
              <tr className="mh-file_item" key={i}>
                <td className="mh-file_name">{file.name}</td>
                <td className="mh-file_size">{formatBytes(file.size)}</td>
                <td className="mh-file_progress">
                  <ProgressBar
                    className="mh-file_progress"
                    variant="success"
                    now={this.state.progressInfos?.[i]?.percentage ?? 0}
                  />
                </td>
                <td className="mh-file_percentage">
                  {this.state.progressInfos?.[i]?.percentage ?? 0}%
                </td>
                <td>
                  <button
                    type="button"
                    className="mh-file_remove"
                    onClick={() => this.removeSelectedFile(i, file.name)}
                  >
                    <img src={IMAGES.constructor.removeSelectedItem} alt="" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mh-file_messages">
          {this.state.messages.map((x, i) => (
            <div
              className={`mh-file_message mh-file_message_${x.type}`}
              key={i}
            >
              {x.message}
            </div>
          ))}
          {this.state.error && (
            <div className="mh-file_message mh-file_message_error">
              {this.state.error}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default InputFile;
