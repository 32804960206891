import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { RD, CiD, Doctors, ActionsTable, CountPicker, PaginationBasic } from "../../components/ddTest.js";
import Stack from 'react-bootstrap/Stack';
import icon from '../../images/iconSet';
import UserEditComponent from '../../components/formComponent/editExUser.jsx';
import React, { Component } from "react";
export class UserEditNew extends React.Component {
   GetUserCount() {

   }
   render() {
      return (
         <div className="container-fluid" id="main-page-block">
            <div id="page-content">
               <UserEditComponent id={window.location.href.split("?")[1] ? window.location.href.split("?")[1].split("=")[1] : 1} />
            </div>
         </div>
      )
   }
}

export default UserEditNew;
