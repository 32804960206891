import React, { useState } from "react";
import "./styles.scss";
import { ICasesSelector } from "../../types";
import { ChevronDown } from "../../assets/icons";
import { Search } from "./components";
import { useAppSelector } from "../../hooks";

interface ISelectorProps {
  title: string;
  options: ICasesSelector[];
  getSelected: (item: ICasesSelector[]) => void;
}

export const SelectorSearch = ({
  title,
  options,
  getSelected,
}: ISelectorProps) => {
  const filters = useAppSelector((state) => state.table.filters);
  const [isVisible, setVisible] = useState<boolean>(false);

  return (
    <div className={"selector"}>
      <div
        className={"selector__header"}
        style={{ zIndex: isVisible ? 10 : 0 }}
        onClick={() => setVisible(!isVisible)}
      >
        <span className={"selector__head"}>{title}</span>
        <span
          className={"selector__header--icon"}
          style={{ transform: isVisible ? "rotate(180deg)" : "rotate(0deg)" }}
        >
          <ChevronDown width={12} height={7} color={"#757575"} />
        </span>
      </div>
      {isVisible ? (
        <Search
          options={options}
          defaultSelected={options.filter((el) =>
            filters.find((filter) => "id" in filter && filter.id === el.case)
          )}
          handler={getSelected}
          handleClose={() => setVisible(!isVisible)}
        />
      ) : null}
      {isVisible ? (
        <div className={"selector-overlay"} onClick={() => setVisible(false)} />
      ) : null}
    </div>
  );
};
