import { Button, Row, Alert } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";

import icon from "../../images/iconSet";
import Tass from "../../tables";
import React, { useCallback, useState } from "react";
import EGFRCalculator from "../../components/calculator/Calculator";

export const Home = () => {
    const [count, setCount] = useState(0);

    const handleChangeCount = useCallback((value) => {
        setCount(value);
    }, []);

    return (
        <div className="container-fluid" id="main-page-block">
            {/*  <Stack direction="horizontal" gap={3}>
                <div className="dashboard-statistic">
                    <div className="d-flex flex-row gap-3">
                        <div className="dashboard-statistic--icon">
                            <img src={icon.home.researhes} alt="Исследования" />
                        </div>
                        <div className="dashboard-statistic--title">Исследования</div>
                        <Row className="dashboard-statistic--stat">
                            <p className="sub_data col-md-auto">21</p>
                            <p className="up_data col-md-auto">+23</p>
                        </Row>
                    </div>
                </div>
                <div className="dashboard-statistic">
                    <div className="d-flex flex-row gap-3">
                        <div className="dashboard-statistic--icon">
                            <img src={icon.home.forms} alt="Анкеты" />
                        </div>
                        <div className="dashboard-statistic--title">Анкеты</div>
                        <Row className="dashboard-statistic--stat">
                            <p className="sub_data col-md-auto">2570</p>
                            <p className="up_data col-md-auto">+438</p>
                        </Row>
                    </div>
                </div>
                <div className="dashboard-statistic">
                    <div className="d-flex flex-row gap-3">
                        <div className="dashboard-statistic--icon">
                            <img src={icon.home.doctors} alt="Врачи" />
                        </div>
                        <div className="dashboard-statistic--title">Врачи</div>
                        <Row className="dashboard-statistic--stat">
                            <p className="sub_data col-md-auto">10</p>
                            <p className="up_data col-md-auto">+2</p>
                        </Row>
                    </div>
                </div>
                <div className="dashboard-statistic">
                    <div className="d-flex flex-row gap-3">
                        <div className="dashboard-statistic--icon">
                            <img src={icon.home.customers} alt="Заказчики" />
                        </div>
                        <div className="dashboard-statistic--title">Заказчики</div>
                        <Row className="dashboard-statistic--stat">
                            <p className="sub_data col-md-auto">21</p>
                            <p className="up_data col-md-auto">+23</p>
                        </Row>
                    </div>
                </div>
            </Stack> */}

            <div id="page-content">
                <div className="page-content--render">
                    {/*  <Alert key={'primary'} variant={'primary'}>
                    Анкеты доступны для редактирования. Для просмотра необходимо зайти в раздел <a href="/forms/">Анкеты</a>
                    </Alert> */}
                    <h2>
                        Исследования <span id="sup">{count ? count : ""}</span>
                    </h2>

                    <Tass
                        url={global.apiUrl + "/research/list/"}
                        token={sessionStorage.getItem("token")}
                        updateCount={handleChangeCount}
                    />
                </div>
            </div>
        </div>
    );
};

export default Home;
