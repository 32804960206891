import Container from 'react-bootstrap/Container';
import iconImage from "../../images/logo.svg";
import bgLoginForm from "../../images/login-bg.webp";
import "./login.css";

export default function Layout({children}) {
    return (
        <Container>
            <div className="login-wrapper">
                <div id="login-card">
                    <div className="login-card--wrapper">
                        <div id="login-form">
                            <a className="logo" href="/">
                            <img src={iconImage} alt="" className="logo--icon" />
                            <div className="logo--text bold">РООИ</div>
                            </a>
                            <img src={bgLoginForm} alt="" className="logo-bg" />
                        </div>
                        {children}
                    </div>
                </div>
            </div>
      </Container>
    )
}