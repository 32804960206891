import React from "react";

import './index.scss';

class Radio extends React.Component {
    render () {
        return (
            <label className="mh-radio" style={this.props.style}>
                <input type="radio" defaultChecked={this.props.defaultValue} checked={this.props.value} onChange={this.props.onChange} className="mh-radio_item"/>
                <div className="mh-radio_field">
                    {
                        this.props.value ? (
                            <span className="" />
                        ) : ""
                    }
                </div>
                {this.props.children}
            </label>
        )
    }
}

export default Radio;