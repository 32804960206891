import { FC, useCallback } from "react";
import { FilterTypes, IQuestionnairesFilters } from "../store/types";
import { Tag } from "../../../components/tags/tag";
import { useAppDispatch } from "../../hooks";
import {
  clearFilters,
  removeFilter,
  sendFilterData,
} from "../store/table.slice";

interface Props {
  filters: IQuestionnairesFilters[];
  handleGetInitTable: () => void;
}

export const ActiveFilters: FC<Props> = ({ filters, handleGetInitTable }) => {
  const dispatch = useAppDispatch();

  const handleClearAll = useCallback(() => {
    dispatch(clearFilters());
    handleGetInitTable();
  }, [handleGetInitTable]);

  const handleClose = useCallback(
    (el: IQuestionnairesFilters) => {
      const otherFilters = filters.filter(
        (other) =>
          el.type !== other.type ||
          (el.type === FilterTypes.searchValue ? false : el.id !== other.id)
      );

      console.log(otherFilters);

      if (otherFilters.length > 0) {
        const otherFiltersObject = {};
        otherFilters.forEach((el) => {
          if (el.type === FilterTypes.searchValue) {
            otherFiltersObject["search"] = el.value;
          } else {
            otherFiltersObject[el.type] = otherFiltersObject[el.type]
              ? [...otherFiltersObject[el.type], el.id]
              : [el.id];
          }
        });
        console.log(otherFiltersObject);
        dispatch(removeFilter(el));
        dispatch(
          sendFilterData({
            data: otherFiltersObject,
          })
        );
      } else {
        dispatch(removeFilter(el));
        dispatch(
          sendFilterData({
            data: {},
          })
        );
      }
    },
    [filters]
  );

  return (
    <div className="active-filters">
      {filters.map((el) => (
        <Tag
          key={el.id !== undefined ? el.id : el.value}
          closable
          onClose={() => {
            handleClose(el);
          }}
        >
          {el.value}
        </Tag>
      ))}
      {filters.length > 1 && (
        <span className="clear-active-filters" onClick={handleClearAll}>
          Очистить все
        </span>
      )}
    </div>
  );
};
