import {createSelectorOptions} from '../helpers';
import {useAppSelector} from './app.hooks';

export const useSettings = () => {
    const {fields, dropdownfilter} = useAppSelector(state => state.table?.initData);
    const {selectors} = createSelectorOptions(fields, dropdownfilter);

    function checkCasesRender(obj: any) {
        const key: string = Object.keys(obj)[0];
        const valueStr: any = Object.values(obj)[0];
        const cases = fields[key]?.cases;

        if (cases) {
            for (const case1 of cases) {
                if (case1.case.toString() === valueStr?.toString()) {
                    return case1.showFilter;
                }
            }
        }
        return valueStr;
    }

    return {
        selectors,
        checkCasesRender
    };
};
