import React, { useState } from "react";
import "./styles.scss";
import { ChevronDown } from "../../../assets/icons";
import { Search } from "./components";
import IMAGES from "../../../../images/iconSet";

interface ISelectorProps {
  title: string;
  options: { id: number | string; name: string }[];
  getSelected: (items: { id: number | string; name: string }[]) => void;
  defaultSelected?: { id: number | string; name: string }[];
}

export type Item = {
  id: number | string;
  name: string;
  addittional?: string;
};

export const SelectorSearch = ({
  title,
  options,
  getSelected,
  defaultSelected,
}: ISelectorProps) => {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [selected, setSelected] = useState<Item[]>(defaultSelected ?? []);

  const removeSelectedItem = (id: number | string) => {
    const filteredItems = selected.filter((item) => item.id !== id);
    setSelected(filteredItems);
    getSelected(filteredItems);
  };

  return (
    <div className={"selector"}>
      <div
        className={"selector__header"}
        style={{ zIndex: isVisible ? 10 : 0 }}
        onClick={() => setVisible(!isVisible)}
      >
        {selected.length === 0 && (
          <span className={"selector__head"}>{title}</span>
        )}
        <div className="mh-multiSelect_inputItems">
          {options.map((item) =>
            selected.find((x) => x.id === item.id) ? (
              <div
                className="mh-multiSelect_inputItem"
                key={item.id}
                style={{ marginBottom: 0 }}
              >
                <span className="mh-multiSelect_inputItemText">
                  {item.name}
                </span>
                <button
                  type="button"
                  className="mh-multiSelect_inputItemBtn"
                  onClick={() => removeSelectedItem(item.id)}
                >
                  <img src={IMAGES.constructor.removeSelectedItem} alt="" />
                </button>
              </div>
            ) : null
          )}
        </div>
        <span
          className={"selector__header--icon"}
          style={{ transform: isVisible ? "rotate(180deg)" : "rotate(0deg)" }}
        >
          <ChevronDown width={12} height={7} color={"#757575"} />
        </span>
      </div>
      {isVisible && (
        <Search
          options={options}
          value={value}
          selected={selected}
          handleChangeValue={setValue}
          handleChangeSelected={setSelected}
          handler={getSelected}
          handleClose={() => setVisible(!isVisible)}
        />
      )}
      {isVisible && (
        <div
          className={"selector-overlay"}
          onClick={() => {
            setVisible(false);
            setSelected(defaultSelected);
          }}
        />
      )}
    </div>
  );
};
