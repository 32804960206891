import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Calculator.module.scss";
import copyIcon from "../../images/icons/copy-black.svg";
import { Checked } from "../../tables/assets/icons";

const EGFRCalculator = (form_id) => {
  const [creatinine, setCreatinine] = useState("");
  const [gender, setGender] = useState("Male");
  const [race, setRace] = useState("Black");
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");

  const [saved, setSaved] = useState(false);

  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);

  const [results, setResults] = useState<{ label: string; result: number }[]>(
    []
  );

  useEffect(() => {
    const values = localStorage.getItem("calculator-values");
    if (values) {
      const parcedValues = JSON.parse(values);
      if (parcedValues.form_id == form_id.form_id) {
        setAge(parcedValues.age);
        setGender(parcedValues.gender);
        setHeight(parcedValues.height);
        setRace(parcedValues.race);
        setCreatinine(parcedValues.creatinine);
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const parsedAge = parseFloat(age);
    const parsedHeight = parseFloat(height);

    const k = gender === "Female" ? 0.7 : 0.9;
    const alpha = gender === "Female" ? -0.329 : -0.411;
    const raceMultiplier = race === "Black" ? 1.159 : 1;
    const genderMultiplier = gender === "Female" ? 1.018 : 1;

    if (parsedAge <= 17) {
      const eGFR = ((0.413 * parsedHeight) / parseFloat(creatinine)) * 88.4;
      console.log(`eGFR child: ${eGFR}`);
      setResults([{ label: "СКФ", result: Number(eGFR.toFixed(0)) }]);
    } else {
      const eGFR: number =
        141 *
        Math.min(parseFloat(creatinine) / 88.4 / k, 1) ** alpha *
        Math.max(parseFloat(creatinine) / 88.4 / k, 1) ** -1.209 *
        0.993 ** parsedAge *
        raceMultiplier *
        genderMultiplier;

      console.log(`eGFR: ${eGFR}`);
      setResults([{ label: "СКФ", result: Number(eGFR.toFixed(0)) }]);
    }

    const values = {
      form_id: form_id.form_id,
      age,
      gender,
      height,
      race,
      creatinine,
    };

    localStorage.setItem("calculator-values", JSON.stringify(values));

    setSaved(true);
  };

  const copyToClickBoard = (index: number, result: number) => {
    navigator.clipboard.writeText(String(result));
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 3000);
  };

  return (
    <div className={styles.calculator}>
      <Row xs={1} lg={2} className="gy-3 ">
        <Col>
          <Form className={styles.form} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className={styles.label}>
                Возраст пациента (полных лет)
              </Form.Label>
              <Form.Control
                type="text"
                name="age"
                placeholder="от 0 до 99"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </Form.Group>

            {parseFloat(age) <= 17 ? (
              <Form.Group className="mb-3">
                <Form.Label className={styles.label}>Рост</Form.Label>
                <Form.Control
                  type="text"
                  name="Рост"
                  placeholder="Рост ребенка, см."
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </Form.Group>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Form.Label className={styles.label}>Пол</Form.Label>
                  <div className="d-flex">
                    <Form.Check
                      value="Male"
                      name="gender"
                      type="radio"
                      label="муж"
                      className="me-3"
                      onChange={(e) => setGender(e.target.value)}
                      checked={gender === "Male"}
                    />
                    <Form.Check
                      value="Female"
                      name="gender"
                      type="radio"
                      label="жен"
                      onChange={(e) => setGender(e.target.value)}
                      checked={gender === "Female"}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className={styles.label}>Раса</Form.Label>

                  <Form.Select
                    value={race}
                    onChange={(e) => setRace(e.target.value)}
                  >
                    <option value="Black">Негроидная</option>
                    <option value="Other">Другая</option>
                  </Form.Select>
                </Form.Group>
              </>
            )}
            <Form.Group className="mb-3">
              <Form.Label className={styles.label}>
                Креатинин (мкмоль/л)
              </Form.Label>
              <Form.Control
                type="number"
                name="Cr"
                placeholder="Креатинин, мкмоль/л"
                value={creatinine}
                onChange={(e) => setCreatinine(e.target.value)}
              />
            </Form.Group>

            <div className="d-flex align-items-center">
              <Button className={styles.submitButton} type="submit">
                Рассчитать
              </Button>
              {saved && <span className={styles.savedText}>Сохранено</span>}
            </div>
          </Form>
        </Col>
        <Col>
          <div id="calculate-result" className="flex-grow-1">
            <div className={styles.title}>Результаты</div>
            <div className={styles.resultContainer}>
              {results.map((el, i) => (
                <div key={i} className={styles.result}>
                  <span className={styles.resultText}>
                    {el.label + " = " + el.result}
                  </span>
                  <Button
                    variant={i === copiedIndex ? "primary" : "info"}
                    className={
                      i === copiedIndex
                        ? styles.copiedButton
                        : styles.resultButton
                    }
                    onClick={() => copyToClickBoard(i, el.result)}
                  >
                    {i === copiedIndex ? (
                      <span className="me-2">
                        <Checked width={15} height={15} color="#019467" />
                      </span>
                    ) : (
                      <img src={copyIcon} className="me-2" alt="Копировать" />
                    )}
                    {i === copiedIndex ? "Скопировано" : "Копировать"}
                  </Button>
                </div>
              ))}
            </div>
            <Alert className={styles.alert} variant="danger">
              Скопируйте результаты и вставьте их в соответствующие ответы в
              анкете
            </Alert>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EGFRCalculator;
