import React from "react";
import ReactPaginate from "react-paginate";
import "./styles.scss";
import { SelectPageCount } from "./components";

type Props = {
  pageCount: number;
  usePaginator: boolean;
  setItemsPerPage: (perPage: number) => void;
  handlePageClick: (e: { selected: number }) => void;
  active: number;
  itemsPerPage: number;
};

export const Pagination = ({
  usePaginator,
  setItemsPerPage,
  handlePageClick,
  active,
  pageCount,
  itemsPerPage,
}: Props) => {
  return (
    <>
      <div className={"pagination-wrapper"}>
        {usePaginator ? (
          <>
            <SelectPageCount
              handleSelect={setItemsPerPage}
              itemsPerPage={itemsPerPage}
            />
            <ReactPaginate
              breakLabel="..."
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              forcePage={active}
              previousLabel=""
              className={"pagination"}
              previousLinkClassName={
                "pagination__button pagination__button--prev"
              }
              nextLinkClassName={"pagination__button pagination__button--next"}
              pageClassName={"pagination__page"}
              breakClassName={"pagination__break"}
              activeClassName={"pagination__page--active"}
            />
          </>
        ) : null}
      </div>
    </>
  );
};
