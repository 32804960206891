import React, { useState } from "react";
import { CartIcon } from "../../assets/icons";
import { IAvailabel } from "../../types";

interface IAllTypesButtonProps {
  tabs: {
    availableValues: IAvailabel[];
    tabsBy: string;
  };
  onClick: (item: IAvailabel, type: string) => void;
  activeFilterValue: string | undefined;
}

export const AllTypesButtons = ({
  tabs,
  activeFilterValue,
  onClick,
}: IAllTypesButtonProps) => {
  const { availableValues, tabsBy } = tabs;

  const buttons: IAvailabel[] = Object.values(availableValues).map((i) => i);

  return (
    <div className={"all-types"}>
      {buttons.map((item, index: number) => (
        <button
          key={item.value}
          className={
            item.value === (activeFilterValue ?? availableValues[0].value)
              ? "all-types__button all-types__button--active"
              : "all-types__button"
          }
          onClick={() => onClick(item, tabsBy)}
        >
          {item.DisplayName}
          {/*<span className={'all-types__count'}>42</span>*/}
        </button>
      ))}
    </div>
  );
};
