import "./index.scss";

import _ from "lodash";

import React from "react";

import { v4 } from "uuid";

import IMAGES from "../../../images/iconSet";
import Modals from "../../modal";
import OptionMenu from "../optionsMenu";
import Tabs from "../tabs";

import getComponentByType from "./getComponentByType";
import templatesList from "./templates";

import Form from "react-bootstrap/Form";
import * as yup from "yup";
import DeleteItemModal from "../../deleteItemModal/DeleteItemModal";
import Checkbox from "../../inputs/checkbox";
import TableEditor from "../optionsMenu/tableEditor";
import Wrapper from "../ques/wrapper";
import { DeleteSelectedModal } from "./DeleteSelectedModal/DeleteSelectedModal";
import EmptyField from "./emptyField";
import Field from "./field";
import ModalAddBlock from "./modalAddBlock";
import { ModalNumberDateQuestionTerms } from "./modalNumberQuestionTerms";
import { ModalQuestionTerms } from "./modalQuestionTerms";

const templateSchema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      type: yup.string().required(),
      options: yup.object(),
    })
  ),
});

const collectAllConditions = (item, conditions) => {
  if (item.options?.conditions.length > 0) {
    item.options.conditions.forEach((c) => {
      conditions.push(c);
    });
  }
  if (item?.options?.data?.items) {
    item.options.data.items.forEach((x, i) => {
      if (x?.options?.conditions.length > 0) {
        collectAllConditions(x, conditions);
      }
    });
  }
  if (Array.isArray(item?.options?.data)) {
    item.options.data.forEach((x, i) => {
      if (x?.options?.conditions.length > 0) {
        collectAllConditions(x, conditions);
      }
    });
  }
  if (item?.options?.data?.rows) {
    item.options.data.rows.forEach((x, i) => {
      if (x?.options?.conditions.length > 0) {
        collectAllConditions(x, conditions);
      }
    });
  }
};

const generateNewIdsForItemData = (
  item,
  conditionIds,
  questionsIds,
  answersIds
) => {
  if (item.options.conditions.length > 0) {
    item.options.conditions.forEach((c, i) => {
      const updatedConditionId = conditionIds.find((ids) => ids.oldId === c.id);
      const updatedQuestionId = questionsIds.find(
        (ids) => ids.oldId === c.questionId
      );
      const updatedAnswerId = answersIds.find(
        (ids) => ids.oldId === c.answer.value
      );
      if (updatedConditionId) {
        item.options.conditions[i].id = updatedConditionId.newId;
      }
      if (updatedQuestionId) {
        item.options.conditions[i].questionId = updatedQuestionId.newId;
      }
      if (updatedAnswerId && item.options.conditions[i].answer.type === "id") {
        item.options.conditions[i].answer.value = updatedAnswerId.newId;
      }
    });
  }

  if (item.options.data) {
    if (item.options.data.items) {
      item.options.data.items.forEach((x, i) => {
        item.options.data.items[i].id = v4();
        if (x?.options) {
          generateNewIdsForItemData(x, conditionIds, questionsIds, answersIds);
        }
      });
    }
    if (Array.isArray(item.options.data)) {
      item.options.data.forEach((x, i) => {
        item.options.data[i].id = v4();
        if (x?.options) {
          generateNewIdsForItemData(x, conditionIds, questionsIds, answersIds);
        }
      });
    }
    if (item.options.data.headers) {
      item.options.data.headers.forEach((_, i) => {
        const currentId = item.options.data.headers[i].id;
        const newId = v4();
        item.options.data.headers[i].id = newId;

        if (item.options.data.rows) {
          item.options.data.rows.forEach((row, i) => {
            if (row.cells) {
              row.cells.forEach((_, index) => {
                row.cells[index].id = v4();
                if (
                  row.cells[index].parentId === currentId &&
                  row.cells[index].parentId !== null
                ) {
                  row.cells[index].parentId = newId;
                }
              });
            }
          });
        }
      });
    }
    if (item.options.data.rows) {
      item.options.data.rows.forEach((x, i) => {
        item.options.data.rows[i].id = v4();
        if (x?.options) {
          generateNewIdsForItemData(x, conditionIds, questionsIds, answersIds);
        }
      });
    }
  }
  return item;
};

export class BuilderResearches extends React.Component {
  constructor(props) {
    super(props);

    this.uploadTemplateRef = React.createRef();

    this.state = {
      activeTab: 0,
      currentBlock: undefined,
      curentForm: {
        form: props.form
          ? props.form
          : {
              visits: [
                {
                  items: [],
                },
              ],
            },
      },
      selectedBlock: undefined,
      modals: [],
      updateTime: true,
      hasUpdate: false,
      deleteModal: {
        isOpened: false,
        id: "",
      },
      deleteSelectedConfirmModal: false,
      selected: [],
      hideDescription: false,
    };
  }

  // -------------- Drag N Drop -------------- //

  dragStartHandler(e, block) {
    if (block.id) {
      this.state.currentBlock = block;
    } else {
      this.state.currentBlock = _.cloneDeep(block);
      this.state.currentBlock.id = v4();

      if (this.state.currentBlock.options.data) {
        if (this.state.currentBlock.options.data.items) {
          this.state.currentBlock.options.data.items.forEach((item, i) => {
            if (!this.state.currentBlock.options.data.items[i].id)
              this.state.currentBlock.options.data.items[i].id = v4();
          });
        }
        if (Array.isArray(this.state.currentBlock.options.data)) {
          this.state.currentBlock.options.data.forEach((item, i) => {
            if (!this.state.currentBlock.options.data[i].id)
              this.state.currentBlock.options.data[i].id = v4();
          });
        }
        if (this.state.currentBlock.options.data.headers) {
          this.state.currentBlock.options.data.headers.forEach((header, i) => {
            if (!this.state.currentBlock.options.data.headers[i].id)
              this.state.currentBlock.options.data.headers[i].id = v4();
          });
        }
        if (this.state.currentBlock.options.data.rows) {
          this.state.currentBlock.options.data.rows.forEach((row, i) => {
            if (!this.state.currentBlock.options.data.rows[i].id)
              this.state.currentBlock.options.data.rows[i].id = v4();
          });
        }
      }
    }
    this.state.selectedBlock = undefined;
    this.setState(this.state);
  }

  dragOverHandler(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  dragLeaveHandler(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  dragEndHandler(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  dropHandler(e, parentId, hoveredComponentId, where) {
    e.preventDefault();
    e.stopPropagation();

    const { state } = this;
    const { activeTab } = state;

    if (document.querySelector(".tp")) {
      document.querySelectorAll(".tp").forEach((tag) => {
        tag.style = "";
      });
    }

    const currentBlock = state.currentBlock;

    if (!currentBlock.id) {
      currentBlock.id = v4();
    } else {
      this.componentSplice(currentBlock.id, { count: 1 });
    }

    if (parentId === -1) {
      // Если родитель не был указан - в текущий визит
      if (hoveredComponentId === undefined) {
        // Без индекса - В конец списка
        state.curentForm.form.visits[activeTab].items.push(currentBlock);
        state.selectedBlock = currentBlock;
        state.currentBlock = undefined;
        this.setState(() => state);
      } else {
        // С индексом - На позицию индекса
        let index = state.curentForm.form.visits[activeTab].items.length - 1;
        state.curentForm.form.visits[activeTab].items.forEach((item, i) => {
          if (item.id === hoveredComponentId) {
            index = where ? (where === "before" ? i : i + 1) : i + 1;
          }
        });
        state.curentForm.form.visits[activeTab].items.splice(
          index,
          0,
          currentBlock
        );
        state.selectedBlock = currentBlock;
        state.currentBlock = undefined;
        this.setState(() => state);
      }
    } else {
      // Если родитель был указан - в указанного родителя

      if (currentBlock.type === "chapter") return;

      state.curentForm.form.visits[activeTab].items.forEach((item, i) => {
        if (item.id === parentId) {
          if (hoveredComponentId === undefined) {
            state.curentForm.form.visits[activeTab].items[
              i
            ].options.data.items.push(currentBlock);
            state.selectedBlock = currentBlock;
            state.currentBlock = undefined;
            this.setState(() => state);
          } else {
            let position = 0;

            state.curentForm.form.visits[activeTab].items[
              i
            ].options.data.items.forEach((child, j) => {
              if (child.id === hoveredComponentId) {
                position = where ? (where === "before" ? j : j + 1) : j + 1;
              }
            });

            state.curentForm.form.visits[activeTab].items[
              i
            ].options.data.items.splice(position, 0, currentBlock);

            state.selectedBlock = currentBlock;
            state.currentBlock = undefined;
            this.setState(() => state);
          }
        }
      });
    }

    const tu = setTimeout(() => {
      this.onChange();
      clearTimeout(tu);
    }, 1000);
  }

  // -------------- Wrapper styles -------------- //

  wrapperLeaveCounter = 0;

  onOverWrapper = (e) => {
    const wrapper = this.findParent(e.target, "mh-constructorWrapper");
    if (wrapper) {
      wrapper.classList.add("mh-constructorWrapper__hover");
    }
  };

  onWrapperLeave = (e) => {
    e.preventDefault();

    if (e.target.className.indexOf("mh-constructorWrapper") > -1) {
      e.target.classList.remove("mh-constructorWrapper__hover");
    }
  };

  onWrapperDrop = (e) => {
    const wrapper = this.findParent(e.target, "mh-constructorWrapper");
    if (wrapper) {
      wrapper.classList.remove("mh-constructorWrapper__hover");
    }
  };

  // -------------- Visits (Tabs) -------------- //

  setActiveTab = (i) => {
    this.setState((state) => {
      state.activeTab = state.activeTab !== i ? i : state.activeTab;
      return { ...state, selected: [] };
    });
  };

  onTabsMove = (i) => {
    if (this.state.curentForm.form.visits[i]) {
      this.state.activeTab = i;
      this.setState(this.state);
    }
  };

  copyVisit = (index) => {
    const { state } = this;
    const visitRef = state.curentForm.form.visits[index];
    // получить все items ids из копируемого визита
    const refVisitallItemsIds = [];
    visitRef.items.forEach((item) => {
      refVisitallItemsIds.push(item.id);
      if (item.type === "chapter") {
        item.options.data.items.forEach((chapterItem) =>
          refVisitallItemsIds.push(chapterItem.id)
        );
      }
    });
    // проставить флаг isLocal в уловия, которые применяются внутри одного визита
    const getConditionsWithStatus = (item) => {
      return item.options.conditions.map((condition) => {
        const isLocal = refVisitallItemsIds.includes(condition.questionId);
        return { ...condition, isLocal };
      });
    };
    visitRef.items.forEach((item) => {
      if (item.options.conditions && item.options.conditions.length > 0) {
        item.options.conditions = getConditionsWithStatus(item);
        if (item.options.numberCondition) {
          const isLocal = refVisitallItemsIds.includes(
            item.options.numberCondition.questionId
          );
          item.options.numberCondition = {
            ...item.options.numberCondition,
            isLocal,
          };
        }
      }
      if (item.type === "chapter") {
        item.options.data.items.forEach((chapterItem) => {
          if (
            chapterItem.options.conditions &&
            chapterItem.options.conditions.length > 0
          ) {
            chapterItem.options.conditions =
              getConditionsWithStatus(chapterItem);
          }
          if (chapterItem.options.numberCondition) {
            const isLocal = refVisitallItemsIds.includes(
              chapterItem.options.numberCondition.questionId
            );
            chapterItem.options.numberCondition = {
              ...chapterItem.options.numberCondition,
              isLocal,
            };
          }
        });
      }
    });

    let copyVisit = _.cloneDeep(visitRef);
    copyVisit = this.updateIds(copyVisit);

    const allCopiedItems = [];
    const allRefItems = [];

    visitRef.items.forEach((item) => {
      allRefItems.push(item);
      if (item.type === "chapter") {
        item.options.data.items.forEach((child) => allRefItems.push(child));
      }
    });
    copyVisit.items.forEach((item) => {
      allCopiedItems.push(item);
      if (item.type === "chapter") {
        item.options.data.items.forEach((child) => allCopiedItems.push(child));
      }
    });

    const updateCondition = (conditions) => {
      return conditions.map((cond) => {
        const condition = { ...cond };
        if (condition.isLocal) {
          const questionIndex = allRefItems.findIndex(
            (refItem) => refItem.id === condition.questionId
          );
          if (questionIndex >= 0) {
            if (condition.answer.type === "id") {
              const question = allRefItems.find(
                (refItem) => refItem.id === condition.questionId
              );
              const answerIndex = question.options?.data?.items.findIndex(
                (el) => el.id === condition.answer.value
              );
              if (answerIndex >= 0) {
                condition.answer.value =
                  allCopiedItems[questionIndex].options.data.items[
                    answerIndex
                  ].id;
              }
            }
            condition.questionId = allCopiedItems[questionIndex].id;
          }
        }
        delete condition.isLocal;
        return condition;
      });
    };

    const updateNumberCondition = (numberCondition) => {
      const condition = { ...numberCondition };
      if (condition.isLocal) {
        const questionIndex = allRefItems.findIndex(
          (refItem) => refItem.id === condition.questionId
        );
        if (questionIndex >= 0) {
          condition.questionId = allCopiedItems[questionIndex].id;
        }
      }
      delete condition.isLocal;
      return condition;
    };

    // апдейт условий на вопросы из нового визита
    copyVisit.items.forEach((item) => {
      item.options.conditions = updateCondition(item.options.conditions);
      if (item.type === "number" && item.options.numberCondition) {
        item.options.numberCondition = updateNumberCondition(
          item.options.numberCondition
        );
      }
      if (item.type === "chapter") {
        item.options.data.items.forEach((child) => {
          child.options.conditions = updateCondition(child.options.conditions);
          if (child.type === "number" && child.options.numberCondition) {
            child.options.numberCondition = updateNumberCondition(
              child.options.numberCondition
            );
          }
        });
      }
    });

    state.curentForm.form.visits.push(copyVisit);
    this.setState(() => state);

    const tu = setTimeout(() => {
      this.onChange();
      clearTimeout(tu);
    }, 1000);
  };

  updateIds = (node, keyName) => {
    if (node) {
      if (Array.isArray(node)) {
        const newNode = [];
        node.forEach((child) => {
          newNode.push(this.updateIds(child));
        });
        return newNode;
      } else if (typeof node === "object") {
        const newNode = {};
        Object.keys(node).forEach((key) => {
          newNode[key] = this.updateIds(node[key], key);
        });
        return newNode;
      } else if (keyName === "id") {
        return v4();
      } else {
        return node;
      }
    } else {
      return node;
    }
  };

  addVisit = () => {
    const { state } = this;
    state.curentForm.form.visits.push({ items: [] });
    state.activeTab = state.curentForm.form.visits.length - 1;
    this.setState(() => state);

    const tu = setTimeout(() => {
      this.onChange();
      clearTimeout(tu);
    }, 1000);
  };

  removeVisit = (i) => {
    const { state } = this;

    state.curentForm.form.visits.splice(i, 1);

    const lastIndex = state.curentForm.form.visits.length - 1;

    if (state.activeTab > lastIndex) {
      state.activeTab = lastIndex;
    }

    this.setState({ ...state });

    const tu = setTimeout(() => {
      this.onChange();
      clearTimeout(tu);
    }, 1000);
  };

  // -------------- Options editing -------------- //

  onShowOption(block) {
    this.state.selectedBlock = block;

    this.setState(this.state);
  }

  updateOptions(block) {
    // this.componentSplice(block.id, {count: 1, new: block});

    const { state } = this;
    state.curentForm.form.visits[state.activeTab].items.forEach((item, i) => {
      if (item.id === block.id) {
        state.curentForm.form.visits[state.activeTab].items[i] =
          _.cloneDeep(block);
      } else {
        if (item.type === "chapter") {
          if (item.options.data.items) {
            item.options.data.items.forEach((child, j) => {
              if (child.id === block.id) {
                state.curentForm.form.visits[state.activeTab].items[
                  i
                ].options.data.items[j] = _.cloneDeep(block);
              }
            });
          }
        }
      }
    });

    this.setState(state, this.onChange);
  }

  // -------------- Modals -------------- //

  addBlockModal = (e, parentId) => {
    const { state } = this;

    let modalIndex;

    const action = (block) => {
      const newBlock = _.cloneDeep(block.obj);
      newBlock.id = v4();

      if (newBlock.options.data) {
        if (newBlock.options.data.items) {
          newBlock.options.data.items.forEach((item, i) => {
            if (!newBlock.options.data.items[i].id)
              newBlock.options.data.items[i].id = v4();
          });
        }
        if (Array.isArray(newBlock.options.data)) {
          newBlock.options.data.forEach((item, i) => {
            if (!newBlock.options.data[i].id)
              newBlock.options.data[i].id = v4();
          });
        }
        if (newBlock.options.data.headers) {
          newBlock.options.data.headers.forEach((header, i) => {
            if (!newBlock.options.data.headers[i].id)
              newBlock.options.data.headers[i].id = v4();
          });
        }
        if (newBlock.options.data.rows) {
          newBlock.options.data.rows.forEach((row, i) => {
            if (!newBlock.options.data.rows[i].id)
              newBlock.options.data.rows[i].id = v4();
          });
        }
      }

      if (parentId === undefined) {
        this.state.curentForm.form.visits[this.state.activeTab].items.push(
          newBlock
        );
      } else {
        this.state.curentForm.form.visits[this.state.activeTab].items.forEach(
          (item, i) => {
            if (item.id == parentId) {
              this.state.curentForm.form.visits[this.state.activeTab].items[
                i
              ].options.data.items.push(newBlock);
            }
          }
        );
      }
      this.state.modals.splice(modalIndex, 1);
      this.state.selectedBlock = newBlock;

      this.setState(this.state);
    };

    state.modals.push({
      header: "Добавить блок",
      action: action,
      content: <ModalAddBlock addBlock={action} hasParent={parentId} />,
    });

    modalIndex = state.modals.length - 1;

    this.setState(state);
  };

  tableEditorModal = (block) => {
    const { state } = this;

    let modalIndex;

    const onSave = (newData, dynamic) => {
      block.options.data = newData;
      block.options.dynamic = dynamic;

      if (dynamic) {
        block.options.required = false;
      }

      state.curentForm.form.visits[state.activeTab].items.forEach((item, i) => {
        if (item.id === block.id) {
          state.curentForm.form.visits[state.activeTab].items[i] =
            _.cloneDeep(block);
        } else {
          if (item.type === "chapter") {
            if (item.options.data.items) {
              item.options.data.items.forEach((child, j) => {
                if (child.id === block.id) {
                  state.curentForm.form.visits[state.activeTab].items[
                    i
                  ].options.data.items[j] = _.cloneDeep(block);
                }
              });
            }
          }
        }
      });

      state.modals.splice(modalIndex, 1);

      this.setState(state, this.onChange);

      this.setState(state);
    };

    state.modals.push({
      header: "Конструктор таблиц",
      content: (
        <TableEditor
          data={block.options.data}
          onSave={onSave}
          dynamic={!!block.options.dynamic}
        />
      ),
    });

    modalIndex = state.modals.length - 1;

    this.setState(state);
  };

  setConditionsToBlock = (questionId, conditions) => {
    const { state } = this;
    let block;
    state.curentForm.form.visits = state.curentForm.form.visits.map((visit) => {
      const items = visit.items.map((item) => {
        if (item.id === questionId) {
          block = {
            ...item,
            options: {
              ...item.options,
              conditions: conditions,
            },
          };
          return {
            ...item,
            options: {
              ...item.options,
              conditions: conditions,
            },
          };
        } else if (item.type === "chapter") {
          return {
            ...item,
            options: {
              ...item.options,
              data: {
                items: item.options.data.items.map((dataItem) => {
                  if (dataItem.id === questionId) {
                    block = {
                      ...dataItem,
                      options: {
                        ...dataItem.options,
                        conditions: conditions,
                      },
                    };
                    return {
                      ...dataItem,
                      options: {
                        ...dataItem.options,
                        conditions: conditions,
                      },
                    };
                  } else return dataItem;
                }),
              },
            },
          };
        } else return item;
      });

      return {
        ...visit,
        items: items,
      };
    });

    if (block) {
      state.selectedBlock = block;
    }

    this.setState(state);
    this.onChange();
    this.forceUpdate();
  };

  setNumberConditionToBlock = (questionId, numberCondition) => {
    const { state } = this;
    let block;
    state.curentForm.form.visits = state.curentForm.form.visits.map((visit) => {
      const items = visit.items.map((item) => {
        if (item.id === questionId) {
          block = {
            ...item,
            options: {
              ...item.options,
              numberCondition,
            },
          };
          return {
            ...item,
            options: {
              ...item.options,
              numberCondition,
            },
          };
        } else if (item.type === "chapter") {
          return {
            ...item,
            options: {
              ...item.options,
              data: {
                items: item.options.data.items.map((dataItem) => {
                  if (dataItem.id === questionId) {
                    block = {
                      ...dataItem,
                      options: {
                        ...dataItem.options,
                        numberCondition,
                      },
                    };
                    return {
                      ...dataItem,
                      options: {
                        ...dataItem.options,
                        numberCondition,
                      },
                    };
                  } else return dataItem;
                }),
              },
            },
          };
        } else return item;
      });

      return {
        ...visit,
        items: items,
      };
    });

    if (block) {
      state.selectedBlock = block;
    }

    this.setState(state);
    this.onChange();
    this.forceUpdate();
  };

  addQuestionTermsModal = (questionId) => {
    const { state } = this;
    const modalIndex = state.modals.length - 1;

    const action = () => {
      this.state.modals.splice(modalIndex, 1);

      this.setState(this.state);
    };

    state.modals.push({
      header: "Настройки условий показа",
      action: action,
      content: (
        <ModalQuestionTerms
          questionId={questionId}
          action={action}
          currentData={state.curentForm.form.visits}
          initialConditions={state.selectedBlock.options.conditions}
          setConditionsToBlock={(conditions) => {
            this.setConditionsToBlock(questionId, conditions);
            action();
          }}
        />
      ),
    });
    this.setState(state);
  };

  addNumberQuestionTermsModal = (questionId, type) => {
    const { state } = this;
    const modalIndex = state.modals.length - 1;

    const action = () => {
      this.state.modals.splice(modalIndex, 1);

      this.setState(this.state);
    };

    state.modals.push({
      header: "Настройка ответа",
      action: action,
      content: (
        <ModalNumberDateQuestionTerms
          questionId={questionId}
          action={action}
          currentData={state.curentForm.form.visits}
          initialConditions={state.selectedBlock.options.numberCondition}
          setConditionsToBlock={(condition) => {
            this.setNumberConditionToBlock(questionId, condition);
            action();
          }}
          type={type}
        />
      ),
    });
    this.setState(state);
  };

  closeModal = (i) => {
    this.state.modals.splice(i, 1);
    this.setState(this.state);
  };

  // -------------- Functions -------------- //

  findParent = (currentNode, parrentClass) => {
    if (currentNode) {
      if ((" " + currentNode.className + " ").indexOf(parrentClass) > 0) {
        return currentNode;
      } else {
        return this.findParent(currentNode.parentNode, parrentClass);
      }
    }
  };

  componentSplice(id, params, array) {
    const { state } = this;
    const { activeTab } = state;

    if (state.selectedBlock !== undefined) {
      state.selectedBlock = undefined;
      this.setState(state);
    }

    let actionDone = false;

    if (!array) {
      state.curentForm.form.visits[activeTab].items.forEach((item, i) => {
        if (!actionDone) {
          if (item.id === id) {
            if (params.new) {
              const copied = _.cloneDeep(params.new);
              copied.id = v4();

              if (copied.options.data) {
                if (copied.options.data.headers) {
                  copied.options.data.headers.forEach((header) => {
                    header.id = v4();
                  });
                }

                if (copied.options.data.rows) {
                  copied.options.data.rows.forEach((row) => {
                    row.id = v4();
                  });
                }

                if (copied.options.data.items) {
                  copied.options.data.items.forEach((item2) => {
                    item2.id = v4();

                    if (item2.options) {
                      if (item2.options.data) {
                        if (item2.options.data.headers) {
                          item2.options.data.headers.forEach((header) => {
                            header.id = v4();
                          });
                        }

                        if (item2.options.data.rows) {
                          item2.options.data.rows.forEach((row) => {
                            row.id = v4();
                          });
                        }

                        if (item2.options.data.items) {
                          item2.options.data.items.forEach((item3) => {
                            item3.id = v4();
                          });
                        }

                        if (Array.isArray(item2.options.data)) {
                          item2.options.data.forEach((item3) => {
                            item3.id = v4();
                          });
                        }
                      }
                    }
                  });
                }

                if (Array.isArray(copied.options.data)) {
                  copied.options.data.forEach((item) => {
                    item.id = v4();
                  });
                }
              }

              state.curentForm.form.visits[activeTab].items.splice(
                i + 1,
                params.count,
                copied
              );
              state.selectedBlock = copied;

              actionDone = true;
            } else {
              this.clearConditionsOnQuestionRemove(id);
              state.curentForm.form.visits[activeTab].items.splice(
                i,
                params.count
              );
            }
          } else {
            if (item.options.data && item.type === "chapter") {
              if (item.options.data.items) {
                state.curentForm.form.visits[activeTab].items[
                  i
                ].options.data.items = this.componentSplice(
                  id,
                  params,
                  item.options.data.items
                );
              }
            }
          }
        }
      });

      this.setState(() => state);
    } else {
      if (!actionDone) {
        array.forEach((item, i) => {
          if (item.id === id) {
            if (params.new) {
              const copied = _.cloneDeep(params.new);
              copied.id = v4();

              if (copied.options.data) {
                if (copied.options.data.headers) {
                  copied.options.data.headers.forEach((header) => {
                    header.id = v4();
                  });
                }

                if (copied.options.data.rows) {
                  copied.options.data.rows.forEach((row) => {
                    row.id = v4();
                  });
                }

                if (copied.options.data.items) {
                  copied.options.data.items.forEach((item) => {
                    item.id = v4();
                  });
                }

                if (Array.isArray(copied.options.data)) {
                  copied.options.data.forEach((item) => {
                    item.id = v4();
                  });
                }
              }

              array.splice(i + 1, params.count, copied);
              state.selectedBlock = copied;

              actionDone = true;
            } else {
              this.clearConditionsOnQuestionRemove(id);
              array.splice(i, params.count);
            }
          }
        });
      }

      return array;
    }

    const tu = setTimeout(() => {
      this.onChange();
      clearTimeout(tu);
    }, 1000);
  }

  clearConditionsOnQuestionRemove(questionId) {
    console.log(questionId);

    const { state } = this;

    if (state.curentForm.form.visits) {
      state.curentForm.form.visits.forEach((visit, v) => {
        if (visit.items) {
          visit.items.forEach((item1, i1) => {
            if (item1.options.conditions) {
              item1.options.conditions.forEach((condition, c) => {
                if (condition.questionId === questionId) {
                  state.curentForm.form.visits[v].items[
                    i1
                  ].options.conditions.splice(c, 1);
                }
              });
            }

            if (item1.type === "chapter") {
              if (item1.options.data.items) {
                item1.options.data.items.forEach((item2, i2) => {
                  if (item2.options.conditions) {
                    item2.options.conditions.forEach((condition, c) => {
                      if (condition.questionId === questionId) {
                        state.curentForm.form.visits[v].items[
                          i1
                        ].options.data.items[i2].options.conditions.splice(
                          c,
                          1
                        );
                      }
                    });
                  }
                });
              }
            }
          });
        }
      });

      this.setState(state);
    }
  }

  onChange = () => {
    if (this.state.updateTime) {
      this.state.updateTime = false;
      this.props.onChange(this.state.curentForm.form);
    } else {
      this.state.hasUpdate = true;
    }
    this.setState(this.state);
  };

  addTemplate = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const template = JSON.parse(event.target.result);
      templateSchema
        .isValid(template)
        .then((valid) => {
          if (valid) {
            const conditions = [];
            template.items.forEach((item) => {
              collectAllConditions(item, conditions);
            });
            const conditionIds = conditions.map((c) => c.id);
            const uniqConditionIds = [...new Set(conditionIds)];
            const updatedConditionIds = uniqConditionIds.map((id) => ({
              oldId: id,
              newId: v4(),
            }));

            const questionIds = conditions.map((c) => c.questionId);
            const uniqQuestionIds = [...new Set(questionIds)];
            const updatedQuestionIds = uniqQuestionIds.map((id) => ({
              oldId: id,
              newId: v4(),
            }));

            const answersIds = conditions.map((c) => c.answer.value);
            const uniqAnswersIds = [...new Set(answersIds)];
            const updatedAnswersIds = uniqAnswersIds.map((id) => ({
              oldId: id,
              newId: v4(),
            }));

            const newItems = template.items.map((block) => {
              const item = _.cloneDeep(block);
              item.id = v4();
              return generateNewIdsForItemData(
                item,
                updatedConditionIds,
                updatedQuestionIds,
                updatedAnswersIds
              );
            });
            const { state } = this;
            state.curentForm.form.visits.push({ items: newItems });
            state.activeTab = state.curentForm.form.visits.length - 1;
            this.setState(() => state);

            const tu = setTimeout(() => {
              this.onChange();
              clearTimeout(tu);
            }, 1000);
          } else {
            console.log("json not valid");
          }
        })
        .catch((e) => console.log(e, "json not valid"));
    };
    reader.readAsText(e.target.files[0]);
    this.uploadTemplateRef.current.value = "";
  };

  componentDidMount = () => {
    this._ismounted = true;
    this.interval = setInterval(() => {
      if (this._ismounted) {
        this.state.updateTime = true;
        this.setState(this.state);
      } else {
        clearInterval(this.interval);
      }
    }, 3000);
  };

  componentDidUpdate = () => {
    if (this.state.hasUpdate && this.state.updateTime) {
      this.state.updateTime = false;
      this.state.hasUpdate = false;
      this.setState(this.state);
      this.props.onChange(this.state.curentForm.form);
    }
  };

  componentWillUnmount = () => {
    this._ismounted = false;
  };

  renderOptionsMenu() {
    return (
      <div className="builder--researches--options">
        <div className="template--preview">
          <h4
            className="template--title--preview bold"
            style={{ paddingLeft: "100px" }}
          >
            Настройки
          </h4>
        </div>
        <div className="template--options-content">
          {this.state.selectedBlock && (
            <OptionMenu
              block={this.state.selectedBlock}
              key={this.state.selectedBlock.id}
              researchId={this.props.researchId}
              onChange={(state) => this.updateOptions(state)}
              onDelete={() =>
                this.setState({
                  deleteModal: {
                    isOpened: true,
                    id: this.state.selectedBlock.id,
                  },
                })
              }
              addQuestionTermsModal={() =>
                this.addQuestionTermsModal(this.state.selectedBlock.id)
              }
              addNumberQuestionTermsModal={(type) =>
                this.addNumberQuestionTermsModal(
                  this.state.selectedBlock.id,
                  type
                )
              }
              onTableEditor={this.tableEditorModal}
            />
          )}
        </div>
      </div>
    );
  }

  getAllItemsIds = () => {
    const { visits } = this.state.curentForm.form;
    const { activeTab } = this.state;

    if (!visits[activeTab]) return [];

    const allSingleItems = visits[activeTab].items
      .filter((item) => item.type !== "chapter")
      .map((item) => item.id);
    const allChapterItems = visits[activeTab].items
      .filter((item) => item.type === "chapter")
      .map((item) => item.options.data.items)
      .flat()
      .map((item) => item.id);

    const allItems = allSingleItems.concat(allChapterItems);
    return allItems;
  };

  sellectAll = () => {
    const allItems = this.getAllItemsIds();
    if (allItems.length === this.state.selected.length) {
      this.setState({ selected: [] });
    } else {
      this.setState({ selected: allItems });
    }
  };

  deleteSelected = () => {
    this.state.selected.forEach((itemId) => {
      this.componentSplice(itemId, {
        count: 1,
      });
    });
    this.setState({ selected: [] });
  };

  handleSelect = (itemId) => {
    if (this.state.selected.includes(itemId)) {
      this.setState((prev) => ({
        ...prev,
        selected: prev.selected.filter((selectedId) => selectedId !== itemId),
      }));
    } else {
      this.setState((prev) => ({
        ...prev,
        selected: [...prev.selected, itemId],
      }));
    }
  };

  render() {
    const { activeTab, modals, selectedBlock, currentBlock } = this.state;
    const { visits } = this.state.curentForm.form;

    const fieldState = !currentBlock
      ? {
          maxHeight: "0px",
          opacity: 0,
        }
      : null;

    return (
      <div className="builder--researches">
        <div className="template">
          <h4 className="template--title bold">Типы вопросов</h4>
          <ul className="template--list">
            {templatesList.map((blok) => (
              <li
                draggable
                className="template--list--item"
                key={blok.id}
                onDragLeave={(e) => this.dragLeaveHandler(e)}
                onDragEnd={(e) => this.dragEndHandler(e)}
                onDragStart={(e) => this.dragStartHandler(e, blok.obj)}
              >
                <img
                  className="template--list--item--image"
                  src={blok.icon}
                  alt=""
                />
                <span className="template--list--item--title bold">
                  {blok.title}
                </span>
                <img
                  className="template--list--item--image"
                  src={IMAGES.icons.dots}
                  alt=""
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="builder--researches--preview">
          <div className="template--preview">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 className="template--title--preview bold">Предпросмотр</h4>
              <a
                className="template--button"
                target="_blank"
                rel="noreferrer"
                download="шаблон.json"
                href={
                  "data:text/json;charset=utf-8," +
                  encodeURIComponent(JSON.stringify(visits[activeTab]))
                }
              >
                Сохранить шаблон
              </a>
              <label className="template--button" onChange={this.addTemplate}>
                Загрузить шаблон
                <input
                  type="file"
                  accept=".json"
                  ref={this.uploadTemplateRef}
                />
              </label>
            </div>
            <div className="tabs">
              <Tabs
                tabs={visits}
                activeTab={activeTab}
                setActiveTab={this.setActiveTab}
                copyVisit={this.copyVisit}
                addVisit={this.addVisit}
                removeVisit={this.removeVisit}
                onTabsMove={this.onTabsMove}
              />
              <div className="tabs--content">
                <div className="tabs--actions">
                  <Checkbox
                    value={
                      this.state.selected.length ===
                        this.getAllItemsIds().length &&
                      this.state.selected.length > 0
                    }
                    onChange={this.sellectAll}
                  >
                    Выбрать все
                  </Checkbox>
                  <Form.Check
                    type="switch"
                    label="Отобразить только заголовки"
                    checked={this.state.hideDescription}
                    onChange={() =>
                      this.setState((prev) => ({
                        ...prev,
                        hideDescription: !prev.hideDescription,
                      }))
                    }
                  />
                </div>
                <div className="tabs--pane" key={currentBlock ? 1 : 0}>
                  {visits[activeTab]
                    ? visits[activeTab].items.map((item, i) => {
                        const TPL = getComponentByType(
                          item.type,
                          item.options?.fieldType
                        );

                        return (
                          <div
                            key={i}
                            className={
                              "mh-constructorWrapper" +
                              (item.type === "chapter"
                                ? " mh-constructorWrapper__chapter"
                                : "") +
                              (selectedBlock
                                ? item.id === selectedBlock.id
                                  ? " mh-constructorWrapper__selected"
                                  : ""
                                : "")
                            }
                            onDragOver={this.onOverWrapper}
                            onDragLeave={this.onWrapperLeave}
                            onDrop={this.onWrapperDrop}
                          >
                            <Field
                              onDragOver={(e) => this.dragOverHandler(e)}
                              onDrop={(e) =>
                                this.dropHandler(e, -1, item.id, "before")
                              }
                              style={fieldState}
                            />
                            <Wrapper
                              number={i + 1 + "."}
                              title={item.options.title}
                              icon={TPL.icon}
                              required={item.options.required}
                              note={item.options.note}
                              isNotyfi={item.options?.data?.items?.some(
                                (item) => item?.notification?.isNotify
                              )}
                              isNumberCondition={!!item.options.numberCondition}
                              type={item.type}
                              selected={this.state.selected.includes(item.id)}
                              onSelect={() => this.handleSelect(item.id)}
                              hideDescription={this.state.hideDescription}
                              hasCondition={item.options.conditions.length > 0}
                              condition={item.options.conditions}
                              visits={visits}
                              onCopy={() =>
                                this.componentSplice(item.id, {
                                  count: 0,
                                  new: item,
                                })
                              }
                              onDelete={() =>
                                this.setState({
                                  deleteModal: {
                                    isOpened: true,
                                    id: item.id,
                                  },
                                })
                              }
                              onDragStart={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.dragStartHandler(e, item);
                              }}
                              onDragOver={(e) => this.dragOverHandler(e)}
                              onDragLeave={(e) => this.dragLeaveHandler(e)}
                              onDrop={(e) => this.dropHandler(e, -1, item.id)}
                              onClick={() => this.onShowOption(item)}
                            >
                              <TPL.component
                                title={item.options.title}
                                data={item.options.data}
                                onDragOver={(e) => this.dragOverHandler(e)}
                                onClick={(e) => this.addBlockModal(e, item.id)}
                                onDrop={(e) => this.dropHandler(e, item.id)}
                              />
                            </Wrapper>
                            {item.options.data && item.type === "chapter" ? (
                              <>
                                {item.options.data.items.map((child, j) => {
                                  const TPLChild = getComponentByType(
                                    child.type
                                  );

                                  return (
                                    <div
                                      key={j}
                                      className={
                                        "mh-constructorWrapper mh-constructorWrapper__sub" +
                                        (selectedBlock
                                          ? child.id === selectedBlock.id
                                            ? " mh-constructorWrapper__selected"
                                            : ""
                                          : "")
                                      }
                                      onDragOver={this.onOverWrapper}
                                      onDragLeave={this.onWrapperLeave}
                                      onDrop={this.onWrapperDrop}
                                    >
                                      <Field
                                        onDragOver={(e) =>
                                          this.dragOverHandler(e)
                                        }
                                        onDrop={(e) =>
                                          this.dropHandler(
                                            e,
                                            item.id,
                                            child.id,
                                            "before"
                                          )
                                        }
                                        style={fieldState}
                                      />
                                      <Wrapper
                                        number={i + 1 + "." + (j + 1)}
                                        title={child.options.title}
                                        required={child.options.required}
                                        note={child.options.note}
                                        isNotyfi={child.options?.data?.items?.some(
                                          (item) => item?.notification?.isNotify
                                        )}
                                        hasCondition={
                                          child.options.conditions.length > 0
                                        }
                                        condition={child.options.conditions}
                                        isNumberCondition={
                                          !!child.options.numberCondition
                                        }
                                        type={child.type}
                                        selected={this.state.selected.includes(
                                          child.id
                                        )}
                                        onSelect={() =>
                                          this.handleSelect(child.id)
                                        }
                                        hideDescription={
                                          this.state.hideDescription
                                        }
                                        visits={visits}
                                        icon={TPLChild.icon}
                                        onCopy={() =>
                                          this.componentSplice(child.id, {
                                            count: 0,
                                            new: child,
                                          })
                                        }
                                        onDelete={() =>
                                          this.setState({
                                            deleteModal: {
                                              isOpened: true,
                                              id: child.id,
                                            },
                                          })
                                        }
                                        onDragStart={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.dragStartHandler(e, child);
                                        }}
                                        onDragOver={(e) =>
                                          this.dragOverHandler(e)
                                        }
                                        onDragLeave={(e) =>
                                          this.dragLeaveHandler(e)
                                        }
                                        onDrop={(e) =>
                                          this.dropHandler(e, item.id, child.id)
                                        }
                                        onClick={() => this.onShowOption(child)}
                                      >
                                        <TPLChild.component
                                          title={child.options.title}
                                          data={child.options.data}
                                        />
                                      </Wrapper>
                                      <Field
                                        onDragOver={(e) =>
                                          this.dragOverHandler(e)
                                        }
                                        onDrop={(e) =>
                                          this.dropHandler(
                                            e,
                                            item.id,
                                            child.id,
                                            "after"
                                          )
                                        }
                                        style={fieldState}
                                      />
                                    </div>
                                  );
                                })}
                                <div style={{ margin: "10px" }}>
                                  <EmptyField
                                    onDragOver={(e) => this.dragOverHandler(e)}
                                    onDrop={(e) => this.dropHandler(e, item.id)}
                                    onClick={(e) =>
                                      this.addBlockModal(e, item.id)
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {item.type !== "chapter" ? (
                              <Field
                                onDragOver={(e) => this.dragOverHandler(e)}
                                onDrop={(e) =>
                                  this.dropHandler(e, -1, item.id, "after")
                                }
                                style={fieldState}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })
                    : ""}
                </div>

                <EmptyField
                  onClick={(e) => this.addBlockModal(e)}
                  onDragOver={(e) => this.dragOverHandler(e)}
                  onDrop={(e) => this.dropHandler(e, -1)}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderOptionsMenu()}
        <Modals modals={modals} onCloseModal={(i) => this.closeModal(i)} />
        <DeleteItemModal
          open={this.state.deleteModal.isOpened}
          onClose={() =>
            this.setState({ deleteModal: { isOpened: false, id: "" } })
          }
          onDelete={() =>
            this.componentSplice(this.state.deleteModal.id, {
              count: 1,
            })
          }
          title="Удалить вопрос"
          body="Вы действительно хотите удалить вопрос?"
        />
        <DeleteItemModal
          open={this.state.deleteSelectedConfirmModal}
          onClose={() => this.setState({ deleteSelectedConfirmModal: false })}
          onDelete={this.deleteSelected}
          title="Удалить выбранные вопросы"
          body="Вы действительно хотите удалить выбранные вопросы?"
        />
        <DeleteSelectedModal
          open={this.state.selected.length > 0}
          onClose={() => this.setState({ selected: [] })}
          count={this.state.selected.length}
          onDelete={() => this.setState({ deleteSelectedConfirmModal: true })}
        />
      </div>
    );
  }
}

export default BuilderResearches;
