import React from 'react';
import {IIcons} from './types';

export const ChatIcon = ({width = 16, height = 17, color = '#000'}: IIcons) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.83749 11.5625C2.09304 10.3066 1.83264 8.82202 2.10518 7.38764C2.37771 5.95326 3.16444 4.66768 4.31766 3.77225C5.47088 2.87682 6.91129 2.43312 8.36847 2.52444C9.82566 2.61576 11.1994 3.23583 12.2318 4.26823C13.2642 5.30064 13.8843 6.67438 13.9756 8.13156C14.0669 9.58874 13.6232 11.0292 12.7278 12.1824C11.8323 13.3356 10.5468 14.1223 9.11239 14.3949C7.67801 14.6674 6.19348 14.407 4.93749 13.6625V13.6625L2.86249 14.25C2.77747 14.2749 2.68733 14.2764 2.60152 14.2545C2.51571 14.2325 2.43738 14.1879 2.37475 14.1253C2.31212 14.0627 2.26749 13.9843 2.24553 13.8985C2.22358 13.8127 2.22512 13.7226 2.24999 13.6375L2.83749 11.5625Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M8 9.25C8.41421 9.25 8.75 8.91421 8.75 8.5C8.75 8.08579 8.41421 7.75 8 7.75C7.58579 7.75 7.25 8.08579 7.25 8.5C7.25 8.91421 7.58579 9.25 8 9.25Z"
                fill={color}/>
            <path
                d="M5 9.25C5.41421 9.25 5.75 8.91421 5.75 8.5C5.75 8.08579 5.41421 7.75 5 7.75C4.58579 7.75 4.25 8.08579 4.25 8.5C4.25 8.91421 4.58579 9.25 5 9.25Z"
                fill={color}/>
            <path
                d="M11 9.25C11.4142 9.25 11.75 8.91421 11.75 8.5C11.75 8.08579 11.4142 7.75 11 7.75C10.5858 7.75 10.25 8.08579 10.25 8.5C10.25 8.91421 10.5858 9.25 11 9.25Z"
                fill={color}/>
        </svg>
    );
};
