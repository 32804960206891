import { useEffect, useState, type FC } from "react";
import IMAGES from "../../../images/iconSet";
import "./index.scss";
import { Form } from "react-bootstrap";

interface Limit {
  name: string;
  surname: string;
  userId: string;
  formsLimit: number;
  email: string;
}

interface ILimitTableProps {
  limits: Limit[];
  onLimitDelete: (limit: Limit) => void;
  onLimitChange: (limit: Limit) => void;
}

const LimitCell = ({
  limit,
  onLimitChange,
}: {
  limit: number;
  onLimitChange: (limit: number) => void;
}) => {
  const [inputMode, setInputMode] = useState(false);
  const [value, setValue] = useState(String(limit));

  const handleBlur = () => {
    setInputMode(false);
    onLimitChange(Number(value));
  };

  useEffect(() => {
    setValue(String(limit));
  }, [limit]);

  if (inputMode)
    return (
      <td className="limit-input">
        <Form.Control
          type="number"
          onBlur={handleBlur}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoFocus
        />
      </td>
    );

  return <td onClick={() => setInputMode(true)}>{limit}</td>;
};

export const LimitTable: FC<ILimitTableProps> = ({
  limits,
  onLimitChange,
  onLimitDelete,
}) => {
  return (
    <div className="user--researches--table-container">
      <table className="user--researches--form--item-horizontal user--researches--table">
        <thead>
          <th>id</th>
          <th>ФИО</th>
          <th>Лимит</th>
        </thead>
        <tbody>
          {limits.map((user) => (
            <tr style={{ height: 55 }}>
              <td>{user?.userId}</td>
              <td className="user--researches--form--item-horizontal">
                <div>
                  {`${user?.surname ?? "Без"} ${user?.name ?? "имени"}`}
                </div>
                <span>{user?.email ?? ""}</span>
              </td>
              <LimitCell
                limit={user.formsLimit}
                onLimitChange={(newLimit) =>
                  onLimitChange({ ...user, formsLimit: newLimit })
                }
              />
              <td>
                <button
                  type="button"
                  style={{
                    border: "1px solid #dfdfdf",
                    borderRadius: 5,
                  }}
                  onClick={() => onLimitDelete(user)}
                >
                  <img src={IMAGES.thin.bin} alt="" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
