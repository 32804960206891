function CloseIcon() {
   return (
      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M11.7188 3.78125L3.28125 12.2188"
            stroke="#636363"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M11.7188 12.2188L3.28125 3.78125"
            stroke="#636363"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   );
}

export default CloseIcon;
