import "./index.scss";
import React from "react";

import Radio from "../../../inputs/radio";

class RadioNote extends React.Component {
   render() {

      const layouClass = "mh-constructor_checkBoxes"+(this.props.data.layout == "column" ? "" : " mh-constructor_checkBoxes__row")

      return (
         <div className={layouClass}>
            {
               this.props.data.items.map((item, i) => (
                  <Radio key={i} value={item.initialValue} onChange={()=>{}}>{item.text}</Radio>
               ))
            }
         </div>
      );
   }
}

export default RadioNote;
