import React from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { NavLink, Navigate } from "react-router-dom";
import Select from "react-select";
import linkIcon from "../../images/icons/link.svg";
import store from "../../store/store";
import { RedirectProvider } from "../../utils/RedirectService.tsx";
import getNoun from "../getNoun";
import { withRouter } from "../withRouter";
import { AboutForm } from "./AboutForm";
import classes from "./AboutForm.module.scss";
import { checkCondition } from "./checkCondition";
import { CreateFormPlaceholder } from "./createFormPlaceholder";
import { getConditionsMap } from "./getConditionsMap";

import parse from "html-react-parser";
import moment from "moment";
import "moment/locale/ru";
import { getDecimalPointsCount } from "../../utils/helpers";
import { getUserInfo } from "../userComponent/services/userInfo";
import { ConditionalQuestionWrapper } from "./ConditionalQuestionWrapper";
import InputsCleaner from "./InputsCleaner";
import ParsedTable from "./ParsedTable";
import "./index.css";
import { CommentaryBlock } from "./CommentaryBlock";
import { ParsedDate } from "./ParsedDate";
import { ParsedNumber } from "./ParsedNumber";
import { customFetch } from "../../utils/http";

moment.locale("ru");

var counterTab = 0;

const pcValidate = (value, type, maxLength) => {
  if (value.length > maxLength) {
    return false;
  }
  if (type === "number" && !/^([0-9]+)?$/.test(value)) {
    return false;
  }
  if (type === "text" && !/^([а-яА-ЯёЁ]+)?$/.test(value)) {
    return false;
  }
  return true;
};

const statusOptions = [
  { value: 2, label: "Принято" },
  { value: 1, label: "Черновик" },
  { value: 3, label: "На проверке" },
];

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: "50%",
    content: '" "',
    display: "block",
    marginRight: 5,
    height: 7,
    width: 7,
  },
});

const getColors = (value) => {
  switch (value) {
    case 1:
      return { backgroundColor: "#FFE5A1", dotColor: "#F2AE00" };
    case 2:
      return { backgroundColor: "#C4FFDD", dotColor: "#019467" };
    case 3:
      return { backgroundColor: "#DFF7F6", dotColor: "#019467" };
    default:
      return { backgroundColor: "inherit", dotColor: "#ccc" };
  }
};

const colorStyles = {
  control: (styles, { getValue }) => ({
    ...styles,
    height: 29,
    minHeight: 29,
    backgroundColor: getColors(getValue()?.[0]?.value).backgroundColor,
    fontSize: "90%",
    border: "none",
  }),
  menu: (styles) => ({ ...styles, minWidth: 145 }),
  menuList: (styles) => ({
    ...styles,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: 145,
    height: 150,
    padding: 10,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  valueContainer: (styles) => ({ ...styles, height: 29, paddingRight: 0 }),
  indicatorsContainer: (styles) => ({ ...styles, height: 29 }),
  indicatorSeparator: () => ({ display: "none" }),
  option: (styles, { data }) => {
    const colors = getColors(data.value);
    return {
      ...styles,
      ...dot(colors.dotColor),
      backgroundColor: colors.backgroundColor,
      color: "#333333",
      borderRadius: 5,
      fontSize: "90%",
      height: 29,
    };
  },
  placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, { data }) => {
    const colors = getColors(data.value);
    return {
      ...styles,
      ...dot(colors.dotColor),
      backgroundColor: colors.backgroundColor,
    };
  },
};

const textAreaReplacer = (text) => {
  const textWithoutLineBreaks = text.replace(/(\r\n|\n|\r)/gm, " ");
  return textWithoutLineBreaks.replace(/(\'+)|(\"{2,})|([\'\"]+)/g, '"');
};

class ViewForm extends React.Component {
  static contextType = RedirectProvider;
  constructor(props) {
    super(props);
    this.state = {
      msg: [],
      condition: [],
      saveForm: false,
      toHide: [],
      values: {},
      toolb: {},
      allItemsId: [],
      error: null,
      pc: "",
      activeTab: null,
      formsRefs: null,
      current_visit: 1,
      visit_id: 1,
      content: { pc: "" },
      errors: [],
      emptyRequired: {},
      patientCodeSettings: null,
      pcLimitsError: false,
      files: [],
      editMode: false,
      user: {},
      author: null,
      openConfirmDeleteModal: false,
      openSendModal: false,
      form: null,
    };
  }
  setActiveForm = (node) => {
    if (node) {
      this.setState((state) => ({
        ...state,
        formsRefs: { ...state.formsRefs, [state.current_visit]: node },
      }));
    }
  };
  handlePC(e) {
    this.setState((state) => {
      if (
        pcValidate(
          e.target.value,
          this.state.patientCodeSettings.type,
          this.state.patientCodeSettings.isLimit ? 4 : 8
        )
      ) {
        if (
          this.state.patientCodeSettings.isLimit &&
          this.state.patientCodeSettings.type === "number" &&
          this.state.patientCodeSettings.limitFrom > 0 &&
          e.target.value.length > 0
        ) {
          state.pc = String(Number(e.target.value));
        } else {
          state.pc = e.target.value.toUpperCase();
        }
      }
      return state;
    });
  }
  handleChange(e) {
    if (e.target.name) {
      this.setState((state) => {
        let emptyRequired = state.emptyRequired;
        let value = e.target.value;
        if (e.target.type === "checkbox") {
          if (e.target.checked) {
            if (
              this.state.values[e.target.name] &&
              this.state.values[e.target.name].length > 0
            ) {
              value = [...this.state.values[e.target.name], e.target.id];
            } else {
              value = [e.target.id];
            }
          } else {
            if (
              this.state.values[e.target.name] &&
              this.state.values[e.target.name].length > 0
            ) {
              value = this.state.values[e.target.name].filter(
                (el) => el !== e.target.id
              );
            } else {
              value = [];
            }
          }
        } else if (e.target.type === "text") {
          value = e.target.value;
        } else if (e.target.type === "number") {
          value = String(e.target.value)
            ? Number(String(e.target.value))
            : undefined;
          if (value) {
            emptyRequired[e.target.name] && delete emptyRequired[e.target.name];
          }
        } else if (e.target.type === "select-one") {
          value = e.target.value;
        } else if (e.target.type === "radio") {
          value = e.target.id;
        }

        return {
          ...state,
          emptyRequired,
          values: {
            ...state.values,
            [e.target.name]: value,
          },
        };
      });
    }
  }
  handleClean(element) {
    if (element.name) {
      this.setState((state) => {
        let emptyRequired = state.emptyRequired;
        let value = element.value;
        if (element.type === "checkbox") {
          if (
            state.values[element.name] &&
            state.values[element.name].length > 0
          ) {
            value = state.values[element.name].filter(
              (el) => el !== element.id
            );
          } else {
            value = [];
          }
        } else if (element.type === "text") {
          value = element.value;
        } else if (element === "number") {
          value = String(element.value)
            ? Number(String(element.value))
            : undefined;
          if (value) {
            emptyRequired[element.name] && delete emptyRequired[element.name];
          }
        } else if (element.type === "select-one") {
          value = element.value;
        } else if (element.type === "radio") {
          value = element.id;
        }

        return {
          ...state,
          emptyRequired,
          values: {
            ...state.values,
            [element.name]: value,
          },
        };
      });
    }
  }
  handleResetQuestValue = (id) => {
    this.setState((state) => ({
      ...state,
      values: {
        ...state.values,
        [id]: undefined,
      },
    }));
  };

  checkPcLimitsError = () => {
    const { isLimit, limitFrom, limitTo } = this.state?.patientCodeSettings;
    if (
      isLimit &&
      (Number(this.state.pc) < limitFrom || Number(this.state.pc) > limitTo)
    ) {
      return true;
    }
    return false;
  };

  getEditVisitStatusPermission = () => {
    if (this.state.user.role === 5) {
      return true;
    }
    if (this.state.user.role === 4) {
      const admins = this.state?.builder?.admins.split(",");
      return !!admins?.includes(String(this.state.user.id));
    }
    if (this.state.user.role === 2) {
      const moderators = this.state?.builder?.moderators.split(",");
      return !!moderators?.includes(String(this.state.user.id));
    }
    return false;
  };

  // Валидация незаполненных полей
  checkOnEmpty = (template, data) => {
    let hasNotEmpty = true;

    template.forEach((elem) => {
      let isEmpty = false;

      const condition = checkCondition(
        this.state.condition[elem.id].conditions,
        this.state.values,
        this.state.condition,
        elem.id,
        elem,
        this.state.allItemsId
      );
      const { state } = this;
      if (condition) {
        if (elem.type !== "chapter") {
          if (elem.options.required) {
            let needUpdate = false;

            const questionType = elem.type;

            if (elem.options.data) {
              let value = data[elem.id];
              if (value) {
                if (questionType === "checkbox" && Array.isArray(value)) {
                  if (
                    value.every((obj) => {
                      const key = Object.keys(obj)[0];
                      return !obj[key];
                    })
                  ) {
                    isEmpty = true;
                  }
                }
                if (questionType === "number" || questionType === "date") {
                  if (questionType === "number") {
                    value = Number(value.replace(",", "."));
                  }
                  if (questionType === "date") {
                    if (data[elem.id].match(/\d{2}\.\d{2}\.\d{4}/g)) {
                      const tmp = value.split(".");
                      value = tmp[2] + "-" + tmp[1] + "-" + tmp[0];
                    } else {
                      isEmpty = true;
                    }

                    if (!isEmpty) value = new Date(value).getTime();
                  }

                  if (!isEmpty) {
                    if (questionType === "number") {
                      const commasLimit = elem.options.data.commasLimit;

                      const decimalPointsCount = getDecimalPointsCount(value);
                      if (
                        commasLimit !== undefined &&
                        commasLimit < decimalPointsCount
                      ) {
                        isEmpty = true;
                      }

                      if (elem.options.numberCondition) {
                        const questionId =
                          elem.options.numberCondition.questionId;
                        const condition =
                          elem.options.numberCondition.condition;
                        const conditionValue = Number(
                          this.state.values[questionId]
                        );
                        const itemValue = Number(this.state.values[elem.id]);

                        if (
                          condition === "more" &&
                          itemValue <= conditionValue
                        ) {
                          isEmpty = true;
                        }
                        if (
                          condition === "less" &&
                          itemValue >= conditionValue
                        ) {
                          isEmpty = true;
                        }
                        if (
                          condition === "equal" &&
                          itemValue !== conditionValue
                        ) {
                          isEmpty = true;
                        }
                        if (
                          condition === "less_or_equal" &&
                          itemValue > conditionValue
                        ) {
                          isEmpty = true;
                        }
                        if (
                          condition === "more_or_equal" &&
                          itemValue < conditionValue
                        ) {
                          isEmpty = true;
                        }
                      }
                    }

                    if (questionType === "date") {
                      if (elem.options.numberCondition) {
                        const questionId =
                          elem.options.numberCondition.questionId;
                        const condition =
                          elem.options.numberCondition.condition;

                        const conditionValue = moment(
                          this.state.content[questionId],
                          "DD.MM.YYYY"
                        ).valueOf();

                        const itemValue = moment(
                          this.state.content[elem.id],
                          "DD.MM.YYYY"
                        ).valueOf();

                        if (
                          condition === "more" &&
                          itemValue <= conditionValue
                        ) {
                          isEmpty = true;
                        }
                        if (
                          condition === "less" &&
                          itemValue >= conditionValue
                        ) {
                          isEmpty = true;
                        }
                        if (
                          condition === "equal" &&
                          itemValue !== conditionValue
                        ) {
                          isEmpty = true;
                        }
                        if (
                          condition === "less_or_equal" &&
                          itemValue > conditionValue
                        ) {
                          isEmpty = true;
                        }
                        if (
                          condition === "more_or_equal" &&
                          itemValue < conditionValue
                        ) {
                          isEmpty = true;
                        }
                      }
                    }

                    if (
                      elem.options.data.limitFrom &&
                      elem.options.data.isLimit
                    ) {
                      let limitFrom = elem.options.data.limitFrom;
                      if (questionType === "number")
                        limitFrom = parseInt(limitFrom);
                      if (questionType === "date")
                        limitFrom = new Date(limitFrom).getTime();

                      if (limitFrom > value) {
                        isEmpty = true;
                      }
                    }
                    if (
                      elem.options.data.limitTo &&
                      elem.options.data.isLimit
                    ) {
                      let limitTo = elem.options.data.limitTo;
                      if (questionType === "number")
                        limitTo = parseInt(limitTo);
                      if (questionType === "date")
                        limitTo =
                          new Date(limitTo).getTime() + 1000 * 60 * 60 * 24;
                      if (limitTo < value) {
                        isEmpty = true;
                      }
                    }
                  }
                } else if (questionType === "table") {
                  let hasEmptyRows = false;
                  elem.options.data.rows.forEach((row, i) => {
                    let emptyRow = true;
                    row.cells.forEach((cell, i) => {
                      if (cell.type !== "title") {
                        value.forEach((val) => {
                          const key = Object.keys(val)[0];

                          if (cell.type === "radio") {
                            if (key === row.id) {
                              if (val[key]) {
                                emptyRow = false;
                              }
                            }
                            /*  else{
                              console.log("key: " + key + " row.id: " + row.id);
                            } */
                          }

                          if (cell.type === "checkbox") {
                            if (key.indexOf(cell.id) > -1) {
                              if (val[key]) {
                                emptyRow = false;
                              }
                            }
                          }

                          if (cell.type === "text" || cell.type === "number") {
                            if (key === cell.id) {
                              if (val[key] !== "") {
                                emptyRow = false;
                              }
                            }
                          }
                        });
                      }
                    });

                    hasEmptyRows = emptyRow ? true : hasEmptyRows;
                  });
                  isEmpty = isEmpty || hasEmptyRows;
                }
              }
            }

            if (!isEmpty) {
              if (!data[elem.id] || data[elem.id] === "Выберите") {
                isEmpty = true;
              } else if (state.emptyRequired[elem.id]) {
                delete state.emptyRequired[elem.id];
                needUpdate = true;
              }
            }

            hasNotEmpty = hasNotEmpty && !isEmpty;

            if (isEmpty) {
              state.emptyRequired[elem.id] = true;
              needUpdate = true;
            }

            if (needUpdate) {
              this.setState(state);
            }
          }
        } else {
          hasNotEmpty =
            this.checkOnEmpty(elem.options.data.items, data) && hasNotEmpty;
        }
      } else {
        if (state.emptyRequired[elem.id]) {
          delete state.emptyRequired[elem.id];
          this.setState(state);
        }
      }
    });

    return hasNotEmpty;
  };

  handleChangeVisitStatus(visitNumber, option) {
    customFetch(global.apiUrl + "/forms/changestatus", {
      method: "POST",
      body: JSON.stringify({
        data: [
          {
            form_id: window.location.href.split("?id=")[1],
            visit_id: visitNumber,
            status: option.value,
            research: this.state.builder.id,
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          return store.dispatch({
            type: "PUSH_NOTIFICATION",
            payload: {
              type: "error",
              title: "",
              text: res.error,
            },
          });
        }
        this.setState((state) => ({
          content: {
            ...state.content,
            visits: { ...state.content.visits, [visitNumber]: option.value },
          },
        }));
        store.dispatch({
          type: "PUSH_NOTIFICATION",
          payload: {
            type: "success",
            title: "",
            text: "Статус успешно изменён",
          },
        });
      });
  }

  handleSubmit(e) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var globalAdd = new Object();
    this.state.saveForm = true;
    var id = 0;
    /*  if(e.target[id].required && (e.target[id].value == "")){
       console.log();

       let errors = {};
       result.error.forEach((error) => {
         errors[error.id] = error.msg;
       });
       this.setState({ ...state, errors });
     } */

    while (e.target[id]) {
      ////console.log(e.target[id].type+": "+e.target[id].checked+": "+e.target[id].name+": "+e.target[id].id);
      if (e.target[id].id.includes("||")) {
        var papa = e.target[id].id.split("||");
        papa = papa[1];
        var self = e.target[id].id.split("||");
        self = self[0];
        if (!globalAdd[papa]) {
          globalAdd[papa] = new Array();
        }
        if (e.target[id].type == "radio") {
          if (e.target[id].checked == true) {
            var object = {};
            object[e.target[id].name] = self;
            globalAdd[papa].push(object);
          } else if (
            !e.target[id].checked &&
            !globalAdd[papa].find((obj) => !!obj[e.target[id].name])
          ) {
            var object = {};
            object[e.target[id].name] = "";
            globalAdd[papa].push(object);
          }
        }
        if (e.target[id].type == "text") {
          globalAdd[e.target[id].id.split("||")[1]].push({
            [e.target[id].id.split("||")[0]]: e.target[id].value.replace(
              /\\/g,
              "\\\\"
            ),
          });
        }
        if (e.target[id].type == "time") {
          globalAdd[e.target[id].id.split("||")[1]].push({
            [e.target[id].id.split("||")[0]]: e.target[id].value,
          });
        }
        if (e.target[id].type == "text" && !e.target[id].id.includes("||")) {
          globalAdd[e.target[id].id] = e.target[id].value;
        }
        if (e.target[id].type == "textarea") {
          //console.log("textare");
          var object = {};
          object[self] = e.target[id].value;
          globalAdd[papa].push(object);
        }
        if (e.target[id].type == "number") {
          var object = {};
          object[self] = e.target[id].value;
          globalAdd[papa].push(object);
        }
        if (e.target[id].type == "checkbox") {
          var objToPush = {};
          objToPush[e.target[id].id] = e.target[id].checked ? "true" : "";
          globalAdd[papa].push(objToPush);
        }
      } else {
        if (
          e.target[id].type == "button" &&
          e.target[id].value.includes("||")
        ) {
          if (e.target[id].className.includes("btn-primary")) {
            globalAdd[e.target[id].value.split("||")[1]] =
              e.target[id].value.split("||")[0];
          } else if (
            !e.target[id].className.includes("btn-primary") &&
            globalAdd[e.target[id].value.split("||")[1]] === undefined
          ) {
            globalAdd[e.target[id].value.split("||")[1]] = "";
          }
        }

        if (e.target[id].type == "radio") {
          if (e.target[id].checked) {
            globalAdd[e.target[id].name] = e.target[id].id;
          } else if (
            !e.target[id].checked &&
            globalAdd[e.target[id].name] === undefined
          ) {
            globalAdd[e.target[id].name] = "";
          }
        }

        if (e.target[id].type == "textarea") {
          globalAdd[e.target[id].name] = e.target[id].value.replace(
            /\\/g,
            "\\\\"
          );
        }

        if (e.target[id].type == "time") {
          globalAdd[e.target[id].name] = e.target[id].value;
        }

        if (e.target[id].type == "text" && !e.target[id].id.includes("||")) {
          if (e.target[id].value != "Выберите дату") {
            globalAdd[e.target[id].id] = e.target[id].value.replace(
              /\\/g,
              "\\\\"
            );
          } else {
            globalAdd[e.target[id].id] = "";
          }
        }

        if (e.target[id].type == "text" && !e.target[id].id.split("||")[1]) {
          if (e.target[id].value != "Выберите дату") {
            globalAdd[e.target[id].id] = e.target[id].value.replace(
              /\\/g,
              "\\\\"
            );
          } else {
            globalAdd[e.target[id].id] = "";
          }
        }
        if (e.target[id].type == "select-one") {
          globalAdd[e.target[id].name] = e.target[id].value;
        }
        if (e.target[id].type == "number") {
          globalAdd[e.target[id].name] = e.target[id].value;
        }
        if (e.target[id].type == "checkbox") {
          if (!globalAdd[e.target[id].name]) {
            globalAdd[e.target[id].name] = new Array();
          }
          var objToPush = {};
          objToPush[e.target[id].id] = e.target[id].checked ? "true" : "";
          globalAdd[e.target[id].name].push(objToPush);
        }
      }
      id++;
    }

    // globalAdd = clearEmpties(globalAdd);
    /*  console.log(globalAdd); */
    //console.log(globalAdd);
    globalAdd.author = JSON.parse(sessionStorage.getItem("token")).insecureid;
    globalAdd.id = this.state.info.id;

    if (window.location.href.split("?id=")[1].includes("-")) {
      globalAdd.fid = window.location.href.split("?id=")[1];
    }
    globalAdd.pc = this.state.pc;
    globalAdd.ts = Math.floor(Date.now() / 1000);
    globalAdd.visit_id = e.target.id;

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(globalAdd),
      redirect: "follow",
    };

    const visitTemplate =
      this.state.form.visits[
        this.state.current_visit > 0
          ? this.state.current_visit - 1
          : this.state.current_visit
      ].items;
    let hasNotEmpty = this.checkOnEmpty(visitTemplate, globalAdd);
    const hasPcLimitsError = this.checkPcLimitsError();

    if (!this.state.pc && this.state.patientCodeSettings.patientCode) {
      const { state } = this;
      state.emptyRequired["pc"] = true;
      hasNotEmpty = false;
      this.setState(state);
    } else {
      const { state } = this;
      delete state.emptyRequired["pc"];
      if (hasPcLimitsError) {
        state.pcLimitsError = true;
        this.setState(state);
        this.state.saveForm = false;
        return store.dispatch({
          type: "PUSH_NOTIFICATION",
          payload: {
            type: "warning",
            text: "Неверно указан код пациента",
          },
        });
      } else {
        state.pcLimitsError = false;
      }

      this.setState(state);
    }

    let url = "/form/note/";

    if (e.typeEvent === "send" && hasNotEmpty) {
      url = "/form/save/";
    }

    if (e.typeEvent === "update" && hasNotEmpty && !hasPcLimitsError) {
      url = "/form/save/";
    }

    if (e.typeEvent === "silent-save") {
      url = "/form/saveSilent/";
    }

    let retryCount = 0;

    const saveForm = () => {
      customFetch(global.apiUrl + url, requestOptions)
        .then((response) => {
          if (e.typeEvent === "save") {
            const { state } = this;
            if (!state.content.visits) {
              state.content.visits = {};
            }
            state.content.visits[state.current_visit] = 1;

            this.setState(state);
          }

          return response.json();
        })
        .then(
          (result) => {
            if (result.msg == "accepted") {
              const { state } = this;
              if (result.error?.length > 0) {
                let errors = {};
                result.error.forEach((error) => {
                  errors[error.id] = error.msg;
                });
                if (Object.keys(errors).length > 0) {
                  this.setState({ ...state, errors });
                }
                store.dispatch({
                  type: "PUSH_NOTIFICATION",
                  payload: {
                    type: "warning",
                    title: "",
                    text: "Не заполнены обязательные поля. Анкета сохранена в черновики.",
                  },
                });

                if (!state.content.visits) {
                  state.content.visits = {};
                }
                state.content.visits[state.current_visit] = 1;
                this.setState(state);

                if (
                  result.id != "" &&
                  result.id != "undefined" &&
                  typeof result.id != "undefined"
                ) {
                  setTimeout(
                    () =>
                      this.context.addEvent({
                        type: "redirect",
                        to:
                          "/form/?id=" +
                          result.id +
                          "&research_id=" +
                          this.state.info.id,
                      }),
                    2000
                  );
                }
              } else {
                if (e.typeEvent === "silent-save") {
                  store.dispatch({
                    type: "PUSH_NOTIFICATION",
                    payload: {
                      type: "success",
                      title: "",
                      text: "Анкета успешно сохранена",
                    },
                  });
                }
                if (e.typeEvent === "send") {
                  if (hasNotEmpty) {
                    store.dispatch({
                      type: "PUSH_NOTIFICATION",
                      payload: {
                        type: "success",
                        title: "",
                        text: "Анкета успешно сохранена",
                      },
                    });

                    if (!state.content.visits) {
                      state.content.visits = {};
                    }
                    state.content.visits[state.current_visit] =
                      this.state.info.moderate === "true" ? 3 : 2;
                    this.setState(state);

                    if (
                      result.id != "" &&
                      result.id != "undefined" &&
                      typeof result.id != "undefined"
                    ) {
                      setTimeout(
                        () =>
                          this.context.addEvent({
                            type: "redirect",
                            to:
                              "/form/?id=" +
                              result.id +
                              "&research_id=" +
                              this.state.info.id,
                          }),
                        1000
                      );
                    }
                  } else {
                    if (
                      result.id != "" &&
                      result.id != "undefined" &&
                      typeof result.id != "undefined"
                    ) {
                      setTimeout(
                        () =>
                          this.context.addEvent({
                            type: "redirect",
                            to:
                              "/form/?id=" +
                              result.id +
                              "&research_id=" +
                              this.state.info.id,
                          }),
                        1000
                      );
                    }
                    store.dispatch({
                      type: "PUSH_NOTIFICATION",
                      payload: {
                        type: "warning",
                        /* ttl: 3000, */
                        text:
                          Object.keys(this.state.emptyRequired).length +
                          getNoun(
                            Object.keys(this.state.emptyRequired).length,
                            " поле имеет",
                            " поля имеют",
                            " полей имеют"
                          ) +
                          " ошибки. Анкета сохранена в черновики.",
                      },
                    });
                  }
                }
                if (e.typeEvent === "update") {
                  store.dispatch({
                    type: "PUSH_NOTIFICATION",
                    payload: {
                      type: "success",
                      title: "",
                      text: "Анкета успешно обновлена",
                    },
                  });
                }
              }

              if (e.typeEvent === "save") {
                store.dispatch({
                  type: "PUSH_NOTIFICATION",
                  payload: {
                    type: "success",
                    title: "",
                    text: "Анкета сохранена в черновике",
                  },
                });
                if (
                  result.id != "" &&
                  result.id != "undefined" &&
                  typeof result.id != "undefined"
                ) {
                  setTimeout(
                    () =>
                      this.context.addEvent({
                        type: "redirect",
                        to:
                          "/form/?id=" +
                          result.id +
                          "&research_id=" +
                          this.state.info.id,
                      }),
                    1000
                  );
                }
              }
            } else if (result.msg === "partially") {
              let errors = {};
              result.error.forEach((error) => {
                errors[error.id] = error.msg;
              });

              if (Object.keys(errors).length > 0) {
                const { state } = this;
                this.setState({ ...state, errors });
                store.dispatch({
                  type: "PUSH_NOTIFICATION",
                  payload: {
                    type: "warning",
                    /* ttl: 3000, */
                    text:
                      Object.keys(errors).length +
                      getNoun(
                        Object.keys(errors).length,
                        " поле имеет",
                        " поля имеют",
                        " полей имеют"
                      ) +
                      " ошибки. Анкета сохранена в черновики.",
                  },
                });
              } else {
                store.dispatch({
                  type: "PUSH_NOTIFICATION",
                  payload: {
                    type: "success",
                    text: "Визит успешно принят.",
                  },
                });
                this.componentDidMount();
              }
              if (
                result.id != "" &&
                result.id != "undefined" &&
                typeof result.id != "undefined"
              ) {
                setTimeout(
                  () =>
                    this.context.addEvent({
                      type: "redirect",
                      to:
                        "/form/?id=" +
                        result.id +
                        "&research_id=" +
                        this.state.info.id,
                    }),
                  2000
                );
              }
            } else if (result.msg === "updated") {
              store.dispatch({
                type: "PUSH_NOTIFICATION",
                payload: {
                  type: "success",
                  text: "Анкета обновлена.",
                },
              });
              this.setState({ editMode: false });
            } else {
              store.dispatch({
                type: "PUSH_NOTIFICATION",
                payload: {
                  type: "error",
                  title: "",
                  text: "Произошла ошибка. Код: " + result.msg,
                },
              });
              if (retryCount === 0) {
                url = "/form/note/";
                e.typeEvent = "save";
                saveForm();
                retryCount++;
              }
            }
            this.state.saveForm = false;
          },
          (error) => {
            if (error == "partially") {
              this.setState({
                error,
              });
              store.dispatch({
                type: "PUSH_NOTIFICATION",
                payload: {
                  type: "error",
                  title: "",
                  text: "Произошла ошибка. Код: " + error.msg,
                },
              });
            }
            this.state.saveForm = false;
          }
        );
    };
    saveForm();
  }
  handleEnableEditMode = () => {
    this.setState({ editMode: true });
  };
  handleDelete(e) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var globalAdd = new Object();

    globalAdd.id = window.location.href.split("?id=")[1];

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        form: globalAdd,
        // token: sessionStorage.getItem("token"),
      }),
      redirect: "follow",
    };

    customFetch(global.apiUrl + "/form/delete/", requestOptions)
      .then((response) => response.json())
      .then(
        (result) => {
          if (result.status === "auth declined") {
            return store.dispatch({
              type: "PUSH_NOTIFICATION",
              payload: {
                type: "error",
                title: "",
                text: "Для удаления анкеты обратитесь к администратору",
              },
            });
          }
          store.dispatch({
            type: "PUSH_NOTIFICATION",
            payload: {
              type: "success",
              title: "",
              text: "Анкета удалена",
            },
          });
          setTimeout(
            () =>
              this.context.addEvent({
                type: "redirect",
                to: "/forms/",
              }),
            2000
          );
        },
        (error) => {
          store.dispatch({
            type: "PUSH_NOTIFICATION",
            payload: {
              type: "error",
              title: "",
              text: "Произошла ошибка. Повторите позднее.",
            },
          });
        }
      );
  }
  parseTable(items, sub, disabled) {
    var dv = {};
    try {
      dv = JSON.parse(this.state.content[items.id]);
      dv.forEach((item) => {
        dv[Object.keys(item)[0].split("||")[0]] = Object.values(item)[0];
      });
    } catch {
      dv = {};
      if (typeof this.state.content[items.id] == "object") {
        dv = this.state.content[items.id];
        dv.forEach((item) => {
          dv[Object.keys(item)[0].split("||")[0]] = Object.values(item)[0];
        });
      }
    }

    return (
      <ParsedTable
        id={items.id}
        key={items.id}
        counterTab={counterTab}
        sub={sub}
        files={items.options.files}
        title={items.options.title}
        required={items.options.required}
        note={items.options.note}
        headers={items.options.data.headers}
        rows={items.options.data.rows}
        dynamic={items.options.dynamic}
        tableId={items.id}
        dv={dv}
        disabled={disabled}
        error={this.state.errors[items.id]}
        isEmpty={this.state.emptyRequired[items.id]}
      />
    );
  }
  parseDate(items, sub, disabled) {
    const handleChange = (date) => {
      this.setState((prev) => ({
        ...prev,
        content: {
          ...prev.content,
          [items.id]: date ? date.toLocaleDateString() : "",
        },
      }));
    };

    return (
      <ParsedDate
        items={items}
        sub={sub}
        counterTab={counterTab}
        disabled={disabled}
        value={this.state.content[items.id]}
        onChange={handleChange}
        condititonQuestionValue={
          this.state.content[items.options?.numberCondition?.questionId]
        }
        errors={this.state.errors[items.id]}
        conditionLabel={this.getConditionLabel(items)}
        isEmpty={this.state.emptyRequired[items.id]}
      />
    );
  }
  parseTime(items, sub, disabled) {
    return (
      <div className={"answer-wrapper"} key={items.id}>
        <div className="counterTab">
          <span className="form-counter">
            {counterTab}
            {sub ? "." + sub : ""}
          </span>
          {items.options.title}
          {items.options.required ? (
            <span style={{ color: "red" }}>*</span>
          ) : (
            ""
          )}
        </div>
        {items.options.files && items.options.files.length > 0 && (
          <div className="d-flex flex-column mb-2" style={{ fontSize: "13px" }}>
            Документы для скачивания
            {items.options.files.map((f) => (
              <a
                key={f.source}
                target="_blank"
                download
                href={`${global.apiUrl}/download?source=${f.source}&target=${f.target}`}
                rel="noreferrer"
                style={{ color: "#019467" }}
              >
                {f.target}
              </a>
            ))}
          </div>
        )}
        {items.options.note && (
          <Alert variant="danger">{parse(items.options.note)}</Alert>
        )}
        <Form.Control
          required={items.options.required ? true : false}
          name={items.id}
          disabled={disabled}
          id={items.id}
          type="time"
          defaultValue={this.state.content[items.id]}
          className="mh-constructor_numberInput"
        />
        {this.state.errors[items.id] || this.state.emptyRequired[items.id] ? (
          <div
            className={classes["mh-formValidationMessage"]}
            style={{ marginTop: "10px" }}
          >
            {this.state.errors[items.id]
              ? this.state.errors[items.id]
              : this.state.emptyRequired[items.id]
              ? "Вопрос обязателен к заполнению."
              : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  parseList(items, sub, disabled) {
    var result = [
      <option value={undefined} key={"-1"}>
        Выберите
      </option>,
    ];
    items.options.data.forEach((item) => {
      result.push(
        <option value={item.id} key={item.id}>
          {item.value}
        </option>
      );
    });
    var cClass;
    if (
      this.state.condition[items.id] != null &&
      this.state.condition[items.id]
    ) {
      cClass = "show"; //this.state.condition[items.id].show ? "show" : "hide";
    }

    return (
      <ButtonToolbar
        className={cClass + " parseList answer-wrapper"}
        key={items.id}
      >
        <div className="counterTab">
          <span className="form-counter">
            {counterTab}
            {sub ? "." + sub : ""}
          </span>{" "}
          {items.options.title}
          {items.options.required ? (
            <span style={{ color: "red" }}>*</span>
          ) : (
            ""
          )}
        </div>
        {items.options.files && items.options.files.length > 0 && (
          <div className="d-flex flex-column mb-2" style={{ fontSize: "13px" }}>
            Документы для скачивания
            {items.options.files.map((f) => (
              <a
                key={f.source}
                target="_blank"
                download
                href={`${global.apiUrl}/download?source=${f.source}&target=${f.target}`}
                rel="noreferrer"
                style={{ color: "#019467" }}
              >
                {f.target}
              </a>
            ))}
          </div>
        )}
        {items.options.note && (
          <Alert variant="danger">{parse(items.options.note)}</Alert>
        )}
        <Container fluid className="p-0">
          <Row>
            <Col key={items.id}>
              <Form.Select
                name={items.id}
                id={items.id}
                defaultValue={this.state.content[items.id]}
                disabled={disabled}
              >
                {result}
              </Form.Select>
            </Col>
          </Row>
          <Row>
            {this.state.errors[items.id] ||
            this.state.emptyRequired[items.id] ? (
              <div className={classes["mh-formValidationMessage"]}>
                {this.state.errors[items.id]
                  ? this.state.errors[items.id]
                  : this.state.emptyRequired[items.id]
                  ? "Вопрос обязателен к заполнению."
                  : ""}
              </div>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </ButtonToolbar>
    );
  }
  parseRadio(items, sub, disabled) {
    var result = [];
    items.options.data.items.forEach((item) => {
      result.push(
        <div className="answer" key={item.id}>
          <input
            type="radio"
            id={item.id}
            className={
              items.options.data.layout == "row"
                ? "rowed parseRadio"
                : "vertical parseRadio"
            }
            disabled={disabled}
            defaultChecked={
              this.state.content[items.id] &&
              this.state.content[items.id] === item.id
            }
            name={items.id}
          />
          <label htmlFor={item.id}>{item.text}</label>
        </div>
      );
    });
    var cClass;
    if (
      this.state.condition[items.id] != null &&
      this.state.condition[items.id]
    ) {
      cClass = "show"; //this.state.condition[items.id].show ? "show" : "hide";
    }

    return (
      <div className={"answer-wrapper"} key={items.id}>
        <div className="counterTab">
          <span className="form-counter">
            {counterTab}
            {sub ? "." + sub : ""}
          </span>{" "}
          {items.options.title}
          {items.options.required ? (
            <span style={{ color: "red" }}>*</span>
          ) : (
            ""
          )}
        </div>
        {items.options.files && items.options.files.length > 0 && (
          <div className="d-flex flex-column mb-2" style={{ fontSize: "13px" }}>
            Документы для скачивания
            {items.options.files.map((f) => (
              <a
                key={f.source}
                target="_blank"
                download
                href={`${global.apiUrl}/download?source=${f.source}&target=${f.target}`}
                rel="noreferrer"
                style={{ color: "#019467" }}
              >
                {f.target}
              </a>
            ))}
          </div>
        )}
        {items.options.note && (
          <Alert variant="danger">{parse(items.options.note)}</Alert>
        )}
        <div className="answer-container">{result}</div>
        {this.state.errors[items.id] || this.state.emptyRequired[items.id] ? (
          <div className={classes["mh-formValidationMessage"]}>
            {this.state.errors[items.id]
              ? this.state.errors[items.id]
              : this.state.emptyRequired[items.id]
              ? "Вопрос обязателен к заполнению."
              : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  parseScale(items, sub, disabled) {
    var result = [];

    items.options.data.forEach((item) => {
      result.push(
        <Button
          key={item.id}
          variant={
            this.state.toolb[items.id] == item.id ||
            (this.state.content[items.id] == item.id &&
              !this.state.toolb[items.id])
              ? "primary"
              : "secondary"
          }
          id={item.id}
          value={item.id + "||" + items.id}
          disabled={disabled}
          onClick={this.parseChangeScale.bind(this)}
        >
          {item.value}
        </Button>
      );
    });
    var cClass;
    if (
      this.state.condition[items.id] != null &&
      this.state.condition[items.id]
    ) {
      cClass = "show"; //this.state.condition[items.id].show ? "show" : "hide";
    }

    return (
      <ButtonToolbar
        className={cClass + " parseScale answer-wrapper"}
        key={items.id}
      >
        <div className="counterTab">
          <span className="form-counter">
            {counterTab}
            {sub ? "." + sub : ""}
          </span>{" "}
          {items.options.title}
          {items.options.required ? (
            <span style={{ color: "red" }}>*</span>
          ) : (
            ""
          )}
        </div>
        {items.options.files && items.options.files.length > 0 && (
          <div className="d-flex flex-column mb-2" style={{ fontSize: "13px" }}>
            Документы для скачивания
            {items.options.files.map((f) => (
              <a
                key={f.source}
                target="_blank"
                download
                href={`${global.apiUrl}/download?source=${f.source}&target=${f.target}`}
                rel="noreferrer"
                style={{ color: "#019467" }}
              >
                {f.target}
              </a>
            ))}
          </div>
        )}
        {items.options.note && (
          <Alert variant="danger">{parse(items.options.note)}</Alert>
        )}
        <Container fluid>
          <Row>
            <Col key={items.id}>
              <ButtonGroup className="me-2" name={"scale" + items.id}>
                {result}
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            {this.state.errors[items.id] ||
            this.state.emptyRequired[items.id] ? (
              <div className={classes["mh-formValidationMessage"]}>
                {this.state.errors[items.id]
                  ? this.state.errors[items.id]
                  : this.state.emptyRequired[items.id]
                  ? "Вопрос обязателен к заполнению."
                  : ""}
              </div>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </ButtonToolbar>
    );
  }
  parseChangeScale(e) {
    this.setState((state) => {
      state.toolb[e.target.value.split("||")[1]] = e.target.id;
      state.values[e.target.value.split("||")[1]] = e.target.id;
      return state;
    });
  }
  clearScale(value) {
    this.setState((state) => {
      state.toolb[value.split("||")[1]] = "";
      state.values[value.split("||")[1]] = "";
      return state;
    });
  }
  parseTextarea(items, sub, disabled) {
    var result = [];
    var cClass;
    if (
      this.state.condition[items.id] != null &&
      this.state.condition[items.id]
    ) {
      cClass = "show"; //this.state.condition[items.id].show ? "show" : "hide";
    }

    const onChange = (e) => {
      this.setState((prev) => ({
        ...prev,
        content: {
          ...prev.content,
          [items.id]: textAreaReplacer(e.target.value),
        },
      }));
    };

    return (
      <div className="parseTextarea-wrapper answer-wrapper" key={items.id}>
        <div className="counterTab">
          <span className="form-counter">
            {counterTab}
            {sub ? "." + sub : ""}
          </span>{" "}
          {items.options.title}
          {items.options.required ? (
            <span style={{ color: "red" }}>*</span>
          ) : (
            ""
          )}
        </div>
        {items.options.files && items.options.files.length > 0 && (
          <div className="d-flex flex-column mb-2" style={{ fontSize: "13px" }}>
            Документы для скачивания
            {items.options.files.map((f) => (
              <a
                key={f.source}
                target="_blank"
                download
                href={`${global.apiUrl}/download?source=${f.source}&target=${f.target}`}
                rel="noreferrer"
                style={{ color: "#019467" }}
              >
                {f.target}
              </a>
            ))}
          </div>
        )}
        {items.options.note && (
          <Alert variant="danger">{parse(items.options.note)}</Alert>
        )}
        <textarea
          id={items.id}
          className="parseTextarea w-100"
          name={items.id}
          defaultValue={this.state.content[items.id]}
          // onChange={onChange}
          disabled={disabled}
        />
        {this.state.errors[items.id] || this.state.emptyRequired[items.id] ? (
          <div className={classes["mh-formValidationMessage"]}>
            {this.state.errors[items.id]
              ? this.state.errors[items.id]
              : this.state.emptyRequired[items.id]
              ? "Вопрос обязателен к заполнению."
              : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  getConditionLabel = (items) => {
    const questionId = items.options.numberCondition?.questionId;
    const form = this.state.form;

    if (!items.options.numberCondition) return;

    let conditionItem;
    let visitNum;
    let itemNum;

    form.visits.forEach((visit, visitIndex) => {
      visit.items.forEach((item, itemIndex) => {
        if (item.type === "chapter") {
          item.options.data.items.forEach((chaptItem, childIndex) => {
            if (chaptItem.id === questionId) {
              conditionItem = chaptItem;
              visitNum = visitIndex + 1;
              itemNum = `${itemIndex + 1}.${childIndex + 1}`;
            }
          });
        }
        if (item.id === questionId) {
          conditionItem = item;
          visitNum = visitIndex + 1;
          itemNum = itemIndex + 1;
        }
      });
    });

    if (visitNum && itemNum && conditionItem) {
      const questionString = `Визит ${visitNum}: ${itemNum} "${conditionItem?.options?.title}"`;
      let conditionLabel = "";
      const condition = items.options.numberCondition.condition;

      if (condition === "more") {
        conditionLabel = "больше";
      }
      if (condition === "less") {
        conditionLabel = "меньше";
      }
      if (condition === "equal") {
        conditionLabel = "равно";
      }
      if (condition === "less_or_equal") {
        conditionLabel = "меньше или равно";
      }
      if (condition === "more_or_equal") {
        conditionLabel = "больше или равно";
      }

      return `Значение должно быть ${conditionLabel} чем установлено в "${questionString}"`;
    }
  };

  parseNumber(items, sub, disabled) {
    const questionId = items.options.numberCondition?.questionId;

    return (
      <ParsedNumber
        items={items}
        counterTab={counterTab}
        defaultValue={this.state.content[items.id]}
        disabled={disabled}
        value={this.state.values[items.id]}
        emptyRequired={this.state.emptyRequired[items.id]}
        errors={this.state.errors[items.id]}
        condititonQuestionValue={this.state.values[questionId]}
        conditionLabel={this.getConditionLabel(items)}
        sub={sub}
      />
    );
  }

  parseCheckBox(items, sub, disabled) {
    var cClass = new Object();
    try {
      cClass = JSON.parse(this.state.content[items.id]);

      cClass.forEach((item) => {
        cClass[Object.keys(item)[0]] = Object.values(item)[0];
      });
    } catch (e) {
      if (typeof this.state.content[items.id] == "object") {
        cClass = this.state.content[items.id];

        cClass.forEach((item) => {
          cClass[Object.keys(item)[0]] = Object.values(item)[0];
        });
      }
    }

    var result = [];
    var cClass;

    items.options.data.items.forEach((item) => {
      result.push(
        <div className="answer" key={item.id}>
          <input
            type="checkbox"
            id={item.id}
            className={
              items.options.data.layout == "row"
                ? "rowed parseCheckBox"
                : "vertical parseCheckBox"
            }
            defaultChecked={cClass[item.id] == "true" ? "true" : ""}
            name={items.id}
            disabled={disabled}
          />
          <label htmlFor={item.id}>{item.text}</label>
        </div>
      );
    });

    return (
      <div className={"parseCheckBox answer-wrapper"} key={items.id}>
        <div className="counterTab">
          <span className="form-counter">
            {counterTab}
            {sub ? "." + sub : ""}
          </span>{" "}
          {items.options.title}
          {items.options.required ? (
            <span style={{ color: "red" }}>*</span>
          ) : (
            ""
          )}
        </div>
        {items.options.files && items.options.files.length > 0 && (
          <div className="d-flex flex-column mb-2" style={{ fontSize: "13px" }}>
            Документы для скачивания
            {items.options.files.map((f) => (
              <a
                key={f.source}
                target="_blank"
                download
                href={`${global.apiUrl}/download?source=${f.source}&target=${f.target}`}
                rel="noreferrer"
                style={{ color: "#019467" }}
              >
                {f.target}
              </a>
            ))}
          </div>
        )}
        {items.options.note && (
          <Alert variant="danger">{parse(items.options.note)}</Alert>
        )}
        <div className="parseRadio radio-container">{result}</div>
        {this.state.errors[items.id] || this.state.emptyRequired[items.id] ? (
          <div className={classes["mh-formValidationMessage"]}>
            {this.state.errors[items.id]
              ? this.state.errors[items.id]
              : this.state.emptyRequired[items.id]
              ? "Вопрос обязателен к заполнению."
              : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  buildTheChapters(data, disabled) {
    var counterTabList = 0;
    var result = [];

    data.options.data.items.forEach((item) => {
      const condition = checkCondition(
        this.state.condition[item.id].conditions,
        this.state.values,
        this.state.condition,
        item.id,
        item,
        this.state.allItemsId
      );

      const clearState = (id) =>
        this.setState((prev) => ({
          ...prev,
          content: { ...prev.content, [id]: "" },
        }));

      if (item.type === "checkbox") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseCheckBox(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type === "scale") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseScale(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type === "list") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseList(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type === "radio") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseRadio(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type === "date") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseDate(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "textarea" && item.options?.fieldType === "time") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseTime(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type === "textarea" && item.options?.fieldType !== "time") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseTextarea(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type === "number") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseNumber(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type === "table") {
        if (condition) counterTabList++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseTable(item, counterTabList, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (
        !condition &&
        this.state.values[item.id] &&
        this.state.values[item.id] !== undefined
      ) {
        this.handleResetQuestValue(item.id);
      }
    });
    var cClass;

    if (
      this.state.condition[data.id] != null &&
      this.state.condition[data.id]
    ) {
      cClass = "show"; //this.state.condition[data.id].show ? "show" : "hide";
    }
    return (
      <div
        id={data.id}
        className={cClass + " chapter answer-wrapper"}
        key={data.id}
      >
        <div className="counterTab">
          <span className="form-counter">{counterTab}</span>{" "}
          {data.options.title}
        </div>
        {data.options.note && (
          <Alert variant="danger">{data.options.note}</Alert>
        )}
        <div className="chapter-elements">
          {result.map((result, index) => (
            <InputsCleaner
              clearState={(id) =>
                this.setState((prev) => ({
                  ...prev,
                  content: { ...prev.content, [id]: "" },
                }))
              }
              clearValues={(el) => this.handleClean(el)}
              clearScale={(el) => this.clearScale(el)}
              key={index}
              isAdmin={this.state.user.role !== 1 && this.state.user.role !== 0}
            >
              {result}
            </InputsCleaner>
          ))}
        </div>
      </div>
    );
  }
  buildTheTabs(data, visitNumber) {
    counterTab = 0;
    var result = [];

    if (this.state.allItemsId.length === 0) {
      let chapterItemsId = [];
      let allItemsId = data.items.map((el) => {
        if (el.type === "chapter") {
          chapterItemsId = [
            ...chapterItemsId,
            el.options.data.items.map((cEl) => cEl.id),
          ];
        }
        return el.id;
      });

      this.setState((state) => ({
        ...state,
        allItemsId: [...allItemsId, chapterItemsId],
      }));
    }

    let disabledTab = true;
    let disabled = false;

    if (this.state.content.visits) {
      // 1 (partially) - частично заполнен, с ошибками
      // 2 (accepted)  - полностью заполнен без ошибок
      // 3 - на проверке
      disabledTab =
        this.state.content.visits[visitNumber] !== 2 &&
        !(visitNumber > 1 && this.state.content.visits[visitNumber - 1] === 2);
      disabled = this.state.content.visits[visitNumber] === 2;
    } else if (this.state.content.source === "clickhouse") {
      disabledTab = false;
      disabled = true;
    } else if (visitNumber === 1) {
      disabledTab = false;
    }

    if (this.state.user.role !== 1) {
      disabledTab = false;
      disabled = false;
    }

    if (
      this.state.user.role === 1 &&
      this.state.content.visits &&
      this.state.content.visits[visitNumber] === 3
    ) {
      disabledTab = true;
      disabled = true;
    }

    if (this.state.editMode) {
      disabled = false;
    }

    data.items.forEach((item) => {
      const condition = checkCondition(
        this.state.condition[item.id].conditions,
        this.state.values,
        this.state.condition,
        item.id,
        item,
        this.state.allItemsId
      );

      const clearState = (id) =>
        this.setState((prev) => ({
          ...prev,
          content: { ...prev.content, [id]: "" },
        }));

      if (item.type === "date") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseDate(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "chapter") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.buildTheChapters(item, disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "checkbox") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseCheckBox(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type === "radio") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseRadio(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "number") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseNumber(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "scale") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseScale(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "list") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseList(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "textarea" && item.options?.fieldType !== "time") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseTextarea(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "textarea" && item.options?.fieldType === "time") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseTime(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (item.type == "table") {
        if (condition) counterTab++;
        result.push(
          <ConditionalQuestionWrapper
            isShowed={condition}
            clearState={clearState}
          >
            {this.parseTable(item, "", disabled)}
          </ConditionalQuestionWrapper>
        );
      }
      if (
        !condition &&
        this.state.values[item.id] &&
        this.state.values[item.id] !== undefined
      ) {
        this.handleResetQuestValue(item.id);
      }
    });
    return (
      <Tab
        eventKey={"visit" + visitNumber}
        title={
          <div className="d-flex align-items-center">
            <span className="me-2" style={{ whiteSpace: "nowrap" }}>
              {"Визит " + visitNumber}
            </span>
            <Select
              options={statusOptions}
              isSearchable={false}
              defaultValue={
                this.state.content.visits?.[visitNumber] === 1
                  ? statusOptions[1]
                  : this.state.content.visits?.[visitNumber] === 2 ||
                    this.state.content.source === "clickhouse"
                  ? statusOptions[0]
                  : undefined
              }
              value={statusOptions.find(
                (x) => x.value === this.state.content.visits?.[visitNumber]
              )}
              onChange={(option) =>
                this.handleChangeVisitStatus(visitNumber, option)
              }
              styles={colorStyles}
              menuPortalTarget={document.body}
              components={
                !this.getEditVisitStatusPermission() || disabledTab
                  ? { DropdownIndicator: () => null }
                  : undefined
              }
              isDisabled={disabledTab || !this.getEditVisitStatusPermission()}
            />
          </div>
        }
        className={"buildTheTabs visit-" + visitNumber}
        key={"visit" + visitNumber}
        disabled={disabledTab}
        mountOnEnter
      >
        <Form
          id={"visit" + visitNumber}
          onSubmit={this.handleSubmit.bind(this)}
          onChange={this.handleChange.bind(this)}
          ref={this.setActiveForm}
          className={disabled ? "view" : "modify"}
        >
          {/* temp */}
          {false &&
            (this.state.content.visits?.[visitNumber] === 1 ||
              this.state.content.visits?.[visitNumber] === 3) && (
              <CommentaryBlock />
            )}
          {result.map((result, index) => (
            <InputsCleaner
              key={index}
              clearState={(id) =>
                this.setState((prev) => ({
                  ...prev,
                  content: { ...prev.content, [id]: "" },
                }))
              }
              clearValues={(el) => this.handleClean(el)}
              clearScale={(el) => this.clearScale(el)}
              isAdmin={this.state.user.role !== 1 && this.state.user.role !== 0}
            >
              {result}
            </InputsCleaner>
          ))}
        </Form>
      </Tab>
    );
  }
  startBuildForm() {
    var builder = this.state.form;
    var idd = 0;
    var tabs = [];
    builder.visits.forEach((visit) => {
      idd++;
      tabs.push([this.buildTheTabs(visit, idd)]);
    });
    return (
      <Tabs
        mountOnEnter
        // unmountOnExit
        className={"visitTabs sticky"}
        defaultActiveKey="visit1"
        onSelect={(k) => {
          window.scrollTo({
            behavior: "smooth",
            top: 0,
            left: 0,
          });
          this.setState({ current_visit: k.replace(/[^0-9]/g, "") });
        }}
      >
        {tabs}
      </Tabs>
    );
  }
  componentDidMount() {
    function getSearchParameters() {
      var prmstr = window.location.search.substr(1);
      return prmstr != null && prmstr != ""
        ? transformToAssocArray(prmstr)
        : {};
    }

    function transformToAssocArray(prmstr) {
      var params = {};
      var prmarr = prmstr.split("&");
      for (var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
      }
      return params;
    }
    var queryParams = getSearchParameters();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        id: queryParams.id,
        research_id: queryParams.research_id,
        user: sessionStorage.getItem("token"),
      }),
      redirect: "follow",
    };
    customFetch(global.apiUrl + "/research/form/load", requestOptions)
      .then((response) => response.json())
      .then(
        (result) => {
          if (
            result.error === "Research is not available now" ||
            result.error === "Добавление анкет завершено" ||
            result.error === "Исследование завершено"
          ) {
            store.dispatch({
              type: "PUSH_NOTIFICATION",
              payload: {
                type: "error",
                title: "",
                text: result.error,
              },
            });
            this.props.navigate("/");
          }

          if (result.error != "limit exceed") {
            this.setState((state) => {
              var conditor = JSON.parse(result.form);
              state.form = conditor;
              state.condition = getConditionsMap(conditor.visits);
              state.author = result.author;
              let values = {};
              conditor.visits.forEach((visit) => {
                visit.items.forEach((item) => {
                  if (item.type === "chapter") {
                  } else {
                    values[item.id] = "";
                  }
                });
              });
              state.info = result;
              state.content = result.content;
              state.pc = result.content.pc ?? "";
              state.patientCodeSettings =
                result.pc && result.pc !== "undefined" && result.pc !== "null"
                  ? JSON.parse(result.pc)
                  : {
                      patientCode: true,
                      placeholder: "",
                      type: "text",
                      isLimit: false,
                      limitFrom: "0",
                      limitTo: "999",
                      note: "",
                    };
              state.files =
                result.files &&
                result.files !== "undefined" &&
                result.files !== "null"
                  ? JSON.parse(result.files)
                  : [];
              let iniValues = {};
              Object.keys(result.content).forEach((key) => {
                if (Array.isArray(result.content[key])) {
                  let values = [];

                  result.content[key].forEach((item) => {
                    if (Object.values(item)[0] !== "") {
                      values.push(...Object.keys(item));
                    }
                  });

                  iniValues = {
                    ...iniValues,
                    [key]: values,
                  };
                } else {
                  iniValues = {
                    ...iniValues,
                    [key]: result.content[key],
                  };
                }
              });
              state.values = iniValues;
              // this.state.current_visit = state.content.visit_id;

              return state;
            });
            this.setState((state) => {
              state.builder = result;
              return state;
            });
            this.setState({
              isLoaded: true,
            });
          } else {
            store.dispatch({
              type: "PUSH_NOTIFICATION",
              payload: {
                type: "error",
                title: "",
                text: "Достигнут лимит количества анкет в данном исследовании",
              },
            });
            setTimeout(
              () =>
                this.context.addEvent({
                  type: "redirect",
                  to: "/forms/",
                }),
              2000
            );
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
          store.dispatch({
            type: "PUSH_NOTIFICATION",
            payload: {
              type: "error",
              title: "",
              text: "Произошла ошибка. Код: " + error.msg,
            },
          });
        }
      );

    getUserInfo(1, (user) => this.setState({ user }));
  }
  /*   getFiles() {

      if (window.location.href.split("?id=")[1] == "81") {
        return (
          <div>
            <ul>
              <li>
                <Link
                  to="/Информированное согласие для пациента_FORTE.docx"
                  target="_blank"
                  download
                >
                  Информированное согласие для пациента_FORTE.docx
                </Link>
              </li>
              <li>
                <Link to="/Форма отчета о СНЯ, СНР.docx" target="_blank" download>
                  Форма отчета о СНЯ, СНР.docx
                </Link>
              </li>
              <li>
                <Link
                  to="/Форма сообщения о беременности.docx"
                  target="_blank"
                  download
                >
                  Форма сообщения о НР,НЯ_.docx
                </Link>
              </li>
            </ul>
          </div>
        );
      } else {
        return "";
      }
    } */

  render() {
    if (
      this.state.user &&
      this.state.user.role === 1 &&
      this.state?.content &&
      this.state.content.author &&
      this.state.content.author !== this.state.user.id
    ) {
      return <Navigate replace to="/" />;
    }

    const isChecking =
      this.state.user.role === 1 &&
      this.state.content.visits &&
      this.state.content.visits[this.state.current_visit] === 3;

    const { isLoaded, error } = this.state;

    let hasSaved = false;
    if (this.state.content.source === "clickhouse") {
      hasSaved = true;
    } else if (this.state.content.visits) {
      if (this.state.content.visits["1"] === 2) {
        hasSaved = true;
      }
    }

    if (error) {
      return (
        <div className="error-message">
          <CreateFormPlaceholder />
        </div>
      );
    } else if (!isLoaded) {
      return (
        <div className="loading-message">
          <CreateFormPlaceholder />
        </div>
      );
    } else {
      return (
        <>
          <Container fluid="xxl" className="p-0">
            <Row className="m-0 " id="form-viewer">
              <Col
                sm={8}
                id="page-content"
                style={{
                  maxWidth: 1000,
                }}
              >
                <div>
                  <div className="d-flex justify-content-between">
                    <h2>
                      {this.state.info ? this.state.info.name : "Загрузка"}
                    </h2>
                    {this.state?.author?.length > 0 &&
                      this.state.author.map((a) => (
                        <div key={a.id} className={classes.params}>
                          <span>
                            Автор:{" "}
                            <NavLink
                              to={`/users/view/?id=${a.id}`}
                              className={classes.download}
                              style={{ fontSize: "13px" }}
                            >
                              {`${
                                a.surname && a.surname !== "null"
                                  ? a.surname
                                  : ""
                              }
                              ${a.name && a.name !== "null" ? a.name : ""}
                              ${
                                a.patronymic && a.patronymic !== "null"
                                  ? a.patronymic
                                  : ""
                              }`.trim()}
                            </NavLink>{" "}
                            <img src={linkIcon} alt="" />
                          </span>
                          <span>Email: {a.email}</span>
                          <span>
                            {moment(this.state.content.ts)
                              .add(3, "hours")
                              .format("lll")}
                          </span>
                        </div>
                      ))}
                  </div>
                  {this.state.patientCodeSettings?.patientCode && (
                    <div className="topInfo">
                      <Form.Group className="mb-3" controlId="patientcode">
                        <div className="counterTab">Код пациента</div>
                        {this.state.patientCodeSettings?.note && (
                          <Alert variant="danger">
                            {this.state.patientCodeSettings?.note}
                          </Alert>
                        )}
                        <Form.Control
                          type={this.state.patientCodeSettings.type}
                          required
                          defaultValue={this.state.pc}
                          placeholder={
                            this.state.patientCodeSettings.placeholder
                          }
                          min={
                            this.state.patientCodeSettings.isLimit
                              ? this.state.patientCodeSettings.limitFrom
                              : undefined
                          }
                          max={
                            this.state.patientCodeSettings.isLimit
                              ? this.state.patientCodeSettings.limitTo
                              : undefined
                          }
                          value={this.state.pc}
                          onChange={(e) => {
                            this.handlePC(e);
                          }}
                          onKeyPress={(event) => {
                            if (!/[а-яА-ЯёЁ0-9\s]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          disabled={hasSaved && !this.state.editMode}
                          isInvalid={
                            this.state.emptyRequired["pc"] ||
                            this.state.pcLimitsError
                          }
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          tooltip
                          style={{
                            position: "static",
                            display:
                              this.state.emptyRequired["pc"] ||
                              this.state.pcLimitsError
                                ? "inline-block"
                                : "none",
                            marginTop: "5px",
                          }}
                        >
                          {this.state.emptyRequired["pc"]
                            ? "Пожалуйста, укажите код пациента."
                            : `Код пациента должен быть в диапазоне от ${this.state.patientCodeSettings.limitFrom} до ${this.state.patientCodeSettings.limitTo}`}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  )}
                  {/*{this.getFiles()}*/}
                  {this.state.builder
                    ? this.startBuildForm()
                    : "Загрузка анкеты"}
                </div>
              </Col>

              {this.state.builder && (
                <AboutForm
                  lastDeadLine={this.state.info.lastDeadLine}
                  data={this.state.builder}
                  formId={window.location.href.split("?id=")[1]}
                  dataId={this.state.content.id}
                  files={this.state.files}
                  /* visitId={this.state.current_visit} */
                  handleSend={() => {
                    this.setState({ openSendModal: true });
                  }}
                  handleSave={() => {
                    if (this.state.formsRefs[this.state.current_visit]) {
                      this.handleSubmit({
                        target: this.state.formsRefs[this.state.current_visit],
                        typeEvent: "save",
                      });
                    }
                  }}
                  handleSilentSave={() => {
                    if (this.state.formsRefs[this.state.current_visit]) {
                      this.handleSubmit({
                        target: this.state.formsRefs[this.state.current_visit],
                        typeEvent: "silent-save",
                      });
                    }
                  }}
                  handleDelete={() => {
                    this.setState({ openConfirmDeleteModal: true });
                  }}
                  handleEnableEditMode={this.handleEnableEditMode}
                  ableToSend={
                    ((this.state.content.visits?.[this.state.current_visit] !==
                      2 &&
                      this.state.saveForm == false) ||
                      this.state.editMode) &&
                    !isChecking
                  }
                  ableToSave={
                    this.state.content.visits?.[this.state.current_visit] !==
                      2 &&
                    this.state.saveForm == false &&
                    !this.state.editMode &&
                    !isChecking
                  }
                  ableToEdit={
                    this.state.content.visits?.[this.state.current_visit] !== 1
                  }
                  ableToDelete={!isChecking}
                />
              )}
            </Row>
          </Container>
          <Modal
            show={this.state.openConfirmDeleteModal}
            onHide={() => this.setState({ openConfirmDeleteModal: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Удалить анкету</Modal.Title>
            </Modal.Header>
            <Modal.Body>Вы действительно хотите удалить анкету?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.setState({ openConfirmDeleteModal: false })}
              >
                Отмена
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  if (this.state.formsRefs[this.state.current_visit]) {
                    this.handleDelete({
                      target: this.state.formsRefs[this.state.current_visit],
                    });
                  }
                  this.setState({ openConfirmDeleteModal: false });
                }}
              >
                Удалить
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.openSendModal}
            onHide={() => this.setState({ openSendModal: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Отправить анкету</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              После нажатия кнопки "Отправить" редактирование данных в Визите
              невозможно. Если Вы уверены в корректности заполнения, подтвердите
              отправку
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.setState({ openSendModal: false })}
                style={{ height: "36px" }}
              >
                Отмена
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (this.state.formsRefs[this.state.current_visit]) {
                    this.handleSubmit({
                      target: this.state.formsRefs[this.state.current_visit],
                      typeEvent: this.state.editMode ? "update" : "send",
                    });
                  }
                  this.setState({ openSendModal: false });
                }}
                style={{
                  borderRadius: "0.25rem",
                  fontSize: "16px",
                  padding: "6px 12px",
                }}
              >
                Отправить
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
}
export default withRouter(ViewForm);
