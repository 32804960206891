import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./styles/fonts.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import store from "./store/store";
import { Provider } from "react-redux";
import { RedirectService } from "./utils/RedirectService";

if (process.env["NODE_ENV"] === "development") {
  //global.apiUrl = "https://mnemohuman.vin.team/api";
  global.apiUrl = "http://localhost:8000";
} else {
  global.apiUrl = "https://new.mnemohuman.ru/api";
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RedirectService>
        <Provider store={store}>
          <App />
        </Provider>
      </RedirectService>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
