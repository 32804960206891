import type { FC } from "react";
import moment from "moment";
import "moment/locale/ru";
import { Alert } from "react-bootstrap";
import parse from "html-react-parser";
import Dates from "../inputs/dates";
import classes from "./AboutForm.module.scss";
import { QuestionDocuments } from "./QuestionDocuments";

moment.locale("ru");

interface IParsedDateProps {
  items: {
    id: string;
    options: {
      title: string;
      note: string;
      required: boolean;
      files?: {
        target: string;
        source: string;
      }[];
      data: {
        isLimit: boolean;
        limitFrom: string | null;
        limitTo: string | null;
        showCalendar: boolean;
      };
      numberCondition?: {
        condition: string;
        id: string;
        questionId: string;
      };
    };
  };
  sub?: string;
  disabled: boolean;
  counterTab: number;
  value: string | undefined | null;
  condititonQuestionValue?: string;
  onChange: (date: Date) => void;
  errors?: string;
  conditionLabel?: string;
  isEmpty?: boolean;
}

export const ParsedDate: FC<IParsedDateProps> = ({
  items,
  sub,
  disabled,
  counterTab,
  value,
  condititonQuestionValue,
  onChange,
  errors,
  conditionLabel,
  isEmpty,
}) => {
  const getLimits = () => {
    const parsedLimits = {
      dateFrom: "",
      dateTo: "",
      dateFromValue: null,
      dateToValue: null,
    };

    if (items.options.data.isLimit) {
      if (items.options.data.limitFrom) {
        const date = new Date(items.options.data.limitFrom);
        parsedLimits.dateFrom = date.toLocaleDateString("ru-RU");
        parsedLimits.dateFromValue = date.getTime();
      }
      if (items.options.data.limitTo) {
        const date = new Date(items.options.data.limitTo);
        parsedLimits.dateTo = date.toLocaleDateString("ru-RU");
        parsedLimits.dateToValue = date.getTime();
      }
    }

    return parsedLimits;
  };

  const { dateFrom, dateTo, dateFromValue, dateToValue } = getLimits();

  const splittedValue =
    value !== undefined &&
    value !== "undefined" &&
    value !== "" &&
    value !== "Выберите дату"
      ? value.split(".")
      : null;

  const parsedValue = splittedValue
    ? new Date(+splittedValue[2], +splittedValue[1] - 1, +splittedValue[0])
    : null;

  const getLimitError = () => {
    if (!items.options.data.isLimit || !parsedValue) return false;
    const timeValue = parsedValue.getTime();
    if (dateFromValue > parsedValue) {
      return true;
    }
    if (dateToValue < timeValue) {
      return true;
    }
    return false;
  };

  const isLimitError = getLimitError();

  const getInvalidNumberCondition = () => {
    if (items.options.numberCondition && parsedValue) {
      const condition = items.options.numberCondition.condition;

      const conditionValue = moment(
        condititonQuestionValue,
        "DD.MM.YYYY"
      ).valueOf();

      const itemValue = moment(parsedValue, "DD.MM.YYYY").valueOf();

      if (condition === "more" && itemValue <= conditionValue) {
        return true;
      }
      if (condition === "less" && itemValue >= conditionValue) {
        return true;
      }
      if (condition === "equal" && itemValue !== conditionValue) {
        return true;
      }
      if (condition === "less_or_equal" && itemValue > conditionValue) {
        return true;
      }
      if (condition === "more_or_equal" && itemValue < conditionValue) {
        return true;
      }
      return false;
    }
  };

  const invalidNumberCondition = getInvalidNumberCondition();

  return (
    <div className={"answer-wrapper"} key={items.id}>
      <div className="counterTab">
        <span className="form-counter">
          {counterTab}
          {sub ? "." + sub : ""}
        </span>
        {items.options.title}
        {items.options.required ? <span style={{ color: "red" }}>*</span> : ""}
      </div>
      {items.options.files && items.options.files.length > 0 && (
        <QuestionDocuments files={items.options.files} />
      )}
      {items.options.note && (
        <Alert variant="danger">{parse(items.options.note)}</Alert>
      )}
      {conditionLabel && (
        <p className="mh-constructor_numberLimit">{conditionLabel}</p>
      )}
      <Dates
        id={items.id}
        // limitFrom={
        //   items.options.data.isLimit && items.options.data.limitFrom
        //     ? items.options.data.limitFrom
        //     : ""
        // }
        // limitTo={
        //   items.options.data.isLimit && items.options.data.limitTo
        //     ? items.options.data.limitTo
        //     : ""
        // }
        value={parsedValue}
        disabled={disabled}
        onDateChange={onChange}
        showCalendar={items.options.data.showCalendar}
      />
      {items.options.data.isLimit && (
        <p className="mh-constructor_numberLimit">
          {errors
            ? errors
            : "Введите дату в диапазоне" +
              (dateFrom ? " от " + dateFrom : "") +
              (dateTo ? " до " + dateTo : "")}
        </p>
      )}
      {(errors || isEmpty || invalidNumberCondition || isLimitError) && (
        <div
          className={classes["mh-formValidationMessage"]}
          style={{ marginTop: "10px" }}
        >
          {invalidNumberCondition
            ? "Проверьте указанную дату"
            : errors
            ? errors
            : isEmpty
            ? "Вопрос обязателен к заполнению."
            : isLimitError
            ? `Введите дату в диапазоне
                  ${dateFrom && `от ${dateFrom}`} 
                  ${dateTo && `до ${dateTo}`}`
            : ""}
        </div>
      )}
    </div>
  );
};
