import React from "react";
import "./index.scss";

import Overlay from "react-bootstrap/Overlay";
import IMAGES from "../../../../images/iconSet";

import parse from "html-react-parser";
import Checkbox from "../../../inputs/checkbox";

const getOptionTitleByAnswer = (answer, id) => {
  switch (answer.type) {
    case "checkbox":
      return answer.options.data.items.find((x) => x.id === id)
        ? answer.options.data.items.find((x) => x.id === id).text
        : null;
    case "list":
      return answer.options.data.find((x) => x.id === id)
        ? answer.options.data.find((x) => x.id === id).value
        : null;
    case "number":
      return {
        from: answer.options.data.limitFrom,
        to: answer.options.data.limitTo,
      };
    case "radio":
      return answer.options.data.items.find((x) => x.id === id)
        ? answer.options.data.items.find((x) => x.id === id).text
        : null;
    case "scale":
      return answer.options.data.find((x) => x.id === id)
        ? answer.options.data.find((x) => x.id === id).value
        : null;
    case "textarea":
      return {
        isText: true,
      };
    default:
      return null;
  }
};

const getOptionTitle = (selectedAnswer, id) => {
  const result = getOptionTitleByAnswer(selectedAnswer, id);
  if (result !== null) {
    if (typeof result === "string") {
      return result;
    } else if ("from" in result) {
      return "Значение из промежутка";
    } else if (result.isText) {
      return "Введите текст";
    }
  } else return "";
};

const getConditionText = (visits, condition) => {
  let visitNumber = 1;
  let answer = null;
  let questionNumber = 1;

  visits.forEach(({ items }, index) => {
    items.forEach((item) => {
      if (item.type === "chapter") {
        const findedAnswer = item.options.data.items.find(
          (question) => question.id === condition.questionId
        );
        if (findedAnswer) {
          answer = findedAnswer;
          questionNumber = `${items.indexOf(item) + 1}.${
            item.options.data.items.indexOf(findedAnswer) + 1
          }`;
          visitNumber = index + 1;
        }
      } else {
        if (item.id === condition.questionId) {
          answer = item;
          questionNumber = items.indexOf(answer) + 1;
          visitNumber = index + 1;
        }
      }
    });
  });

  if (!answer) return "";

  return `Визит ${visitNumber} - вопрос ${questionNumber}. "${
    answer.options.title
  }" ${
    condition.equals === "range"
      ? "Между"
      : condition.equals
      ? "Равно"
      : "Не равно"
  } "${getOptionTitle(
    answer,
    condition.answer && "value" in condition.answer
      ? condition.answer?.value
      : ""
  )}"`;
};

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.conditionButtonRef = React.createRef();
    this.state = {
      showCondition: false,
    };
  }

  onClick = (e) => {
    if ("imgbutton".indexOf(e.target.localName.toLowerCase()) === -1) {
      this.props.onClick();
    }
  };

  render() {
    const {
      onDragOver,
      onDrop,
      onDragStart,
      onDragLeave,
      onDelete,
      onCopy,
      style,
      className,
      condition,
      visits,
      type,
      selected,
      onSelect,
      hideDescription,
      isNumberCondition,
    } = this.props;

    return (
      <div
        className={"tp" + (className ? " " + className : "")}
        style={style}
        onClick={this.onClick}
      >
        {type !== "chapter" && (
          <Checkbox
            value={selected}
            onChange={onSelect}
            style={{
              position: "absolute",
              left: 10,
              top: 10,
              zIndex: 10,
            }}
          />
        )}
        <span
          className="tp--number"
          {...(type === "chapter" && { style: { top: 10 } })}
        >
          {this.props.number}
        </span>
        <div className="tp--title bold">
          <div className="tp--title--wrap">
            <h4>
              {this.props.title}
              {this.props.required ? <span></span> : ""}
            </h4>
            <img
              src={this.props.icon}
              width="19"
              height="19"
              alt=""
              className={
                this.props.icon ? "tp--title--wrep--image" : "tp--hide"
              }
            />
          </div>
          <div className="conditional">
            <button
              type="button"
              className={`conditional--button conditional--button-green ${
                this.props.hasCondition ? "" : "conditional--button-hide"
              }`}
              onMouseEnter={() => this.setState({ showCondition: true })}
              ref={this.conditionButtonRef}
              onMouseLeave={() => this.setState({ showCondition: false })}
            >
              <img src={IMAGES.thin.que} alt="" />
            </button>
            <Overlay
              target={this.conditionButtonRef.current}
              show={this.state.showCondition}
              placement="bottom"
            >
              {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
              }) => (
                <div
                  className="question-tooltip"
                  {...props}
                  style={props.style}
                >
                  {condition.map((c) => (
                    <div key={c.id} className="question-tooltip--condition">
                      {getConditionText(visits, c)}
                    </div>
                  ))}
                </div>
              )}
            </Overlay>
            <button
              type="button"
              className={
                "conditional--button conditional--button-yellow conditional--button-show" +
                (!this.props.isNotyfi ? " conditional--button-hide" : "")
              }
            >
              <img src={IMAGES.thin.bell} alt="" />
            </button>
            <button
              type="button"
              className={
                "conditional--button conditional--button-blue conditional--button-show" +
                (!isNumberCondition ? " conditional--button-hide" : "")
              }
            >
              <img src={IMAGES.constructor.calculator} alt="" />
            </button>
          </div>
          <div className="tp--action">
            <button
              type="button"
              className="tp--action--button"
              onClick={onCopy}
            >
              <img src={IMAGES.thin.copy} alt="" />
            </button>
            <button
              type="button"
              className="tp--action--button"
              onClick={onDelete}
            >
              <img src={IMAGES.thin.bin} alt="" />
            </button>
            <button
              type="button"
              className="tp--action--button"
              style={{
                cursor: "grab",
              }}
              onDragEnter={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragLeave={onDragLeave}
            >
              <img src={IMAGES.thin.move} alt="" />
            </button>
          </div>
        </div>
        {this.props.note ? (
          <div className="tp--note">{parse(this.props.note)}</div>
        ) : (
          ""
        )}
        {!hideDescription && (
          <div className="tp--content">{this.props.children}</div>
        )}
      </div>
    );
  }
}

export default Wrapper;
