import { FC } from "react";
import { Button, Modal } from "react-bootstrap";

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  body: string;
  onDelete: () => void;
}

const DeleteItemModal: FC<Props> = ({
  open,
  onClose,
  onDelete,
  title,
  body,
}) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Удалить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteItemModal;
