import React from 'react';
import {IIcons} from './types';

export const DropUpFull = ({width = 10, height = 8, color = '#333333'}: IIcons) => {
    return (
        <svg  width={width} height={height} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.40962 0.585166C5.21057 0.300808 4.78943 0.300807 4.59038 0.585166L0.300712 6.71327C0.0687404 7.04466 0.305816 7.5 0.710328 7.5H9.28967C9.69418 7.5 9.93126 7.04466 9.69929 6.71327L5.40962 0.585166Z"
                fill={color}/>
        </svg>
    );
};
