import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IAvailabel, ITableProps } from "../types";
import { AllTypesButtons, TableHeader, TableRow } from "./components";
import { Pagination } from "./components/pagination";
import {
  clearFilters,
  getInitTable,
  setFilters,
  setToken,
  setUrl,
  setVisitFilters,
} from "./store/table.slice";
import "./styles.scss";

import { NavLink } from "react-router-dom";
import { getUserInfo } from "../../components/userComponent/services/userInfo";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import IMAGES from "../../images/iconSet";
import plusIconUrl from "../../images/icons/plus.svg";
import { SearchIcon } from "../assets/icons";
import { TablePlaceholder } from "../components/tablePlaceholder";
import { SelectorSearch } from "./components/selectorSearch";

const CustomToggle = React.forwardRef(
  ({ children, onClick }: any, ref: any) => (
    <Button
      className={"table-head__add"}
      variant={"primary"}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  )
);

const visitOptions = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
];

const statusOptions = [
  { id: 1, name: "Черновик" },
  { id: 2, name: "Принято" },
  { id: 3, name: "На проверке" },
];

export const FormsTable = ({
  url,
  token,
  updateCount,
  hideTabs,
}: ITableProps) => {
  const dispatch = useAppDispatch();
  const {
    useTabs,
    usePaginator,
    tabs,
    content,
    filters: options,
  } = useAppSelector((state) => state.formsTable.initData);

  const currentSavedUrl = useAppSelector(
    (state) => state.formsTable.currentUrl
  );
  const { filters, visitFilters, loader } = useAppSelector(
    (state) => state.formsTable
  );

  const [urlChecked, setUrlChecked] = useState(false);

  const [rerenderTable, setRerenderTable] = useState<boolean>(false);
  const [user, setUser] = useState(null);

  const countRows = useAppSelector((state) => state.formsTable.initData.count);

  const [pagination, setPagination] = useLocalStorage(`${url}pagination`, {
    active: 0,
    itemsPerPage: 10,
  });

  const [pcFilter, setPcFilter] = useState(filters?.pc ?? "");

  useEffect(() => {
    if (currentSavedUrl !== url) {
      dispatch(clearFilters());
    }
    setUrlChecked(true);
    //временное решение пока нет общего store
    getUserInfo(1, (user) => setUser(user));
  }, []);

  useEffect(() => {
    if (urlChecked) {
      dispatch(setUrl(url));
      dispatch(setToken(token));
    }
  }, [urlChecked]);

  useEffect(() => {
    if (urlChecked) {
      dispatch(
        getInitTable({
          url,
          token,
          filter: filters,
          filters: visitFilters,
          limit: pagination.itemsPerPage,
          offset: pagination.active * pagination.itemsPerPage,
        })
      );
    }
  }, [dispatch, filters, visitFilters, token, url, urlChecked, pagination]);

  const onPageChange = (offset: number) => {
    setPagination((prev) => ({
      ...prev,
      active: Math.round(offset / prev.itemsPerPage),
    }));
  };

  const onLimitChange = (itemsPerPage: number) => {
    setPagination({ active: 0, itemsPerPage });
  };

  useEffect(() => {
    setRerenderTable((prev) => !prev);
    if (countRows) {
      updateCount(countRows);
    }
  }, [content]);

  const handleTypesButton = (item: IAvailabel, tabsBy: string) => {
    const data = {
      ...filters,
      [tabsBy]: item.value ? [item.value] : [],
    };
    setPagination((prev) => ({ ...prev, active: 0 }));
    dispatch(setFilters(data));
  };

  const handleSelect = ({ type, options }: any) => {
    const data = {
      ...filters,
      [type]: options.map((i) => i.id),
    };
    setPagination((prev) => ({ ...prev, active: 0 }));
    dispatch(setFilters(data));
  };

  const handleSelectVisitFilter = ({
    type,
    options,
  }: {
    type: "visit" | "status";
    options: { id: number | string; name: string }[];
  }) => {
    if (visitFilters.length === 0) {
      const visitsWithAllStatuses = options
        .map((visit) =>
          statusOptions.map((status) => ({
            id: visit.id,
            status: status.id,
          }))
        )
        .flat(1);

      if (type === "visit") {
        dispatch(setVisitFilters(visitsWithAllStatuses));
      }
      if (type === "status") {
        const statusesWithAllVisits = visitOptions
          .map((visit) =>
            options.map((status) => ({
              id: visit.id,
              status: status.id,
            }))
          )
          .flat(1);

        dispatch(setVisitFilters(statusesWithAllVisits));
      }
    } else {
      if (type === "visit") {
        const selectedStatuses = statusOptions.filter(
          (el) => !!visitFilters?.find(({ status }) => status === el.id)
        );
        const visitsWithSelectedStatuses = options
          .map((visit) =>
            selectedStatuses.map((status) => ({
              id: visit.id,
              status: status.id,
            }))
          )
          .flat(1);
        dispatch(setVisitFilters(visitsWithSelectedStatuses));
      }
      if (type === "status") {
        const selectedVisits = visitOptions.filter(
          (el) => !!visitFilters?.find(({ id }) => id === el.id)
        );
        const statusesWithSelectedVisits = selectedVisits
          .map((visit) =>
            options.map((status) => ({
              id: visit.id,
              status: status.id,
            }))
          )
          .flat(1);
        dispatch(setVisitFilters(statusesWithSelectedVisits));
      }
    }
  };

  const removeFilters = () => {
    setPcFilter("");
    dispatch(clearFilters());
  };

  const hasFilters =
    (Object.keys(filters).length > 0 &&
      Object.values(filters as { [filter: string]: string[] | number[] }).some(
        (el) => el.length > 0
      )) ||
    visitFilters.length > 0;

  if (loader) {
    return <TablePlaceholder />;
  }

  return (
    <div className={"wrapper-table"}>
      {/* {!hideFilters && <Filters allowFilterByDate={allowFilterByDate} useSearch={useSearch} />} */}
      {/* {filters.length > 0 && !hideFilters && (
        <ActiveFilters
          handleGetInitTable={handleGetInitTable}
          filters={filters}
        />
      )} */}
      {user?.role !== 1 && (
        <div className="filters">
          <div className="filters__item">
            <SelectorSearch
              title="Исследование"
              options={options?.researches ?? []}
              defaultSelected={(options?.researches ?? []).filter(
                (el) => !!filters?.researches?.find((id) => id === el.id)
              )}
              getSelected={(options) =>
                handleSelect({
                  type: "researches",
                  options,
                })
              }
            />
          </div>
          <div className="filters__item">
            <SelectorSearch
              title="Пользователь"
              options={options?.users ?? []}
              defaultSelected={(options?.users ?? []).filter(
                (el) => !!filters?.users?.find((id) => id === el.id)
              )}
              getSelected={(options) =>
                handleSelect({
                  type: "users",
                  options,
                })
              }
            />
          </div>
          <div className="filters__item">
            <div className={"input-field-search"}>
              <SearchIcon width={12} height={12} />
              <input
                value={pcFilter}
                onChange={(e) => setPcFilter(e.target.value)}
                type="text"
                placeholder={"Код пациента"}
                className={"input-field-search__input"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(setFilters({ ...filters, pc: pcFilter }));
                  }
                }}
              />
            </div>
          </div>
          <div className="filters__item">
            <SelectorSearch
              title="Визит"
              options={visitOptions}
              defaultSelected={visitOptions.filter(
                (el) => !!visitFilters?.find(({ id }) => id === el.id)
              )}
              getSelected={(options) =>
                handleSelectVisitFilter({
                  type: "visit",
                  options,
                })
              }
            />
          </div>
          <div className="filters__item">
            <SelectorSearch
              title="Статус визита"
              options={statusOptions}
              defaultSelected={statusOptions.filter(
                (el) => !!visitFilters?.find(({ status }) => status === el.id)
              )}
              getSelected={(options) =>
                handleSelectVisitFilter({
                  type: "status",
                  options,
                })
              }
            />
          </div>
          {hasFilters && (
            <div className="outline-primary">
              <Button
                variant="light"
                className="filter-clear"
                onClick={removeFilters}
              >
                Очистить все
                <img src={IMAGES.constructor.removeSelectedItem} alt="" />
              </Button>
            </div>
          )}
        </div>
      )}
      {useTabs && !hideTabs ? (
        <div className={"table-head__wrapper"}>
          <AllTypesButtons
            tabs={tabs}
            activeFilterValue={filters?.[tabs.tabsBy]?.[0]}
            onClick={handleTypesButton}
          />
          {url === "/users/" && (
            <Dropdown placement={"left-end"}>
              <Dropdown.Toggle
                as={CustomToggle}
                variant="success"
                id="dropdown-basic"
              >
                <img src={plusIconUrl} alt="" />
                Добавить
              </Dropdown.Toggle>

              <Dropdown.Menu className={"table-head__menu"}>
                <Dropdown.Item
                  as={React.forwardRef(
                    ({ children, onClick }: any, ref: any) => (
                      <NavLink ref={ref} to={"/users/add"}>
                        {children}
                      </NavLink>
                    )
                  )}
                >
                  Добавить вручную
                </Dropdown.Item>
                <Dropdown.Item
                  as={React.forwardRef(
                    ({ children, onClick }: any, ref: any) => (
                      <NavLink ref={ref} to={"/users/import"}>
                        {children}
                      </NavLink>
                    )
                  )}
                >
                  Загрузить из Excel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      ) : null}

      <div className={"table"}>
        <table style={{ width: "100%" }}>
          <TableHeader />
          <tbody>
            {content &&
              content.map((item: any, i) => (
                <TableRow key={item.id ?? i} data={item} index={i} />
              ))}
          </tbody>
        </table>
        <Pagination
          usePaginator={usePaginator}
          onLimitChange={onLimitChange}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};
