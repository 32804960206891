import React from 'react';

interface IButton {
    icon?: JSX.Element;
    text: string;
    onClick: () => void;
}

export const Button = (
    {icon, onClick, text}:IButton
) => {
    return (
        <button className={'button button--lg'} onClick={onClick}>
            {icon && <span className={'button__icon'}>{icon}</span>}
            {text}
        </button>
    );
};
