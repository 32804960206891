import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { Button, CheckBox } from "../../../fields";
import { SearchIcon } from "../../../assets/icons";
import { ICasesSelector } from "../../../types";

interface ISearchProps {
  options: ICasesSelector[];
  handler: (items: ICasesSelector[]) => void;
  handleClose: () => void;
  defaultSelected?: ICasesSelector[];
}

export const Search = ({
  options,
  handler,
  handleClose,
  defaultSelected,
}: ISearchProps) => {
  const [isValue, setValue] = useState<string>("");
  const [selected, setSelected] = useState<ICasesSelector[]>(
    defaultSelected ?? []
  );

  const handleSelect = useCallback(
    (item: ICasesSelector) => {
      if (
        !_.includes(
          Object.values(selected).map((i) => i.showFilter),
          item.showFilter
        )
      ) {
        setSelected([...selected, item]);
      } else {
        const update = selected.filter((i) => i.showFilter !== item.showFilter);
        setSelected(update);
      }
    },
    [selected]
  );

  const renderOptions = useMemo(() => {
    return options
      .filter((i) => _.includes(i.showFilter, isValue))
      .map((i) => (
        <div
          key={i.showFilter}
          className={"selector-search__item"}
          onClick={() => handleSelect(i)}
        >
          <CheckBox
            isChecked={_.includes(
              Object.values(selected).map((i) => i.showFilter),
              i.showFilter
            )}
          />
          <span className={"selector-search__text"}>{i.showFilter}</span>
        </div>
      ));
  }, [isValue, selected, handleSelect, options]);

  return (
    <div className={"selector-search"}>
      <div className={"selector-search__header"}>
        <div className={"selector-search__wrapper"}>
          <SearchIcon width={12} height={12} />
          <input
            className={"selector-search__input"}
            value={isValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValue(e.target.value)
            }
          />
        </div>
      </div>

      <div className={"selector-search__list"}>{renderOptions}</div>
      <div className={"selector-search__footer"}>
        <Button
          text={"Применить"}
          onClick={() => {
            handler(selected);
            handleClose();
          }}
        />
        <span className={"selector-search__counter"}>Выбрано: {0}</span>
      </div>
    </div>
  );
};
