import { Row, Col, Accordion, Spinner, Table } from "react-bootstrap";
import React from "react";
import { customFetch } from "../../utils/http";
export class Systemstatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      info: "",
    };
  }
  componentDidMount() {
    document.title = "Системный монитор";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: "",
      redirect: "follow",
    };
    customFetch(global.apiUrl + "/status/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState((state) => {
          state.info = result;
          var dataUpdate = [];
          if (state.info?.click) {
            var rows = new Array();
            state.info.click.forEach((item) => {
              var cells = new Array();
              for (let key in item) {
                cells.push(<td>{item[key]}</td>);
              }
              rows.push(<tr>{cells}</tr>);
            });
            state.table = <tbody>{rows}</tbody>;
          }
          return state;
        });
      });
    this.state.set = setInterval(() => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: "",
        redirect: "follow",
      };
      customFetch(global.apiUrl + "/status/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.setState((state) => {
            state.info = result;
            var dataUpdate = [];
            if (state.info?.click) {
              var rows = new Array();
              state.info.click.forEach((item) => {
                var cells = new Array();
                for (let key in item) {
                  cells.push(<td>{item[key]}</td>);
                }
                rows.push(<tr>{cells}</tr>);
              });
              state.table = <tbody>{rows}</tbody>;
            }
            return state;
          });
        });
    }, 3000);
  }
  render() {
    return (
      <div className="container-fluid" id="main-page-block">
        <div id="page-content">
          <h2 className="wrapper-status">
            Статус системы <span id="sup">HM2</span>{" "}
            {this.state.info?.uptime ? (
              <div className="online_server">
                <Spinner animation="grow" variant="success" />
              </div>
            ) : (
              <div className="online_server">
                <Spinner animation="grow" variant="danger" />
              </div>
            )}
          </h2>
          <Row>
            <Col></Col>
          </Row>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Потребление памяти</Accordion.Header>
              <Accordion.Body>
                <p>
                  <b>V8 external memory: </b>
                  {this.state.info.external}
                </p>
                <p>
                  <b>Allocated heap: </b>
                  {this.state.info.heapTotal}
                </p>
                <p>
                  <b>Actual memory used: </b>
                  {this.state.info.heapUsed}
                </p>
                <p>
                  <b>Resident Set Size: </b>
                  {this.state.info.rss}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Общая информация</Accordion.Header>
              <Accordion.Body>
                <p>
                  <b>Аптайм системы: </b>
                  {this.state.info.uptime}
                </p>
                <p>
                  <b>Количество черновиков на хосте: </b>
                  {this.state.info.drafts}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Данные по количеству анкет Clickhouse
              </Accordion.Header>
              <Accordion.Body>
                <Table>
                  <th>
                    <tr>Имя</tr>
                    <tr>ID</tr>
                    <tr>Кол-во</tr>
                  </th>
                  {this.state.table}
                </Table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Статистика по врачам</Accordion.Header>
              <Accordion.Body>
                <Table>
                  <th>
                    <tr>Имя</tr>
                    <tr>ID</tr>
                    <tr>Кол-во</tr>
                  </th>
                  {this.state.table}
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default Systemstatus;
