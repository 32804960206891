import React from 'react';
import {IIcons} from './types';

export const Checked = ({width = 15, height = 10, color = '#00A1A0'}: IIcons) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="4" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.3169 5.18306C17.561 5.42714 17.561 5.82286 17.3169 6.06694L8.56694 14.8169C8.32286 15.061 7.92714 15.061 7.68306 14.8169L3.30806 10.4419C3.06398 10.1979 3.06398 9.80214 3.30806 9.55806C3.55214 9.31398 3.94786 9.31398 4.19194 9.55806L8.125 13.4911L16.4331 5.18306C16.6771 4.93898 17.0729 4.93898 17.3169 5.18306Z"
                  fill="white"/>
        </svg>
    );
};
