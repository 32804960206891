import "./index.scss";
import React from "react";

class ScaleNote extends React.Component {
   render() {
      return (
         <div className="mh-constructor_scale">
            {
               this.props.data.map((item, i) => (
                  <div key={i} className="mh-constructor_scaleItem">{item.value}</div>
               ))
            }
         </div>
      );
   }
}

export default ScaleNote;
