import { Button } from "react-bootstrap";
import Tass from "../../tables";

import { useCallback, useState } from "react";

const Users = () => {
  const [count, setCount] = useState(0);

  const handleChangeCount = useCallback((value) => {
    setCount(value);
  }, []);

  return (
    <div className="container-fluid" id="main-page-block">
      <div id="page-content">
        <div className="d-flex justify-content-between">
          <h2>
            Пользователи <span id="sup">{count ? count : ""}</span>
          </h2>
          <Button
            target="_blank"
            download
            href={`${global.apiUrl}/users/get`}
            rel="noreferrer"
            className="py-2"
          >
            Скачать
          </Button>
        </div>
        <Tass
          url={global.apiUrl + "/users/"}
          token={sessionStorage.getItem("token")}
          updateCount={handleChangeCount}
        />
      </div>
    </div>
  );
};

export default Users;
