import { FC } from "react";
import { TABLE_WIDTH } from "../../tables/utils/constants";
import "../../tables/table/styles.scss";

type TableProps = {
    columns: {
        key: string | number;
        label: string;
        dataKey: string;
        width?: string;
        style?: React.CSSProperties
    }[];
    data: any[]
}

export const Table: FC<TableProps> = ({ columns, data }) => {
    return (
        <div className="table">
            <table style={{ width: "100%" }}>
                <thead className={"table-row table-row--header"}>
                    <tr className={"table-row table-row--header"}>
                        {columns.map(({ key, label, width }) => (
                            <th
                                key={key}
                                style={{ width: width ? width : `${TABLE_WIDTH / columns.length}px` }}
                                className={"table-row__item"}
                            >
                                <span className={"table-head"}>
                                    {label}
                                </span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr className={"index" ? "table-row table-row--bg" : "table-row"}>
                            {columns.map(({ dataKey, key, width, style }) => (
                                <td
                                    key={key}
                                    style={{ width: width ? width : `${TABLE_WIDTH / columns.length}px` }}
                                    className={"table-row__item"}
                                >
                                    <div className={"table-row__wrap-text"}>
                                        <span className={"table-row__text"} style={style}>
                                            {item[dataKey]}
                                        </span>
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}