import * as yup from "yup";

export const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "Пароль должен быть не менее 6 символов")
    .max(10, "Максимум 10 символов")
    .matches(
      /^[A-Za-z0-9!#@$%_-]+$/,
      "Пароль должен содержать только латинские буквы, цифры и/или символы !#@$%_-"
    )
    .required("Поле не может быть пустым"),
});
