import { Navbar, Nav, NavItem, Button, Container, Row, Col, Table, Tabs, Tab, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TestTable } from "../../components/testTable.js";
import Tass from '../../tables';

import { RD, ActionsTable, CountPicker, PaginationBasic, CustomersTable } from "../../components/ddTest.js";

import React, { Component, useState, useCallback } from "react";

export function Customers() {
   const [count, setCount] = useState(0);

   const handleChangeCount = useCallback((value) => {
      setCount(value);
   }, [])

   return (
      <div className="container-fluid" id="main-page-block">
         <div id="page-content">
            <h2>
               Заказчики <span id="sup">{count ? count : ""}</span>
            </h2>
            <div>
               <Tass url={'/api/requestors/'}
                  token={sessionStorage.getItem('token')}
                  updateCount={handleChangeCount} />
               <a href='/customers/add' className='fill-questionairy btn btn-secondary btn-sm'>Добавить заказчика</a>
            </div>
         </div></div>
   );
}

export default Customers;
