import axios from "axios";
import { getTokenString } from "./getToken";

export const customFetch = (input: RequestInfo | URL, init?: RequestInit) => {
  const token = getTokenString();
  const options = {
    ...init,
    headers: { ...init?.headers, Authorization: token },
  };
  return fetch(input, options);
};

axios.interceptors.request.use(
  function (config) {
    const token = getTokenString();
    config.headers = { ...config.headers, Authorization: token };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
