import React from 'react';
import {IIcons} from './types';
import Button from 'react-bootstrap/Button';

export const DraftIcon = ({width = 18, height = 18, color = '#828282'}: IIcons) => {
    return (
     

        <Button variant="secondary" size="sm" className="fill-questionairy">Заполнить</Button>
    
    );
};
