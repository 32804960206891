import "./index.scss";

import React from "react";
import IMAGES from "../../../images/iconSet";

export class Select extends React.Component {

   constructor (props) {
      super(props);

      this.state = {
         shown: false,
         showTimer: null
      };
   }

   show = () => {
      this.state.shown = true;
      this.setState(this.state);
   }
   preventHiding = () => {
      this.state.showTimer = null;
      this.setState(this.state);
   }
   hide = () => {
      this.state.showTimer = setTimeout(()=>{
         if(this.state.showTimer) {
            this.state.shown = false;
            this.setState(this.state);
         }
      }, 300);
      this.setState(this.state);
   }

   render() {
      
      const {items, enabled, style, type, value, className} = this.props;
      let selectedText = "";
      
      Object.keys(items).forEach(id => {
         if(id == value) {
            selectedText = items[id];
         };
      });

      return <div className={"mh-selectWrapper"+ (className ? " "+className : "")} style={style}>
         <div
            className={
               "mh-select" +
               (!enabled && enabled !== undefined ? " mh-select__disabled" : "") +
               (type === "outline" ? " mh-select__outline" : "")
            }

         >
            <div className="mh-select_input" onClick={this.show} onMouseLeave={this.hide}>
               {value === -1 || value === undefined || value === null ? "Выберите из списка" : selectedText}
               <img src={IMAGES.constructor.listArrow} />
            </div>
            <ul className={"mh-select_list" + (this.state.shown ? " mh-select_list__shown" : "")} onMouseEnter={this.preventHiding} onMouseLeave={this.hide}>
               {
                  Object.keys(items).map(id=>(
                     <li className={"mh-select_item" + (value === id ? " mh-select_item__current" : "")} key={id} onClick={()=>this.props.onChange(id)}>
                        {items[id]}
                     </li>
                  ))
               }
            </ul>
         </div>
      </div>;
   }
}

export default Select;
