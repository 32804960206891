import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import axios from "axios";

import styles from "./UserImport.module.scss";
import addFileUrl from "../../images/icons/add-file.svg";
import deleteFileUrl from "../../images/icons/modalClose.svg";
import getNoun from "../../components/getNoun";
import { Table } from "../../components/commonTable/Table";

const errorColumns = [
  {
    key: "line",
    label: "Строка",
    dataKey: "line",
    width: "80px",
  },
  {
    key: "surname",
    label: "Фамилия",
    dataKey: "surname",
  },
  {
    key: "name",
    label: "Имя",
    dataKey: "name",
  },
  {
    key: "patronymic",
    label: "Отчество",
    dataKey: "patronymic",
  },
  {
    key: "email",
    label: "Email",
    dataKey: "email",
    width: "160px",
  },
  {
    key: "city",
    label: "Город",
    dataKey: "city",
    width: "150px",
  },
  {
    key: "phone",
    label: "Телефон",
    dataKey: "phone",
    width: "120px",
  },
  {
    key: "spec",
    label: "Специальность",
    dataKey: "spec",
  },
  {
    key: "error",
    label: "Ошибки",
    dataKey: "error",
    style: { color: "#FF837B" },
  },
];

const roles = [
  "Пользователь",
  "Врач",
  "Модератор",
  "Монитор",
  "Администратор",
  "Суперадминистратор",
];

const userColumns = [
  {
    key: "id",
    label: "id",
    dataKey: "id",
  },
  {
    key: "name",
    label: "Инициалы",
    dataKey: "name",
  },
  {
    key: "email",
    label: "Email",
    dataKey: "email",
  },
  {
    key: "pass",
    label: "Пароль",
    dataKey: "pass",
  },
  {
    key: "role",
    label: "Роль",
    dataKey: "role",
  },
];

export const UserImport: FC = () => {
  const files = useRef<HTMLInputElement>();
  const [rerender, setRerender] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [countAdded, setCountAdded] = useState(0);
  const [errorTableItems, setErrorTableItems] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [error, setError] = useState("");

  const copyTable = () => {
    const stringToCopy = addedUsers.reduce((acc, val) => {
      const values = Object.values(val);
      return acc + values.join("\t") + "\n";
    }, "");
    navigator.clipboard.writeText(stringToCopy);
  };

  useEffect(() => { }, [rerender]);

  const handleUpload = useCallback(() => {
    setShowAlert(false);
    setCountAdded(0);
    setErrorTableItems([]);
    setAddedUsers([]);
    setError("");
    if (files.current?.files && files.current?.files.length !== 0) {
      const file = files.current.files[0];
      const formData = new FormData();

      formData.append("importFile", file);
      files.current.value = "";
      setRerender((prev) => !prev);

      axios
        .post(global.apiUrl + "/users/import", formData)
        .then(({ data }) => {
          if (data.message === "ok") {
            const { response } = data;
            if (
              response.count === 0 &&
              response.count_added === 0 &&
              response.skipped.length === 0
            ) {
              setError("Произошла ошибка. Проверьте формат файла");
            }
            if (response.count_added > 0) {
              setCountAdded(response.count_added);
              setShowAlert(true);
              setAddedUsers(
                response.added.map((user) => ({
                  ...user,
                  role: roles[user.role],
                }))
              );
            }
            if (response.skipped.length > 0) {
              const items = response.skipped.map((x) => ({
                ...x.data,
                line: x.line,
                error: x.reason.join(", "),
              }));
              setErrorTableItems(items);
              setError("Есть ошибки");
            }
          }
        });
    }
  }, [rerender]);

  return (
    <div className="container-fluid" id="main-page-block">
      <div id="page-content">
        <h2>Импорт пользователей</h2>
        <a
          target="_blank"
          download
          href="/Шаблон_импорта_пользователей.xlsx"
          rel="noreferrer"
        >
          Скачать шаблон
        </a>
        <div className={styles.upload}>
          <label className={styles.label}>
            <img src={addFileUrl} alt="" />
            <span>Добавить файл</span>
            <input
              type="file"
              ref={files}
              accept=".csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={() => {
                setRerender((prev) => !prev);
              }}
            />
          </label>
          {files.current?.files.length > 0 && (
            <span className={styles.files}>
              {files.current?.files[0]["name"]}
              <img
                src={deleteFileUrl}
                alt=""
                onClick={() => {
                  files.current.value = "";
                  setRerender((prev) => !prev);
                }}
              />
            </span>
          )}
          <Button
            variant={"primary"}
            onClick={handleUpload}
            className={styles.button}
            disabled={
              !files.current ||
              !files.current.files ||
              files.current.files.length === 0
            }
          >
            Загрузить
          </Button>
        </div>
        {showAlert && (
          <>
            <Alert
              className={styles.alert}
              dismissible
              variant="success"
              onClose={() => setShowAlert(false)}
            >
              {`${countAdded === 1 ? "Добавлен" : "Добавлено"
                } ${countAdded} ${getNoun(
                  countAdded,
                  "пользователь",
                  "пользователя",
                  "пользователей"
                )}`}
            </Alert>
            <Button className="mb-3" onClick={copyTable}>
              Копировать
            </Button>
            <Table columns={userColumns} data={addedUsers} />
          </>
        )}
        {error && <div className={styles.error}>{error}</div>}
        {!!errorTableItems.length && (
          <Table columns={errorColumns} data={errorTableItems} />
        )}
      </div>
    </div>
  );
};
