import React, { useEffect, useState } from "react";
import { ChatIcon, DraftIcon, ThreeDots, ViewShow } from "../../assets/icons";
import { IActonButtons, IParam } from "../store/types";
import { DropDown } from "../../fields";
import { useAppDispatch } from "../../hooks";
import { deleteItem } from "../store/table.slice";

const enum buttonsType {
  single = "single",
  dropdown = "dropdown",
}

const enum iconTypes {
  view = "view",
  threedot = "threedot",
  draft = "draft",
  chat = "chat",
}

const enum paramsTypes {
  open = "open",
  fetch = "fetch",
}

interface IRowMenuProps {
  id: string;
  data: IActonButtons[];
}

export interface IDropOption extends IParam {
  downloadUrl: string;
}

interface IDropProps {
  items: IDropOption[];
  handleClick: (item: IParam) => void;
}

const RenderDrop = ({ items, handleClick }: IDropProps) => {
  return <DropDown onClick={handleClick} options={items} />;
};

const RenderSingle = (icon: any) => {
  /* {console.log(icon['item'])} */
  switch (icon["item"]) {
    case iconTypes.chat:
      return <ChatIcon width={16} height={17} />;
    case iconTypes.view:
      return <ViewShow width={16} height={12} />;
    case iconTypes.draft:
      return <DraftIcon width={18} height={18} />;
    default:
      return <ThreeDots width={5} height={16} />;
  }
};

export const RowMenu = ({ data, id }: IRowMenuProps) => {
  const dispatch = useAppDispatch();
  const [isRedirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    isRedirect && window.location.replace(isRedirect);
  }, [isRedirect]);

  const handleDrop = (item: IParam) => {
    item.type === paramsTypes.fetch
      ? dispatch(deleteItem({ id: id, url: item.url }))
      : setRedirect(`${item.url}?id=${id}`);
  };
  const handleSingle = (item: any) => {
    item.type === paramsTypes.open && setRedirect(`${item.url}?id=${id}`);
  };

  // Это по той же логике что и другие пункты нужно выводить.
  // Один момент. Сейчас в роуте ID прописывается как узел т.е. /123/ а не ?id=123

  return (
    <div className={"row-menu"}>
      {data.map((i) => {
        return (
          <span key={i.type} className={"row-menu__button"}>
            {/* {console.log(i.params['icon'])} */}
            {i.type === buttonsType.single ? (
              <div onClick={() => handleSingle(i.params)}>
                <RenderSingle item={i.params["icon"]} />
              </div>
            ) : (
              <RenderDrop
                items={i.params.map((p) => ({
                  ...p,
                  downloadUrl: global.apiUrl + p.url + `?id=${id}`,
                }))}
                handleClick={handleDrop}
              />
            )}
          </span>
        );
      })}
    </div>
  );
};
