function IconSearch() {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M10.0001 2.5C11.3981 2.49994 12.7682 2.89061 13.956 3.62792C15.1437 4.36523 16.1017 5.41983 16.7219 6.6727C17.3421 7.92558 17.5998 9.32686 17.4659 10.7184C17.332 12.11 16.8118 13.4364 15.9641 14.548L20.7071 19.293C20.8864 19.473 20.9906 19.7144 20.9983 19.9684C21.0061 20.2223 20.9169 20.4697 20.7488 20.6603C20.5808 20.8508 20.3465 20.9703 20.0936 20.9944C19.8407 21.0185 19.5881 20.9454 19.3871 20.79L19.2931 20.707L14.5481 15.964C13.6011 16.6861 12.4958 17.1723 11.3235 17.3824C10.1513 17.5925 8.94592 17.5204 7.80709 17.1721C6.66826 16.8238 5.62874 16.2094 4.77455 15.3795C3.92035 14.5497 3.27604 13.5285 2.89492 12.4002C2.51381 11.2719 2.40684 10.0691 2.58289 8.89131C2.75893 7.7135 3.21292 6.59454 3.90729 5.62703C4.60165 4.65951 5.51644 3.87126 6.57594 3.32749C7.63544 2.78372 8.8092 2.50006 10.0001 2.5ZM10.0001 4.5C8.5414 4.5 7.14246 5.07946 6.11101 6.11091C5.07956 7.14236 4.50009 8.54131 4.50009 10C4.50009 11.4587 5.07956 12.8576 6.11101 13.8891C7.14246 14.9205 8.5414 15.5 10.0001 15.5C11.4588 15.5 12.8577 14.9205 13.8892 13.8891C14.9206 12.8576 15.5001 11.4587 15.5001 10C15.5001 8.54131 14.9206 7.14236 13.8892 6.11091C12.8577 5.07946 11.4588 4.5 10.0001 4.5Z"
            fill="#C6C6C6"
         />
      </svg>
   );
}

export default IconSearch;
