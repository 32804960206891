import "./index.scss";
import React from "react";
import { Link } from "react-router-dom";

class PopoverSubItem extends React.Component {
   render() {
      return (
         <li className="popver--item">
            <Link className="popover--item--link light" to={this.props.url}>
               {this.props.text}
            </Link>
         </li>
      );
   }
}

export default PopoverSubItem;
