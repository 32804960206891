import React from "react";
import { connect } from "react-redux";
import UserAvatar from "../../avatar";
import "./style.scss";
import moment from "moment";

class ContactList extends React.Component {
  render() {
    const { onChatSelect, contacts, isSearch } = this.props;

    const shortLastMessage = (text) =>
      text.substring(0, 25) + (text.length > 24 ? "..." : "");

    return (
      <div
        style={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        {contacts.length === 0 && isSearch ? (
          <div className="chat-emptyContacts">
            <p className="chat-emptyContacts_title">Нет результата...</p>
            <p className="chat-emptyContacts_text">
              Попробуйте изменить запрос&nbsp;поиска
            </p>
          </div>
        ) : contacts.length === 0 ? (
          <div className="chat-emptyContacts">
            <p className="chat-emptyContacts_title">Нет доступных контактов</p>
          </div>
        ) : (
          contacts
            .sort((a, b) => {
              return moment(b.lastUpdate).unix() - moment(a.lastUpdate).unix();
            })
            .map((contact) =>
              contact ? (
                <div
                  className="chat-contact"
                  key={contact.id}
                  onClick={() => onChatSelect(contact.id)}
                >
                  <UserAvatar
                    className="chat-contact_avatar"
                    img={contact.avatar}
                    name={contact.name}
                    surname={contact.surname}
                    id={contact.id}
                    size={40}
                  />

                  <div className="chat-contact_info chat-contactInfo">
                    <p className="chat-contactInfo_name">
                      {contact.name} {contact.surname}
                    </p>
                    <p
                      className={
                        "chat-contactInfo_lastMessage" +
                        (contact.unread_count > 0
                          ? " chat-contactInfo_lastMessage__unreaded"
                          : "")
                      }
                    >
                      {this.props.selectedChatId === contact.id
                        ? this.props.messages[0]
                          ? shortLastMessage(this.props.messages[0].text)
                          : ""
                        : contact.messages[0]
                        ? shortLastMessage(contact.messages[0].text)
                        : ""}
                    </p>
                  </div>

                  <div className="chat-contact_messageInfo chat-messageInfo">
                    <p className="chat-messageInfo_time">
                      {contact.lastMessageTime}
                    </p>
                    {contact.unreadCount > 0 ? (
                      <p className="chat-messageInfo_count">
                        {contact.unreadCount}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )
            )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedChatId: state.selectedChatId,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(ContactList);
