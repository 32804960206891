import { createStore } from "redux";
import { v4 } from "uuid";
import _ from "lodash";

const initialState = {
  io: null,
  contacts: [],
  selectedChatId: -1,
  selectedChat: null,
  usersOnline: [],
  notifications: [],
  user: {},
  messages: [],
  unreadCount: 0,
  loading: false
};

const reducer = (state = initialState, action) => {
  if (action.type === "CHAT_LOADING") {
    return { ...state, loading: action.payload };
  }

  if (action.type === "UPDATE_CONTACT_LIST") {
    return { ...state, contacts: state.contacts.sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime()) }
  }
  
  if (action.type === "CHAT_CONNECT") {
    return { ...state, io: action.payload };
  }
  if (action.type === "CHAT_AUTH") {
    const { dialogs, contacts, user, unreadCount } = action.payload;
    const selectedChat =
      state.selectedChatId > -1
        ? contacts.find((contact) => {
            if (contact.id === state.selectedChatId) {
              if (contact.roomId) {
                state.io.emit("chat.history", {
                  room_id: contact.roomId,
                });
              }
              return contact;
            }
            return null;
          })
        : null;
    const alwaysInArray = [];
    return {
      ...state,
      dialogs,
      contacts: contacts.map((contact) => {
        if (alwaysInArray.indexOf(contact.id) === -1) {
          alwaysInArray.push(contact.id);
          if (dialogs.find((el) => el.room.id === contact.roomId)) {
            return {
              ...contact,
              unreadCount: dialogs.find((el) => el.room.id === contact.roomId)
                .chat.unread_count,
            };
          } else
            return {
              ...contact,
              unreadCount: 0,
            };
        }
      }),
      user,
      selectedChat,
      messages: selectedChat ? selectedChat.messages : [],
      unreadCount,
    };
  }

  if (action.type === "SET_ONLINE_USERS") {
    return { ...state, usersOnline: action.payload };
  }

  if (action.type === "SELECT_CHAT") {
    const { id, chat, callBack } = action.payload;

    if (callBack) {
      callBack();
    }

    return {
      ...state,
      selectedChatId: id,
      selectedChat: chat,
      messages: chat ? chat.messages : [],
    };
  }

  if (action.type === "NEW_MESSAGE") {
    const { contactId, roomId, message, chat } = action.payload;

    const contacts = _.cloneDeep(state.contacts);
    let newMessages = [];

    const currentUnreadCount = state.contacts.reduce((prev, curr) => {
      return curr
        ? curr?.roomId === roomId
          ? prev + Number(chat.unread_count)
          : prev + Number(curr?.unreadCount)
        : prev;
    }, 0);

    contacts.forEach((contact, i) => {
      if (contact && contact.id === contactId) {
        contacts[i].messages.unshift(message);
        contacts[i].roomId = roomId;
        contacts[i].lastUpdate = message.updated_at;
        contacts[i].unreadCount = chat.unread_count;
        newMessages = contacts[i].messages;
      }
    });

    const sortedContacts = contacts.sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());

    if (state.selectedChatId === contactId) {
      newMessages = [message, ...state.messages];
      return {
        ...state,
        contacts: sortedContacts,
        messages: newMessages,
        unreadCount: currentUnreadCount,
      };
    }

    return { ...state, contacts, unreadCount: currentUnreadCount };
  }

  if (action.type === "UPDATE_CHAT") {
    const { contactId, messages } = action.payload;

    const contacts = _.cloneDeep(state.contacts);

    contacts.forEach((contact, i) => {
      if (contact && contact.id === contactId) {
        contacts[i].messages = messages;
      }
    });

    const selectedChat = _.cloneDeep(state.selectedChat);

    if (state.selectedChatId === contactId) {
      selectedChat.messages = messages;
    }

    return { ...state, contacts, selectedChat, messages };
  }

  if (action.type === "PUSH_NOTIFICATION") {
    action.payload.id = v4();
    if (!action.payload.type) action.payload.type = "info";

    return {
      ...state,
      notifications: [...state.notifications, action.payload],
    };
  }

  if (action.type === "REMOVE_NOTIFICATION") {
    const tmpState = _.cloneDeep(state);

    if (tmpState.notifications.length > 0) {
      tmpState.notifications.forEach((notification, i) => {
        /*              console.log("dis:" + i); */
        if (notification.id === action.payload.id) {
          tmpState.notifications.splice(i, 1);
        }
      });
    }
    return { ...tmpState };
  }

  if (action.type === "READ_ROOM") {
    let newSelectedChat = state.selectedChat;

    if (newSelectedChat.roomId === action.payload.roomId) {
      newSelectedChat = {
        ...newSelectedChat,
        unreadCount: 0,
      };
    }

    const currentUnreadCount = state.contacts.reduce((prev, curr) => {
      return curr
        ? curr?.roomId === action.payload.roomId
          ? prev
          : prev + Number(curr?.unreadCount)
        : prev;
    }, 0);

    return {
      ...state,
      contacts: state.contacts.map((contact) => {
        if (contact && contact?.roomId === action.payload.roomId) {
          return {
            ...contact,
            unreadCount: 0,
          };
        } else return contact;
      }),
      selectedChat: newSelectedChat,
      unreadCount: currentUnreadCount,
    };
  }

  return state;
};

const store = createStore(reducer);

export default store;
