import axios from "axios";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";

import showPasswordOff from "../../images/show-password-off.svg";
import showPasswordOn from "../../images/show-password-on.svg";

import { useNavigate } from "react-router";
import Layout from "./layout";
import "./login.css";
import { passwordSchema } from "./validations";
import { useSearchParams } from "react-router-dom";
import store from "../../store/store";
import { customFetch } from "../../utils/http";

const handleSubmit = async (e) => {
  e.preventDefault();
  if (e.target[0].value) {
    customFetch(global.apiUrl + "/user/restore", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(e.target[0].value),
    }).then(() => {
      console.log("string sended");
    });
  }
};

export default function Restore() {
  return (
    <div className="login-wrapper">
      <h1>Восстановление пароля</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Адрес электронной почты</p>
          <input type="text" />
        </label>
        <div>
          <button type="submit">Отправить</button>
          <p style={{ marginTop: "1rem", marginBottom: "0rem" }}>
            Если вы ввели корректный адрес почты, то получите новые учетные
            данные
          </p>
          <a href="/">Назад на главную</a>
        </div>
      </form>
    </div>
  );
}

async function resetPassword(data) {
  return await axios
    .post(global.apiUrl + "/user/restore/validate", data)
    .then((r) => r.data);
}

export const RestoreValidate = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  // const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // const handleBackToLogin = () => {
  //   navigate("/");
  // };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleRestore = (e) => {
    setError("");
    // setSuccess(false);
    e.preventDefault();
    const token = searchParams.get("hash");
    passwordSchema
      .validate({ password: e.target[0].value })
      .then((res) => {
        resetPassword({
          token,
          newpassword: res.password,
        })
          .then((res) => {
            if (res.response.done === "yes") {
              // setSuccess(true);
              store.dispatch({
                type: "PUSH_NOTIFICATION",
                payload: {
                  type: "success",
                  text: "Пароль успешно изменен",
                },
              });
              navigate("/");
            }
            console.log(res);
          })
          .catch((err) =>
            setError("Что-то пошло не так. Повторите попытку позже")
          );
      })
      .catch((err) => {
        setError(err.errors[0]);
      });
  };

  return (
    <Layout>
      <div className="login-wrapper">
        <h1>Восстановление пароля</h1>
        <form onSubmit={handleRestore}>
          <label>
            <p>Новый пароль</p>
            <div id="password-input--with-icon">
              <input type={showPassword ? "text" : "password"} />
              <span className="show-password" onClick={handleShowPassword}>
                <img
                  src={showPassword ? showPasswordOn : showPasswordOff}
                  alt=""
                />
              </span>
            </div>
          </label>
          <Alert variant="info">
            Максимум 10 символов, можно использовать цифры, специальные символы
            и/или латинские буквы
          </Alert>
          <div className="reset-password-button">
            <Button type="submit" className="button">
              Сохранить
            </Button>
          </div>
          {error && <span>{error}</span>}
          {/* {success && (
            <>
              <Alert variant="success">Пароль успешно изменен</Alert>
              <Button
                className={"reset-password-to-login"}
                variant="light"
                onClick={handleBackToLogin}
              >
                Войти в аккаунт
                <img className={"to-login-ico"} src={arrowRight} alt="" />
              </Button>
            </>
          )} */}
        </form>
      </div>
    </Layout>
  );
};
