import React from "react";
import IMAGES from "../../../../images/iconSet";

import { v4 } from "uuid";

import _ from "lodash";

import { Form } from "react-bootstrap";
import Checkbox from "../../../inputs/checkbox";
import Dates from "../../../inputs/dates";
import Radio from "../../../inputs/radio";
import "./style.scss";
import TypeSelect from "./typeSelect";

class TableEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: _.cloneDeep(props.data),
      dynamic: props.dynamic,
    };
  }

  addColumn = () => {
    const { state } = this;

    state.data.headers.push({
      id: v4(),
      text: "",
      type: "text",
    });

    state.data.rows.forEach((row, i) => {
      state.data.rows[i].cells.push({
        id: v4(),
        value: "",
        checked: false,
        type: "text",
      });
    });

    this.setState(state);
  };

  removeColumn = (columnIndex) => {
    const { state } = this;

    state.data.rows.forEach((row, i) => {
      state.data.rows[i].cells.splice(columnIndex, 1);
    });

    state.data.headers.splice(columnIndex, 1);

    this.setState(state);
  };

  addRow = () => {
    const { state } = this;

    const cells = [];

    state.data.headers.forEach((header) => {
      cells.push({
        id: v4(),
        parentId: header.id,
        type: header.type,
        value: "",
        checked: false,
      });
    });

    state.data.rows.push({
      id: v4(),
      cells,
    });

    this.setState(state);
  };

  removeRow = (i) => {
    const { state } = this;

    state.data.rows.splice(i, 1);

    this.setState(state);
  };

  onHeaderTextChange = (e, i) => {
    let value = e.target.value;

    const matchMultiSpaces = value.match(/\s{2,}/);

    if (matchMultiSpaces?.[0]) {
      value = value.replace(matchMultiSpaces[0], " ");
    }
    const { state } = this;
    state.data.headers[i].text = value;
    this.setState(state);
  };

  changeType = (i, type) => {
    const { state } = this;
    state.data.headers[i].type = type;
    state.data.rows.forEach((row, j) => {
      state.data.rows[j].cells[i].type = type;
    });
    this.setState(state);
  };

  onChangeCellText = (i, j, e) => {
    let value = e.target.value;

    const matchMultiSpaces = value.match(/\s{2,}/);

    if (matchMultiSpaces?.[0]) {
      value = value.replace(matchMultiSpaces[0], " ");
    }
    const { state } = this;
    state.data.rows[i].cells[j].value = value;
    this.setState(state);
  };

  onChangeCellTime = (i, j, e) => {
    let value = e.target.value;
    const { state } = this;
    state.data.rows[i].cells[j].value = value;
    this.setState(state);
  };

  onChangeCellDate = (i, j, date) => {
    const { state } = this;
    state.data.rows[i].cells[j].value = date ? date.toLocaleDateString() : "";
    this.setState(state);
  };

  onSave = () => {
    // дополнительная проверка на присвоение типа (для старых исследований)
    const checkedRows = this.state.data.rows.map((row) => {
      return {
        ...row,
        cells: row.cells.map((cell, index) => {
          if (cell.type) return cell;
          return { ...cell, type: this.state.data.headers[index].type };
        }),
      };
    });

    this.props.onSave(
      { ...this.state.data, rows: checkedRows },
      this.state.dynamic
    );
  };

  render = () => {
    return (
      <div className="mnh-tableEditor">
        <div className="mnh-tableEditor_header">
          <div className="d-flex align-items-center gap-3">
            <Form.Check
              type="switch"
              label="Динамические строки"
              id="dynamic-switch"
              checked={this.state.dynamic}
              onChange={(e) => this.setState({ dynamic: e.target.checked })}
            />
            <button
              className="mnh-tableEditor_button"
              type="button"
              onClick={this.addColumn}
            >
              <img src={IMAGES.constructor.addListItem} alt="" />
              Столбец
            </button>
          </div>
        </div>

        <div className="mnh-tableEditor_tableArea">
          <table className="mnh-tableEditor_table">
            <thead>
              <tr>
                {this.state.data.headers.map((header, i) => (
                  <td key={header.id} style={{ minWidth: "150px" }}>
                    <div
                      className="mnh-tableEditor_headerType"
                      style={{ height: 35 }}
                    >
                      {i !== 0 && (
                        <TypeSelect
                          type={header.type}
                          onChange={(type) => this.changeType(i, type)}
                          key={header.type}
                        />
                      )}
                      <div
                        className="mnh-tableEditor_headerActions"
                        style={{ height: 35 }}
                      >
                        {this.state.data.headers.length > 1 && i !== 0 ? (
                          <>
                            <button
                              type="button"
                              className="mnh-tableEditor_actionButton"
                              onClick={() => this.removeColumn(i)}
                            >
                              <img src={IMAGES.thin.bin} alt="" />
                            </button>
                            <button
                              type="button"
                              className="mnh-tableEditor_actionButton"
                              style={{ cursor: "grab" }}
                            >
                              <img src={IMAGES.thin.move} alt="" />
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="mnh-tableEditor_headerText">
                      <input
                        type="text"
                        className="mnh-tableEditor_headerInput"
                        value={header.text}
                        placeholder="Введите заголовок..."
                        onChange={(e) => this.onHeaderTextChange(e, i)}
                      />
                    </div>
                  </td>
                ))}
                <td style={{ width: "60px" }}>
                  <div
                    className="mnh-tableEditor_headerType"
                    style={{ height: "35.11px" }}
                  ></div>
                  <div
                    className="mnh-tableEditor_headerText"
                    style={{ height: "40px" }}
                  ></div>
                </td>
              </tr>
            </thead>
            <tbody>
              {this.state.data.rows.map((row, i) => (
                <tr key={row.id}>
                  {row.cells.map((cell, j) => {
                    const { type } = this.state.data.headers[j];
                    let content = cell.value;

                    if (type === "title")
                      content = (
                        <input
                          type="text"
                          className="mnh-tableEditor_cellTitle"
                          value={cell.value}
                          title={cell.value}
                          placeholder="Заголовок..."
                          onChange={(e) => this.onChangeCellText(i, j, e)}
                        />
                      );

                    if (type === "text")
                      content = (
                        <input
                          type="text"
                          className="mnh-tableEditor_cellInput"
                          value={cell.value}
                          title={cell.value}
                          placeholder="Текст..."
                          onChange={(e) => this.onChangeCellText(i, j, e)}
                        />
                      );

                    if (type === "number")
                      content = (
                        <input
                          type="number"
                          className="mnh-tableEditor_cellInput"
                          value={cell.value}
                          title={cell.value}
                          placeholder="Число..."
                          onChange={(e) => this.onChangeCellText(i, j, e)}
                        />
                      );

                    if (type === "radio")
                      content = (
                        <div class="mnh-tableEditor_cellCheck">
                          <Radio value={cell.checked} onChange={(e) => {}} />
                          <input
                            type="text"
                            className="mnh-tableEditor_cellTitle"
                            value={cell.value}
                            title={cell.value}
                            placeholder="Текст..."
                            onChange={(e) => this.onChangeCellText(i, j, e)}
                          />
                        </div>
                      );

                    if (type === "checkbox")
                      content = (
                        <div class="mnh-tableEditor_cellCheck">
                          <Checkbox value={cell.checked} onChange={(e) => {}} />
                          <input
                            type="text"
                            className="mnh-tableEditor_cellTitle"
                            value={cell.value}
                            title={cell.value}
                            placeholder="Текст..."
                            onChange={(e) => this.onChangeCellText(i, j, e)}
                          />
                        </div>
                      );

                    if (type === "date") {
                      content = (
                        <Dates
                          className="mnh-tableEditor_cellInput"
                          value={cell.value ? new Date(cell.value) : null}
                          tilte={cell.value}
                          placeholder="Дата..."
                          onDateChange={(date) =>
                            this.onChangeCellDate(i, j, date)
                          }
                          showCalendar={false}
                        />
                      );
                    }

                    if (type === "time") {
                      content = (
                        <Form.Control
                          type="time"
                          value={cell.value}
                          onChange={(e) => this.onChangeCellTime(i, j, e)}
                          className="mnh-tableEditor_cellInput"
                        />
                      );
                    }

                    return (
                      <td
                        key={cell.id}
                        valign="center"
                        className="mnh-tableEditor_cell"
                      >
                        {content}
                      </td>
                    );
                  })}
                  <td className="">
                    <div className="mnh-tableEditor_headerActions">
                      <button
                        type="button"
                        className="mnh-tableEditor_actionButton"
                        onClick={() => this.removeRow(i)}
                      >
                        <img src={IMAGES.thin.bin} alt="" />
                      </button>
                      <button
                        type="button"
                        className="mnh-tableEditor_actionButton"
                        style={{ cursor: "grab" }}
                      >
                        <img src={IMAGES.thin.move} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mnh-tableEditor_afterTable">
          <button
            className="mnh-tableEditor_button"
            type="button"
            onClick={this.addRow}
          >
            <img src={IMAGES.constructor.addListItem} alt="" />
            Строка
          </button>
        </div>

        <div className="mnh-tableEditor_footer">
          <button
            className="mnh-tableEditor_saveBtn"
            type="button"
            onClick={this.onSave}
          >
            Сохранить
          </button>
          <button className="mnh-tableEditor_removeBtn" type="button">
            <img src={IMAGES.constructor.trash} alt="" /> Удалить
          </button>
        </div>
      </div>
    );
  };
}

export default TableEditor;
