import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import "./login.css";
import showPasswordOff from "../../images/show-password-off.svg";
import showPasswordOn from "../../images/show-password-on.svg";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { ResetPassword } from "./reset-password";
import Form from "react-bootstrap/Form";
import Layout from "./layout";

const loginActions = {
  error: "login/error",
  success: "login/success",
  setPassword: "login/set-password",
  setUserName: "login/set-username",
  setIsReset: "login/reset",
};

const initialLoginState = {
  userName: "",
  password: "",
  isPending: false,
  isSuccess: false,
  isError: false,
  response: {},
  isResetWindow: false,
};

const loginReducer = (state, action) => {
  switch (action.type) {
    case loginActions.error:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        response: action.payload,
      };
    case loginActions.success:
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        response: action.payload,
      };
    case loginActions.setUserName:
      return {
        ...state,
        userName: action.payload,
      };
    case loginActions.setPassword:
      return {
        ...state,
        password: action.payload,
      };
    case loginActions.setIsReset:
      return {
        ...state,
        isResetWindow: action.payload,
      };
    default:
      return state;
  }
};

async function loginUser(credentials) {
  return await axios
    .post(global.apiUrl + "/auth/", credentials)
    .then((r) => r.data);
}

export default function Login({ setToken }) {
  const [showPassword, setShowPassword] = useState(false);
  const [loginState, dispatch] = useReducer(loginReducer, initialLoginState);
  const passwordRef = useRef();
  const userNameRef = useRef();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const response = await loginUser({
        username: loginState.userName,
        password: loginState.password,
      });
      if ("alert" in response) {
        dispatch({ type: loginActions.error, payload: response });
      } else {
        setToken(response);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginState.userName, loginState.password]
  );

  const handleSwitchPasswordVisibility = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleSetUserName = useCallback((e) => {
    const value = e.target.value.split(" ").join("");
    userNameRef.current.value = value;
    dispatch({ type: loginActions.setUserName, payload: value });
  }, []);

  const handleSetPassword = useCallback((e) => {
    const value = e.target.value.split(" ").join("");
    passwordRef.current.value = value;
    dispatch({ type: loginActions.setPassword, payload: value });
  }, []);

  const handleResetPassword = useCallback(() => {
    dispatch({ type: loginActions.setIsReset, payload: true });
  }, []);

  const handleBackToLogin = useCallback(() => {
    dispatch({ type: loginActions.setIsReset, payload: false });
  }, []);

  useEffect(() => {
    if (
      loginState.response &&
      loginState.response.alert?.focus === "password"
    ) {
      passwordRef.current.focus();
    } else if (
      loginState.response &&
      loginState.response.alert?.focus === "username"
    ) {
      userNameRef.current.focus();
    }
  }, [passwordRef, userNameRef, loginState.response]);

  return (
    <Layout>
      {loginState.isResetWindow ? (
        <ResetPassword handleBackToLogin={handleBackToLogin} />
      ) : (
        <div className="login-wrapper">
          <h1>Добро пожаловать</h1>
          <form onSubmit={handleSubmit} id="autorize">
            {/*  <label>
              <p>Email</p>
              <input
                ref={userNameRef}
                value={loginState.userName}
                className="form-input"
                type="text"
                onChange={handleSetUserName}
              />
            </label> */}
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={userNameRef}
                /* value={loginState.userName} */
                defaultValue={loginState.userName}
                name="user_email"
                id="inputLogin"
                className="form-input"
                type="text"
                autoComplete="text"
                placeholder="Введите email"
                onChange={handleSetUserName}
              />
            </Form.Group>
            <label className="forgot-password">
              <p>Пароль</p>
              <div className="d-flex flex-column">
                <div id="password-input--with-icon">
                  <input
                    ref={passwordRef}
                    id="inputPassword"
                    value={loginState.password}
                    className="form-input"
                    type={showPassword ? "text" : "password"}
                    onChange={handleSetPassword}
                    autoComplete="true"
                  />
                  <span
                    className="show-password"
                    onClick={handleSwitchPasswordVisibility}
                  >
                    <img
                      src={showPassword ? showPasswordOn : showPasswordOff}
                      alt=""
                    />
                  </span>
                </div>
                <span
                  className="restore-password"
                  onClick={handleResetPassword}
                >
                  Забыли пароль?
                </span>
              </div>
            </label>
            <div>
              <button className="button" type="submit">
                Войти
              </button>
            </div>

            <div className="use-term">
              Нажимая кнопку «Войти» и пользуясь данным сайтом, вы даете
              согласие на{" "}
              <a
                target="_blank"
                download
                href="/Согласие на ОПД.docx"
                rel="noreferrer"
              >
                обработку персональных данных
              </a>{" "}
              и соглашаетесь с{" "}
              <a
                target="_blank"
                download
                href="/Политика_Конфиденциальности.docx"
                rel="noreferrer"
              >
                политикой конфиденциальности
              </a>
              .
            </div>

            {loginState.isError &&
              !loginState.isPending &&
              loginState.response && (
                <Alert
                  variant={loginState.response.alert?.type ?? "warning"}
                  dismissible={
                    !!loginState.response.alert?.control?.find(
                      (el) => el.closeBtn
                    )
                  }
                >
                  {loginState.response.alert?.title && (
                    <Alert.Heading>
                      {loginState.response.alert.title}
                    </Alert.Heading>
                  )}
                  {loginState.response.alert?.description}
                </Alert>
              )}
          </form>
        </div>
      )}
    </Layout>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
