import React from 'react';
import {IIcons} from './types';

export const ViewShow = ({width = 10, height = 8, color = '#000'}: IIcons) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1574_17368)">
                <path d="M8 1.96667C10.7564 1.96667 13.2145 3.52867 14.4145 6C13.2145 8.47133 10.7636 10.0333 8 10.0333C5.23636 10.0333 2.78545 8.47133 1.58545 6C2.78545 3.52867 5.24364 1.96667 8 1.96667ZM8 0.5C4.36364 0.5 1.25818 2.78067 0 6C1.25818 9.21933 4.36364 11.5 8 11.5C11.6364 11.5 14.7418 9.21933 16 6C14.7418 2.78067 11.6364 0.5 8 0.5ZM8 4.16667C9.00364 4.16667 9.81818 4.988 9.81818 6C9.81818 7.012 9.00364 7.83333 8 7.83333C6.99636 7.83333 6.18182 7.012 6.18182 6C6.18182 4.988 6.99636 4.16667 8 4.16667ZM8 2.7C6.19636 2.7 4.72727 4.18133 4.72727 6C4.72727 7.81867 6.19636 9.3 8 9.3C9.80364 9.3 11.2727 7.81867 11.2727 6C11.2727 4.18133 9.80364 2.7 8 2.7Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_1574_17368">
                    <rect width={width} height={height - 1} fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
