import { ICondition } from "../researches/builderResearches/modalQuestionTerms";
import { IConditionMap } from "./getConditionsMap";

type IValues = Record<string, number | string | string[]> | string[];

const isSameQuestions = (
  ourId: string | number,
  compareId: string | number
) => {
  return ourId === compareId;
};

export const checkCondition: (
  conditions: ICondition[],
  values: IValues,
  allConditions: IConditionMap,
  elemId: string | number,
  item: any,
  allItemsId: Array<string | number>
) => boolean = (
  conditions,
  values,
  allConditions,
  elemId,
  item,
  allItemsId
) => {
  let result = true;
  let deepResult = true;

  let conditionsDeepList = conditions.map((el) => el.questionId);

  conditions.forEach((condition) => {
    if (
      condition.questionId !== undefined &&
      condition.answer &&
      values[condition.questionId] !== undefined
    ) {
      if (condition.equals === "range" && condition.answer.type === "range") {
        if (
          Number(values[condition.questionId]) > condition.answer.to ||
          Number(values[condition.questionId]) < condition.answer.from
        ) {
          result = false;
        }
      } else if (condition.equals && condition.answer.type !== "range") {
        const isArray = Array.isArray(values[condition.questionId]);
        if (
          isArray &&
          values[condition.questionId].indexOf(condition.answer.value) === -1
        ) {
          result = false;
        } else if (
          !isArray &&
          condition.answer.value !== values[condition.questionId]
        ) {
          result = false;
        }
      } else if (condition.answer.type !== "range" && !condition.equals) {
        const isArray = Array.isArray(values[condition.questionId]);
        if (
          isArray &&
          (values[condition.questionId].indexOf(condition.answer.value) !==
            -1 ||
            values[condition.questionId].length === 0)
        ) {
          result = false;
        } else if (
          !isArray &&
          condition.answer.value === values[condition.questionId]
        ) {
          result = false;
        }
      }
    } else if (
      condition.questionId !== undefined &&
      condition.answer &&
      values[condition.questionId] === undefined
    ) {
      result = false;
    }
  });

  conditionsDeepList.forEach((questionId) => {
    if (allConditions[elemId]) {
      /*  console.log(allConditions[questionId]); */
      allConditions[elemId].conditions.forEach((condition) => {
        if (!isSameQuestions(questionId, condition.questionId)) {
          /*     console.log(condition.questionId); */
          if (
            condition.questionId !== undefined &&
            condition.answer &&
            values[condition.questionId] !== undefined
          ) {
            if (
              condition.equals === "range" &&
              condition.answer.type === "range"
            ) {
              if (
                Number(values[condition.questionId]) > condition.answer.to ||
                Number(values[condition.questionId]) < condition.answer.from
              ) {
                deepResult = false;
              }
            } else if (condition.equals && condition.answer.type !== "range") {
              const isArray = Array.isArray(values[condition.questionId]);
              if (
                isArray &&
                values[condition.questionId].indexOf(condition.answer.value) ===
                  -1
              ) {
                deepResult = false;
              } else if (
                !isArray &&
                condition.answer.value !== values[condition.questionId]
              ) {
                deepResult = false;
              }
            } else if (condition.answer.type !== "range" && !condition.equals) {
              const isArray = Array.isArray(values[condition.questionId]);
              if (
                isArray &&
                (values[condition.questionId].indexOf(
                  condition.answer.value
                ) !== -1 ||
                  values[condition.questionId].length === 0)
              ) {
                deepResult = false;
              } else if (
                !isArray &&
                condition.answer.value === values[condition.questionId]
              ) {
                deepResult = false;
              }
            }
          } else if (
            condition.questionId !== undefined &&
            condition.answer &&
            !values[condition.questionId]
          ) {
            deepResult = false;
          }
        }
      });
    }
  });

  if (!deepResult) {
    result = false;
  }

  return result;
};
