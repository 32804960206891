import type { FC } from "react";
import { getDecimalPointsCount } from "../../utils/helpers";
import { Alert, Form } from "react-bootstrap";
import parse from "html-react-parser";
import { QuestionDocuments } from "./QuestionDocuments";

interface IParsedNumberProps {
  items: {
    id: string;
    options: {
      title: string;
      note: string;
      required: boolean;
      files?: {
        target: string;
        source: string;
      }[];
      conditions: {
        answer: { type: string; value: string };
        equals: boolean;
        id: string;
        questionId: string;
      }[];
      data: {
        isLimit: boolean;
        limitFrom: string | null;
        limitTo: string | null;
        commasLimit: number;
        unit?: string;
      };
      numberCondition?: {
        condition: string;
        id: string;
        questionId: string;
      };
    };
  };
  counterTab: number;
  sub?: string;
  disabled: boolean;
  value: string | number;
  defaultValue: string;
  condititonQuestionValue?: string;
  errors?: string;
  emptyRequired?: boolean;
  conditionLabel?: string;
}

export const ParsedNumber: FC<IParsedNumberProps> = ({
  items,
  value,
  condititonQuestionValue,
  counterTab,
  sub,
  disabled,
  defaultValue,
  errors,
  emptyRequired,
  conditionLabel,
}) => {
  const decimalPointsCount = getDecimalPointsCount(Number(value));

  const isInvalidDecimalPointCount =
    items.options.data.commasLimit !== undefined &&
    items.options.data.commasLimit < decimalPointsCount;

  const getInvalidNumberCondition = () => {
    if (items.options.numberCondition) {
      const condition = items.options.numberCondition.condition;
      const conditionValue = Number(condititonQuestionValue);
      const itemValue = Number(value);

      if (condition === "more" && itemValue <= conditionValue) {
        return true;
      }
      if (condition === "less" && itemValue >= conditionValue) {
        return true;
      }
      if (condition === "equal" && itemValue !== conditionValue) {
        return true;
      }
      if (condition === "less_or_equal" && itemValue > conditionValue) {
        return true;
      }
      if (condition === "more_or_equal" && itemValue < conditionValue) {
        return true;
      }
    }
  };

  const invalidNumberCondition = getInvalidNumberCondition();

  const isLimit = items.options.data.isLimit;
  const limitFrom = Number(items.options.data.limitFrom);
  const limitTo = Number(items.options.data.limitTo || 99999999);

  const notInLimit =
    isLimit && (limitFrom > Number(value) || limitTo < Number(value));

  const isFieldInvalid =
    ((typeof value === "number" || value) && notInLimit) ||
    isInvalidDecimalPointCount ||
    invalidNumberCondition ||
    emptyRequired;

  return (
    <div className={"parseNumber answer-wrapper"} key={items.id}>
      <div className="counterTab">
        <span className="form-counter">
          {counterTab}
          {sub ? "." + sub : ""}
        </span>{" "}
        <div className="parseNumber-title">{items.options.title}</div>
        {items.options.required && <span style={{ color: "red" }}>*</span>}
      </div>
      {items.options.files && items.options.files.length > 0 && (
        <QuestionDocuments files={items.options.files} />
      )}
      {items.options.note && (
        <Alert variant="danger">{parse(items.options.note)}</Alert>
      )}
      {conditionLabel && (
        <p className="mh-constructor_numberLimit">{conditionLabel}</p>
      )}

      <div>
        <div className="mh-constructor_number">
          <div
            className="mh-constructor_numberArea"
            style={{
              position: "relative",
            }}
          >
            <Form.Control
              required={items.options.required ? true : false}
              name={items.id}
              disabled={disabled}
              id={items.id}
              type="number"
              onWheel={(e) => e.currentTarget.blur()}
              defaultValue={defaultValue}
              className="mh-constructor_numberInput"
              isInvalid={isFieldInvalid}
            />
            <Form.Control.Feedback
              type="invalid"
              tooltip
              style={{
                top: "60px",
              }}
            >
              {(typeof value === "number" || value) && notInLimit
                ? `Введите число в диапазоне
                      ${items.options.data.limitFrom
                  ? `от ${items.options.data.limitFrom}`
                  : "от 0"
                }
                      ${items.options.data.limitTo &&
                `до ${items.options.data.limitTo}`
                }`
                : isInvalidDecimalPointCount
                  ? `Количество знаков после запятой должно быть не более ${items.options.data.commasLimit}`
                  : invalidNumberCondition
                    ? "Значение не удовлетворяет условию"
                    : errors || emptyRequired
                      ? errors
                        ? errors
                        : "Вопрос обязателен к заполнению."
                      : ""}
            </Form.Control.Feedback>
            <span className="mh-constructor_numberUnit">
              {items.options.data.unit}
            </span>
          </div>
          {isLimit && (
            <p className="mh-constructor_numberLimit">
              Введите число в диапазоне{" "}
              {items.options.data.limitFrom
                ? `от ${items.options.data.limitFrom}`
                : "от 0"}
              {items.options.data.limitTo &&
                ` до ${items.options.data.limitTo}`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
