import React from "react";
import IMAGES from "../../../../images/iconSet";

import './style.scss';

class EmptyField extends React.Component {
    render () {
        return (
            <div className="drag--field" style={this.props.style}>

                {
                    this.props.onClick ? (
                        <button className="drag--fieldButton" type="button" onClick={this.props.onClick}><img src={IMAGES.constructor.addBlock} /></button>
                    ) : ""
                }

                <div
                    className="drag--fieldArea"
                    onDragOver={(e) => this.props.onDragOver(e)}
                    onDrop={(e) => this.props.onDrop(e)}
                >
                    Перенесите вопрос в это поле
                </div>
            </div>
        );
    }
}

export default EmptyField;