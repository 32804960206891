import type { FC } from "react";
import images from "../../../../images/iconSet";
import "./DeleteSelectedModal.scss";

interface IDeleteSelectedModalProps {
  count: number;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteSelectedModal: FC<IDeleteSelectedModalProps> = ({
  open,
  onClose,
  count,
  onDelete,
}) => {
  if (!open) return null;

  return (
    <div className="delete-selected-modal">
      <div className="delete-selected-modal--info">
        <div>Выбрано: {count}</div>
        <img
          className="delete-selected-modal--info--close"
          src={images.toast.close}
          onClick={onClose}
          alt=""
        />
      </div>
      <button
        className="delete-selected-modal--delete"
        type="button"
        onClick={onDelete}
      >
        <img src={images.constructor.trash} alt="" /> Удалить
      </button>
    </div>
  );
};
