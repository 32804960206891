import "./index.scss";
import React from "react";
import { Link } from "react-router-dom";

import PopoverSubItem from "./popoverSubItem";

import icons from "../../images/iconSet";

class Popover extends React.Component {
   render() {
      const arrowStyle = {
         transform: "rotate(" + (this.props.isActive ? "90deg" : "0deg") + ")",
      };

      return (
         <li className={
            this.props.isActive ?
               "popover--head active" :
               "popover--head"
         }/* style={{width: + 100/this.props.count + "%"}}*/
         >
            <div className="popover--wrapper" onClick={this.props.onClick}>
               {this.props.subelemnts && this.props.subelemnts.length === 0 ? (
                  <>
                     <div className="popover--wrapper--header">
                        <Link to={this.props.link} className="popover--text semi--bold">
                           <img src={this.props.icon} alt="" className="popover--head--icon" />

                           <span className="popover--title">{this.props.header}</span>
                        </Link>
                     </div>
                     {this.props.notification === "0" ? (
                        ""
                     ) : (
                        <span className="message--count" value={this.props.notification} />
                     )}
                  </>
               ) : (
                  <>
                     <div className="popover--wrapper--header" onClick={this.props.onClick}>
                        <Link to={this.props.link} className="popover--text semi--bold">
                           <img src={this.props.icon} alt="" className="popover--head--icon" />
                           {/* <span className="popover--text semi__bold">{this.props.header}</span> */}

                           <span className="popover--title">{this.props.header}</span>
                        </Link>
                     </div>
                     <img src={icons.icons.chewron} alt="" className="popover--head--arrow" style={arrowStyle} />
                  </>
               )}
            </div>
            {this.props.subelemnts && this.props.subelemnts.length === 0 ? (
               ""
            ) : (
               <ul className={"popover--sublist" + (this.props.isActive ? "" : " popover--sublist__hidden")}>
                  {this.props.subelemnts.map((item, i) => (
                     <PopoverSubItem text={item.title} url={item.url} key={i} />
                  ))}
               </ul>
            )}
         </li>
      );
   }
}

export default Popover;
