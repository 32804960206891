const IMAGES = {
  logo: require("./logo.svg").default,
  menu: {
    home: require("./menu/home.svg").default,
    forms: require("./menu/forms.svg").default,
    users: require("./menu/users.svg").default,
    customers: require("./menu/customers.svg").default,
    researhes: require("./menu/researhes.svg").default,
    messages: require("./menu/messages.svg").default,
    manuals: require("./menu/manuals.svg").default,
    reports: require("./menu/reports.svg").default,
    servera: require("./icons/server_a.svg").default,
  },
  thin: {
    text: require("./icons_thin/text.svg").default,
    que: require("./icons_thin/que.svg").default,
    bell: require("./icons_thin/bell.svg").default,
    copy: require("./icons_thin/copy.svg").default,
    bin: require("./icons_thin/bin.svg").default,
    move: require("./icons_thin/move.svg").default,
  },
  icons: {
    loader: require("./icons/loader.svg").default,
    chewron: require("./icons/chewron.svg").default,
    out: require("./icons/out.svg").default,
    message: require("./icons/messages_header.svg").default,
    notification: require("./icons/notification.svg").default,
    dots: require("./icons/dots.svg").default,
    chapter: require("./icons/chapter.svg").default,
    file: require("./icons/file.svg").default,
    table: require("./icons/table.svg").default,
    number: require("./icons/number.svg").default,
    checkbox: require("./icons/checkbox.svg").default,
    radio: require("./icons/radio.svg").default,
    list: require("./icons/list.svg").default,
    bar: require("./icons/bar.svg").default,
    text: require("./icons/text.svg").default,
    date: require("./icons/date.svg").default,
    time: require("./icons/clock.svg").default,
    plus: require("./icons/plus.svg").default,
    copy: require("./icons/copy.svg").default,
    modalClose: require("./icons/modalClose.svg").default,
    closeRed: require("./icons/closeRed.svg").default,
    download: require("./icons/download.svg").default,
    eye: require("./icons/eye.svg").default,
    eyeSlash: require("./icons/eye-slash.svg").default,
  },
  home: {
    forms: require("./forms.svg").default,
    customers: require("./customers.svg").default,
    researhes: require("./Исследования.svg").default,
    doctors: require("./Врачи (белая).svg").default,
  },
  constructor: {
    addBlock: require("./constructor/addBlock.svg").default,
    addFromModal: require("./constructor/addFromModal.svg").default,
    checbox: require("./constructor/checkbox.svg").default,
    listArrow: require("./constructor/list-arrow.svg").default,
    condition: require("./constructor/condition.svg").default,
    conditionWhite: require("./constructor/conditionWhite.svg").default,
    bell: require("./constructor/bell.svg").default,
    removeSelectedItem: require("./constructor/removeSelectedItem.svg").default,
    search: require("./constructor/search.svg").default,
    closeSearch: require("./constructor/closeSearch.svg").default,
    calendar: require("./constructor/calendar.svg").default,
    arrow: require("./constructor/arrow.svg").default,
    trash: require("./constructor/trash.svg").default,
    row: require("./constructor/row.svg").default,
    column: require("./constructor/column.svg").default,
    addListItem: require("./constructor/addListItem.svg").default,
    table: require("./constructor/table.svg").default,
    calculator: require("./constructor/calculator.svg").default,
  },
  toast: {
    success: require("./toast/success.svg").default,
    info: require("./toast/info.svg").default,
    warning: require("./toast/warning.svg").default,
    error: require("./toast/error.svg").default,
    close: require("./toast/close.svg").default,
  },
};

export default IMAGES;
