import "./index.scss";
import React from "react";
import Wrapper from "../wrapper";

import IMAGES from "../../../../images/iconSet";

class Textarea extends React.Component {
   render() {
      return <textarea className="itme--textarea" placeholder="Текст вопроса" disabled />;
   }
}

export default Textarea;
