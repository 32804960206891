export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return "0";
  } else {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["байт", "кб", "мб", "гб", "тб"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}

export function getDecimalPointsCount(number: number) {
  return number.toString().includes(".")
    ? number.toString().split(".").pop().length
    : 0;
}
