function SendIcon() {
   return (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M13.3151 12.1968L5.78308 13.4528C5.69649 13.4673 5.61522 13.5043 5.54746 13.5601C5.47971 13.6159 5.42784 13.6886 5.39708 13.7708L2.80008 20.7278C2.55208 21.3678 3.22108 21.9778 3.83508 21.6708L21.8351 12.6708C21.9598 12.6086 22.0646 12.5128 22.1379 12.3943C22.2112 12.2758 22.25 12.1392 22.25 11.9998C22.25 11.8605 22.2112 11.7239 22.1379 11.6054C22.0646 11.4868 21.9598 11.3911 21.8351 11.3288L3.83508 2.32884C3.22108 2.02184 2.55208 2.63284 2.80008 3.27184L5.39808 10.2288C5.42869 10.3113 5.4805 10.3841 5.54827 10.4401C5.61604 10.4962 5.69737 10.5333 5.78408 10.5478L13.3161 11.8028C13.3624 11.811 13.4044 11.8352 13.4347 11.8712C13.465 11.9072 13.4816 11.9528 13.4816 11.9998C13.4816 12.0469 13.465 12.0925 13.4347 12.1285C13.4044 12.1645 13.3624 12.1887 13.3161 12.1968H13.3151Z"
            fill="#8BABD8"
         />
      </svg>
   );
}

export default SendIcon;
