import React from "react";
import "./index.scss";

import { Form } from "react-bootstrap";
import Checkbox from "../../../inputs/checkbox";
import Dates from "../../../inputs/dates";
import Radio from "../../../inputs/radio";

class TableNote extends React.Component {
  render() {
    const { headers, rows } = this.props.data;

    return (
      <table className="mh-constructorTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <td key={header.id}>{header.text}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              {row.cells.map((cell, j) => (
                <td key={cell.id} valign="middle">
                  {headers[j].type === "title" ? (
                    cell.value
                  ) : headers[j].type === "radio" ? (
                    <div className="mh-constructorTable_cellCheck">
                      <Radio defaultValue={cell.checked} />
                      {cell.value}
                    </div>
                  ) : headers[j].type === "text" ? (
                    <input
                      type="text"
                      className=""
                      style={{ width: "100%", height: 32 }}
                      defaultValue={cell.value}
                    />
                  ) : headers[j].type === "checkbox" ? (
                    <div className="mh-constructorTable_cellCheck">
                      <Checkbox defaultValue={cell.checked} />
                      {cell.value}
                    </div>
                  ) : headers[j].type === "number" ? (
                    <input
                      type="text"
                      className=""
                      style={{ width: "100%", height: 32 }}
                      defaultValue={cell.value}
                    />
                  ) : headers[j].type === "date" ? (
                    <Dates value={cell.value ? new Date(cell.value) : null} />
                  ) : headers[j].type === "time" ? (
                    <Form.Control
                      style={{ height: 32, border: "1px solid #eee" }}
                      type="time"
                      defaultValue={cell.value}
                    />
                  ) : (
                    ""
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default TableNote;
