import "./index.scss";
import React from "react";

import "./index.scss";

import Select from "../../../inputs/select";

class ListNote extends React.Component {
  render() {
    return (
      <div className="mh-constructor_list">
        <Select
          items={this.props.data.map((el) => el.value)}
          enabled={false}
          style={{ width: "260px" }}
        />
      </div>
    );
  }
}

export default ListNote;
