import { Table } from "./table";
import { store } from "./store";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.scss";
import { Provider } from "react-redux";
import { ITableProps } from "./types";

function index({
  url,
  token,
  updateCount,
  hideFilters,
  hideTabs,
}: ITableProps) {
  /*     console.log("tabke", supersetToken); */
  return (
    <Provider store={store}>
      <Table
        url={url}
        token={token}
        updateCount={updateCount}
        hideFilters={hideFilters}
        hideTabs={hideTabs}
      />
    </Provider>
  );
}

export default index;
