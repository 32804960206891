import "./index.scss";
import "../../styles/fonts.scss";
import React from "react";
import { Link } from "react-router-dom";

class QuickActionButton extends React.Component {
   render() {
      return (
         <Link to={this.props.link} className="quick--action--button">
            <span className="quick--action--button--text regular">{this.props.text}</span>
         </Link>
      );
   }
}

export default QuickActionButton;
