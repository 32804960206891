import React from 'react';
import {IIcons} from './types';

export const ThreeDots = ({width = 5, height = 16, color = '#000'}: IIcons) => {
    return (
        <svg width={width} height={height} viewBox="0 0 5 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.25 1.875C4.25 2.841 3.466 3.625 2.5 3.625C1.534 3.625 0.75 2.841 0.75 1.875C0.75 0.909 1.534 0.125 2.5 0.125C3.466 0.125 4.25 0.909 4.25 1.875Z"
                fill={color}/>
            <path
                d="M2.5 6.25C1.534 6.25 0.75 7.034 0.75 8C0.75 8.966 1.534 9.75 2.5 9.75C3.466 9.75 4.25 8.966 4.25 8C4.25 7.034 3.466 6.25 2.5 6.25Z"
                fill={color}/>
            <path
                d="M2.5 12.375C1.534 12.375 0.75 13.159 0.75 14.125C0.75 15.091 1.534 15.875 2.5 15.875C3.466 15.875 4.25 15.091 4.25 14.125C4.25 13.159 3.466 12.375 2.5 12.375Z"
                fill={color}/>
        </svg>
    );
};
