import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function VerticallyModal(props) {

  const [passwordType, setPasswordType] = useState({ prevPassword: "", password: "" })
  const [avatarType, setAvatarType] = useState({ avatar: "" })
  const [emailType, setEmailType] = useState({ email: "", password: "" })

  console.log(passwordType, emailType, avatarType)

  const handleEmailChange = () => {
    props.changeUserInfo(props.id, { id: props.id, email: emailType.email }, props.setError)
    props.setEmail(emailType.email)
    props.onHide()
  }

  const changeEmailJSX = <>
    <Modal.Header >
      <Modal.Title id="contained-modal-title-vcenter">
        Изменить email
    </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Email адрес</Form.Label>
          <Form.Control
            onChange={(e) => setEmailType({ ...emailType, email: e.target.value })}
            type="email"
            placeholder="name@example.com"
            autoFocus
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Пароль</Form.Label>
          <Form.Control
            onChange={(e) => setEmailType({ ...emailType, password: e.target.value })}
            type="password"
            placeholder="Пароль"
          // autoFocus
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={handleEmailChange}>Сохранить</Button>
      <Button onClick={props.onHide}>Закрыть</Button>
    </Modal.Footer></>


  const changePasswordJSX = <>
    <Modal.Header >
      <Modal.Title id="contained-modal-title-vcenter">
        Изменнить пароль
  </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Пароль</Form.Label>
          <Form.Control
            onChange={(e) => setPasswordType({ ...passwordType, prevPassword: e.target.value })}
            type="password"
            placeholder="Пароль"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Новый пароль</Form.Label>
          <Form.Control
            onChange={(e) => setPasswordType({ ...passwordType, password: e.target.value })}
            type="password"
            placeholder="Новый пароль"
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Сохранить</Button>
      <Button onClick={props.onHide}>Закрыть</Button>
    </Modal.Footer>
  </>

  const changeAvatarJSX = <>
    <Modal.Header >
      <Modal.Title id="contained-modal-title-vcenter">
        Изменить аватар
</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>выберите аватар</Form.Label>
          <Form.Control
            onChange={(e) => setAvatarType({ avatar: e.target.value })}
            style={{ padding: "2px 3px" }}
            type="file"
            placeholder="выбрать"
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Сохранить</Button>
      <Button onClick={props.onHide}>Закрыть</Button>
    </Modal.Footer>
  </>



  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.type === "password" ? changePasswordJSX : props.type === "email" ? changeEmailJSX : props.type === "avatar" && changeAvatarJSX}
    </Modal>
  );
}

export default VerticallyModal
