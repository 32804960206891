import "./index.scss";
import "../../../styles/fonts.scss";
import React from "react";
import { Link } from "react-router-dom";

import QuickActionButton from "../../../components/quickActionsButton";

class NotFound extends React.Component {
   render() {
      return (
         <div className="error">
            <div className="error--wrapper">
               <div className="error--code">
                  <h3 className="error--code--title">Ooooops!</h3>
               </div>
               <div className="error--decription">
                  <p className="error--decription-text regular">
                     This page is not ready yet, but it will appear for you soon :)
                  </p>
               </div>
               <QuickActionButton link="/" text="Go Home" />
            </div>
         </div>
      );
   }
}

export default NotFound;
