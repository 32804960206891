import "./index.scss";
import React from "react";
import Dates from "../../../inputs/dates";

class DateNote extends React.Component {
   render() {
      const {data} = this.props;

      let dateFrom, dateTo;

      if (data.limitFrom) {
         dateFrom = (new Date(data.limitFrom)).toLocaleDateString("ru-RU");
      }
      if (data.limitTo) {
         dateTo = (new Date(data.limitTo)).toLocaleDateString("ru-RU");
      }
      
      return (
         <div className="mh-constructor_number">
            <div className="mh-constructor_numberArea">
               <Dates
                  style={{maxWidth: "300px"}}
                  className="mh-dates_input__filed"
                  dateFrom={data.isLimit && data.limitFrom ? data.limitFrom : ((new Date()).getTime())}
                  disasbled={true}
               />
            </div>
            {
               data.isLimit ? (
                  <p className="mh-constructor_numberLimit">
                     Введите дату {
                        dateFrom && dateTo ? "в диапазоне от "+dateFrom+" до "+dateTo : ""
                     }{
                        dateFrom && !dateTo ? "начиная с "+dateFrom : ""
                     }{
                        !dateFrom && dateTo ? "до "+dateTo : ""
                     }
                  </p>
               ) : ""
            }
         </div>
      );
   }
}

export default DateNote;
