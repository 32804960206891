import { FC, useCallback, useMemo, useState } from "react";

import { v4 } from "uuid";
import IMAGES from "../../../../images/iconSet";
import { ConditionItem } from "./ConditionItem";
import styles from "./ModalQuestionTerms.module.scss";

export interface ICondition {
  questionId?: string | number | undefined; // id Вопроса
  equals: boolean | "range"; // Тип сравнения (true = равно, false = не равно, "range" = В диапазоне только для чисел)
  answer?: // Ответ на вопрос
  | {
        type: "id" | "value"; // "id" для значений с id, "value" для значений введенных пользователем
        value: string | number | undefined; // Значение
      }
    /*
        Тип ниже может быть только для числовых значений с equals: "range";
       */
    | {
        type: "range"; // Диапазон
        from: number; // Начальное значение
        to: number; // Конечное значение
      };
  id: string; // id Условия
}

export interface ILayoutItem {
  id: string;
  initialValue: boolean;
  text: string;
}

type IFormDataBaseOptions = {
  note: string;
  required: boolean;
  title: string;
  conditions: ICondition[];
  notification: {
    isNotify: boolean;
    notifyDate: any;
  };
};

type IFormDataCheckbox = {
  id: number;
  type: "checkbox";
  options: {
    data: {
      layout: string;
      items: ILayoutItem[];
    };
  } & IFormDataBaseOptions;
};

type IFormDataRadio = {
  id: number;
  type: "radio";
  options: {
    data: {
      layout: string;
      items: ILayoutItem[];
    };
  } & IFormDataBaseOptions;
};

type IFormDataList = {
  id: number;
  type: "list";
  options: {
    data: Array<{
      id: string;
      value: string;
    }>;
  } & IFormDataBaseOptions;
};

type IFormDataScale = {
  id: number;
  type: "scale";
  options: {
    data: Array<{
      id: string;
      value: string;
    }>;
  } & IFormDataBaseOptions;
};

type IFormDataNumber = {
  id: number;
  type: "number";
  options: {
    data: {
      isLimit: boolean;
      limitFrom: number;
      limitTo: number;
      unit: string;
    };
  } & IFormDataBaseOptions;
};

type IFormDataTextArea = {
  id: number;
  type: "textarea";
  options: {} & IFormDataBaseOptions;
};

type IFormDataTable = {
  id: number;
  type: "table";
  options: { data: any } & IFormDataBaseOptions;
};

export type IDefaultItems =
  | IFormDataNumber
  | IFormDataTextArea
  | IFormDataCheckbox
  | IFormDataRadio
  | IFormDataList
  | IFormDataScale
  | IFormDataTable;

export interface IFormDataNumberIndexed extends IFormDataNumber {
  index: string;
  visitIndex: number;
}
export interface IFormDataTextAreaIndexed extends IFormDataTextArea {
  index: string;
  visitIndex: number;
}
export interface IFormDataCheckboxIndexed extends IFormDataCheckbox {
  index: string;
  visitIndex: number;
}
export interface IFormDataRadioIndexed extends IFormDataRadio {
  index: string;
  visitIndex: number;
}
export interface IFormDataListIndexed extends IFormDataList {
  index: string;
  visitIndex: number;
}
export interface IFormDataScaleIndexed extends IFormDataScale {
  index: string;
  visitIndex: number;
}
export interface IFormDataTableIndexed extends IFormDataTable {
  index: string;
  visitIndex: number;
}

export type IDefaultItemsIndexed =
  | IFormDataNumberIndexed
  | IFormDataTextAreaIndexed
  | IFormDataCheckboxIndexed
  | IFormDataRadioIndexed
  | IFormDataListIndexed
  | IFormDataScaleIndexed
  | IFormDataTableIndexed;

type IFormDataChapter = {
  id: number;
  type: "chapter";
  options: {
    conditions: ICondition[];
    notification: {
      isNotify: boolean;
      notifyDate: any;
    };
    title: string;
    data: {
      items: Array<IDefaultItems>;
    };
  };
};

export type IFormDataItems = IDefaultItems | IFormDataChapter;

interface IFormData {
  items: IFormDataItems[];
}

interface Props {
  questionId: number;
  currentData: IFormData[];
  action: () => void;
  setConditionsToBlock: (conditions: ICondition[]) => void;
  initialConditions: ICondition[];
}

export const ModalQuestionTerms: FC<Props> = ({
  questionId,
  currentData,
  action,
  setConditionsToBlock,
  initialConditions,
}) => {
  const availableVisits: number[] = useMemo(() => {
    return currentData.map((el, index) => index);
  }, [currentData]);
  const availableAnswers: IDefaultItemsIndexed[] = useMemo(() => {
    let result: IDefaultItemsIndexed[] = [];
    currentData.forEach((itemList, visitIndex) => {
      itemList.items.forEach((dataItem, index) => {
        if (!(dataItem.type === "chapter" && dataItem.id === questionId)) {
          if (dataItem.type === "chapter") {
            dataItem.options.data.items.forEach((chapterItem, chapterIndex) => {
              if (chapterItem.id !== questionId) {
                result.push({
                  ...chapterItem,
                  index: `${index + 1}.${chapterIndex + 1}`,
                  visitIndex: visitIndex + 1,
                });
              }
            });
          } else {
            if (dataItem.id !== questionId) {
              result.push({
                ...dataItem,
                index: `${index + 1}`,
                visitIndex: visitIndex + 1,
              });
            }
          }
        }
      });
    });
    return result.filter((q) => q.type !== "table");
  }, [currentData, questionId]);

  const [conditionsForCurrentAnswer, setConditions] = useState<ICondition[]>(
    initialConditions && initialConditions.length > 0
      ? initialConditions
      : [
          {
            equals: true,
            id: v4(),
          },
        ]
  );

  const handleAddCondition = useCallback(() => {
    setConditions((prev) => [
      ...prev,
      {
        equals: true,
        id: v4(),
      },
    ]);
  }, []);

  const handleEditCondition = useCallback((condition: ICondition) => {
    setConditions((prev) => [
      ...prev.map((el) => (el.id === condition.id ? condition : el)),
    ]);
  }, []);

  const handleDeleteCondition = useCallback((id: string) => {
    setConditions((prev) => prev.filter((el) => el.id !== id));
  }, []);

  const haveRangeCondition = useMemo(
    () => !!conditionsForCurrentAnswer.find((x) => x.equals === "range"),
    [conditionsForCurrentAnswer]
  );

  const handleSave = useCallback(
    (conditions: ICondition[]) => {
      setConditionsToBlock(conditions);
    },
    [setConditionsToBlock]
  );

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Вопрос будет показан если</span>
      <div className={styles.conditionList}>
        {conditionsForCurrentAnswer.map((condition, index) => (
          <ConditionItem
            condition={condition}
            key={condition.id}
            availableAnswers={availableAnswers}
            handleEditCondition={handleEditCondition}
            handleDeleteCondition={handleDeleteCondition}
            index={index}
            conditionsCount={conditionsForCurrentAnswer.length}
            haveRangeCondition={haveRangeCondition}
            availableVisits={availableVisits}
          />
        ))}
        <button
          className={`${styles.button} ${styles.btn_condition}`}
          onClick={(e) => {
            e.preventDefault();
            handleAddCondition();
          }}
        >
          <img src={IMAGES.constructor.addListItem} alt="" /> Условие
        </button>
      </div>
      <div className={styles.buttonsWrapper}>
        <button
          className={styles.button}
          type={"button"}
          onClick={(e) => {
            e.preventDefault();
            handleSave(conditionsForCurrentAnswer);
          }}
        >
          Добавить
        </button>
        <button
          className={`${styles.button} ${styles.delete}`}
          type={"button"}
          onClick={(e) => {
            e.preventDefault();
            handleSave([]);
            action();
          }}
        >
          <img src={IMAGES.constructor.trash} alt="" /> Удалить
        </button>
      </div>
    </div>
  );
};
