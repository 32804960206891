import { Button, Container, Row, Col, Tabs, Tab, Alert } from "react-bootstrap";
import { TestTable } from "../../components/testTable.js";
import Tass from '../../tables';

import { DD, RD, DaD, ActionsTable, CountPicker, PaginationBasic } from "../../components/ddTest.js";
import React, { Component, useCallback, useState } from "react";
import { FormsTable } from "../../tables/formsTable/FormsTable"
import { store } from "../../tables/store"
import { Provider } from "react-redux";

const FormsCreateNew = () => {
   const [count, setCount] = useState(0);

   const handleChangeCount = useCallback((value) => {
      setCount(value);
   }, [])

   return (
      <div className="container-fluid" id="main-page-block">
         <div id="page-content">
            <div>
               <h2>
                  Анкеты <span id="sup">{count ? count : ""}</span>
               </h2>
               {/* <Alert key={'primary'} variant={'primary'}>
                  После редактирования ранее сохраненной анкеты, изменения отображаются спустя 1-2 минуты. 
                  </Alert> */}
               {<Provider store={store}>
                  <FormsTable url={global.apiUrl + '/forms/list/'} token={sessionStorage.getItem('token')} updateCount={handleChangeCount} />
               </Provider>}

            </div>
         </div>
      </div>
   );
}
export default FormsCreateNew;
