import { createAsyncThunk, createSlice, Draft } from "@reduxjs/toolkit";
import api from "../../api";
import {
  FilterTypes,
  IQuestionnairesFilters,
  IQuestionnairesState,
  IResponseInit,
} from "./types";

interface IRequestProps {
  url: string;
  token: string;
  filter: any;
  filters: { id: number; status: number }[];
  limit: number;
  offset: number;
}

export const lsTableFiltersKey = "FORMS_TABLE_FILTERS";
export const VISIT_STATUS_FILTER_KEY = "VISIT_STATUS_FILTER_KEY";
export const lsTableUrlKey = "TABLE_URL";

const initFilters = {
  researches: [],
  users: [],
  pc: "",
};

const getFilter = (key: string) => {
  let filter = initFilters;

  const storagedFilters = localStorage.getItem(key);

  if (storagedFilters) {
    filter = JSON.parse(storagedFilters);
  }

  return filter;
};

const getVisitFilters = (key: string) => {
  let filter = [];

  const storagedFilters = localStorage.getItem(key);

  if (storagedFilters) {
    filter = JSON.parse(storagedFilters);
  }

  return filter;
};

const initialState: IQuestionnairesState = {
  currentUrl: localStorage.getItem(lsTableUrlKey)
    ? localStorage.getItem(lsTableUrlKey)
    : "",
  loader: false,
  selectedQuestionnaire: [],
  selectAll: false,
  token: "",
  initData: {
    UrlToFilter: "",
    fields: {},
    dropdownfilter: [],
    useSearch: false,
    usePaginator: false,
    actionButtons: [],
    useTabs: false,
    tabs: {},
    availableSorts: [],
    globalActionTable: {},
    fastFilters: [],
    content: [],
    allowFilterByDate: false,
    filters: {},
    count: 0,
  },
  content: [],
  selectors: [],
  filters: getFilter(lsTableFiltersKey),
  visitFilters: getVisitFilters(VISIT_STATUS_FILTER_KEY),
  limit: 10,
  offset: 0,
};

export const getInitTable = createAsyncThunk(
  "forms-table/getInitTable",
  async ({ url, token, filter, filters, limit, offset }: IRequestProps) => {
    const user = JSON.parse(token);
    const res = await api.post(url, {
      ...user,
      filter,
      filters,
      limit,
      offset,
    });
    return { data: res.data.response, filters: filter, limit, offset };
  }
);

export const sendFilterData = createAsyncThunk(
  "forms-table/sendFilterData",
  async ({ data }: { data: any }, { getState }) => {
    try {
      const { formsTable: table } = getState() as any;
      const { token, filters, limit } = table;
      const defaultUrl = table.currentUrl;

      const user = JSON.parse(token);

      const dataToSend: any = {
        ...user,
        limit,
        filter: { ...filters },
      };

      if (data.researches) {
        dataToSend.filter.researches = data.researches;
      }
      if (data.users) {
        dataToSend.filter.users = data.users;
      }

      return await api
        .post<{ message: string; response: IResponseInit }>(
          defaultUrl,
          dataToSend
        )
        .then((r) => {
          localStorage.setItem(
            lsTableFiltersKey,
            JSON.stringify(dataToSend.filter)
          );
          return {
            data: r.data.response.content,
            filters: dataToSend.filter,
          };
        });
    } catch (e: any) {}
  }
);

export const deleteItem = createAsyncThunk(
  "forms-table/deleteItem",
  async ({ id, url }: { id: string; url: string }, { getState }) => {
    try {
      const { table } = getState() as any;
      const res = await api.post(url, {
        id: id,
      });
      return res.data;
    } catch (e) {}
  }
);

const questionnairesSlice = createSlice({
  name: "forms-table",
  initialState,
  reducers: {
    setSelectAll: (
      state: Draft<{ selectAll }>,
      { payload }: { payload: boolean }
    ) => {
      state.selectAll = payload;
    },
    setSelectItem: (
      state: Draft<{ selectedQuestionnaire }>,
      { payload }: { payload: any }
    ) => {
      state.selectedQuestionnaire = payload;
    },
    setFilterTabs: (
      state: Draft<{ content }>,
      { payload }: { payload: any[] }
    ) => {
      state.content = payload;
    },
    setUrl: (state, { payload }) => {
      state.currentUrl = payload;
      localStorage.setItem(lsTableUrlKey, payload);
    },
    setToken: (state, { payload }: { payload: string }) => {
      state.token = payload;
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
      state.offset = 0;
    },
    setOffset: (state, { payload }) => {
      state.offset = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
      state.offset = 0;
      localStorage.setItem(lsTableFiltersKey, JSON.stringify(payload));
    },
    setVisitFilters: (state, { payload }) => {
      state.visitFilters = payload;
      state.offset = 0;
      localStorage.setItem(VISIT_STATUS_FILTER_KEY, JSON.stringify(payload));
    },
    removeFilter: (state, { payload }: { payload: IQuestionnairesFilters }) => {
      state.filters = state.filters.filter((el) => {
        if (el.type === payload.type) {
          if (el.type === FilterTypes.searchValue) {
            return false;
          } else {
            return el.id !== payload.id;
          }
        } else return true;
      });
      if (state.filters.length === 0) {
        localStorage.removeItem(lsTableFiltersKey);
      }
    },
    clearFilters: (state) => {
      state.filters = initFilters;
      state.visitFilters = [];
      localStorage.removeItem(lsTableFiltersKey);
      localStorage.removeItem(VISIT_STATUS_FILTER_KEY);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitTable.pending, (state: Draft<{ loader }>) => {
      state.loader = true;
    });
    builder.addCase(
      getInitTable.fulfilled,
      (
        state: Draft<{ loader; initData; content; filters; limit; offset }>,
        {
          payload,
        }: {
          payload: {
            data: IResponseInit;
            filters: any;
            limit: number;
            offset: number;
          };
        }
      ) => {
        state.loader = false;
        state.initData = payload.data;
        state.content = payload.data?.content ?? [];
        state.filters = payload.filters;
        state.limit = payload.limit;
        state.offset = payload.offset;
      }
    );
    builder.addCase(getInitTable.rejected, (state: Draft<{ loader }>) => {
      state.loader = false;
    });
    builder.addCase(
      sendFilterData.pending,
      (state: Draft<{ loader; offset }>) => {
        state.loader = true;
      }
    );
    builder.addCase(
      sendFilterData.fulfilled,
      (state: Draft<{ loader; initData; content; filters }>, { payload }) => {
        state.loader = false;
        state.initData.content = payload.data;
        state.content = payload.data;
        state.filters = payload.filters;
      }
    );
    builder.addCase(sendFilterData.rejected, (state: Draft<{ loader }>) => {
      state.loader = false;
    });
  },
});

export const {
  setSelectAll,
  setSelectItem,
  setFilterTabs,
  setVisitFilters,
  setToken,
  setLimit,
  setOffset,
  setFilters,
  removeFilter,
  clearFilters,
  setUrl,
} = questionnairesSlice.actions;

export default questionnairesSlice.reducer;
