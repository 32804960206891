import React from "react";
import IconSearch from "../icons/search";

import "./style.scss";

class SearchInput extends React.Component {
   render() {
      const {value, onChange} = this.props;
      return (
         <div className={"chat-searcInput " + this.props.className}>
            <IconSearch />
            <input
               className="chat-searcInput_field"
               type="text"
               placeholder="Поиск"
               value={value}
               onChange={onChange}
               style={{
                  border: "none",
                  padding: "0px",
                  borderRadius: "0px"
               }}
            />
         </div>
      );
   }
}

export default SearchInput;
