import IMAGES from "../../../images/iconSet";

export default [
  {
    id: "1",
    title: "Раздел",
    icon: IMAGES.icons.chapter,
    obj: {
      type: "chapter",
      options: {
        title: "Раздел",
        note: "",
        data: {
          items: [],
        },
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "2",
    title: "Текст",
    icon: IMAGES.icons.text,
    obj: {
      type: "textarea",
      options: {
        title: "Вопрос",
        note: "",
        required: true,
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "10",
    title: "Дата",
    icon: IMAGES.icons.date,
    obj: {
      type: "date",
      options: {
        title: "Дата",
        note: "",
        required: true,
        data: {
          showCalendar: false,
          isLimit: false,
          limitFrom: null,
          limitTo: null,
        },
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "3",
    title: "Время",
    icon: IMAGES.icons.time,
    obj: {
      type: "textarea",
      options: {
        fieldType: "time",
        title: "Время",
        note: "",
        required: true,
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "4",
    title: "Таблица",
    icon: IMAGES.icons.table,
    type: "table",
    obj: {
      type: "table",
      options: {
        title: "Таблица",
        note: "",
        data: {
          headers: [
            {
              id: null,
              text: "Столбец №1",
              type: "title",
            },
            {
              id: null,
              text: "Столбец №2",
              type: "radio",
            },
          ],
          rows: [],
        },
        required: true,
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "5",
    title: "Число",
    icon: IMAGES.icons.number,
    type: "number",
    obj: {
      type: "number",
      options: {
        title: "Вопрос",
        note: "",
        required: true,
        data: {
          unit: "балл",
          isLimit: true,
          limitFrom: 0,
          limitTo: 100,
          commasLimit: 0,
        },
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "6",
    title: "Чекбокс",
    icon: IMAGES.icons.checkbox,
    type: "checkbox",
    obj: {
      type: "checkbox",
      options: {
        title: "Вопрос",
        note: "",
        required: true,
        data: {
          layout: "row",
          items: [
            {
              initialValue: false,
              text: "",
              id: null,
            },
          ],
        },
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "7",
    title: "Радио",
    icon: IMAGES.icons.radio,
    type: "radio",
    obj: {
      type: "radio",
      options: {
        title: "Вопрос",
        note: "",
        required: true,
        data: {
          layout: "row",
          items: [
            {
              initialValue: false,
              text: "",
              id: null,
            },
          ],
        },
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "8",
    title: "Список",
    icon: IMAGES.icons.list,
    type: "select",
    obj: {
      type: "list",
      options: {
        title: "Вопрос",
        note: "",
        required: true,
        data: [
          {
            id: null,
            value: "",
          },
        ],
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
  {
    id: "9",
    title: "Шкала",
    icon: IMAGES.icons.bar,
    type: "scale",
    obj: {
      type: "scale",
      options: {
        title: "Вопрос",
        note: "",
        required: true,
        data: [
          {
            id: null,
            value: "",
          },
        ],
        notification: {
          isNotify: false,
          notifyDate: undefined,
        },
        conditions: [],
      },
    },
  },
];
