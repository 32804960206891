import { io } from "socket.io-client";
import store from "./store/store";

import { getUserInfo } from "./components/userComponent/services/userInfo";
import { customFetch } from "./utils/http";
import { getTokenString } from "./utils/getToken";

const chat = () => {
  store.dispatch({
    type: "CHAT_LOADING",
    payload: true,
  });

  store.dispatch({
    type: "CHAT_CONNECT",
    payload: new io("/", {
      transports: ["websocket"],
      path: "/chat",
    }),
  });

  const socket = store.getState().io;

  let token;
  try {
    //token = JSON.parse(sessionStorage.getItem("token"));
    token = getTokenString();
  } catch { }

  if (socket && token) {
    socket.on("connect", () => {
      socket.off("connect");
      socket.emit("user.auth", {
        //id: token.insecureid,
        token,
      });
    });

    socket.on("on.user.online", (data) => {
      if (data.success) {
        store.dispatch({
          type: "SET_ONLINE_USERS",
          payload: data.users,
        });
      }
    });

    socket.on("on.user.authorized", (data) => {
      socket.off("on.user.authorized");

      if (data.success) {
        const { dialogs, user } = data;
        let contacts = [];

        getUserInfo(user.id, ({ role }) => (user.role = role));

        customFetch(global.apiUrl + "/data/users", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            try {
              response.text().then(async (text) => {
                const users = JSON.parse(text);

                const withChat = [];
                const justUsers = [];
                const doctors = [];
                const moderators = [];
                const monitors = [];
                const admins = [];
                const superAdmins = [];
                const removed = [];

                let researches = [];

                researches = await customFetch(
                  global.apiUrl + "/research/list/",
                  {
                    method: "post",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                );

                researches = await researches.json();

                if (researches.content) {
                  if (researches.content.length > 0) {
                    researches = researches.content;
                  } else {
                    researches = [];
                  }
                } else {
                  researches = [];
                }

                let i = 0;
                for (const research of researches) {
                  const resp = await customFetch(
                    global.apiUrl + "/research/listtable/",
                    {
                      method: "post",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        id: research.id,
                      }),
                    }
                  );
                  const researchUsers = await resp.json();

                  const stringToIntArray = (string) => {
                    return string.split(",").map((ids) => parseInt(ids));
                  };

                  const resAdmins = stringToIntArray(researchUsers.admins);
                  const resModerators = stringToIntArray(
                    researchUsers.moderators
                  );
                  const resMonitor = stringToIntArray(researchUsers.monitor);
                  const resDoctors = stringToIntArray(researchUsers.doctors);

                  researches[i] = {
                    ...researches[i],
                    admins: resAdmins,
                    moderators: resModerators,
                    monitor: resMonitor,
                    doctors: resDoctors,
                  };
                  i++;
                }

                users.forEach((tmpUser, i) => {
                  const userToContact = ({
                    id,
                    name,
                    surname,
                    patronymic,
                    spec,
                    picture,
                    role,
                  }) => {
                    picture = picture === "null" ? null : picture;
                    picture = picture === "undefined" ? undefined : picture;

                    let roomId = null;
                    let lastUpdate = null;
                    let messages = [];

                    dialogs.forEach((dialog) => {
                      dialog.room.users.forEach((user) => {
                        if (user.id === id) {
                          roomId = dialog.room.id;
                          lastUpdate = dialog.chat.updated_at;
                          messages.push(dialog.message);
                        }
                      });
                    });

                    return {
                      id,
                      name,
                      surname,
                      patronymic,
                      spec,
                      avatar: picture,
                      role,
                      roomId,
                      lastUpdate,
                      messages,
                    };
                  };

                  tmpUser = userToContact(tmpUser);

                  users[i] = tmpUser;

                  if (tmpUser.roomId && tmpUser.id !== token.insecureid) {
                    withChat.push(tmpUser);
                  } else {
                    researches.forEach((research) => {
                      if (
                        research.admins.indexOf(tmpUser.id) > -1 ||
                        research.moderators.indexOf(tmpUser.id) > -1 ||
                        research.monitor.indexOf(tmpUser.id) > -1 ||
                        research.doctors.indexOf(tmpUser.id) > -1
                      ) {
                        if (parseInt(tmpUser.role) === 0)
                          justUsers.push(tmpUser);
                        if (parseInt(tmpUser.role) === 1) doctors.push(tmpUser);
                        if (parseInt(tmpUser.role) === 2)
                          moderators.push(tmpUser);
                        if (parseInt(tmpUser.role) === 3)
                          monitors.push(tmpUser);
                        if (parseInt(tmpUser.role) === 4) admins.push(tmpUser);
                        if (parseInt(tmpUser.role) === 5)
                          superAdmins.push(tmpUser);
                        if (parseInt(tmpUser.role) === 6) removed.push(tmpUser);
                      }
                    });
                  }
                });

                contacts = [...withChat];

                if (user.role === 0) {
                  contacts = [...contacts, ...moderators];
                }
                if (user.role === 1) {
                  contacts = [
                    ...contacts,
                    ...justUsers,
                    ...admins,
                    ...moderators,
                    ...monitors,
                  ];
                }
                if (user.role === 2) {
                  contacts = [...contacts, ...doctors, ...admins, ...monitors];
                }
                if (user.role === 3) {
                  contacts = [
                    ...contacts,
                    ...doctors,
                    ...admins,
                    ...moderators,
                  ];
                }
                if (user.role === 4) {
                  contacts = [
                    ...contacts,
                    ...doctors,
                    ...moderators,
                    ...monitors,
                  ];
                }
                if (user.role === 5) {
                  contacts = [...contacts, ...users];
                }
                if (user.role === 6) {
                  contacts = [...contacts, ...superAdmins];
                }

                // ⬜ TODO: сделать сортировку

                // contacts.sort((a,b)=>{
                //     if (a.)
                // });

                const unreadCount = dialogs.reduce((prev, current) => {
                  return prev + current.chat.unread_count;
                }, 0);

                const storedDialogs = dialogs.sort(
                  (a, b) => b.chat.unread_count - a.unread_count
                );

                store.dispatch({
                  type: "CHAT_AUTH",
                  payload: {
                    dialogs: storedDialogs,
                    contacts,
                    user,
                    unreadCount,
                  },
                });

                store.dispatch({
                  type: "CHAT_LOADING",
                  payload: false,
                });
              });
            } catch {
              console.error("Ошибка при прочтении данных");
              store.dispatch({
                type: "CHAT_LOADING",
                payload: false,
              });
            }
          })
          .catch((err) => {
            store.dispatch({
              type: "CHAT_LOADING",
              payload: false,
            });
            console.log(err);
          });
      } else {
        /*    store.dispatch({
                   type: "PUSH_NOTIFICATION",
                   payload: {
                      type: "error",
                      title: "Ошибка авторизации",
                      text: "Не удалось подключиться к серверу для получения информации о чатах и сообщениях. Попробуйте перезагрузить страницу или обратиться в службу поддержки."
                   }
                }); */
        store.dispatch({
          type: "CHAT_LOADING",
          payload: false,
        });
      }
    });

    socket.on("on.message.new", (data) => {
      const user = data.room.users.find((user) => user.id !== token.insecureid);
      store.dispatch({
        type: "NEW_MESSAGE",
        payload: {
          contactId: user.id,
          roomId: data.room.id,
          message: data.message,
          chat: data.chat,
        },
      });

      //  store.dispatch({
      //     type: "PUSH_NOTIFICATION",
      //     payload: {
      //        type: "info",
      //        text: "У вас новое сообщение"
      //     }
      //  });
    });

    socket.on("on.chat.history", (data) => {
      store.dispatch({
        type: "UPDATE_CHAT",
        payload: {
          contactId: store.getState().selectedChatId,
          messages: data.messages,
        },
      });
    });
  }
};

export default chat;
