import "./index.scss";

import Breadcrumbs from "../../components/breacrumbs";
import IconButton from "../../components/inputs/buttons/buttonIcon";
import User from "../../components/user";
import icons from "../../images/iconSet";

function Header(props) {
  const userInfo = props.user;

  return (
    <header className="header">
      <Breadcrumbs paths={props.breadcrumbs} />
      <div className="header--menu">
        {/*   <IconButton link="/notifications/" notification={true} pathIcon={icons.icons.notification} /> */}
        <IconButton
          link="/messages/"
          notification={false}
          pathIcon={icons.icons.message}
        />
        <User
          userAvatar={userInfo?.picture}
          userName={userInfo?.name}
          userSurame={userInfo?.surname}
          userId={userInfo?.id}
        />
      </div>
    </header>
  );
}

export default Header;
