import React from "react";
import IMAGES from "../../../images/iconSet";

import "./index.scss";

class Checkbox extends React.Component {
  render() {
    return (
      <label className="mh-checkbox" style={this.props.style}>
        <input
          type="checkbox"
          defaultChecked={this.props.defaultChecked}
          checked={this.props.value}
          onChange={this.props.onChange}
          className="mh-checkbox_item"
        />
        <div className="mh-checkbox_field">
          {this.props.value ? <img src={IMAGES.constructor.checbox} /> : ""}
        </div>
        {this.props.children}
      </label>
    );
  }
}

export default Checkbox;
