import { FC, useEffect, useState } from "react";
import { Button, CloseButton, Form, Modal } from "react-bootstrap";
import trashSvgUrl from "../../images/constructor/trash.svg";
import styles from "./NotificationModal.module.scss";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import IMAGES from "../../images/iconSet";

type Notification = {
  notifyMail: string[];
  isNotify: boolean;
};

interface Props {
  open: boolean;
  onClose: () => void;
  items: {
    initialValue: boolean;
    text: string;
    id: string;
    notification?: Notification;
  }[];
  title: string;
  onNotificationChange: (items: Props["items"]) => void;
}

const NotificationModal: FC<Props> = ({
  open,
  onClose,
  items,
  title,
  onNotificationChange,
}) => {
  const [selectors, setSelectors] = useState<
    { value: string; items: Props["items"] }[]
  >([]);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const selectors = [];
    let notifyMail = [];

    items.forEach((item) => {
      if (item?.notification?.isNotify) {
        selectors.push({ value: item.id, items });
        notifyMail = item.notification.notifyMail;
      }
    });

    setSelectors(selectors);
    setEmails(notifyMail);
  }, [items]);

  const handleChange = (value: string, index: number) => {
    setSelectors((prev) =>
      prev.map((s, i) => {
        if (i !== index) return s;
        return { ...s, value };
      })
    );
  };

  const handleDelete = (index: number) => {
    setSelectors((prev) => prev.filter((_, i) => i !== index));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const activeSelectors = selectors.filter((s) => s.value);
    const updatedItems = items.map((item) => {
      const selector = activeSelectors.find((s) => s.value === item.id);
      if (selector) {
        return {
          ...item,
          notification: {
            title: title,
            isNotify: true,
            notifyMail: emails,
          },
        };
      }
      return item;
    });
    onNotificationChange(updatedItems);
    onClose();
  };

  const onDeleteNotification = () => {
    const updatedItems = items.map((item) => ({
      ...item,
      notification: { isNotify: false, notifyMail: [] },
    }));
    onNotificationChange(updatedItems);
    onClose();
  };

  return (
    <Modal show={open} onHide={onClose} contentClassName={styles.container}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton className={styles.header}>
          <Modal.Title className={styles.title}>
            Настройки уведомлений
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <Form.Group>
            <Form.Label style={{ fontWeight: "bold" }} className="mb-0">
              Уведомлять если
            </Form.Label>
            {selectors.map((s, i) => (
              <div key={i} className="d-flex align-items-center gap-3">
                <Form.Select
                  className="my-2"
                  value={s.value}
                  onChange={(e) => handleChange(e.target.value, i)}
                >
                  <option>Выберите условие</option>
                  {s.items.map((el) => (
                    <option value={el.id} key={el.id}>
                      {el.text}
                    </option>
                  ))}
                </Form.Select>
                <button
                  type="button"
                  className={styles.closeButton}
                  onClick={() => handleDelete(i)}
                >
                  <img src={IMAGES.icons.closeRed} alt="delete" />
                </button>
              </div>
            ))}
          </Form.Group>
          <Button
            className={styles.addSelectorButton}
            variant="light"
            onClick={() =>
              setSelectors((prev) => [...prev, { value: "", items }])
            }
          >
            + Условие
          </Button>
          <div className="mt-3">
            <span style={{ fontWeight: "bold" }}>Почта для уведомлений</span>
            <ReactMultiEmail
              emails={emails}
              onChange={(_emails: string[]) => {
                setEmails(_emails);
              }}
              className={styles.multiInput}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div
                    data-tag
                    key={index}
                    style={{ backgroundColor: "#EDF4FB" }}
                  >
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.buttons}>
          <Button
            type="submit"
            variant="primary"
            className="save-visit"
            disabled={selectors.every((s) => !s.value) || emails.length === 0}
          >
            Сохранить
          </Button>
          <Button
            variant="danger"
            className={styles.danger}
            disabled={items.every((item) => !item?.notification?.isNotify)}
            onClick={onDeleteNotification}
          >
            <img src={trashSvgUrl} alt="" />
            Удалить
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NotificationModal;
