import "./index.scss";
import React from "react";

import './index.scss';

import Checkbox from "../../../inputs/checkbox";

class CheckboxNote extends React.Component {
   render() {

      const layouClass = "mh-constructor_checkBoxes"+(this.props.data.layout == "column" ? "" : " mh-constructor_checkBoxes__row")

      return (
         <div className={layouClass}>
            {
               this.props.data.items.map((item, i) => (
                  <Checkbox key={i} value={item.initialValue} onChange={()=>{}}>{item.text}</Checkbox>
               ))
            }
         </div>
      );
   }
}

export default CheckboxNote;
