import "./index.scss";
import React from "react";
import { Link } from "react-router-dom";

class PopupHeaderMenu extends React.Component {
  handleClick(){
    sessionStorage.clear();
    localStorage.clear();
   
  }
   render() {
      return (
         <div className={this.props.shown ? "popup show" : "popup hide"}>
            <div className="popup--list">
               <div className="modal-header">
               {this.props.userName} {this.props.userSurname} 
               </div>
               <Link to="/profile/" className="popup--list--item light">
                  Профиль
               </Link>
               <a href = "/" onClick = {() => this.handleClick()} className="popup--list--item light popup--list--item--exit">
                  Выход
               </a>
            </div>
         </div>
      );
   }
}

export default PopupHeaderMenu;
