import React from "react";
import IMAGES from "../../../../images/iconSet";

import './style.scss';

import templates from "../templates";

class ModalAddBlock extends React.Component {
    render () {
        return (
            <div className="mh-modalAddBlock">
                {templates.map((block, i) => (
                    this.props.hasParent !== undefined && i === 0 ? "" : (
                        <div
                            className="mh-modalAddBlock_item"
                            key={i}
                            onClick={()=>this.props.addBlock(block)}
                        >
                            <img className="mh-modalAddBlock_itemImage" src={block.icon} alt="" />
                            <span className="mh-modalAddBlock_itemTitle">{block.title}</span>
                            <img className="mh-modalAddBlock_itemAdd" src={IMAGES.constructor.addFromModal} alt="" />
                        </div>
                    )
                ))}
            </div>
        );
    }
}

export default ModalAddBlock;