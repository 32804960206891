import React from "react";
import styles from "./placeholderTable.module.scss";
import Placeholder from 'react-bootstrap/Placeholder';

export function CreateFormPlaceholder() {
    return (
        <div className="container-fluid" id="main-page-block">
            <div id="createForm-container">
                <div id="page-content">
                    <div>

                        <Placeholder as="h2" animation="glow">
                            <Placeholder xs={2} size="lg" />
                        </Placeholder>

                        <Placeholder as="p" animation="glow">
                            <Placeholder xs={1} />
                        </Placeholder>

                        <div className="tab-content">
                            <div role="tabpanel" id="react-aria7095674053-4-tabpane-visit1" aria-labelledby="react-aria7095674053-4-tab-visit1" className="fade buildTheTabs tab-pane active show">
                                <form id="1" className="">
                                    <div className="parseNumber answer-wrapper">
                                        <Placeholder as="p" animation="glow">
                                            <Placeholder xs={1} /> <Placeholder xs={3} />
                                        </Placeholder>
                                        <div className="parseNumber-title">
                                            <Placeholder as="h2" animation="glow">
                                                <Placeholder xs={2} />
                                            </Placeholder>
                                        </div>
                                        <div>
                                            <Placeholder as="p" animation="glow">
                                                <Placeholder xs={3} size="lg" />
                                            </Placeholder>
                                        </div>
                                    </div>
                                    <div className="answer-wrapper">
                                        <Placeholder as="p" animation="glow">
                                            <Placeholder xs={1} /> <Placeholder xs={3} />
                                        </Placeholder>
                                        <div className="parseNumber-title">
                                            <Placeholder as="h2" animation="glow">
                                                <Placeholder xs={2} />
                                            </Placeholder>
                                        </div>
                                        <div>
                                            <Placeholder as="p" animation="glow">
                                                <Placeholder xs={3} size="lg" />
                                            </Placeholder>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="AboutForm_wrapper__MB2cf">
                    <div className="AboutForm_content__bVWKf">
                        <Placeholder as="h2" animation="glow">
                            <Placeholder xs={3} size="lg" />
                        </Placeholder>
                        <div className="AboutForm_params__m97Pl">
                            <Placeholder xs={12} size="sm" />
                            <Placeholder xs={10} size="xs" />
                            <Placeholder xs={11} size="sm" />
                            <Placeholder xs={6} size="xs" />
                        </div>
                        <Placeholder.Button xs={6} aria-hidden="true" />
                    </div>
                </div>
            </div>
        </div>
    );
}