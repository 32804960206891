import React from "react";

import "./index.scss";

import icons from "../../images/iconSet";

import { Link } from "react-router-dom";

class Breadcrumbs extends React.Component {
   render() {
      const { paths } = this.props;
      return (
         <div className="header--breadcrumbs regular">
            {paths.map((item, i) => (
               <span key={i}>
                  <Link to={item.url} className="header--breadcrumbs--link">
                     {item.title}
                  </Link>
                  {i < paths.length - 1 && paths.length > 1 ? (
                     <img src={icons.icons.chewron} alt="" className="header--breadcrumbs--arrow" />
                  ) : (
                     ""
                  )}
               </span>
            ))}
         </div>
      );
   }
}

export default Breadcrumbs;
