import React, { useState } from "react";
import { ThreeDots } from "../assets/icons";
import { IDropOption, paramsTypes } from "../table/components/RowMenu";
import { IParam } from "../table/store/types";

interface IDropDownProps {
  onClick: (item: IParam) => void;
  options: IDropOption[];
}

export const DropDown = ({ options, onClick }: IDropDownProps) => {
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleClick = (item: IParam) => {
    setVisible(false);
    onClick(item);
  };

  return (
    <div className={"drop-down"}>
      <div className={"drop-down__button"} onClick={() => setVisible(!isVisible)}>
        <ThreeDots width={5} height={16} />
      </div>
      {isVisible ? (
        <ul className={"drop-down__list"}>
          {options.map((i) => {
            if (i.type === paramsTypes.external) {
              return (
                <a target="_blank" download href={i.downloadUrl} className={"drop-down__item"} rel="noreferrer">
                  {i.title}
                </a>
              );
            }
            return (
              <li key={i.title} className={"drop-down__item"} onClick={() => handleClick(i)}>
                {i.title}
              </li>
            );
          })}
        </ul>
      ) : null}
      {isVisible ? <div className={"selector-overlay"} onClick={() => setVisible(false)} /> : null}
    </div>
  );
};
