import { customFetch } from "../../../utils/http";

export const getCurrentUser = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: sessionStorage.getItem("token"),
    redirect: "follow",
  };
  const res = await customFetch(global.apiUrl + "/user/list", requestOptions);
  const resData = await res.json();

  return resData;
};

export const getUserInfo = async (id, container) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: sessionStorage.getItem("token"),
    redirect: "follow",
  };
  const res = await customFetch(global.apiUrl + "/user/list", requestOptions);
  const resData = await res.json();

  return await container({ ...resData });
};

export const getUserInfoById = async (id, container) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ id }),
    redirect: "follow",
  };
  const res = await customFetch(global.apiUrl + "/user/edit", requestOptions);
  const resData = await res.json();

  return await container({ ...resData });
};

export const changeUserInfo = async (id, data, handleError) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  customFetch(global.apiUrl + "/user/update", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => handleError(true));
};
