import { FC, memo, useState } from "react";

import md5 from "md5";
import { Button, Col, Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import trashSvgUrl from "../../images/constructor/trash.svg";
import calcIcon from "../../images/icons/calculator.svg";
import editIcon from "../../images/icons/edit.svg";
import fileSvgUrl from "../../images/icons/file.svg";
import pdfSvgUrl from "../../images/icons/pdf.svg";
import pptSvgUrl from "../../images/icons/ppt.svg";
import saveIcon from "../../images/icons/save.svg";
import wordSvgUrl from "../../images/icons/word.svg";
import xlSvgUrl from "../../images/icons/xlsx.svg";
import { formatBytes } from "../../utils/helpers";
import EGFRCalculator from "../calculator/Calculator";
import styles from "./AboutForm.module.scss";

const icons = {
  ".xlsx": xlSvgUrl,
  ".xls": xlSvgUrl,
  ".csv": xlSvgUrl,
  ".ods": xlSvgUrl,
  ".pdf": pdfSvgUrl,
  ".ppt": pptSvgUrl,
  ".docx": wordSvgUrl,
  ".docm": wordSvgUrl,
  ".doc": wordSvgUrl,
};

var month = [
  "янв",
  "фев",
  "мар",
  "апр",
  "мая",
  "июн",
  "июл",
  "авг",
  "сен",
  "окт",
  "ноя",
  "дек",
];

interface File {
  target: string;
  size: number;
  source: string;
  extension: string;
}

interface Props {
  data: {
    admins: string;
    content: string;
    analyst: string;
    announce: string;
    date_start: string;
    doctors: string;
    id: number;
    limits: string;
    moderators: string;
    monitor: string;
    name: string;
    object: string;
    owner: number;
    regions: string;
    requestor: string;
    status: number;
    until_expired: string;
  };
  formId: string;
  dataId: string;
  handleSave: () => void;
  handleDelete: () => void;
  handleSend: () => void;
  handleSilentSave: () => void;
  handleEnableEditMode: () => void;
  ableToSave: boolean;
  ableToSend: boolean;
  ableToEdit: boolean;
  ableToDelete: boolean;
  files: File[];
}

function ParseFiles({ files }: { files: File[] }) {
  /* console.log(props.files); */
  const listItems = (
    <div className={styles.files}>
      {files.map((file, index) => (
        <div key={index} className={styles.file}>
          <img src={icons[file.extension.toLowerCase()] || fileSvgUrl} alt="" />
          <div className={styles.fileContent}>
            <span className={styles.name}>{file.target}</span>
            <div className={styles.contentRow}>
              <span className={styles.size}>{formatBytes(file.size)}</span>
            </div>
          </div>
          <a
            target="_blank"
            download
            href={`${global.apiUrl}/download?source=${file.source}&target=${file.target}`}
            className={styles.download}
            rel="noreferrer"
          >
            Скачать
          </a>
        </div>
      ))}
    </div>
  );
  return <div className={styles.files}>{listItems}</div>;
}

const AboutFormComponent: FC<Props> = ({
  data,
  formId,
  dataId,
  handleSave,
  handleSend,
  handleDelete,
  handleEnableEditMode,
  handleSilentSave,
  ableToSave,
  ableToSend,
  ableToDelete,
  ableToEdit,
  files,
}) => {
  const [showCalculator, setShowCalculator] = useState(false);

  const date_until =
    new Date(data.until_expired).getDate() +
    " " +
    month[new Date(data.until_expired).getMonth()] +
    " " +
    new Date(data.until_expired).getFullYear();
  const dataUuid = dataId;
  const dateOptions = {
    weekday: "short",
    year: "2-digit",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const dateTo = data.until_expired;

  /* console.log("visit current: " + visitId); */
  var scope = JSON.parse(sessionStorage.getItem("token")).role;

  const isActive =
    !dateTo ||
    dateTo === "undefined" ||
    dateTo === "null" ||
    new Date().getTime() < new Date(dateTo).getTime();

  return (
    <>
      <Col md={4} className={styles.wrapper}>
        <div className={styles.content}>
          <span className={styles.title}>{data.announce}</span>
          <div className={styles.params}>
            {data.object && <span>Объект исследования: {data.object}</span>}
            {data.until_expired != "undefined" ||
              (data.until_expired != null && (
                <span>Дата окончания: {date_until}</span>
              ))}

            {/*data.content.ts && <span>Дата анкеты: {new Date(Date.parse(data.content.ts)).toLocaleDateString('ru-RU', dateOptions)}</span>*/}
          </div>

          <ParseFiles files={files} />
          {(data.id == 132 || data.id == 138) && (
            <div className={`mt-4 ${styles.calculator}`}>
              <span className={styles.label}>Калькуляторы</span>
              <div className="d-flex mt-2">
                <Button
                  className={styles.button}
                  onClick={() => setShowCalculator(true)}
                >
                  <img src={calcIcon} className="me-2" alt="" />
                  Расчет СКФ
                </Button>
              </div>
            </div>
          )}
          {md5(0) != scope && //скрыть кнопки у пользователя с ролью "Пользователь"
            (md5(1) == scope ? isActive : true) && ( //скрыть кнопки у пользователя с ролью "Врач" если исследование завершено
              <div className={styles.buttons}>
                <Button
                  variant="light"
                  className="save-visit"
                  onClick={handleSave}
                  disabled={!ableToSave}
                >
                  Сохранить
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSend}
                  disabled={!ableToSend}
                  style={{ width: 106 }}
                >
                  Отправить
                </Button>
              </div>
            )}
          {(md5(4) == scope || md5(5) == scope || md5(2) == scope) && (
            <Button
              variant="light"
              className="save-visit mt-3"
              onClick={handleSilentSave}
              style={{ borderRadius: 19 }}
            >
              Отправить без уведомлений
            </Button>
          )}
          <div className="d-flex gap-3 align-items-center mt-3">
            {md5(5) == scope && ( //скрыть кнопки у пользователя с ролью "Пользователь"
              <Button
                variant="light"
                className="save-visit"
                onClick={handleEnableEditMode}
                disabled={!ableToEdit}
              >
                Редактировать
              </Button>
            )}
            {dataUuid && (
              <Button
                variant="danger"
                className={styles.danger}
                onClick={handleDelete}
                disabled={!ableToDelete}
              >
                <img src={trashSvgUrl} alt="" />
                Удалить
              </Button>
            )}
          </div>
        </div>
      </Col>
      {(data.id == 132 || data.id == 138) && (
        <Modal
          show={showCalculator}
          onHide={() => setShowCalculator(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Расчет СКФ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EGFRCalculator form_id={formId} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export const AboutForm = memo(AboutFormComponent);
