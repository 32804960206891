import { useState } from "react";
import { IAvailabel } from "../../types";

interface IAllTypesButtonProps {
  tabs: {
    availableValues: IAvailabel[];
    tabsBy: string;
  };
  onClick: (item: IAvailabel, type: string) => void;
}

export const AllTypesButtons = ({ tabs, onClick }: IAllTypesButtonProps) => {
  const { availableValues, tabsBy } = tabs;
  const buttons: IAvailabel[] = Object.values(availableValues).map((i) => i);

  const [isActive, setActive] = useState<IAvailabel>(tabs.availableValues[0]);
  const handleClick = (item: IAvailabel) => {
    setActive(item);
    onClick(item, tabsBy);
  };

  return (
    <div className={"all-types"}>
      {buttons.map((item, index: number) => (
        <button
          key={item.value}
          className={
            item.value === isActive.value
              ? "all-types__button all-types__button--active"
              : "all-types__button"
          }
          onClick={() => handleClick(item)}
        >
          {item.DisplayName}
          {/*<span className={'all-types__count'}>42</span>*/}
        </button>
      ))}
    </div>
  );
};
